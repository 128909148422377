<div class="container">
  <div class="row">
    <div class="col-lg-1 col-md-12"></div>
    <div class="col-lg-10 col-md-12  "><br><br>
      <div class="d-flex justify-content-between">
        <div>
          <h2>{{'banners' | translate}}</h2>
        </div>
        <div>
          <button class="btn btn-dark pointer"
                  (click)="uploadBanner()">{{'addNew' | translate}} {{'banner' | translate}}</button>
        </div>
      </div>
    </div>
    <div class="col-lg-1 col-md-12"></div>
  </div>
  <div class="row">
    <div class="col-lg-1 col-md-12"></div>
    <div class="col-lg-10 col-md-12"><br>
      <div class="row">
        <div class="col-lg-6 col-md-12 p-3" *ngFor="let b of banners">
          <div *ngIf="b.is_active == 1">
            <img [src]="b.image" alt="image" class="w-100 border-success">
            <br>
            <div class="d-flex justify-content-between">
              <div>
                <mat-form-field class="form_priority pt-1">
                  <mat-label>{{"priority" | translate}}</mat-label>
                  <input matInput placeholder="Priority" [(ngModel)]="b.priority"
                         (keyup.enter)="savePriority(b)"
                         type="number">
                </mat-form-field>
              </div>
              <div class="pt-3">
                <input type="button" class="btn btn-danger" value="{{'deactivate' | translate}}"
                       (click)="deactive(b.id)">
              </div>

            </div>
            <br>
          </div>
          <div *ngIf="b.is_active == 0">
            <img [src]="b.image" alt="image" class="w-100 border-danger">
            <br>
            <div class="d-flex justify-content-between">
              <div>
                <mat-form-field class="form_priority pt-1">
                  <mat-label>{{"priority" | translate}}</mat-label>
                  <input matInput placeholder="Priority" [value]="b.priority" type="number">
                </mat-form-field>
              </div>
              <div class="pt-3">
                <input type="button" class="btn btn-success" (click)="activate(b.id)"
                       value="{{'activate' | translate}}">
              </div>

            </div>
            <br>
          </div>

          <hr class="d-lg-none"/>
        </div>
      </div>
    </div>
    <div class="col-lg-1 col-md-12">
      <span></span>
    </div>
  </div>
</div>
