import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material";
import {MatDialogRef} from "@angular/material/dialog";

class DialogData {
  text: string;
}

@Component({
  selector: 'app-more-text',
  templateUrl: './more-text.component.html',
  styleUrls: ['./more-text.component.css']
})
export class MoreTextComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, public dialogRef: MatDialogRef<MoreTextComponent>) {
  }
  ngOnInit() {
  }

}
