export interface Theme {
  name: string;
  properties: any;
}

export const main: Theme = {
  name: 'main',
  properties: {
    '--white': '#FFFFFF',
    '--pink': '#B61052',
    '--ultra-pink': '#D92A73',
    '--dark': '#5C5C5C',
    '--gray': '#F0F0F0'
  }
};
