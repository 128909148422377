<div style="min-height: 93vh; background-color: #eceff1;">
  <div class="header" style="background-color: #d92a73" [ngClass]="{'hiddenHeader' : !showHeader}">
    <span style="font-size:1.7em; color:white; padding-left: 20px; font-weight: 500; ">
    {{'tutorial' | translate}} / {{'employees' | translate}}
    </span>
  </div>
  <div class="menu" [ngClass]="{'hiddenHeader' : !showHeader}"
       style="background-color: #d92a73; color:white; width: 100% !important; font-size:18px; font-weight: 500; padding-top: 15px;">
    <div class="drawer header-scroll">
      <span class="menuItem" *ngFor="let p of sectors;let i = index;" [ngClass]="{'active' : currentPage == p.id}"
            (click)="toTop(p.id)"
            style="cursor: pointer;display: inline-block ;margin: 10px 20px 0 0;width: max-content;">
            <span *ngIf="i === 0"> {{p.name}}</span>
            <span *ngIf="i > 0"> {{p.name}}</span>
      </span>
    </div>
  </div>

  <div class="stickyMenu" *ngIf="!showHeader" style="background-color: #d92a73; color:white; font-size:18px;
 font-weight: 500; padding-top: 15px; position: fixed; z-index: 3; width: 100%; top:0; display:inline;">
    <app-sticky-header class="stickyHeader" style="right: 0px; position: fixed; top: 8px;"></app-sticky-header>
    <div class="drawer header-scroll">
    <span class="menuItem" *ngFor="let p of sectors;let i = index;" [ngClass]="{'active' : currentPage == p.id}"
          id="{{p.id}}nav" (click)="toTop(p.id)"
          style=" cursor: pointer; display: inline-block ;margin: 10px 20px 0 0;width: max-content;">
            <span *ngIf="i === 0"> {{p.name}}</span>
            <span *ngIf="i > 0"> {{p.name}}</span>
      </span>
    </div>
  </div>
  <div class="main" style="margin-top: 4vh; margin-left: 4vw; margin-right: 4vw;">
    <div class="sector" *ngFor="let s of sectors" style="margin-bottom:5vh;" snInViewport
         (inViewportChange)="onInViewportChange(s.id,$event)" [id]="s.id">
      <div class="numberOfEmployees">
        <span style="color: #424242; font-size: 24px; float:left;">{{s.name}}</span>
        <div
          style="color: #747474; font-size: 18px; float:right; display: inline-flex; align-items: baseline; height: 25px;">
          {{'peopleInThisSector' | translate}}
          <div class="numberCircle">
            <span>{{s.peopleCount}} </span>
          </div>
        </div>
      </div>

      <div style="clear: both !important; float: unset;"></div>
      <div class="row" style="margin-top: 20px;">
        <div class="col-md-5 margin-container">
          <mat-card class="socialMenu"
                    style="overflow: hidden; height: 38vh; background-color: white; border-radius: 13px; padding: 0px !important;">
            <div class="row box-content">
              <div class="col-5 image-box">
                <div style="width: 70px; height: 70px; border-radius: 50%; display: flex; align-items: center;">
                  <img src="assets/rules.svg"
                       style="object-fit: contain; border-radius: 50%; width: 60px; height: 60px;">
                </div>
              </div>
              <div class="col-7" style="padding: 10px 0 0 0;">
                <span class="bannerTitle"> {{'generalRules' | translate}} </span>
                <!--                <br>-->
                <span class="bannerTitle" style="font-size: 0.8em"> {{'rulesMore' | translate}} </span>
              </div>
            </div>
            <div class="row box-content">
              <div class="col-5 image-box">
                <div style="width: 70px; height: 70px; border-radius: 50%; display: flex; align-items: center;">
                  <img src="assets/organisation.svg"
                       style="object-fit: contain; border-radius: 50%; width: 60px; height: 60px;">
                </div>
              </div>
              <div class="col-7" style="padding: 10px 0 0 0;">
                <span class="bannerTitle"> {{'organisation' | translate}} </span>
                <!--                <br>-->
                <span class="bannerTitle" style="font-size: 0.8em"> {{'organisationMore' | translate}} </span>
              </div>
            </div>
            <div class="row box-content">
              <div class="col-5 image-box">
                <div style="width: 70px; height: 70px; border-radius: 50%; display: flex; align-items: center;">
                  <img src="assets/faq.svg" style="object-fit: contain; border-radius: 50%; width: 60px; height: 60px;">
                </div>
              </div>
              <div class="col-7" style="padding: 10px 0 0 0;">
                <span class="bannerTitle"> {{'FAQ' | translate}} </span>
                <!--                <br>-->
                <span class="bannerTitle" style="font-size: 0.8em"> {{'faqMore' | translate}} </span>
              </div>
            </div>
            <div class="row box-content">
              <div class="col-5 image-box">
                <div style="width: 70px; height: 70px; border-radius: 50%; display: flex; align-items: center;">
                  <img src="assets/career.svg"
                       style="object-fit: contain; border-radius: 50%; width: 60px; height: 60px;">
                </div>
              </div>
              <div class="col-7" style="padding: 10px 0 0 0;">
                <span class="bannerTitle"> {{'career' | translate}} </span>
                <!--                <br>-->
                <span class="bannerTitle" style="font-size: 0.8em"> {{'careerMore' | translate}} </span>
              </div>
            </div>
          </mat-card>
        </div>
        <div class="col-md-7">
          <mat-card class="video" style="padding: 0px !important;  border-radius: 13px; height: 38vh;">
            <iframe style="width: 100%; height: 38vh; border-radius: 13px;" *ngIf="s.video" [src]="s.video| safe"
                    scrolling="no" frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>

