import {Component, Inject, OnInit} from '@angular/core';
import {DataService} from "../data-service.service";
import {TranslateService} from "@ngx-translate/core";
import {MAT_DIALOG_DATA} from "@angular/material";
import {MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ApiService} from "../api.service";
import {DialogData} from "../refund-dialog/refund-dialog.component";

@Component({
  selector: 'app-refund-popup',
  templateUrl: './refund-popup.component.html',
  styleUrls: ['./refund-popup.component.css']
})
export class RefundPopupComponent implements OnInit {

  translateParams = {orderId: this.data.order.id, invoiceId: this.data.order.invoiceId}
  refundTypes = [
    {id: 1, name: 'refundTypeReturn'},
    {id: 2, name: 'refundTypeRefund'},
    {id: 3, name: 'refundTypeCancelled'}
  ]
  selectedRefundType =  1;

  constructor(private dataService: DataService,private translate: TranslateService, private snackBar: MatSnackBar, private apiService: ApiService, public dialogRef: MatDialogRef<RefundPopupComponent>,
  @Inject(MAT_DIALOG_DATA) public data: DialogData,) { }

  ngOnInit() {
  }

}
