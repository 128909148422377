import {Component, Inject, OnInit} from '@angular/core';
import {Address} from "../../../models";
import {countries} from "../../../Countries";
import {inspect} from "util";
import {Colors} from "../../../Colors";
import {MAT_DIALOG_DATA} from "@angular/material";
import {MatDialogRef} from "@angular/material/dialog";
import {DialogData} from "../../../gallery/gallery.component";

@Component({
  selector: 'app-address-modal',
  templateUrl: './address-modal.component.html',
  styleUrls: ['./address-modal.component.css']
})
export class AddressModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, public dialogRef: MatDialogRef<AddressModalComponent>) { }

  colors = Colors;
  address: Address = {
    address: "",
    addressNumber: "",
    apartmentNumber: "",
    city: "",
    country: "",
    created_at: "",
    email: "",
    estate: "",
    floor: "",
    fullName: "",
    id: 0,
    number: "",
    phone: "",
    place: "",
    postalCode: "",
    type: "",
    updated_at: ""
  };

  types = [
    {id: 1, name: 'house'},
    {id: 2, name: 'work'},
    {id: 3, name: 'other'}
  ]

   readonly countries = countries;
   readonly inspect = inspect;

  ngOnInit() {
  }

  saveAddress() {
    this.dialogRef.close(this.address);
  }
}
