import {Component, Inject, OnInit} from '@angular/core';
import {ApiService} from "../api.service";
import {MAT_DIALOG_DATA} from "@angular/material";
import {DialogData} from "../supply-history/supply-history.component";

@Component({
  selector: 'app-cover-history',
  templateUrl: './cover-history.component.html',
  styleUrls: ['./cover-history.component.css']
})
export class CoverHistoryComponent implements OnInit {
  history = [];
  change = 0;
  hide: boolean;

  constructor(private apiService: ApiService, @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  ngOnInit() {
    this.hide = this.data.hide;
    this.apiService.getCoverHistory(this.data.id).subscribe(
      data => {
        const d = [];
        d['d'] = data;
        this.history = d['d'];
      }
    )
  }

  toAddChange(id) {
    this.apiService.addCoverHistory(id, this.change).subscribe(
      data2 => {
        this.apiService.getCoverHistory(this.data.id).subscribe(
          data => {
            const d = [];
            d['d'] = data;
            this.history = d['d'];
          }
        )
      });
  }

}
