import {Component, OnInit, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ApiService} from "../api.service";

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css']
})
export class ConfirmModalComponent implements OnInit {
  update = false;

  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<ConfirmModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private apiService: ApiService) {
  }

  ngOnInit() {
  }

  crudLink(): void {
    if (this.data.mode == 'other') {
      this.closeDialog(true);
    }
    if (this.data.mode === 'delete') {
      this.apiService.deleteDynamicLink(this.data.data.id).subscribe(() =>
        this.closeDialog(this.data.data)
      );
    } else if (this.data.mode === 'create') {
      this.apiService.createDynamicLink(this.data.data).subscribe(createdLink => {
        this.closeDialog(createdLink);
      })
    } else if (this.data.mode === 'update') {
      this.apiService.updateDynamicLink(this.data.data).subscribe(updateLink => {
        this.closeDialog(updateLink);
      })
    } else if (this.data.mode === 'temporally-price') {
      this.closeDialog('ok');
      // })
    } else if (this.data.mode === 'active-status') {
      this.data.data.forEach(id => {
        this.apiService.changeStatusOfNotification(id).subscribe();
      })
      this.closeDialog(true);
    }
  }

  closeDialog(data: any) {
    this.dialogRef.close(data);
  }
}
