import { Component, OnInit } from '@angular/core';
import {ApiService} from "../api.service";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-new-statistic',
  templateUrl: './new-statistic.component.html',
  styleUrls: ['./new-statistic.component.css']
})
export class NewStatisticComponent implements OnInit {

  modes = [
    {
      id: 1, name: 'allTime'
    },
    {
      id: 2, name: 'customers'
    },
    {
      id: 3, name: 'sales'
    },
    {
      id: 4, name: 'customerJourney'
    },
    {
      id: 5, name: 'campaigns'
    }
  ];

  mode = 1;
  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    const selectedMode = this.route.snapshot.paramMap.get('mode');
    if (selectedMode) {
      this.changeMode(selectedMode);
    }
  }

  changeMode(mode) {
    this.mode = mode;
    this.router.navigate([`main`, 'stat', mode]);

  }

}
