import {Component, Inject, OnInit} from '@angular/core';
import {ApiService} from "../api.service";
import {MAT_DIALOG_DATA} from "@angular/material";
export interface DialogData {
  hide: boolean;
   'id' : number;
}


@Component({
  selector: 'app-supply-history',
  templateUrl: './supply-history.component.html',
  styleUrls: ['./supply-history.component.css']
})
export class SupplyHistoryComponent implements OnInit {
  history = [];

  constructor(private apiService: ApiService, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {

    this.apiService.getSupplyHistories(this.data).subscribe(
      data => {
        const d = [];
        d['d'] = data;
        this.history = d['d'];
      }
    )
  }

}
