import { Component, OnInit } from '@angular/core';
import {ApiService} from "../api.service";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-download-by-id',
  templateUrl: './download-by-id.component.html',
  styleUrls: ['./download-by-id.component.css']
})
export class DownloadByIdComponent implements OnInit {

  ids = "";
  diff = 0;

  constructor(public dialogRef: MatDialogRef<DownloadByIdComponent>) {
  }

  ngOnInit() {
  }

  onCheck($event) {
    this.check8()
  }

  onConfirm($event) {
    this.dialogRef.close(this.ids);
  }
  //this.dialogRef.close(true);

  check8() {
    const idsList = this.ids.split(",");

    if (idsList.length < 4)
      return;

    let diff = idsList.length % 8;

    if (diff > 4)
      diff = 8 - diff;

    this.diff = diff;

    if (idsList.length == 0)
      this.diff = -1;


    //num = (a / b) >> 0;
  }
}
