import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-download-by-id6',
  templateUrl: './download-by-id6.component.html',
  styleUrls: ['./download-by-id6.component.css']
})
export class DownloadById6Component implements OnInit {

  ids = "";
  diff = 0;

  constructor(public dialogRef: MatDialogRef<DownloadById6Component>) {
  }

  ngOnInit() {
  }

  onCheck($event) {
    this.check8()
  }

  onConfirm($event) {
    this.dialogRef.close(this.ids);
  }
  //this.dialogRef.close(true);

  check8() {
    const idsList = this.ids.split(",");

    if (idsList.length < 3)
      return;

    let diff = idsList.length % 6;

    if (diff > 3)
      diff = 6 - diff;

    this.diff = diff;

    if (idsList.length == 0)
      this.diff = -1;


    //num = (a / b) >> 0;
  }
}
