import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material";
import {MatDialogRef} from "@angular/material/dialog";
import {Product, UpsellingAction} from "../../models";
import {Colors} from "../../Colors";

class DialogData {
  upsellingAction?: UpsellingAction;
  mode?: string;
  products?: Product[]
}

@Component({
  selector: 'app-upselling-modal',
  templateUrl: './upselling-modal.component.html',
  styleUrls: ['./upselling-modal.component.css']
})
export class UpsellingModalComponent implements OnInit {
  colors = Colors;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, public dialogRef: MatDialogRef<UpsellingModalComponent>) { }

  ngOnInit() {
    if (this.data.mode == 'duplicate') {
      this.data.upsellingAction.id = -1;
    }
  }

}
