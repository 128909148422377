<div class="main">

  <h2>#{{data.id}}</h2>

  <ngx-datatable
    [rows]="data.histories"
    [headerHeight]="50"
    [footerHeight]="75"
    [rowHeight]="75"
    [scrollbarH]="true"
    [scrollbarV]="false"
    [externalPaging]="false"
    [columnMode]="'force'"
  >
    <ngx-datatable-column name="ID" sortable="false" prop="id" width="70">
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'admin' | translate}}" sortable="false" prop="admin" width="180">
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'description' | translate}}" sortable="false" prop="description" width="180">
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'date' | translate}}" sortable="false" prop="createdAt" width="180">
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'oldValue' | translate}}"  sortable="false" prop="oldValue" width="180">
    </ngx-datatable-column>
    <ngx-datatable-column name=""  sortable="false" prop=" " width="10">
    </ngx-datatable-column>
    <ngx-datatable-column name="{{'newValue' | translate}}" sortable="false" prop="newValue" width="180">
    </ngx-datatable-column>


  </ngx-datatable>

<!--</div>-->
