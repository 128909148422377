<div class="statistics-box-data" *ngIf="!loading">
  <div class="labels-box">
    <div class="label-tag">{{'total' | translate}}: {{total}} <span *ngIf="totalPrice && totalPrice > 0"> | € {{totalPrice}}</span> </div>
    <div class="label-tag" *ngFor="let p of products; let i = index">
      {{p.name}}: {{p.count}}<span *ngIf="p.price && p.price > 0"> | € {{p.price}} (€ {{p.priceNoTax}}) </span>
    </div>
  </div>
    <canvas baseChart height="400"
            [datasets]="data"
            [labels]="labels"
            [legend]="false"
            [options]="stackedBar"
            [chartType]="'bar'">

    </canvas>
  </div>
  <app-loading height="200px" *ngIf="loading"></app-loading>
