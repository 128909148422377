import {Component, OnInit} from '@angular/core';
import {ApiService} from "../../api.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-campaign-statistics',
  templateUrl: './campaign-statistics.component.html',
  styleUrls: ['./campaign-statistics.component.css']
})
export class CampaignStatisticsComponent implements OnInit {
  campaignTypes = [
    {
      id: 1,
      name: 'Email'
    },
    {
      id: 2,
      name: 'Notification'
    },
    {
      id: 3,
      name: 'Viber'
    }
  ];
  selectedType = null;
  selectedCampaign = null;
  campaigns = [];
  filteredCampaigns = [];
  colors = [
    {backgroundColor: ["#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF"]}
  ];
  barChartOptions = {
    barThickness: 2
  };
  labels = [];
  data = [];

  constructor(private service: ApiService, private snackBar: MatSnackBar, private translate: TranslateService) {
  }

  ngOnInit() {
    this.getCampaigns();
  }

  getCampaigns() {
    this.service.getCampaigns('*').subscribe((data: any[]) => {
      this.campaigns = data;
    }, (err) => {
      this.snackBar.open(this.translate.instant('thereWasAnError'), '', {duration: 2000});
    })
  }

  filterCampaigns() {
    this.filteredCampaigns = this.campaigns.filter(c => c.type == this.selectedType);
  }

  getStatistics(value) {
    this.selectedCampaign = null;
    setTimeout(() => {
      this.selectedCampaign = value;
      if (this.selectedCampaign.type == 2) {
        this.labels = [this.translate.instant("sent"),
          this.translate.instant("numberOfReceived"),
          this.translate.instant("numberOfOpened"),
          this.translate.instant("immediatelyOpen"),
          this.translate.instant("laterOpen")];
        this.data = [{
          data: [
            this.selectedCampaign.sent,
            this.selectedCampaign.delivered,
            this.selectedCampaign.open,
            this.selectedCampaign.notification_open,
            this.selectedCampaign.open - this.selectedCampaign.notification_open

          ], barPercentage: 0.5
        }];
      } else {
        this.labels = [this.translate.instant("sent"),
          this.translate.instant("numberOfReceived"),
          this.translate.instant("numberOfOpened")];
        this.data = [{
          data: [
            this.selectedCampaign.sent,
            this.selectedCampaign.delivered,
            this.selectedCampaign.open
          ], barPercentage: 0.5
        }];

      }
    }, 250);
  }

}
