<div>
  <h3 mat-dialog-title>
    Carina
  </h3>
  <mat-dialog-content>
    <div style="width: 400px; padding: 16px">
      <div class="row">
        <span class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">YoloBook: {{bookPrice.toFixed(2)}}€ ({{bookWeight.toFixed(2)}}kg)</span>
      </div>
      <div class="row">
        <span class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">YoloBook XL: {{bookXLPrice.toFixed(2)}}€ ({{bookXLWeight.toFixed(2)}}kg)</span>
      </div>
      <div class="row">
        <span class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">YoloBox: {{boxPrice.toFixed(2)}}€ ({{boxWeight.toFixed(2)}}kg)</span>
      </div>
      <div class="row">
        <span class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">YoloFrame: {{framePrice.toFixed(2)}}€ ({{frameWeight.toFixed(2)}}kg)</span>
      </div>
      <div class="row">
        <span class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">YoloFrame XL: {{frameXLPrice.toFixed(2)}}€ ({{frameXLWeight.toFixed(2)}}kg)</span>
      </div>
      <div class="row">
        <span class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">YoloPrints: {{printsPrice.toFixed(2)}}€ ({{printsWeight.toFixed(2)}}kg)</span>
      </div>
      <div class="row">
        <span class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">Calendars: {{calendarPrice.toFixed(2)}}€ ({{calendarWeight.toFixed(2)}}kg)</span>
      </div>
      <div class="row">
        <span class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">YoloHard Square: {{hardMiniPrice.toFixed(2)}}€ ({{hardMiniWeight.toFixed(2)}}kg)</span>
      </div>
      <div class="row">
        <span class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">YoloHard Landscape: {{hardMaxiPrice.toFixed(2)}}€ ({{hardMaxiWeight.toFixed(2)}}kg)</span>
      </div>
      <div class="row">
        <span class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">YoloHard Landscape: {{hardMaxiPrice.toFixed(2)}}€ ({{hardMaxiWeight.toFixed(2)}}kg)</span>
      </div>
      <div class="row">
        <span class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">YoloTiles Square: {{tilesSquarePrice.toFixed(2)}}€ ({{tilesSquareWeight.toFixed(2)}}kg)</span>
      </div>
      <div class="row">
        <span class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">YoloTiles Hexa: {{tilesHexPrice.toFixed(2)}}€ ({{tilesHexWeight.toFixed(2)}}kg)</span>
      </div>
      <div class="row">
        <span class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">Total: {{totalPrice.toFixed(2)}}€ ({{totalWeight.toFixed(2)}}kg)</span>
      </div>
    </div>
  </mat-dialog-content>
</div>
