import {Component, OnInit} from '@angular/core';
import {ApiService} from "../api.service";
import {Admin, Banner, ProductAlbums} from "../models";
import {DataService} from "../data-service.service";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";

@Component({
  selector: 'app-product-albums',
  templateUrl: './product-albums.component.html',
  styleUrls: ['./product-albums.component.css']
})
export class ProductAlbumsComponent implements OnInit {
  products: ProductAlbums[] = [];
  allowedEdit = false;
  user: Admin;

  constructor(private apiService: ApiService,
              private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private translate: TranslateService,
              private  router: Router) {
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('adminYolo'));
    if (!this.checkPermission('boxes')) {
      this.router.navigate(['/']);
    }

    this.getProduct()
  }

  checkPermission(page) {
    const permissions = this.user.permissions;
    const rightPermission = permissions.find((x) => x.page === page);
    if (rightPermission) {
      if (rightPermission.edit === 1) {
        this.allowedEdit = true;
      } else {
        this.allowedEdit = false;
      }
      if (rightPermission.view === 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  getProduct() {
    this.apiService.getProductAlbums().subscribe(
      (response: ProductAlbums[]) => {
        this.products = response;

      }
    )
  }

  changeLagerValues(event: Event, product) {
    if (this.allowedEdit) {
      product.lager = (event.target as HTMLInputElement).value
      this.apiService.updateLagerValue(product).subscribe(
        (response) => {
          console.log(response);
          this.snackBar.open(this.translate.instant('Success'), 'OK', {duration: 3000});
        }
      )
    } else {
      this.snackBar.open(this.translate.instant('notAllowed'), 'OK', {duration: 2000});
    }
  }

  activate(id) {
    if (this.allowedEdit) {
      this.apiService.changeActivityTrueProduct(id).subscribe(
        (response) => {
          id = response;
          const index = this.products.findIndex((b) => b.id == id.id);
          if (index != -1) {
            this.products[index].isActive = id.isActive;
          }
          this.snackBar.open(this.translate.instant('Active'), 'OK', {duration: 3000});
        }
      )
    } else {
      this.snackBar.open(this.translate.instant('notAllowed'), 'OK', {duration: 2000});
    }
  }

  deactivate(id) {
    if (this.allowedEdit) {
      this.apiService.changeActivityFalseProduct(id).subscribe(
        (response) => {

          id = response;
          const index = this.products.findIndex((b) => b.id == id.id);
          if (index != -1) {
            this.products[index].isActive = id.isActive;
          }
          this.snackBar.open(this.translate.instant('Deactive'), 'OK', {duration: 3000});
        }
      )
    } else {
      this.snackBar.open(this.translate.instant('notAllowed'), 'OK', {duration: 2000});
    }
  }
}
