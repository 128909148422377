import { Component, OnInit } from '@angular/core';
import {Subject} from "rxjs/Subject";
import {ApiService} from "../../api.service";
import {DatePipe} from "@angular/common";
import {DataService} from "../../data-service.service";

@Component({
  selector: 'app-customer-journey',
  templateUrl: './customer-journey.component.html',
  styleUrls: ['./customer-journey.component.css']
})
export class CustomerJourneyComponent implements OnInit {
  date = new Date();
  start = new Date(this.date.getFullYear(), this.date.getMonth(), 1).toISOString();
  end = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0).toISOString();
  datesChanged: Subject<any> = new Subject<any>();


  constructor(private service: ApiService, private datePipe: DatePipe, private dataService: DataService) {
  }

  ngOnInit() {
    this.dataService.country.subscribe((country) => {
      if (country) {
        this.onDateChanged(country);
      }
    });
  }

  onDateChanged(value) {
    this.datesChanged.next({start: this.start, end: this.end});
  }


}
