import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from "./login/login.component";
import {MainComponent} from "./main/main.component";
import {PrintComponent} from "./print/print.component";
import {StatComponent} from "./stat/stat.component";
import {PromoComponent} from "./promo/promo.component";
import {OrdersListComponent} from "./orders-list/orders-list.component";
import {CostsComponent} from "./costs/costs.component";
import {InvoicesComponent} from "./invoices/invoices.component";
import {UsersComponent} from './users/users.component';
import {UserComponent} from './users/user/user.component';
import {ProfileComponent} from "./profile/profile.component";
import {AboutComponent} from "./about/about.component";
import {ProductsComponent} from "./products/products.component";
import {PrintOrdersComponent} from "./print-orders/print-orders.component";
import {EmployeesComponent} from "./employees/employees.component";
import {SectorsComponent} from "./sectors/sectors.component";
import {StrukturaComponent} from "./struktura/struktura.component";
import {PermissionsComponent} from "./permissions/permissions.component";
import {SuppliesComponent} from "./supplies/supplies.component";
import {CoversComponent} from "./covers/covers.component";
import {MachinesComponent} from "./machines/machines.component";
import {UploadBannerComponent} from "./upload-banner/upload-banner.component";
import {GetBannersComponent} from "./get-banners/get-banners.component";
import {ProductAlbumsComponent} from "./product-albums/product-albums.component";
import {DynamicLinksComponent} from './dynamic-links/dynamic-links.component';
import {TemporalPriceComponent} from "./temporal-price/temporal-price.component";
import {ListingNotificationComponent} from "./listing-notification/listing-notification.component";
import {PricesComponent} from "./prices/prices.component";
import {MarketingStatisticComponent} from "./marketing-statistic/marketing-statistic.component";
import {PriceCombinedComponent} from "./price-combined/price-combined.component";
import {IssuesComponent} from "./issues/issues.component";
import {CampaignComponent} from "./users/campaign/campaign.component";
import {CampaignsComponent} from "./users/campaigns/campaigns.component";
import {SurveysComponent} from "./survey/surveys/surveys.component";
import {NewSurveyComponent} from "./survey/new-survey/new-survey.component";
import {SurveyComponent} from "./survey/survey.component";
import {NewStatisticComponent} from "./new-statistic/new-statistic.component";

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: LoginComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'main',
    component: MainComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: OrdersListComponent
      },
      {
        path: 'orders',
        component: OrdersListComponent
      },
      {
        path: 'print',
        component: PrintComponent
      },
      {
        path: 'stat',
        component: NewStatisticComponent
      },
      {
        path: 'stat/:mode',
        component: NewStatisticComponent
      },
      {
        path: 'promo',
        component: PromoComponent
      },
      {
        path: 'cost',
        component: CostsComponent
      },
      {
        path: 'invoices',
        component: InvoicesComponent
      },
      {
        path: 'profile',
        component: ProfileComponent
      },
      {
        path: 'about',
        component: AboutComponent
      },
      {
        path: 'prices/:mode',
        component: PriceCombinedComponent
      },
      {
        path: 'products',
        component: ProductsComponent
      },
      {
        path: 'employees',
        component: EmployeesComponent
      },
      {
        path: 'marketingStat',
        component: MarketingStatisticComponent
      },

      {
        path: 'sectors',
        component: SectorsComponent
      },
      {
        path: 'users',
        component: UsersComponent,
        pathMatch: 'full'
      },
      {
        path: 'users/:filter',
        component: UsersComponent,
        pathMatch: 'full'
      },
      {
        path: 'users/:id/:mode',
        component: UserComponent
      },
      {
        path: 'users/:id/:mode/:orderId',
        component: UserComponent
      },
      {
        path: 'structure',
        component: StrukturaComponent
      },
      {
        path: 'campaign/:ids',
        component: CampaignComponent
      },
      {
        path: 'campaigns',
        component: CampaignsComponent
      },
      {
        path: 'survey',
        component: NewSurveyComponent
      },
      {
        path: 'surveys',
        component: SurveysComponent
      },
      {
        path: 'issues',
        component: IssuesComponent
      },
      {
        path: 'permissions',
        component: PermissionsComponent
      },
      {
        path: 'supplies',
        component: SuppliesComponent
      },
      {
        path: 'covers/:type/:category',
        component: CoversComponent
      },
      {
        path: 'covers',
        component: CoversComponent
      },
      {
        path: 'covers/:type',
        component: CoversComponent
      },
      {
        path: 'machines',
        component: MachinesComponent
      },
      {
        path: 'banners',
        component: GetBannersComponent
      },
      {
        path: 'boxes',
        component: ProductAlbumsComponent
      },
      {
        path: 'survey',
        component: SurveyComponent
      },
      {
        path: 'dynamic-links',
        component: DynamicLinksComponent
      },
      {
        path: 'temporal-price',
        component: TemporalPriceComponent
      },
      {
        path: 'notifications',
        component: ListingNotificationComponent
      }

    ]
  },
  {
    path: 'print',
    component: PrintComponent,
  },
  {
    path: 'print-orders',
    component: PrintOrdersComponent
  },
  {
    path: '**',
    component: LoginComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
