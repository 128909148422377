<div class="user-page">
  <div class="user-header">
    <div class="user-header__left">
      <img class="user-header__left__image" [src]="profileData?.avatar"
           (error)="profileData.avatar = '/assets/avatar.svg'"/>
      <span class="user-header__left__name">{{profileData.fullName}}</span>
    </div>
    <div class="user-header__right">
      <div class="user-header__right__box">
        <div class="user-header__right__box__title">
          {{'status' | translate}}
        </div>
        <div class="user-header__right__box__text">
          {{profileData.statusText}}
        </div>
      </div>

      <div class="user-header__right__box">
        <div class="user-header__right__box__title">
          {{'id' | translate | uppercase}}
        </div>
        <div class="user-header__right__box__text">
          {{profileData.id}}
        </div>
      </div>

      <div class="user-header__right__box desktop-only" >
        <div class="user-header__right__box__title">
          {{'country' | translate}}
        </div>
        <div class="user-header__right__box__text">
          {{profileData.fullCountry | titlecase}}
        </div>
      </div>

      <div class="user-header__right__box desktop-only">
        <div class="user-header__right__box__title">
          {{'gender' | translate}}
        </div>
        <div class="user-header__right__box__text">
          {{profileData.genderText | titlecase}}
        </div>
      </div>

      <div class="user-header__right__box desktop-only">
        <div class="user-header__right__box__title">
          {{'birthDate' | translate}}
        </div>
        <div class="user-header__right__box__text">
          {{profileData.birthday | date : 'shortDate' : 'Europe/Belgrade' }}
        </div>
      </div>


    </div>
  </div>
  <div class="main-sub-header">
    <div class="main-sub-header__button" [ngClass]="{'main-sub-header__button--selected': mode == 'summary'}"
         (click)="changeMode('summary')">
      {{'summary' | translate}}
    </div>
    <div class="main-sub-header__button" [ngClass]="{'main-sub-header__button--selected': mode == 'details'}"
         (click)="changeMode('details')">
      {{'details' | translate}}
    </div>
    <div class="main-sub-header__button" [ngClass]="{'main-sub-header__button--selected': mode == 'orders'}"
         (click)="changeMode('orders')">
      {{'orders' | translate}}
    </div>
    <div class="main-sub-header__button" [ngClass]="{'main-sub-header__button--selected': mode == 'issues'}"
         (click)="changeMode('issues')">
      {{'issues' | translate}}
    </div>
    <div class="main-sub-header__button" [ngClass]="{'main-sub-header__button--selected': mode == 'surveys'}"
         (click)="changeMode('surveys')">
      {{'surveys' | translate}}
    </div>

  </div>
  <div class="user-main-container">
    <div class="breadcrumb">
      <span class="breadcrumb__main" (click)="openUsers()"> {{'users' | translate}} </span> <span
      class="breadcrumb__sign">></span><span
      [ngClass]="{'breadcrumb__main': selectedOrder != null, 'breadcrumb__name': !selectedOrder}"> {{profileData.fullName}}</span>
      <span *ngIf="selectedOrder">
      <span
        class="breadcrumb__sign">></span><span class="breadcrumb__name"> {{selectedOrder.id}}</span>
        </span>

    </div>
    <div *ngIf="mode === 'summary'" class="user-row summary-row">
      <div class="user-summary-card" *ngFor="let x of [].constructor(9); let i = index">
        <div class="user-summary-title">{{getSummaryTitle(i)}}</div>
        <div class="user-summary-value">{{getSummaryValue(i)}}</div>
      </div>
    </div>
    <div>
      <div *ngIf="mode == 'details'" class="block-row">
        <div *ngIf="profileData.blocked" class="block-button unblock-button"
             (click)="toggleBlock()">{{'unblockUser' | translate}}</div>
        <div *ngIf="!profileData.blocked" class="block-button"
             (click)="toggleBlock()">{{'blockUser' | translate}}</div>
      </div>

      <div *ngIf="mode === 'details'" class="user-row">
        <div class="details-box">
          <div class="details-box__title">
            {{'accountDetails' | translate }}
          </div>
          <mat-form-field class="details-box__user_field">
            <mat-label>{{'name' | translate}}</mat-label>
            <input matInput type="text" [(ngModel)]="profileData.name">
          </mat-form-field>
          <br>
          <mat-form-field class="details-box__user_field">
            <mat-label>{{'lastName' | translate}}</mat-label>
            <input matInput type="text" [(ngModel)]="profileData.lastname">
          </mat-form-field>
          <br>
          <mat-form-field class="details-box__user_field">
            <input
              (focus)="birthdayPicker.open()"
              [(ngModel)]="profileData.birthday"
              matInput
              [matDatepicker]="birthdayPicker"
              placeholder="{{'birthDate' | translate}}">
            <mat-datepicker #birthdayPicker></mat-datepicker>
          </mat-form-field>
          <br>
          <mat-form-field class="details-box__user_field">
            <mat-label>{{"gender" | translate}}</mat-label>
            <mat-select [(value)]="profileData.gender">
              <mat-option [value]="1">{{"male" | translate}}</mat-option>
              <mat-option [value]="2">{{"female" | translate}}</mat-option>
              <mat-option [value]="3">{{"other" | translate}}</mat-option>
            </mat-select>
          </mat-form-field>
          <br>
          <mat-form-field class="details-box__user_field">
            <mat-label>{{'email' | translate}}</mat-label>
            <input matInput type="email" [(ngModel)]="profileData.email">
          </mat-form-field>
          <br>
          <mat-form-field class="details-box__user_field last-field">
            <mat-label>{{'phone' | translate}}</mat-label>
            <input matInput type="phone" [(ngModel)]="profileData.phone">
          </mat-form-field>
          <br>
          <div
            style="display: flex; justify-content: space-between; align-items: center; position: absolute; bottom: 20px; width: calc(100% - 40px);">
            <div (click)="updateUser()">
              <app-button text="{{'save' | translate}}" [height]="'48px'" [width]="'104px'"
                          [textColor]="colors.WHITE"
                          [color]="colors.PINK"></app-button>
            </div>
            <div class="add-new-address" (click)="changePassword()">{{'changePassword' | translate}}</div>
          </div>
        </div>
        <div class="details-box" *ngIf="profileData.addresses.length > 0">
          <div class="details-box__title">
            {{'addressDetails' | translate }}
          </div>
          <mat-accordion>
            <div style="padding-bottom: 20px">
              <mat-expansion-panel *ngFor="let address of profileData.addresses"
                                   class="details-box__address-panel">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <img class="details-box__address-panel__image" *ngIf="address.type === 1"
                         src="assets/ic_home@0.5x.svg"/>
                    <img class="details-box__address-panel__image" *ngIf="address.type === 2"
                         src="assets/ic_job@0.5x.svg"/>
                    <img class="details-box__address-panel__image" *ngIf="address.type === 3"
                         src="assets/ic_other@0.5x.svg"/>
                    {{getAddressTypeText(address.type)}}
                    | {{address.address}} {{address.addressNumber}}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-label style="color: gray; font-size: 12px">{{'country' | translate}}</mat-label>
                <mat-select [(value)]="address.country" style="margin-bottom: 10px">
                  <mat-option *ngFor="let c of countries" [value]="c.value">{{c.name}}</mat-option>
                </mat-select>
                <mat-form-field class="details-box__address_field">
                  <mat-label>{{"address" | translate}}</mat-label>
                  <input matInput type="text" [(ngModel)]="address.address">
                </mat-form-field>
                <mat-form-field class="details-box__address_field">
                  <mat-label>{{"number" | translate}}</mat-label>
                  <input matInput type="text" [(ngModel)]="address.addressNumber">
                </mat-form-field>
                <mat-form-field class="details-box__address_field details-box__address_field--half ">
                  <mat-label>{{"city" | translate}}</mat-label>
                  <input matInput type="text" [(ngModel)]="address.city">
                </mat-form-field>
                <mat-form-field class="details-box__address_field details-box__address_field--half ">
                  <mat-label>{{"postalCode" | translate}}</mat-label>
                  <input matInput type="text" [(ngModel)]="address.postalCode">
                </mat-form-field>
                <mat-form-field class="details-box__address_field details-box__address_field--half ">
                  <mat-label>{{"floor" | translate}}</mat-label>
                  <input matInput type="text" [(ngModel)]="address.floor">
                </mat-form-field>
                <mat-form-field class="details-box__address_field details-box__address_field--half ">
                  <mat-label>{{"apartmentNumber" | translate}}</mat-label>
                  <input matInput type="text" [(ngModel)]="address.apartmentNumber">
                </mat-form-field>
                <!--            <mat-form-field class="details-box__address_field">-->
                <!--              <mat-label>{{"note" | translate}}</mat-label>-->
                <!--              <input matInput type="text" [(ngModel)]="address.note">-->
                <!--            </mat-form-field>-->
                <div (click)="updateAddress(address)" style="margin-top: 15px">
                  <app-button text="{{'save' | translate}}" [height]="'48px'" [width]="'104px'"
                              [textColor]="colors.WHITE"
                              [color]="colors.PINK"></app-button>
                </div>
              </mat-expansion-panel>
            </div>
            <div style="display: flex; justify-content: end; position: absolute; bottom: 20px; right: 20px;">
              <div class="add-new-address" (click)="addAddress()">{{'addNewAddress' | translate}}</div>
            </div>
          </mat-accordion>
        </div>
        <div class="details-box" *ngIf="profileData.devices.length > 0">
          <div class="details-box__title">
            {{'deviceDetails' | translate }}
          </div>
          <mat-accordion>
            <mat-expansion-panel *ngFor="let device of profileData.devices; let i = index"
                                 class="details-box__device-panel">
              <mat-expansion-panel-header>
                <mat-panel-title class="details-box__device-panel__header">
                  <img class="details-box__address-panel__image" *ngIf="device.deletedAt != null"
                       src="assets/ic_log_state.svg"/>
                  <img class="details-box__address-panel__image" *ngIf="device.deletedAt == null"
                       src="assets/ic_logged.svg"/>
                  {{"device" | translate}} {{i + 1}} | {{device.model}}
                  <span class="details-box__device-panel__text"
                        *ngIf="device.deletedAt != null"> ({{'loggedInDevice' | translate}}) </span>
                  <span class="details-box__device-panel__text"
                        *ngIf="device.deletedAt == null"> ({{'loggedOutDevice' | translate}}) </span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-form-field class="details-box__device_field">
                <mat-label>{{"os" | translate}}</mat-label>
                <input matInput type="text" [value]="getDeviceTypeText(device.type)" [disabled]="true">
              </mat-form-field>
              <br>
              <mat-form-field class="details-box__device_field">
                <mat-label>{{"device" | translate}}</mat-label>
                <input matInput type="text" [value]="device.model" [disabled]="true">
              </mat-form-field>
              <br>
              <mat-form-field class="details-box__device_field">
                <mat-label>{{"appVersion" | translate}}</mat-label>
                <input matInput type="text" [value]="device.appVersion" [disabled]="true">
              </mat-form-field>
              <br>
              <mat-form-field class="details-box__device_field">
                <mat-label>{{"osVersion" | translate}}</mat-label>
                <input matInput type="text" [value]="device.osVersion" [disabled]="true">
              </mat-form-field>
              <br>
              <mat-form-field class="details-box__device_field">
                <input
                  [(ngModel)]="device.createdAt"
                  matInput
                  [disabled]="true"
                  placeholder="{{'firstLogin' | translate}}">
              </mat-form-field>
              <br>
              <mat-form-field class="details-box__device_field">
                <input
                  [(ngModel)]="device.updatedAt"
                  matInput
                  [disabled]="true"
                  placeholder="{{'lastLogin' | translate}}">
              </mat-form-field>
            </mat-expansion-panel>
          </mat-accordion>
          <br>
        </div>
        <div class="details-box">
          <div class="details-box__title">
            {{'additionalNotes' | translate }}
          </div>
          <mat-form-field class="details-box__user_field">
            <mat-label>{{'note' | translate}}</mat-label>
            <textarea matInput cdkAutosizeMinRows="1" cdkAutosizeMaxRows="3"
                      [(ngModel)]="profileData.note"> </textarea>
          </mat-form-field>
          <br>
          <mat-form-field class="details-box__user_field">
            <mat-label>{{'maritalStatus' | translate}}</mat-label>
            <input matInput type="text" [(ngModel)]="profileData.maritalStatus">
          </mat-form-field>
          <br>
          <mat-form-field class="details-box__user_field">
            <mat-label>{{'parentalStatus' | translate}}</mat-label>
            <input matInput type="text" [(ngModel)]="profileData.parentalStatus">
          </mat-form-field>
          <br>
          <mat-form-field class="details-box__user_field">
            <mat-label>{{'favoriteColor' | translate}}</mat-label>
            <input matInput type="text" [(ngModel)]="profileData.favoriteColor">
          </mat-form-field>
          <div (click)="updateAdditional()">
            <app-button text="{{'save' | translate}}" [height]="'48px'" [width]="'104px'"
                        [textColor]="colors.WHITE"
                        [color]="colors.PINK"></app-button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="mode === 'orders'" class="user-row">
      <div *ngIf="orders.length === 0"
           class="details-box__title no-orders__div">{{'userHasNoOrders' | translate}}</div>
      <ngx-datatable
        #table
        class="material users-orders-list"
        [rows]="orders"
        [columnMode]="'force'"
        [headerHeight]="50"
        [footerHeight]="75"
        [rowHeight]="'auto'"
        [scrollbarH]="true"
        [scrollbarV]="false"
        *ngIf="orders.length > 0"
      >

        <ngx-datatable-column [name]="getTranslation('id') | titlecase" sortable="false" prop="id" width="100">
          <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
            {{value}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="getTranslation('price')" sortable="false" prop="price">
          <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
            {{value}} {{row.currency}} (€ {{row.priceEur}})
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="getTranslation('date')" sortable="false" prop="created_at">
          <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
            {{value | date: 'dd.MM.yyyy HH:mm':'+GMT'}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="getTranslation('products')" sortable="false" prop="id" width="250">
          <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
            {{getItems(row)}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="getTranslation('satisfaction')" sortable="false" prop="rating">
          <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
            {{getSatisfaction(value)}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="getTranslation('status')" sortable="false" prop="status">
          <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
            {{getStatusText(value)}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column width="100">
          <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
            <img style="cursor: pointer" src="/assets/ic_arrow.png" (click)="openOrder(row)">
          </ng-template>
        </ngx-datatable-column>

      </ngx-datatable>
    </div>

    <div *ngIf="mode == 'order'" class="user-row user-row-order">
      <div class="details-box details-box-order" *ngIf="selectedOrder">
        <div style="display: flex; justify-content: space-between">
          <div class="details-box__title">
            {{'orderDetails' | translate }}
          </div>
          <div class="for-item-actions for-item-actions__row" *ngIf="selectedOrder?.status != 5">
            <img src="/assets/ic_trash.svg" (click)="deleteOrder()"
                 style="width: 18px; height: 18px; cursor: pointer">
            <div class="duplicate-button" (click)="copyOrder()">{{'duplicate' | translate}}</div>
          </div>
        </div>

        <mat-form-field class="details-box__user_field details-box__order_field">
          <mat-label>{{'orderId' | translate}}</mat-label>
          <input matInput type="string" [value]="selectedOrder.id" disabled>
        </mat-form-field>

        <div class="order-details">
        <mat-form-field
          class="details-box__user_field details-box__order_field--quarter details-box__order_field">
          <mat-label>{{'totalQuantity' | translate}}</mat-label>
          <input matInput type="text" [(ngModel)]="selectedOrder.items.length" disabled>
        </mat-form-field>

        <mat-form-field
          class="details-box__user_field details-box__order_field--quarter details-box__order_field">
          <mat-label>{{'totalSize' | translate}}</mat-label>
          <input matInput type="text" [(ngModel)]="selectedOrder.size" disabled>
        </mat-form-field>

        <mat-form-field
          class="details-box__user_field details-box__order_field--quarter details-box__order_field">
          <mat-label>{{'satisfaction' | translate}}</mat-label>
          <input matInput type="text" [value]="getSatisfaction(selectedOrder.rating)" disabled>
        </mat-form-field>
        <mat-form-field
          class="details-box__user_field details-box__order_field--half details-box__order_field"
          *ngIf="selectedOrder?.status == 5">
          <mat-label>{{'status' | translate}}</mat-label>
          <input matInput type="text" [value]="getStatusText(selectedOrder.status)" disabled>
        </mat-form-field>
        <mat-form-field
          class="details-box__user_field details-box__order_field--half details-box__order_field"
          *ngIf="selectedOrder?.status != 5">
          <mat-label>{{"status" | translate}}</mat-label>
          <mat-select [(ngModel)]="selectedOrder.status" (selectionChange)="changeOrderStatus($event)">
            <mat-option [value]="s.value"
                        *ngFor="let s of orderStatuses">{{s.name | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
        </div>
        <div class="for-order-items">
          <div class="order-item" *ngFor="let item of selectedOrder.items; let index = index;">
            <mat-form-field
              class="details-box__user_field details-box__order_field details-box__order_field--quarter">
              <mat-label>{{'product' | translate}} {{index + 1}} ID</mat-label>
              <input matInput type="text" [(ngModel)]="item.id" disabled>
            </mat-form-field>

            <mat-form-field
              class="details-box__user_field details-box__order_field details-box__order_field--quarter">
              <mat-label>{{'productName' | translate}}</mat-label>
              <input matInput [title]="item.product.name" type="text" [(ngModel)]="item.product.name"
                     disabled>
            </mat-form-field>

            <mat-form-field
              class="details-box__user_field details-box__order_field details-box__order_field--quarter">
              <mat-label>{{'cover' | translate}}</mat-label>
              <input matInput type="text" [(ngModel)]="item.coverId" disabled>
            </mat-form-field>

            <mat-form-field
              class="details-box__user_field details-box__order_field details-box__order_field--quarter">
              <mat-label>{{'size' | translate}}</mat-label>
              <input matInput type="text" [(ngModel)]="item.count" disabled>
            </mat-form-field>

            <mat-form-field
              class="details-box__user_field details-box__order_field details-box__order_field--quarter">
              <mat-label>{{'price' | translate}}</mat-label>
              <input matInput type="text" [(ngModel)]="item.price" (keyup.enter)="changedItemPrice(item)">
            </mat-form-field>

            <div class="for-item-actions desktop-only" *ngIf="selectedOrder?.status != 5">
              <img src="/assets/ic_trash.svg" (click)="deleteItem(item)"
                   style="width: 18px; height: 18px; cursor: pointer">
              <div class="duplicate-button"
                   (click)="duplicateItem(item)">{{'duplicate' | translate}}</div>
            </div>

            <div class="mobile-item-delete mobile-only">
              <img src="/assets/ic_trash.svg" (click)="deleteItem(item)"
                   style="width: 18px; height: 18px; cursor: pointer">
            </div>

            <div class="duplicate-button duplicate-button-mobile mobile-only"
                 (click)="duplicateItem(item)">{{'duplicate' | translate}}</div>

          </div>

          <div class="details-box-separator">
          </div>


          <mat-form-field
            class="details-box__user_field details-box__order_field--half details-box__order_field">
            <mat-label>{{'invoice' | translate}}</mat-label>
            <input matInput type="text" [value]="selectedOrder.invoice?.id" disabled>
          </mat-form-field>
          <mat-form-field
            class="details-box__user_field details-box__order_field--quarter details-box__order_field"
            *ngIf="selectedOrder?.status != 5">
            <mat-label>{{"freeOrder" | translate}}</mat-label>
            <mat-select [(ngModel)]="selectedOrder.is_free" (selectionChange)="changeOrderFree($event)">
              <mat-option [value]="0">{{'no' | translate}}</mat-option>
              <mat-option [value]="1">{{'yes' | translate}}</mat-option>

            </mat-select>
          </mat-form-field>
          <mat-form-field
            class="details-box__user_field details-box__order_field--half details-box__order_field"
            *ngIf="selectedOrder.status == 5">
            <mat-label>{{'freeOrder' | translate}}</mat-label>
            <input matInput type="text" [value]="getIsFree()" disabled>
          </mat-form-field>
          <mat-form-field
            class="details-box__user_field details-box__order_field--half details-box__order_field">
            <mat-label>{{'orderDate' | translate}}</mat-label>
            <input matInput type="text" [value]="selectedOrder.date" disabled>
          </mat-form-field>
          <mat-form-field
            class="details-box__user_field details-box__order_field--half details-box__order_field">
            <mat-label>{{'orderPrice' | translate}}</mat-label>
            <input matInput type="text" [value]="getOrderPrice()" disabled>
          </mat-form-field>
          <mat-form-field
            class="details-box__user_field details-box__order_field--half details-box__order_field">
            <mat-label>{{'currency' | translate}}</mat-label>
            <input matInput type="text" [value]="selectedOrder.currency" disabled>
          </mat-form-field>
          <mat-form-field
            class="details-box__user_field details-box__order_field--half details-box__order_field"
            *ngIf="selectedOrder.status == 5">
            <mat-label>{{'deliveryPrice' | translate}}</mat-label>
            <input matInput type="text" [value]="getDeliveryPrice()" disabled>
          </mat-form-field>
          <mat-form-field
            class="details-box__user_field details-box__order_field--quarter details-box__order_field"
            *ngIf="selectedOrder.status != 5">
            <mat-label>{{'deliveryPrice' | translate}}</mat-label>
            <div class="editable-input-box"></div>
            <input class="editable-input" matInput type="text" [(ngModel)]="selectedOrder.delivery"
                   (keyup.enter)="changeOrderDelivery()">
            <span matSuffix>{{selectedOrder.currency}}</span>
          </mat-form-field>
          <br>
          <mat-form-field
            class="details-box__user_field details-box__order_field--half details-box__order_field"
            *ngIf="selectedOrder.status == 5">
            <mat-label>{{'paymentType' | translate}}</mat-label>
            <input matInput type="text" [value]="getPaymentType()" disabled>
          </mat-form-field>

          <mat-form-field
            class="details-box__user_field details-box__order_field--quarter details-box__order_field"
            *ngIf="selectedOrder?.status != 5">
            <mat-label>{{"paymentType" | translate}}</mat-label>
            <mat-select [(ngModel)]="selectedOrder.payingMethod"
                        (selectionChange)="changePayingMethod($event)">
              <mat-option *ngFor="let t of paymentTypes" [value]="t.id">{{t.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <span style="display: inline-block;width: 200px;">

          </span>

          <mat-form-field
            class="details-box__user_field details-box__order_field--half details-box__order_field"
            *ngIf="selectedOrder.status == 5">
            <mat-label>{{'promoCode' | translate}}</mat-label>
            <input matInput type="text" [value]="selectedOrder.promo_text" disabled>
          </mat-form-field>
          <mat-form-field
            class="details-box__user_field details-box__order_field--quarter details-box__order_field"
            *ngIf="selectedOrder.status != 5">
            <mat-label>{{'promoCode' | translate}}</mat-label>
            <div class="editable-input-box"></div>
            <input class="editable-input" matInput type="text" [(ngModel)]="selectedOrder.promo_text"
                   (keyup.enter)="changeOrderPromoCode()">
          </mat-form-field>

          <br>
        </div>
      </div>
      <div class="details-box">
        <div class="details-box__title">
          {{'addressDetails' | translate }}
        </div>
        <mat-form-field class="details-box__address_field">
          <mat-label>{{"address" | translate}}</mat-label>
          <input matInput type="text" [(ngModel)]="selectedOrder.address.address">
        </mat-form-field>
        <mat-form-field class="details-box__address_field">
          <mat-label>{{"number" | translate}}</mat-label>
          <input matInput type="text" [(ngModel)]="selectedOrder.address.number">
        </mat-form-field>
        <mat-form-field class="details-box__address_field  ">
          <mat-label>{{"city" | translate}}</mat-label>
          <input matInput type="text" [(ngModel)]="selectedOrder.address.city">
        </mat-form-field>
        <mat-form-field class="details-box__address_field  ">
          <mat-label>{{"postalCode" | translate}}</mat-label>
          <input matInput type="text" [(ngModel)]="selectedOrder.address.postalCode">
        </mat-form-field>
        <mat-form-field class="details-box__address_field details-box__address_field--half ">
          <mat-label>{{"phone" | translate}}</mat-label>
          <input matInput type="text" [(ngModel)]="selectedOrder.address.phone">
        </mat-form-field>
        <mat-form-field class="details-box__address_field  ">
          <mat-label>{{"apartmentNumber" | translate}}</mat-label>
          <input matInput type="text" [(ngModel)]="selectedOrder.address.place">
        </mat-form-field>
        <mat-form-field class="details-box__address_field">
          <mat-label>{{"note" | translate}}</mat-label>
          <input matInput type="text" [(ngModel)]="selectedOrder.address.note">
        </mat-form-field>
        <div (click)="updateOrderAddress()" style="margin-top: 15px">
          <app-button text="{{'save' | translate}}" [height]="'48px'" [width]="'104px'"
                      [textColor]="colors.WHITE"
                      [color]="colors.PINK"></app-button>
        </div>
      </div>
    </div>
    <div *ngIf="mode === 'issues'" class="user-row">
      <div class="new-issue-row">
        <app-button text="{{'addNewIssue' | translate}}" (click)="addNewIssue()" [height]="'48px'"
                    [width]="'183px'" [textColor]="colors.WHITE"
                    [color]="colors.PINK"></app-button>
      </div>
      <div *ngIf="issues.length === 0"
           class="details-box__title no-orders__div">{{'userHasNoIssues' | translate}}</div>
      <ngx-datatable
        #table
        class="material users-orders-list"
        [rows]="issues"
        [columnMode]="'force'"
        [headerHeight]="50"
        [footerHeight]="75"
        [rowHeight]="'auto'"
        [scrollbarH]="true"
        [scrollbarV]="false"
        [rowClass]="getRowClass"
        *ngIf="issues.length > 0"
      >

        <ngx-datatable-column [name]="getTranslation('initiationDate')" sortable="false" prop="initiation_date"
                              width="100">
          <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
            {{value  | date : 'dd.MM.yyyy' : 'Europe/Belgrade' }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="getTranslation('typeOfRequest')" sortable="false" prop="type" width="100">
          <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
            {{getIssueOption(1, value)?.name}} <span *ngIf="value == 3"
                                                     style="cursor: pointer; margin-left: 5px"
                                                     (click)="openMore(row)">(<span
            style="text-decoration: underline">{{'seeMore' | translate}}</span>)</span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="getTranslation('status')" sortable="false" prop="status" width="100">
          <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
            <mat-select [(value)]="row.status" class="select-field" style="margin-bottom: 10px"
                        (selectionChange)="updateIssue(row)">
              <mat-option *ngFor="let t of issueOptions[2]" class="select-field"
                          [value]="t.id">{{t.name}}</mat-option>
            </mat-select>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="getTranslation('resolutionDate')" sortable="false" prop="resolution_date"
                              width="100">
          <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
            <input class="editable-input" readonly [autofocus]="false" style="cursor: auto;"
                   [matDatepicker]="resolutionDate" matInput
                   formControlName="startDate" [value]="value" (focus)="resolutionDate.open()"
                   (dateChange)="changedResolutionDate($event.value, row)">
            <mat-datepicker #resolutionDate></mat-datepicker>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="getTranslation('resolutionType')" sortable="false" prop="resolution_type"
                              width="100">
          <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
            <mat-select [(value)]="row.resolution_type" class="select-field" style="margin-bottom: 10px"
                        (selectionChange)="updateIssue(row)">
              <mat-option *ngFor="let t of issueOptions[3]" [value]="t.id">{{t.name}}</mat-option>
            </mat-select>
          </ng-template>
        </ngx-datatable-column>

      </ngx-datatable>
    </div>
    <div *ngIf="mode === 'surveys'" class="user-row">
      <ngx-datatable
        #table
        class="material users-orders-list"
        [rows]="surveys"
        [columnMode]="'force'"
        [headerHeight]="50"
        [footerHeight]="75"
        [rowHeight]="'auto'"
        [scrollbarH]="true"
        [scrollbarV]="false"
        *ngIf="surveys.length > 0"
      >
        <ngx-datatable-column [name]="getTranslation('name')" sortable="false" prop="name"
                              width="100">
          <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
            {{ value }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="getTranslation('date')" sortable="false" prop="created_at"
                              width="100">
          <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
            {{value  | date : 'dd.MM.yyyy' : 'Europe/Belgrade' }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column sortable="false" width="100">

          <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
            <app-button text="{{'show' | translate}}" (click)="showSurvey(row)"
                        [color]="colors.WHITE" [textColor]="colors.ULTRA_PINK" fontSize="14px" height="30px"
                        width="103px"></app-button>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>
</div>
