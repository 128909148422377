import {Component, OnInit, Renderer2} from "@angular/core";
import {ApiService} from "../api.service";
import {DataService} from "../data-service.service";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {MatDialog} from "@angular/material/dialog";
import {MatDialogConfig} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MessagingService} from "../shared/messaging.service";
import {Admin, EmployeeFilter, Filter} from "../models";
import {VacationComponent} from "../vacation/vacation.component";
import {EmployeeComponent} from "../employee/employee.component";
import {DeleteEmployeeComponent} from "../delete-employee/delete-employee.component";

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.css'],
})
export class EmployeesComponent implements OnInit {
  showHeader = true;
  currentPage = 0;
  user: Admin;
  sectors = [];
  employees = [];
  filterName = '';
  filterPhone = '';
  filterEmail = '';
  filterPosition = '';
  actionOpen = true;
  openModal = true;
  allowedEdit = false;

  constructor(private dialog: MatDialog,
              private router: Router,
              private service: ApiService,
              private dataService: DataService,
              private translate: TranslateService,
              private snackBar: MatSnackBar,
              private messagingService: MessagingService,
  ) {
  }


  ngOnInit() {
    this.dataService.refresh.subscribe((obj) => {
      console.log('refresh    ' + obj);
      this.service.sectorEmployees(this.filterName, this.filterPhone, this.filterEmail, this.filterPosition).subscribe(
        data => {
          const e = [];
          e['empl'] = data;
          this.employees = e['empl'];
        }
      );
    });
    this.dataService.country.subscribe((country) => {
      console.log("country changed employees " + country);
    });
    window.addEventListener('scroll', this.scroll, true);
    this.user = JSON.parse(localStorage.getItem('adminYolo'));
    if (!this.checkPermission('employees')) {
      this.router.navigate(['/']);
    }
    this.service.sectorsList().subscribe(
      data => {
        const s = [];
        s['sectors'] = data;
        this.sectors = s['sectors'];
      }
    );
    this.service.sectorEmployees(this.filterName, this.filterPhone, this.filterEmail, this.filterPosition).subscribe(
      data => {
        const e = [];
        e['empl'] = data;
        this.employees = e['empl'];
      }
    );

  }

  onInViewportChange(type, event) {
    if (event == true) {
      this.currentPage = type;
      console.log(type);
      this.toCenter(type + 'nav');
    }
  }

  toCenter(id) {
    console.log('id is  ' + id);
    if (document.getElementById(id) != null) {
      document.getElementById(id).scrollIntoView({inline: 'start', behavior: "smooth"});
      console.log(document.getElementById(id));
    }
  }

  checkPermission(page) {
    const permissions = this.user.permissions;
    const rightPermission = permissions.find((x) => x.page === page);
    if (rightPermission) {
      if (rightPermission.edit === 1) {
        this.allowedEdit = true;
      } else {
        this.allowedEdit = false;
      }
      if (rightPermission.view === 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  checkEditPermission(page) {
    const permissions = this.user.permissions;
    const rightPermission = permissions.find((x) => x.page === page);
    if (rightPermission) {
      if (rightPermission.edit === 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  toTop(id) {
    document.getElementById(id).scrollIntoView({behavior: "smooth", block: "start"});
  }

  onSearch($event) {
    this.service.sectorEmployees(this.filterName, this.filterPhone, this.filterEmail, this.filterPosition).subscribe(
      data => {
        const e = [];
        e['empl'] = data;
        this.employees = e['empl'];
      }
    )
  }

  stopPropagation(event) {
    console.log('stop');
    event.stopPropagation();
  }

  onReset() {
    this.filterName = '';
    this.filterEmail = '';
    this.filterPhone = '';
    this.filterPosition = '';
    this.service.sectorEmployees(this.filterName, this.filterPhone, this.filterEmail, this.filterPosition).subscribe(
      data => {
        const e = [];
        e['empl'] = data;
        this.employees = e['empl'];
      }
    )
  }

  addNew() {
    if (this.allowedEdit) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.panelClass = 'appointment-info-dialog';
      dialogConfig.data = {'mode': 'new'};
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      this.dialog.open(EmployeeComponent, dialogConfig);
    } else {
      this.snackBar.open(this.translate.instant('notAllowed'), 'OK', {duration: 2000});
    }
  }

  clickEmpl(data) {
    setTimeout(() => {
      console.log(this.openModal);
      if (this.checkEditPermission('employees')) {
        if (this.openModal) {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.panelClass = 'appointment-info-dialog';
          dialogConfig.data = {'admin': data};
          dialogConfig.disableClose = false;
          dialogConfig.autoFocus = true;
          this.dialog.open(EmployeeComponent, dialogConfig);
        }
      } else if (this.user.roleId === 2) {
        if (this.user.sectorId === data.sectorId) {
          if (this.openModal) {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.panelClass = 'appointment-info-dialog';
            dialogConfig.data = {'admin': data};
            dialogConfig.disableClose = false;
            dialogConfig.autoFocus = true;
            this.dialog.open(EmployeeComponent, dialogConfig);
          }
        }
      } else if (this.user.roleId === 3) {
        if (this.user.id === data.id) {
          if (this.openModal) {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.panelClass = 'appointment-info-dialog';
            dialogConfig.data = {'admin': data, 'mode': 'noPerm'};
            dialogConfig.disableClose = false;
            dialogConfig.autoFocus = true;
            this.dialog.open(EmployeeComponent, dialogConfig);
          }
        }
      }

    }, 100);
  }

  isDeleteable(u) {
    if (this.user.roleId === 1) {
      return true;
    } else if (this.user.roleId === 2) {
      if (this.user.sectorId === u.sectorId) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  onDelete(u) {
    this.openModal = false;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'no-info-dialog';
    dialogConfig.data = {'admin': u};
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    this.dialog.open(DeleteEmployeeComponent, dialogConfig);
    setTimeout(() => {
      this.openModal = true;
    }, 150);
  }

  scroll = (): void => {
    if (window.pageYOffset > 110) {
      this.showHeader = false;
    } else {
      this.showHeader = true;
    }
  };

  filteredEmployees(id) {
    return this.employees.filter((e) => {
      return e.sectorId === id;
    })
  }
}
