import {Component, Input, OnInit} from '@angular/core';
import {Observable} from "rxjs/Observable";
import {ApiService} from "../../../api.service";
import {TranslateService} from "@ngx-translate/core";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-users-per-gender',
  templateUrl: './users-per-gender.component.html',
  styleUrls: ['./users-per-gender.component.css']
})
export class UsersPerGenderComponent implements OnInit {
  @Input() start;
  @Input() end;
  @Input() datesChanged: Observable<void>;

  loading = false;
  labels = [];
  data = [{}];
  numbers = [];
  options = {
    cutoutPercentage: 65,
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          const label = data.labels[tooltipItem.index];

          return value + '% - ( ' + label + ' )';
        }
      }
    }
  };
  colors = [
    {backgroundColor: ["#FF7300", "#2CD9C5", "#826AF9"]}
  ];
  total = 0;
  constructor(private service: ApiService, public translate: TranslateService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.getCustomersGender();

    this.datesChanged.subscribe((data: any) => {
      if (data) {
        this.start = data.start;
        this.end = data.end;
        this.getCustomersGender();
      }
    });
  }

  getCustomersGender() {
    this.loading = true;
    const start = this.datePipe.transform(this.start, 'yyyy-MM-dd');
    const end = this.datePipe.transform(this.end, 'yyyy-MM-dd');
    this.service.getStatisticsUsersPerGender(start, end).subscribe((apiData: any) => {
      this.data = [{data: apiData.data}];
      this.labels = apiData.labels.map(l => {
        if (l == 1) {
          return this.translate.instant('male');
        } else if (l == 2) {
          return this.translate.instant('female');
        } else {
          return this.translate.instant('other');
        }
      });      this.total = apiData.total;
      this.numbers = apiData.numbers;
      this.loading = false;
    })
  }


}
