import {Component, Inject, OnInit} from '@angular/core';
import {DataService} from "../data-service.service";
import {ApiService} from "../api.service";
import {MAT_DIALOG_DATA} from "@angular/material";
import {MatDialogRef} from "@angular/material/dialog";
import {DialogData} from "../supply-history/supply-history.component";

@Component({
  selector: 'app-delete-employee',
  templateUrl: './delete-employee.component.html',
  styleUrls: ['./delete-employee.component.css']
})
export class DeleteEmployeeComponent implements OnInit {
name = "";
  constructor(private dataService: DataService, public apiService: ApiService, @Inject(MAT_DIALOG_DATA) public data: DialogData, private dialogRef: MatDialogRef<DeleteEmployeeComponent>) { }

  ngOnInit() {
    console.log(this.data["admin"]);
    if (this.data['admin']["fullName"] == null){
      this.name = this.data['admin']["username"];
    } else {
      this.name = this.data['admin']["fullName"];
    }
  }

  confirm() {
  this.apiService.deleteAdmin(this.data["admin"]["id"]).subscribe(
    data => {
      console.log(data);
      this.dialogRef.close();
      this.dataService.newRefresh('new');
    }
  )
  }

}
