<div class="header" style="background-color: #d92a73">
    <span style="font-size:1.7em; color:white; padding-left: 20px; font-weight: 500; ">
      {{'dynamicLinks' | translate}}
    </span>
</div>
<mat-card class="col-md-10 offset-md-1 col-12">
  <div>
    <h4> {{'addLink' | translate}}</h4>
    <mat-accordion class="example-headers-align">
      <mat-expansion-panel [expanded]='isExpanded'>
        <mat-expansion-panel-header (click)="expand()">
          <mat-panel-title>
            <mat-icon class="icon">insert_linkl</mat-icon>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <form [formGroup]="createDynamicLinkForm">
          <mat-form-field style="margin: 0 15px 0 0;">
            <mat-label>{{'name' | translate}}</mat-label>
            <input matInput formControlName="name">
          </mat-form-field>

          <mat-form-field style="width:-webkit-fill-available">
            <mat-label>{{'link' | translate}}</mat-label>
            <input matInput formControlName="link">
          </mat-form-field>
        </form>
        <mat-action-row>
          <button mat-raised-button color="accent" class='button button--margin'
                  (click)="closePanel()">{{'cancel' | translate}}</button>
          <button mat-raised-button color="primary" class='button button--margin' (click)="createLink()">
            {{'create' | translate}}
          </button>
        </mat-action-row>

      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <hr/>

  <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef style="width:18%;padding:0 5px 0 10px;">{{'name' | translate}}</th>
      <td mat-cell style="padding:0 5px 0 10px;" *matCellDef="let element"
          (click)="expandedElement = expandedElement === element ? null : element"> {{element.name}} </td>
    </ng-container>
    <ng-container matColumnDef="link">
      <th mat-header-cell *matHeaderCellDef style="width:53%;padding-left: 20px;">
        {{'link' | translate}}
      </th>
      <td style="cursor: pointer;" mat-cell *matCellDef="let element" [ngClass]="'customWidthClass'"
          (click)="expandedElement = expandedElement === element ? null : element"> {{element.link}} </td>
    </ng-container>


    <ng-container matColumnDef="click">
      <th mat-header-cell *matHeaderCellDef
          style="width: 10%;padding-left: 10px;overflow: hidden; white-space: inherit; text-overflow: ellipsis;"
      >{{'numberOfClicks' | translate}}</th>
      <td mat-cell style="padding-left: 10px;" *matCellDef="let element"
          (click)="expandedElement = expandedElement === element ? null : element"> {{element.countClick}} </td>
    </ng-container>

    <ng-container matColumnDef="order">
      <th mat-header-cell *matHeaderCellDef
          style="width: 10%;overflow: hidden; white-space: inherit; text-overflow: ellipsis;">{{'numberOfOrders' | translate}}</th>
      <td mat-cell *matCellDef="let element"
          (click)="expandedElement = expandedElement === element ? null : element"> {{element.contOrder}} </td>
    </ng-container>


    <ng-container matColumnDef="delete">
      <th class="th-delete" mat-header-cell *matHeaderCellDef style="width: 10%"></th>
      <td style="cursor: pointer;line-height: 1px;" mat-cell *matCellDef="let element" (click)="deleteLink(element)">
        <mat-icon [ngStyle]="{'color':'red'}">highlight_off</mat-icon>
      </td>
    </ng-container>
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length" class="expand-row">
        <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
          <div class="example-element-diagram">
            <form style="padding: 10px 0 10px 24px;" [formGroup]="updateDynamicLinkForm">
              <mat-form-field style="margin: 0 25px 0 0;">
                <mat-label>{{'updateModalName' | translate}}</mat-label>
                <input matInput formControlName="updateName">
              </mat-form-field>

              <mat-form-field class="update-link">
                <mat-label>{{'updateModalLink' | translate}}</mat-label>
                <input matInput formControlName="updateLink">
              </mat-form-field>
              <button mat-raised-button color="primary" class='button'
                      (click)="updateLink(element)"> {{'updateLink' | translate}}
              </button>
            </form>
          </div>

        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
        class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="getRow(element)">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>

  <hr style="margin:70px 0"/>

  <h3>{{'numberOfClicks' | translate}}</h3>
  <div style="display: block">
    <canvas baseChart height="100"
            [datasets]="numberOfClicksData"
            [labels]="numberOfClicksLabels"
            [options]="lineChartOptions"
            [legend]="true"
            [chartType]="'line'">
    </canvas>
  </div>

  <hr style="margin:70px 0"/>

  <h3>{{'numberOfOrders' | translate}}</h3>
  <div style="display: block">
    <canvas baseChart height="100"
            [datasets]="numberOfOrdersData"
            [labels]="numberOfOrdersLabels"
            [options]="lineChartOptions"
            [legend]="true"
            [chartType]="'line'">
    </canvas>
  </div>

  <hr style="margin:70px 0"/>

  <div class="col-12">
    <div class="col align-self-center">
      <h4>{{'numberOfClicksPercentage' | translate}}</h4>
    </div>
    <canvas baseChart height="200"
            [data]="pieChartClicksData"
            [labels]="pieChartClicksLabel"
            [options]="PieChartOrdersOptions"
            [legend]="true"
            [chartType]="'pie'">
    </canvas>
  </div>

  <hr style="margin:70px 0"/>

  <div class="col-12">
    <div class="col align-self-center">
      <h4>{{'numberOfOrdersPercentage' | translate}}</h4>
    </div>
    <canvas baseChart height="200"
            [data]="pieChartOrdersData"
            [labels]="pieChartOrdersLabel"
            [options]="PieChartOrdersOptions"
            [legend]="true"
            [chartType]="'pie'">
    </canvas>
  </div>

</mat-card>
