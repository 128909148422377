import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent implements OnInit {
  @Input() text = '';
  @Input() textColor = '';
  @Input() color = '';
  @Input() width = '';
  @Input() height = '';
  @Input() img = '';
  @Input() fontSize = '18px';



  constructor() { }

  ngOnInit() {
  }

}
