import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Colors} from "../../Colors";
import {TranslateService} from "@ngx-translate/core";
import {ApiService} from "../../api.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-survey-form',
  templateUrl: './survey-form.component.html',
  styleUrls: ['./survey-form.component.css']
})
export class SurveyFormComponent implements OnInit {
  @Input() survey;
  @Input() filter;
  @Input() isNew;
  @Input() viewOnly;

  @Output() surveyChange = new EventEmitter<any>();


  colors = Colors;
  questionTypes = [{
    id: 1, name: 'freeInput'
  },
    {
      id: 2, name: 'options'
    }];
  isMobile = false;
  getQuestionTypeValue(typeId: number): number | null {
    return typeId === 1 ? 1 : 2;
  }
  constructor(private translate: TranslateService, private service: ApiService, private snackBar: MatSnackBar) {
  }

  ngOnInit() {
    if (window.innerWidth <= 900) {
      this.isMobile = true;
    }
    window.onresize = (e) => {
      if (window.innerWidth <= 900) {
        this.isMobile = true;
      }
    }
    if (!this.survey) {
      this.survey = {
        name: '',
        description: '',
        start: '',
        end: '',
        questions: []
      }
    }

    if (this.survey) {
      this.survey.questions.map(q => {
        if (q.option_answers) {
          q.answers = q.option_answers.split(",");
        }
        return q;
      })
    }
  }


  saveSurvey()
  {
    if (this.survey.questions.length == 0)
    {
      return
    }

    if (this.isNew == 1)
    {
      this.survey.filter = this.filter;

      this.service.createSurvey(this.survey).subscribe((data) => {

        this.snackBar.open(this.translate.instant('saved'), "OK", {duration: 3000});
        this.surveyChange.emit(data);

      }, (err) => {
        if (err.status == 470) {
          this.snackBar.open(err.error, "OK", {duration: 3000});
        } else {
          this.snackBar.open(this.translate.instant('thereWasAnError'), "OK", {duration: 3000});

        }
      })
    }
    else
    {
      this.service.updateSurvey(this.survey).subscribe((data) => {
        this.snackBar.open(this.translate.instant('saved'), "OK", {duration: 3000});
        this.surveyChange.emit(data);
      }, (err) => {
        if (err.status == 470)
        {
          this.snackBar.open(err.error, "OK", {duration: 3000});
        }
        else
        {
          this.snackBar.open(this.translate.instant('thereWasAnError'), "OK", {duration: 3000});
        }
      })
    }
  }

  addQuestion() {
    this.survey.questions.push({
      question: '',
      description: '',
      type: 0
    })
  }

  addAnswer(i) {
    const length = this.survey.questions[i].answers.length;
    this.survey.questions[i].answers.push("");

  }

  getAnswerPlaceholder(answerIndex) {
    return this.translate.instant("question") + " " + (Number(answerIndex) + 1);
  }

  deleteQuestion(index) {
    this.survey.questions.splice(index, 1);
  }

  padNumber(number) {
    return String(number).padStart(2, '0')
  }

  selectedQuestionType(index, type) {

    this.survey.questions[index].type = type;

    if (type == 2 && !this.survey.questions[index].answers) {
      this.survey.questions[index].answers = ["", "",]
    }

    if (type == 1) {
      this.survey.questions[index].answers = null;
    }
  }

  changedAnswer(questionIndex, answerIndex, value) {
    this.survey.questions[questionIndex].answers[answerIndex] = value;
  }

  // getAnswer(questionIndex) {
  //   if (this.survey.questions[questionIndex].type == 1 && this.survey.questions[questionIndex].answers != null) {
  //     return this.survey.questions[questionIndex].answers.isArray() ? '' : this.survey.questions[questionIndex].answers;
  //   }
  // }
  getAnswer(questionIndex: number): string | undefined {
    const question = this.survey.questions[questionIndex];

    if (!question) {
      return undefined;
    }

    const { type, answers } = question;

    if (type === 1 && answers != null) {
      return answers;
    }

    return answers;
  }
}
