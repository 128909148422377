import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {ApiService} from "../../../api.service";
import {TranslateService} from "@ngx-translate/core";
import {Observable} from "rxjs/Observable";
import {DatePipe} from "@angular/common";

@Component({
    selector: 'app-user-per-status',
    templateUrl: './user-per-status.component.html',
    styleUrls: ['./user-per-status.component.css']
})
export class UserPerStatusComponent implements OnInit {
    @Input() start;
    @Input() end;
    @Input() datesChanged: Observable<void>;
    @Input() refreshData: Observable<void>;

    loading = false;
    labels = [];
    data = [{}];
    options = {
        cutoutPercentage: 65,
      tooltips: {
        callbacks: {
          label: function(tooltipItem, data) {
            const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            const label = data.labels[tooltipItem.index];

            return value + '% - ( ' + label + ' )';
          }
        }
      }
    };
    numbers = [];
    colors = [
        {backgroundColor: ["#2D99FF", "#FF7300", "#2CD9C5", "#826AF9"]}
    ];
    total = 0;

    constructor(private service: ApiService, public translate: TranslateService, private datePipe: DatePipe) {
    }

    ngOnInit() {
        this.getCustomersPerStatus();
        this.labels = [
            this.translate.instant("userNoOrder"),
            this.translate.instant("loyalUserStatistics"),
            this.translate.instant("deletedAccount"),
            this.translate.instant("activeUserStatistics")
        ];


        this.datesChanged.subscribe((data: any) => {
            this.start = data.start;
            this.end = data.end;
            this.getCustomersPerStatus();
        });


    }

    getCustomersPerStatus() {
        this.loading = true;
        const start = this.datePipe.transform(this.start, 'yyyy-MM-dd');
        const end = this.datePipe.transform(this.end, 'yyyy-MM-dd');
        this.service.getStatisticsUserPerStatus(start, end).subscribe((apiData: any) => {
            this.data = [{data: apiData.data}];
            this.labels = apiData.labels.map(l => this.translate.instant(l));
            this.total = apiData.total;
            this.numbers = apiData.numbers;
            this.loading = false;
        })
    }


}
