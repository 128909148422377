import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {MAT_DIALOG_DATA} from "@angular/material";
import {Colors} from "../../Colors";
import {ApiService} from "../../api.service";

class DialogData {
  campaign: any;
  audiences: any[];
  mode: number;
}

@Component({
  selector: 'app-campaign-popup',
  templateUrl: './campaign-popup.component.html',
  styleUrls: ['./campaign-popup.component.css']
})
export class CampaignPopupComponent implements OnInit {
  totalCount = 0;
  audienceIds = [];
  buttons = [];
  selectedButton = null;
  constructor(public dialogRef: MatDialogRef<CampaignPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData, private service: ApiService) { }

  ngOnInit() {
    this.getEmailButtons();
    this.audienceIds = this.data.campaign.audience_ids.split(",");
    this.audienceIds = this.audienceIds.map(id => {
      return Number(id);
    });
    this.data.audiences.map((audience) => {
      audience.userCount = audience.user_ids.split(",").length;
      if (this.audienceIds.includes(audience.id)) {
        this.totalCount = this.totalCount + audience.userCount;
      }
      return audience;
    })
  }

  getEmailButtons() {
    this.service.getEmailButtons().subscribe((data: any[]) => {
      this.buttons = data;
      if (this.data.campaign.button_option) {
        console.log("has button: ", this.data.campaign.button_option);
        const index = this.buttons.findIndex((b) => b.id == this.data.campaign.button_option);
        if (index != -1) {
          console.log("index  ", index);
          this.selectedButton = this.buttons[index];
        }
      }
    });
  }

  getAudienceArray() {
    if (this.audienceIds) {
      const fineAudiences = this.data.audiences.filter((a) => this.audienceIds.includes(a.id));
      return fineAudiences;
    } else {
      return [];
    }
  }

  campaignChanged(campaign) {
    this.dialogRef.close(campaign);
  }

}
