import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-customs',
  templateUrl: './customs.component.html',
  styleUrls: ['./customs.component.css']
})
export class CustomsComponent implements OnInit {

  bookPrice = 0;
  bookXLPrice = 0;
  boxPrice = 0;
  framePrice = 0;
  frameXLPrice = 0;
  printsPrice = 0;
  calendarPrice = 0;
  totalPrice = 0;


  bookWeight = 0;
  bookXLWeight = 0;
  boxWeight = 0;
  frameWeight = 0;
  frameXLWeight = 0;
  printsWeight = 0;
  calendarWeight = 0;
  totalWeight = 0;
  hardMiniPrice = 0;
  hardMaxiPrice = 0;
  hardMiniWeight = 0;
  hardMaxiWeight = 0;
  hardMiniSPrice = 0;
  hardMaxiSPrice = 0;
  hardMiniSWeight = 0;
  hardMaxiSWeight = 0;

  tilesSquareCount = 0;
  tilesSquarePrice = 0;
  tilesSquareWeight = 0;

  tilesHexCount = 0;
  tilesHexPrice = 0;
  tilesHexWeight = 0;

  constructor(public dialogRef: MatDialogRef<CustomsComponent>) {
  }


  ngOnInit() {
    this.totalPrice = this.bookPrice + this.boxPrice + this.framePrice + this.frameXLPrice
      + this.bookXLPrice + this.printsPrice + this.calendarPrice + this.hardMiniPrice
    + this.hardMiniSPrice + this.hardMaxiSPrice + this.hardMaxiPrice + this.tilesSquarePrice + this.tilesHexPrice;
    this.bookWeight = this.bookWeight / 1000;
    this.bookXLWeight = this.bookXLWeight / 1000;
    this.boxWeight = this.boxWeight / 1000;
    this.frameWeight = this.frameWeight / 1000;
    this.frameXLWeight = this.frameXLWeight / 1000;
    this.printsWeight = this.printsWeight / 1000;
    this.calendarWeight = this.calendarWeight / 1000;
    this.hardMiniWeight = this.hardMiniWeight / 1000;
    this.hardMaxiWeight = this.hardMaxiWeight / 1000;
    this.hardMiniSWeight = this.hardMiniSWeight / 1000;
    this.hardMaxiSWeight = this.hardMaxiSWeight / 1000;
    this.tilesSquareWeight = this.tilesSquareWeight / 1000;
    this.tilesHexWeight = this.tilesHexWeight / 1000;

    this.totalWeight = +this.bookWeight.toFixed(2) + +this.boxWeight.toFixed(2)
      + +this.frameWeight.toFixed(2) + +this.frameXLWeight.toFixed(2)
      + +this.bookXLWeight.toFixed(2) + +this.printsWeight.toFixed(2)
      + +this.calendarWeight.toFixed(2) + +this.hardMiniWeight.toFixed(2) + +this.hardMaxiWeight.toFixed(2)
    + +this.hardMiniSWeight.toFixed(2) + +this.hardMaxiSWeight.toFixed(2) + +this.tilesSquareWeight.toFixed(2) +  +this.tilesHexWeight.toFixed(2);



  }

//merge

}
