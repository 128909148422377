<div class="survey-form-container">
    <div class="survey-form-box">
        <mat-form-field class="survey-form-box--text-field">
            <mat-label>{{"name" | translate}}</mat-label>
            <input matInput [(ngModel)]="survey.name" type="text" [disabled]="viewOnly == 1">
        </mat-form-field>

        <mat-form-field class="survey-form-box--text-field">
            <mat-label>{{"description" | translate}}</mat-label>
            <input matInput [(ngModel)]="survey.description" type="text"  [disabled]="viewOnly == 1">
        </mat-form-field>

        <mat-form-field class="survey-form-box--text-field">
            <mat-label>{{"startDate" | translate}}</mat-label>
            <input
                    [value]="survey.start"
                    matInput
                    [matDatepicker]="startDatePicker"
                    placeholder="{{'startDate' | translate}}"
                    (focus)="startDatePicker.open()"
                    (dateChange)="survey.start = $event.value.toISOString()"
                    [disabled]="viewOnly == 1"
            >
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker [touchUi]="isMobile" #startDatePicker></mat-datepicker>

        </mat-form-field>

        <mat-form-field class="survey-form-box--text-field">
            <mat-label>{{"endDate" | translate}}</mat-label>
            <input
                    [value]="survey.end"
                    matInput
                    [matDatepicker]="endDatePicker"
                    placeholder="{{'endDate' | translate}}"
                    (focus)="endDatePicker.open()"
                    (dateChange)="survey.end = $event.value.toISOString()"
                    [disabled]="viewOnly == 1"
            >
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker [touchUi]="isMobile" #endDatePicker></mat-datepicker>

        </mat-form-field>
    </div>
    <div class="survey-form-box" *ngFor="let q of survey.questions; let i = index">
        <div class="question-number">{{padNumber(i + 1)}}</div>
        <img class="question-delete" (click)="deleteQuestion(i)" src="assets/ic_trash.svg" *ngIf="viewOnly != 1">

        <mat-form-field class="survey-form-box--text-field">
            <mat-label>{{"question" | translate}}</mat-label>
            <input matInput [(ngModel)]="q.question" type="text"  [disabled]="viewOnly == 1">
        </mat-form-field>

        <mat-form-field class="survey-form-box--text-field">
            <mat-label>{{"description" | translate}}</mat-label>
            <input matInput [(ngModel)]="q.description" type="text"  [disabled]="viewOnly == 1">
        </mat-form-field>

        <div class="question-type" *ngIf="viewOnly != 1">
            <mat-label class="question-label" [ngClass]="{'invisible-label': q.type == 0}">{{'questionType' | translate}}</mat-label>
            <mat-select style="border-bottom: 1px solid rgba(0, 0, 0, 0.42)" placeholder="{{'questionType' | translate}}" [(ngModel)]="q.type"  (ngModelChange)="selectedQuestionType(i, $event)" [disabled]="viewOnly == 1">
                <mat-option *ngFor="let t of questionTypes" [value]="t.id">
                    {{t.name | translate}}
                </mat-option>
            </mat-select>
        </div>

        <div *ngIf="viewOnly == 1" class="survey-form-box--text-field">
          <mat-label class="question-label" [ngClass]="{'invisible-label': q.type == 0}">{{'questionType' | translate}}</mat-label>
          <input matInput [(ngModel)]="q.questionTypeValue" type="text"  [disabled]="viewOnly == 1">
        </div>

        <textarea *ngIf="q.type == 1" class="free-input-text" disabled placeholder="{{'freeInput' | translate}}...">{{getAnswer(i)}}</textarea>

        <div class="option-answers-container" *ngIf="q.type == 2">
            <input type="text" class="option-answer" [disabled]="viewOnly == 1" [value]="a" (change)="changedAnswer(i, answerIndex, $event.target.value)" [placeholder]="getAnswerPlaceholder(answerIndex)" *ngFor="let a of q.answers; let answerIndex = index">
        </div>

        <div class="survey-add-answer"  *ngIf="q.type == 2 && viewOnly != 1" (click)="addAnswer(i)">
            <img src="assets/ic_plus.svg"> {{'addAnswer' | translate}}
        </div>
    </div>
    <div class="survey-add-question" *ngIf="viewOnly != 1"  (click)="addQuestion()">
        <img src="assets/ic_plus.svg"> {{'addQuestion' | translate}}
    </div>
    <div class="button-container" *ngIf="viewOnly != 1" [ngClass]="{'disabled-button': survey.questions.length == 0}">

        <app-button text="{{'save' | translate}}" [color]="colors.PINK" [textColor]="colors.WHITE" height="48px"
                    width="110px" (click)="saveSurvey()">
        </app-button>
    </div>
</div>
