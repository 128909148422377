import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material";
import {MatDialogRef} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {ApiService} from "../api.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {DomSanitizer} from "@angular/platform-browser";

export interface DialogData {
  id?: number,
  media?: string,
  image?: string,
  mode?: string,
  thumbnail?: any,
  position?: number,
  positions?: number,
  isVideo?: boolean,
  thumbnailUploaded?: boolean
  thumbnailFormData?: any;
}

@Component({
  selector: 'app-detail-image',
  templateUrl: './detail-image.component.html',
  styleUrls: ['./detail-image.component.css']
})
export class DetailImageComponent implements OnInit {
  positions: any;

  constructor(
    public dialogRef: MatDialogRef<DetailImageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private translate: TranslateService, private apiService: ApiService, private snackBar: MatSnackBar, private dialog: MatDialog, private sanitize: DomSanitizer
  ) {
    console.log("data:  ", data);
  }

  ngOnInit() {
    this.positions = Array(this.data.positions + 1).fill(1).map((x, i) => i + 1);
  }

  uploadThumbnail() {
    const uploader = document.getElementById('upload-file-thumbnail');
    if (uploader) {
      uploader.click();
    }
  }

  onSelectFile(files: FileList) {
    const fileToUpload = files.item(0);
    const formData = new FormData();
    formData.append('image', fileToUpload, fileToUpload.name);
    this.data.thumbnail = this.sanitize.bypassSecurityTrustUrl(URL.createObjectURL(files[0]));
    this.data.thumbnailFormData = formData;
    this.data.thumbnailUploaded = true;
  }

}
