import {Component, OnInit,} from '@angular/core';
import {ApiService} from "../api.service";
import {MatDialog} from '@angular/material/dialog';
import {ConfirmModalComponent} from "../confirm-modal/confirm-modal.component";
import {TranslateService} from "@ngx-translate/core";
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatSnackBar} from "@angular/material/snack-bar";
import {ChartOptions} from 'chart.js';
import {Admin} from "../models";
import {Router} from "@angular/router";


@Component({
  selector: 'app-dynamic-links',
  templateUrl: './dynamic-links.component.html',
  styleUrls: ['./dynamic-links.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({height: '0px'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('325ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DynamicLinksComponent implements OnInit {
  columnsToDisplay: string[] = ['name', 'link', 'click', 'order', 'delete'];
  dataSource: any;
  expandedElement: any;
  nameText: string | undefined;
  isExpanded = false;
  createDynamicLinkForm: FormGroup = this.fb.group({
    name: ['', [Validators.required]],
    link:  ['', [Validators.required]],
  });

  updateDynamicLinkForm: FormGroup = this.fb.group({
    updateName: ['', [Validators.required]],
    updateLink: ['', [Validators.required]],
  });

  numberOfClicksData = [{data: [], label: 'number of clicks'}];
  numberOfClicksLabels = [];

  numberOfOrdersData = [{data: [], label: 'number of orders'}];
  numberOfOrdersLabels: string[] = [];

  pieChartClicksData = [];
  pieChartClicksLabel = [];

  /**
   * Delete X-axis labels
   */
  lineChartOptions: ChartOptions = {
    height: 300,
    scales: {
      xAxes: [
        {
          ticks: {
            display: false, //this removed the labels on the x-axis
          },
        },
      ],
    }
  }

  /**
   * Shrink Pie  Chart Legend
   */
  PieChartOrdersOptions: ChartOptions = {
    legend: {
      display: true,
      position: 'left',
    },
  }


  pieChartOrdersData = [];
  pieChartOrdersLabel = [];

  albumPercentageOptions = {
    height: 300,
    legend: {
      position: 'top',
    }
  };

  allowedEdit = false;
  user: Admin;


  constructor(private apiService: ApiService,
              private dialog: MatDialog,
              public translate: TranslateService,
              private fb: FormBuilder,
              private snackBar: MatSnackBar,
              private router: Router) {
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('adminYolo'));
    if (!this.checkPermission('dynamicLinks')) {
      this.router.navigate(['/']);
    }

    this.apiService.getAllDynamicLinks().subscribe(
      links => {
        console.log('Links', links);
        this.dataSource = links;
        this.numberOfClicksData = [];
        this.numberOfClicksLabels = [];
        this.numberOfOrdersData = [];
        this.numberOfOrdersLabels = [];

        const clicksOnLink = [];
        const OrdersOnLink = [];

        this.pieChartClicksData = this.dataSource.map(val => val.countClick);
        this.pieChartOrdersData = this.dataSource.map(val => val.contOrder);

        setTimeout(() => {
          this.numberOfOrdersLabels = this.dataSource.map(val => val.name);
          this.numberOfClicksLabels = this.dataSource.map(val => val.name);
          this.pieChartClicksLabel = this.dataSource.map(val => val.name);
          this.pieChartOrdersLabel = this.dataSource.map(val => val.name);
        }, 500)

        this.dataSource.forEach(data => {
          clicksOnLink.push(Number(data.countClick));
          OrdersOnLink.push(data.contOrder);
        })

        this.numberOfOrdersData.push(
          {data: OrdersOnLink, label: this.translate.instant('numberOfOrders')},
        );
        this.numberOfClicksData.push(
          {data: clicksOnLink, label: this.translate.instant('numberOfClicks')},
        );
      }
    )

    // this.apiService.getDynamicLink().subscribe(
    //   link => {
    //     console.log('link', link)
    //   }
    // )
  }

  checkPermission(page) {
    const permissions = this.user.permissions;
    const rightPermission = permissions.find((x) => x.page === page);
    if (rightPermission) {
      console.log('Proveraaaaaaaaa', rightPermission);
      if (rightPermission.edit === 1) {
        this.allowedEdit = true;
      } else {
        this.allowedEdit = false;
      }
      if (rightPermission.view === 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  getRow(element: any): void {
    if (this.allowedEdit) {
      this.updateDynamicLinkForm.patchValue({
        updateName: element.name,
        updateLink: element.link,
      });
    }
  }

  updateLink(element: any): void {
    if (this.allowedEdit) {
      const change = {
        id: element.id,
        name: this.updateDynamicLinkForm.controls.updateName.value,
        link: this.updateDynamicLinkForm.controls.updateLink.value,
      }

      const findLinkIndex = this.dataSource.findIndex(dynamic => {
        return dynamic.link === change.link
      })

      if (findLinkIndex !== -1) {
        this.snackBar.open(this.translate.instant('existingLink'), null, {
          duration: 3000, panelClass: ['custom-snack-bar']
        });
        return;
      }

      if (this.updateDynamicLinkForm.valid) {
        const openUpdateDialog = this.dialog.open(ConfirmModalComponent, {
          disableClose: true,
          width: '400px',
          height: '200px',
          position: {
            top: '150px',
          },
          data: {
            data: change,
            message: this.translate.instant('updateInfoText'),
            mode: 'update',
          },
        });
        openUpdateDialog.afterClosed().subscribe((result) => {
          if (result) {
            const findLinkIndex = this.dataSource.findIndex(index => {
              return index.id == result.id
            })

            if (findLinkIndex !== -1) {
              this.dataSource[findLinkIndex].id = result.id;
              this.dataSource[findLinkIndex].name = result.name;
              this.dataSource[findLinkIndex].link = result.link;
              this.expandedElement = undefined;
              this.snackBar.open(this.translate.instant('linkUpdated'), null, {
                duration: 3000, panelClass: ['custom-snack-bar']
              });
            }
          }
        });
      } else {
        this.snackBar.open(this.translate.instant('thereWasAnError'), null, {
          duration: 3000, panelClass: ['custom-snack-bar']
        });
      }
    } else {
      this.snackBar.open(this.translate.instant('notAllowed'), 'OK', {duration: 2000});
    }
  }

  deleteLink(deleteLink: any): void {
    if (this.allowedEdit) {
      this.expandedElement = undefined;
      const openDeleteDialog = this.dialog.open(ConfirmModalComponent, {
        disableClose: true,
        width: '400px',
        height: '200px',
        position: {
          top: '150px',
        },
        data: {
          data: deleteLink,
          message: this.translate.instant('deleteInfoText'),
          mode: 'delete',
        },
      });

      openDeleteDialog.afterClosed().subscribe((result) => {
        if (result) {
          const findDeleteIndex = this.dataSource.findIndex(index => {
            return index.id == result.id
          })

          if (findDeleteIndex !== -1) {
            this.dataSource.splice(findDeleteIndex, 1);
            this.dataSource = [...this.dataSource];
            this.snackBar.open(this.translate.instant('linkDeleted'), null, {
              duration: 3000, panelClass: ['custom-snack-bar']
            });
          }
        }
      });
    } else {
      this.snackBar.open(this.translate.instant('notAllowed'), 'OK', {duration: 2000});
    }
  }

  expand() {
    this.isExpanded = !this.isExpanded;
    if (this.isExpanded === false) {
      this.createDynamicLinkForm.reset();
    }
  }

  closePanel() {
    this.isExpanded = false;
    this.createDynamicLinkForm.reset();
  }

  createLink(): void {
    if (this.allowedEdit) {
      const findLinkIndex = this.dataSource.findIndex(dynamic => {
        return dynamic.link === this.createDynamicLinkForm.controls.link.value
      })

      if (findLinkIndex !== -1) {
        this.snackBar.open(this.translate.instant('existingLink'), null, {
          duration: 3000, panelClass: ['custom-snack-bar']
        });
        return;
      }

      if (this.createDynamicLinkForm.valid) {
        const linkForSend = {
          name: this.createDynamicLinkForm.controls.name.value,
          link: this.createDynamicLinkForm.controls.link.value,
        }

        const openCreateDialog = this.dialog.open(ConfirmModalComponent, {
          disableClose: true,
          width: '400px',
          height: '200px',
          position: {
            top: '150px',
          },
          data: {
            data: linkForSend,
            message: this.translate.instant('createInfoText'),
            mode: 'create',
          },
        });
        openCreateDialog.afterClosed().subscribe((result) => {
          if (result) {
            const findLinkIndex = this.dataSource.findIndex(index => {
              return index.id == result.id
            })

            if (findLinkIndex === -1) {
              this.dataSource.push(result);
              this.dataSource = [...this.dataSource];
              this.snackBar.open(this.translate.instant('linkCreated'), null, {
                duration: 3000, panelClass: ['custom-snack-bar']
              });
              this.closePanel();
            }
          }
        });
      } else {
        this.snackBar.open(this.translate.instant('thereWasAnError'), null, {
          duration: 3000, panelClass: ['custom-snack-bar']
        });
      }
    } else {
      this.snackBar.open(this.translate.instant('notAllowed'), 'OK', {duration: 2000});
    }
  }
}
