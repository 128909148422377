// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  url: "https://dev.yolobook.com",
  urlV2: "https://stage.yolobook.com",
  socketHost: "stage.yolobook.com",
  socketPort: 6001,

  firebase: {
    // tslint:disable-next-line:max-line-length
    apiKey: "AIzaSyDWemhoosDx6v5JFnv0SBv2e8Mp8MkoJXI",
    databaseURL: 'https://mango-243309.firebaseio.com',
    messagingSenderId: '654966034835'

  }

};
