<div class="header" style="background-color: #d92a73" >
    <span   style="font-size:1.7em; color:white; padding-left: 20px; font-weight: 500; ">
      {{'permissions' | translate}}
    </span>
</div>
<div class="container">
  <div style="margin-top:20px; margin-bottom:20px;">
    <mat-chip-list>
      <div *ngFor="let s of sectors;">
        <mat-chip (click)="changeSector(s['id'])" [ngClass]="{'chosen' : chosenSector === s['id']}"   style="cursor: pointer !important;">
          {{s['name']}}
        </mat-chip>
      </div>

    </mat-chip-list>
  </div>

  <div>
    <ngx-datatable
        #table
        style="margin-bottom: 70px;"
        class="material expandable"
        [rows]="permissions"
        [columnMode]="'force'"
        [headerHeight]="50"
        [footerHeight]="75"
        [rowHeight]="'auto'"
        [scrollbarH]="true"
        [loadingIndicator]="isLoading"
        [scrollbarV]="false" >
      <ngx-datatable-column sortable="false" prop="id" [width]="30">
        <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
          <span>{{value}}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column sortable="false" prop="page" name="{{'page' | translate}}" [width]="30">
        <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
          <span>{{value | translate}}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column sortable="false" prop="view" name="{{'view' | translate}}" [width]="30">
        <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
          <mat-checkbox [(ngModel)]="value" (change)="update(row, 'view', value)" ></mat-checkbox>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column sortable="false" prop="edit"  name="{{'edit' | translate}}"[width]="30">
        <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
          <mat-checkbox [(ngModel)]="value" (change)="update(row, 'edit', value)"></mat-checkbox>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>

  </div>

</div>
