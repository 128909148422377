import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef, MatDialogConfig} from "@angular/material/dialog";
import {MAT_DIALOG_DATA} from "@angular/material";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ApiService} from "../api.service";
import {TranslateService} from "@ngx-translate/core";
import {NotificationModalComponent} from "../notification-modal/notification-modal.component";

class DialogData {
  public total: number;
  public filter: any;
}

@Component({
  selector: 'app-send-notification',
  templateUrl: './send-notification.component.html',
  styleUrls: ['./send-notification.component.css']
})
export class SendNotificationComponent implements OnInit {
public notificationTitle = '';
public notificationBody = '';
public notificationImagePath = '';
  public notificationImageUrl = '';
  constructor(private dialog: MatDialogRef<SendNotificationComponent>,  private dialogNew: MatDialog, private apiService: ApiService, @Inject(MAT_DIALOG_DATA) public data: DialogData,  private SnackBar: MatSnackBar,private translate: TranslateService ) { }

  ngOnInit() {
  }

  openModalForNotifications() {
    if (this.notificationTitle.trim() == '' || this.notificationBody.trim() == '' ){
      this.SnackBar.open(this.translate.instant("completeAllFields"), 'OK',{duration : 3000});
      return;
    }
    const ids = -1;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'no-info-dialog';
    dialogConfig.data = {
      users: ids, message: this.notificationTitle,
      notif: {title: this.notificationTitle, body: this.notificationBody, imagePath: this.notificationImagePath, imageUrl: this.notificationImageUrl},
      filter: this.data.filter
    };
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    this.dialogNew.open(NotificationModalComponent, dialogConfig);
  }

  public onSelectFile(files: FileList) {
    const fileToUpload = files.item(0);
    console.log("MIME TYPE   ", fileToUpload.type);
    const formData = new FormData();
    formData.append('image', fileToUpload);
    this.apiService.uploadNotificationPhoto(formData).subscribe(
      (data: {path, url}) => {
        console.log("Upload OK: " + data.url);
        this.notificationImagePath = data.path;
        this.notificationImageUrl = data.url;
      });
  }

}
