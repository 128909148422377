import {Component, OnInit} from '@angular/core';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import {DataService} from "./data-service.service";
import {ThemeService} from "./theme.service";
import {main} from "./theme/theme";

declare global {
  interface Window {
    Pusher: any;
  }
}

window.Pusher = Pusher;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';
  currentUser = JSON.parse(localStorage.getItem('adminYolo'));
  apiKey = this.currentUser ? this.currentUser.apiKey : "";
  message: any;
  echo: any;

  echoOptions = {
    broadcaster: "pusher",
    key: "yolo",
    authEndpoint: "http://websockets.yolobook.com/broadcasting/auth",
    wsHost: "websockets.yolobook.com",
    auth: {
      headers: {
        accept: "application/json",
        Authorization: "Bearer " + this.apiKey
      },
    },
    wsPort: 6001,
    wssPort: 6001,
    disableStats: true,
    encrypted: false,
    forceTLS: false,
    enabledTransports: ["ws"],
    transports: ["websocket"],
    cluster: 'mt-1',
  }

  constructor(private dataService: DataService, private themeService: ThemeService) {
    this.themeService.setActiveTheme(main);
  }

  ngOnInit() {
    try {
      // Initialize Echo
      this.echo = new Echo(this.echoOptions);

      this.echo.connector.pusher.connection.bind('connected', () => {
        const socketId = this.echo.socketId();
        console.log('Socket connection established with ID: ' + socketId);

        this.echo.channel('admin_panel')
          .listen('.order_update', (message: string) => {
            console.log('Order update Broadcast received! ', message);
            this.dataService.updateOrder(message);
          })
          .listen('.download_complete', (data: any) => {
            console.log('Download prepared! ', data);
            this.dataService.sendNotification(data);
          })
          .listen('.test-1', (message: string) => {
            console.log('Broadcast received from channel (test-1)! ', message);
          })
          .listen('.test-2', (message: string) => {
            console.log('Broadcast received from channel (test-2)! ', message);
          });
      });

    } catch (err) {
      console.log(err);
    }
  }
}
