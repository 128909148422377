// Utility class for making REST API call in users
// demo :
// new PostUserData({id: "321", email: 'kralj@ja.ozb', foo: 'bar' });
// will bind value of properties in parameter provided Object to the corresponding Class properties.

export class FilterData {
  id = "";
  username = "";
  fullName = "";
  email = "";
  phone= "";
  fromDate = "";
  toDate = "";
  loginFrom = "";
  loginTo = "";
  isDeleted = "";
  device = "";
  appVersion = "";
  country = "";
  ordersCount = "";
  promoPercentage = "";
  averageSpent = "";
  lastOrderFrom = "";
  lastOrderTo = "";
  satisfactionOptions = [];

  constructor(data?: Object){
    data = data || {};
    // tslint:disable-next-line: forin
    for(const property in data) {
      (this[property] = data[property])
        || console.warn(`there is no such property as ${property} in ${this.constructor.name}`);
    }
  }
}

export class SortData {
  prop = "id";
  dir = "desc";

  constructor(data?: Object){
    data = data || {};
    // tslint:disable-next-line: forin
    for(const property in data) {
      typeof this[property] === 'string' && (this[property] = data[property])
        || console.warn(`there is no such property as ${property} in ${this.constructor.name}`);
    }
  }
}


