import {Component, OnInit} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {Router} from "@angular/router";
import {PrintUser, User} from "../models";
import {ApiService} from "../api.service";
import {MessagingService} from "../shared/messaging.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [ApiService]
})
export class LoginComponent implements OnInit {

  user: PrintUser = null;

  constructor(private loginService: ApiService, private router: Router,
              public snackBar: MatSnackBar, private messagingService: MessagingService) {

    const login = JSON.parse(localStorage.getItem('adminYolo'));

    console.log(login);

    if (login) {
      this.user = login;
    }
  }

  ngOnInit() {
    if (localStorage.getItem('adminYolo')) {
      this.router.navigate(['/main/profile']);
    }
  }

  onLogin(username, password, blur) {
    blur.focus();
    this.loginService.login(username, password).subscribe(
      (login: PrintUser) => {
        if (login) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('adminYolo', JSON.stringify(login));
          this.user = login;
          this.messagingService.requestPermission(this.user.id);
          this.router.navigate(['/main/profile']);
        }

      }, (error) => {
        localStorage.removeItem('adminYolo');
        const err = error;
        this.snackBar.open(err.error, null, {
          duration: 2000
        });
      });
  }

}
