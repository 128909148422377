<div class="statistics-box-data" *ngIf="!loading">
    <div class="for-chart">
        <canvas baseChart height="250"
                [datasets]="data"
                [labels]="labels"
                [legend]="false"
                [options]="options"
                [colors]="colors"
                [chartType]="'doughnut'"
                (chartHover)="chartHovered($event)">

        >

        </canvas>
    </div>
    <div class="total-div">
        <div class="total-amount">€ {{total}}</div>
        <div class="total-text">{{'total' | translate}}</div>

    </div>
    <div class="statistic-box-legend">
        <div *ngFor="let l of labels; let i = index" class="flex-legend">
            <div class="legend-box" [ngStyle]="{backgroundColor: colors[0].backgroundColor[i]}"></div>
            <span>{{l}} <span *ngIf="l == 1">{{'order' | translate}}</span><span
                    *ngIf="l > 1">{{'orders' | translate}}</span> </span>
        </div>
    </div>
</div>
<app-loading height="539px" *ngIf="loading"></app-loading>
