import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {Album, Order, Tracking} from "../models";
import {ApiService} from "../api.service";

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css'],
  providers: [ApiService]
})
export class InfoComponent implements OnInit {

  orderId: number;
  trackings: Tracking[];
  messages = {emptyMessage: 'Nema informacija', totalMessage: 'Ukupno'};
  isLoading = false;

  constructor(public dialogRef: MatDialogRef<InfoComponent>, private apiService: ApiService) {
  }

  ngOnInit() {
    this.isLoading = true;
    this.apiService.getTrackings(this.orderId).subscribe((trackings : Tracking[]) => {
      console.log(trackings);
      this.trackings = trackings;
      this.isLoading = false;
    });
  }
}
