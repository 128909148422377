import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Subject} from "rxjs/Subject";
import {Observable} from "rxjs";

@Injectable()
export class DataService {

  private refreshSource = new BehaviorSubject<string>('refresh');
  refresh = this.refreshSource.asObservable();
  private checkedSource = new BehaviorSubject<boolean>(false);
  allChecked = this.checkedSource.asObservable();
  private countrySource = new BehaviorSubject<string>('rs');
  country = this.countrySource.asObservable();
  private localeSource = new BehaviorSubject<string>('en');
  locale = this.localeSource.asObservable();
  notification: Subject<object> = new Subject();
  close: Subject<string> = new Subject();
  private navSource = new BehaviorSubject<string>('data');
  navbar = this.navSource.asObservable();
  private eventSource = new BehaviorSubject<object>(null);
  event = new BehaviorSubject(null);
  toRefresh = new BehaviorSubject(null);
  private orderUpdateAction = new BehaviorSubject<any>(null);
  orderUpdateObservable = this.orderUpdateAction.asObservable();

  private notificationSource = new Subject<any>();
  notification$ = this.notificationSource.asObservable();


  constructor() {
  }

  refreshing: Subject<object> = new Subject();
  removedRepair: Subject<object> = new Subject();
  removedEvent: Subject<number> = new Subject();
  changeDayOffs: Subject<number> = new Subject();
  reportChange: Subject<object> = new Subject();
  mergedOrders: Subject<object> = new Subject();
  changeOrderObject: Subject<object> = new Subject();


  public countryChange: Subject<string> = new Subject();
  readonly resetCounter$: Observable<string> = this.countryChange.asObservable();


  changeCountry(country: string) {
    console.log("Country changed!");
    this.countrySource.next(country);
  }

  changeLanguage(locale: string) {
    this.localeSource.next(locale);
  }

  newRefresh(value: string) {
    this.refreshSource.next(value);
  }

  addEvent(event: object) {
    console.log('event');
    this.eventSource.next(event);
  }

  openNavbar(data) {
    this.navSource.next(data);
  }

  changeChecked(data: boolean) {
    this.checkedSource.next(data);
  }

  updateOrder(data: any) {
    this.orderUpdateAction.next(data);
  }

  sendNotification(data: any) {
    this.notificationSource.next(data);
  }
}
