import { Component, OnInit } from '@angular/core';
import {ApiService} from "../api.service";
import * as FileSaver from 'file-saver';
import {saveAs} from "file-saver";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.css'],
  providers: [ApiService]
})
export class PrintComponent implements OnInit {

  constructor(private service: ApiService, private snackBar: MatSnackBar) { }

  ngOnInit() {
  }

  onPrintAddress(from, to) {

   /* this.service.getPrintAddress(from, to).subscribe((res) => {
      console.log(res.text());
      window.open('http://www.yoloapp.rs/' + res.text(), '_blank');
    }, (err) => {
      console.log(err);
      this.snackBar.open('Desila se greška!', null, { duration: 3000});
    } );*/

  }


}
