<div class="header" style="background-color: #d92a73">
  <span
    style="font-size:1.7em; color:white; padding-left: 20px; font-weight: 500; margin-bottom:10px;">
    {{'prices' | translate}}
  </span>
</div>
<div class="main-sub-header">
  <div class="main-sub-header__button main-sub-header--prices" *ngFor="let mode of modes" [ngClass]="{'main-sub-header__button--selected': mode.id == selectedMode}"
       (click)="changeMode(mode)">
    {{ mode.title | translate}}
  </div>

</div>

<app-prices *ngIf="selectedMode == 1"></app-prices>
<app-promo *ngIf="selectedMode == 2"></app-promo>
<app-upselling *ngIf="selectedMode == 3"></app-upselling>
