<div style="min-height: 93vh; padding-bottom: 100px; background-color: #eceff1;">
  <div class="header" style="background-color: #d92a73" [ngClass]="{'hiddenHeader' : !showHeader}">
    <span style="font-size:1.7em; color:white; padding-left: 20px; font-weight: 500; ">
     {{'products' | translate}}
    </span>
  </div>
  <div class="menu" [ngClass]="{'hiddenHeader' : !showHeader}"
       style="background-color: #d92a73; color:white; font-size:18px; font-weight: 500; padding: 15px 20px 10px;">
    <div class="labels-wrapper">
    <span class="menuItem noSelect" *ngFor="let p of products;let i = index;"
          [ngClass]="{'active' : currentPage == p.id}"
          (click)="toTop(p.id)" style="margin-right: 20px; cursor: pointer"> {{p.name}} </span>
    </div>
  </div>

  <div class="stickyMenu" *ngIf="!showHeader" style="background-color: #d92a73; color:white; font-size:18px;
 font-weight: 500; padding: 15px 20px 10px; position: fixed; z-index: 3; width: 100%; top:0;">
    <app-sticky-header class="stickyHeader" style="right: 0px; position: fixed;"></app-sticky-header>
    <div class="labels-wrapper">
    <span class="menuItem noSelect" id="{{p.id}}nav" *ngFor="let p of products;let i = index;"
          [ngClass]="{'active' : currentPage === p.id}" (click)="toTop(p.id)"
          style="margin-right: 20px; cursor: pointer;"> {{p.name}} </span>
    </div>
  </div>

  <div class="main" style="margin-top: 4vh; margin-left: 10vw; margin-right: 10vw;">
    <div *ngFor="let p of products" snInViewport
         (inViewportChange)="onInViewportChange(p.id,$event)" >
      <span style=" color:#424242; font-size: 2em;"> {{p.name}} </span>
      <div [id]="p.id" style="visibility: hidden; height: 3px;"></div>
      <div [ngClass]="{highlight: highlight, 'inactive': p.active == 0}" class="product row"
           style="background-color: white; height: 30vh; overflow: hidden; margin-bottom: 4vh; border-radius: 30px; -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75); -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75); box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);">
        <div class="col-6 product-image">
          <img
            style="object-fit: cover; position: absolute; margin-left: -1vw !important; width: 100%; height: 100%; margin-left: auto; margin-right: auto; display: block;"
            [src]="p.image">
          <button class="change-picture"  (click)="open(p)" mat-fab color="accent" title="{{'changeImage' | translate}}">
            <mat-icon>file_upload</mat-icon>
          </button>
        </div>
        <div class="col-6" style="text-align: center;">
          <div style="position: absolute;
          top: 50%; left: 50%;
          transform: translate(-50%,-50%);">
            <span class="product-box-title"
            > {{p.name}} </span>
            <br>
            <span
              style=" color:#424242; font-size: 1.2em; margin-top: 2vh; position:relative;"> {{p.options.descMini}} </span>
            <br>
            <span
              style=" color:#424242; font-size: 1.2em; margin-top: 2vh; position:relative;"> {{p.price}} {{p.currency}} </span>
            <br>
            <mat-button-toggle-group class="activeToggle">
              <mat-button-toggle  [disabled]="p.active == 1" [checked]="p.active == 1" (change)="changeProductActive(p, 1)">{{'active' | translate}}</mat-button-toggle>
              <mat-button-toggle  [disabled]="p.active == 0" [checked]="p.active == 0" (change)="changeProductActive(p, 0)">{{'inactive' | translate}}</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
      <div *ngFor="let s of p.children">
        <div class="product row"  [ngClass]="{'inactive': s.active == 0}"
        style="background-color: white; height: 30vh; overflow: hidden; margin-bottom: 4vh; border-radius: 30px; -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75); -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75); box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);">
        <div class="col-6">
          <img
            style="object-fit: cover; position: absolute;  margin-left: -1vw !important; width: 100%; height: 100%; margin-left: auto; margin-right: auto; display: block;"
            [src]="s.image">
          <button class="change-picture"  (click)="open(s)" mat-fab color="accent" title="{{'changeImage' | translate}}">
            <mat-icon>file_upload</mat-icon>
          </button>
        </div>
        <div class="col-6" style="text-align: center;">
          <div style="position: absolute;
             top: 50%; left: 50%;
           transform: translate(-50%,-50%);">
            <span class="product-box-title"> {{s.name}} </span>
            <br>
            <span style=" color:#424242; font-size: 1.2em; margin-top: 2vh;"> {{s.options.descMini}} </span>
            <br>
            <span style=" color:#424242; font-size: 1.2em; margin-top: 2vh;"> {{s.price}} {{s.currency}} </span>
            <br>
            <mat-button-toggle-group class="activeToggle">
              <mat-button-toggle [disabled]="s.active == 1" [checked]="s.active == 1" (change)="changeProductActive(s, 1)">{{'active' | translate}}</mat-button-toggle>
              <mat-button-toggle [disabled]="s.active == 0" [checked]="s.active == 0" (change)="changeProductActive(s, 0)">{{'inactive' | translate}}</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        </div>
        <div *ngFor="let ss of s.children" >
          <div class="product row" [ngClass]="{'inactive': ss.active == 0}"
               style="background-color: white; height: 30vh; overflow: hidden; margin-bottom: 4vh; border-radius: 30px; -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75); -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75); box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);">
            <div class="col-6">
              <img
                style="object-fit: cover; position: absolute;  margin-left: -1vw !important; width: 100%; height: 100%; margin-left: auto; margin-right: auto; display: block;"
                [src]="ss.image">
              <button class="change-picture"  (click)="open(ss)" mat-fab color="accent" title="{{'changeImage' | translate}}">
                <mat-icon>file_upload</mat-icon>
              </button>
            </div>
            <div class="col-6" style="text-align: center;">
              <div style="position: absolute;
             top: 50%; left: 50%;
           transform: translate(-50%,-50%);">
                <span class="product-box-title"> {{ss.name}} </span>
                <br>
                <span style=" color:#424242; font-size: 1.2em; margin-top: 2vh;"> {{ss.options.descMini}} </span>
                <br>
                <span style=" color:#424242; font-size: 1.2em; margin-top: 2vh;"> {{ss.price}} {{ss.currency}} </span>
                <br>
                <mat-button-toggle-group class="activeToggle">
                  <mat-button-toggle [disabled]="ss.active == 1" [checked]="ss.active == 1" (change)="changeProductActive(ss, 1)">{{'active' | translate}}</mat-button-toggle>
                  <mat-button-toggle [disabled]="ss.active == 0" [checked]="ss.active == 0" (change)="changeProductActive(ss, 0)">{{'inactive' | translate}}</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>

