import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ApiService} from "../api.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {mergeMap} from "rxjs/operators";
import {Colors} from "../Colors";

@Component({
  selector: 'app-cover-category-add-new',
  templateUrl: './cover-category-add-new.component.html',
  styleUrls: ['./cover-category-add-new.component.css']
})
export class CoverCategoryAddNewComponent implements OnInit {
  frontPic: any;
  backPic: any;
  amountValue = 0;
  frontImage: string | undefined;
  backImage: string | undefined;
  name = '';
  colors = Colors;
  selectedType = null;
  types = [];

  uploadedFrontImage: any;
  uploadedBackImage: any;

  constructor(public dialog: MatDialog,
              public dialogRef: MatDialogRef<CoverCategoryAddNewComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private apiService: ApiService,
              private snackBar: MatSnackBar,
              private translate: TranslateService,
  ) {
  }

  ngOnInit() {
    console.log(this.data);
    this.selectedType = this.data.type.type;
    this.types = this.data.types;
  }

  saveCoverCategory() {
    if (this.frontPic !== undefined ) {
      const sendData = {
        name: this.name,
        type: this.selectedType,
      }

      this.apiService.addNewCoverCategory(sendData).pipe(
        mergeMap((cover) => {
          const frontFormData: FormData = new FormData();
          frontFormData.append('image', this.frontImage);
          return this.apiService.uploadCategoryImage(cover.id, frontFormData);
        }),
      ).subscribe(val => {
        this.dialogRef.close(val);
      });
    } else {
      this.snackBar.open(this.translate.instant('PleaseAddPicture'), 'OK', {duration: 2000});
    }
  }

  readImageURL(event: any, type: string): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const reader = new FileReader();
      if (type === 'front') {
        this.frontImage = event.target.files[0];
        reader.onload = () => this.frontPic = reader.result;
        reader.readAsDataURL(file);
      } else if (type === 'back') {
        this.backImage = event.target.files[0];
        reader.onload = () => this.backPic = reader.result;
        reader.readAsDataURL(file);
      }
    }
  }
}
