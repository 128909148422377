import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material";
import {MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {DataService} from "../data-service.service";
import {ApiService} from "../api.service";
import {DialogData} from "../appointment/appointment.component";

@Component({
  selector: 'app-event-specifics',
  templateUrl: './event-specifics.component.html',
  styleUrls: ['./event-specifics.component.css']
})
export class EventSpecificsComponent implements OnInit {

  constructor(private apiService: ApiService, @Inject(MAT_DIALOG_DATA) public data: DialogData, private translate: TranslateService, private dataService: DataService, public snackBar: MatSnackBar, private service: ApiService, public dialogRef: MatDialogRef<EventSpecificsComponent>) { }


  ngOnInit() {
  }

  cancel() {
    const id = this.data['id'];

    if (this.data['type'] == 0) {
    //dayOff
    this.apiService.cancelDayOff(id).subscribe(
      data => {
        this.dialogRef.close();
        this.dataService.removedEvent.next(id);
      }
    )
    } else if (this.data['type'] == 1) {
      //appointment
      this.apiService.cancelAppointment(id).subscribe(
        data => {
          this.dialogRef.close();
          this.dataService.removedEvent.next(id);
        }
      )
    }

  }
}
