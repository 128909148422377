import { Component, OnInit } from '@angular/core';
import {Colors} from "../../../Colors";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-change-pass-modal',
  templateUrl: './change-pass-modal.component.html',
  styleUrls: ['./change-pass-modal.component.css']
})
export class ChangePassModalComponent implements OnInit {
  colors = Colors;
  password= '';
  confirmPassword = '';
  constructor(private snackBar: MatSnackBar, private translate: TranslateService, private dialog: MatDialogRef<ChangePassModalComponent>) { }

  ngOnInit() {
  }

  check() {
    if (this.password.length < 6) {
      this.snackBar.open(this.translate.instant("passwordMustBe6"), "OK", {duration: 1000});
    } else if (this.password != this.confirmPassword) {
      this.snackBar.open(this.translate.instant("passwordsNotSame"), "OK", {duration: 1000});
    } else {
      this.dialog.close(this.password);
    }
  }

  copyPassword() {
    navigator.clipboard.writeText(this.password);
  }

}
