import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-new-survey',
  templateUrl: './new-survey.component.html',
  styleUrls: ['./new-survey.component.css']
})
export class NewSurveyComponent implements OnInit {
  showHeader = false;
  totalUsers = 0;
  filter = null;
  savedFilter = null;

  constructor(private router: Router) { }

  ngOnInit() {
    if (localStorage.getItem('total-users')) {
      this.totalUsers = Number(localStorage.getItem('total-users'));
    }

    if (localStorage.getItem('user-filter')) {
      this.filter = JSON.parse(localStorage.getItem('user-filter'));
    }

    if (localStorage.getItem('user-saved-filter')) {
      this.savedFilter = JSON.parse(localStorage.getItem('user-saved-filter'));
    }
  }

  surveySent() {
    this.router.navigate(['main', 'surveys']);
  }

}
