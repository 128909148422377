import { Component, OnInit } from '@angular/core';
import {ApiService} from "../api.service";
import {DataService} from "../data-service.service";
import {Banner} from "../models";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";


@Component({
  selector: 'app-upload-banner',
  templateUrl: './upload-banner.component.html',
  styleUrls: ['./upload-banner.component.css']
})
export class UploadBannerComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<UploadBannerComponent>,  private apiService : ApiService, private dataService: DataService, private snackBar: MatSnackBar, private translate: TranslateService) { }
  banner: Banner = {path:"" , url:"", priority:1};
  ngOnInit() {
    this.dataService.country.subscribe((country) => {
      this.banner.country = country;
    });
  }


  public onSelectFile(files: FileList) {
    const fileToUpload = files.item(0);
    const formData = new FormData();
    formData.append('image', fileToUpload);
    this.apiService.uploadBanner(formData).subscribe(
      (data: {path, url}) => {
        this.banner.path = data.path;
        this.banner.url = data.url;

      });
  }

  saveBanner(){
    this.apiService.createBanner(this.banner).subscribe(
      (response)=>{
        this.snackBar.open(this.translate.instant('saved'), 'OK', {duration: 3000});
        this.dialogRef.close(response);
      }
    )
  }

}
