import {Component, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../../api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Order, UpsellingAction, User} from "../../models";
import {TranslateService} from "@ngx-translate/core";
import {Location} from '@angular/common';
import {Colors} from "../../Colors";
import {MatDialogConfig} from "@angular/material/dialog";
import {RatingCommentPopUpComponent} from "../../rating-comment-pop-up/rating-comment-pop-up.component";
import {MatDialog} from "@angular/material/dialog";
import {countries} from "../../Countries";
import {MatSnackBar} from "@angular/material/snack-bar";
import {FullImageComponent} from "../../full-image/full-image.component";
import {AddressModalComponent} from "./address-modal/address-modal.component";
import {ChangePassModalComponent} from "./change-pass-modal/change-pass-modal.component";
import {ConfirmModalComponent} from "../../confirm-modal/confirm-modal.component";
import {NewIssueComponent} from "./new-issue/new-issue.component";
import {MoreTextComponent} from "./more-text/more-text.component";
import {SurveyPopupComponent} from "../../survey/survey-popup/survey-popup.component";


@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: [`./user.component.css`]
})
export class UserComponent implements OnInit {
  profileId: string;
  profileData: User = {
    apiKey: "",
    appVersion: "",
    created_at: "",
    deviceTypeText: "",
    email: "",
    fullName: "",
    id: 0,
    isActive: 0,
    password: "",
    updated_at: "",
    username: ""
  };
  error = false;
  colors = Colors;
  mode: string;
  orders: Order[] = [];
  ratingOptions: any[] = [];
  ratingAdditionalOptions: any[] = [];
  orderStatuses: Array<any> = [
    {name: 'uploading', value: 1},
    {name: 'impartiallyDownloaded', value: 2},
    {name: 'processed', value: 3},
    {name: 'printing', value: 4},
    {name: 'sent', value: 5},
    {name: 'delivered', value: 6},
    {name: 'leftReport', value: 7},
    {name: 'returning', value: 8},
    {name: 'returned', value: 9},
    {name: 'unsuccessful', value: 10},
    {name: 'notwanted', value: 11}
  ];

  paymentTypes = [
    {id: 1, name: 'COD'},
    {id: 2, name: 'Card'},
    {id: 3, name: 'Referral'}];
  profileDataSummary: any;
  selectedOrder: Order;
  issueOptions: any;
  issues: any = [];
  surveys = [];

  constructor(private location: Location, private services: ApiService, private route: ActivatedRoute, private router: Router, private translate: TranslateService, private dialog: MatDialog, private snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.profileId = this.route.snapshot.paramMap.get('id');
    this.mode = this.route.snapshot.paramMap.get('mode');
    if (!this.mode || this.mode === '') {
      this.mode = 'details';
    }
    const orderId = this.route.snapshot.paramMap.get('orderId');

    if (orderId) {
      this.getOrder(orderId);
    }

    this.getRatings();
    this._getProfile();
    this._getSummary();
    this._getProfileOrders();
    this.getIssues();
    this.getSurveys();
    this.getIssueOptions();
  }

  _getProfile() {
    this.services.getUser(this.profileId)
      .subscribe((response: User) => {
        this.profileData = response;
        this.profileData.statusText = this.getStatus(this.profileData.status);
        this.profileData.fullCountry = this.getFullCountry(this.profileData.country);
        this.profileData.genderText = this.getTextGender(this.profileData.gender);
        if (this.profileData.device) {
          this.profileData.deviceTypeText = this.getDeviceTypeText(this.profileData.device.type);
        }
      }, err => {
        this.error = true;
      });
  }

  _getSummary() {
    this.services.getUserSummary(this.profileId)
      .subscribe((response) => {
        this.profileDataSummary = response;
      }, err => {
        this.error = true;
      });
  }

  getSummaryTitle(index) {
    switch (index) {
      case 0:
        return this.translate.instant("userSummaryJoined");
      case 1:
        return this.translate.instant("userSummaryLastLogin");
      case 2:
        return this.translate.instant("userSummaryLastOrderDate");
      case 3:
        return this.translate.instant("userSummaryTotalOrders");
      case 4:
        return this.translate.instant("userSummaryLastYearOrders");
      case 5:
        return this.translate.instant("userSummaryLastOrder");
      case 6:
        return 'AOV';
      case 7:
        return this.translate.instant("userSummaryAvgProduct");
      case 8:
        return this.translate.instant("userSummaryPromoAvg");
    }
  }

  getSummaryValue(index) {
    if (this.profileDataSummary)
      switch (index) {
        case 0:
          return this.profileDataSummary.joined;
        case 1:
          return this.profileDataSummary.lastLogin;
        case 2:
          return this.profileDataSummary.lastOrderTime;
        case 3:
          return this.profileDataSummary.totalOrders;
        case 4:
          return this.profileDataSummary.lastYearOrders;
        case 5:
          return this.profileDataSummary.lastOrder;
        case 6:
          return this.profileDataSummary.avg;
        case 7:
          return this.profileDataSummary.avgProduct;
        case 8:
          return this.profileDataSummary.promoAvg + "%";
      }
  }

  _getProfileOrders() {
    this.services.getUserOrders(this.profileId)
      .subscribe((response: Order[]) => {
        this.orders = response;
      }, err => {
        this.error = true;
      });
  }

  updateUser() {
    this.services.updateUser(this.profileData)
      .subscribe((response: User) => {
        const oldBirthday = this.profileData.birthday;
        this.profileData = response;
        this.profileData.birthday = oldBirthday;
        this.profileData.statusText = this.getStatus(this.profileData.status);
        this.profileData.fullCountry = this.getFullCountry(this.profileData.country);
        this.profileData.genderText = this.getTextGender(this.profileData.gender);
        this.snackBar.open(this.translate.instant('saved'), '', {duration: 2000});
        if (this.profileData.device) {
          this.profileData.deviceTypeText = this.getDeviceTypeText(this.profileData.device.type);
        }
      }, err => {
        this.error = true;
      });
  }

  updateAdditional() {
    console.log(this.profileData);
    this.services.updateAdditional(this.profileData)
      .subscribe((response: User) => {
        this.snackBar.open(this.translate.instant('saved'), '', {duration: 2000});
      }, err => {
        this.snackBar.open(this.translate.instant('thereWasAnError'), '', {duration: 2000});
      });
  }

  updateAddress(address) {
    this.services.updateAddress(address).subscribe((res) => {
      this.snackBar.open(this.translate.instant("saved"), "", {duration: 1000});
    }, (err) => {
      this.snackBar.open(this.translate.instant("thereWasAnError"), "", {duration: 1000});
    })
  }

  addAddress() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {userId: this.profileData.id};
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    const dialogOpen = this.dialog.open(AddressModalComponent, dialogConfig);

    dialogOpen.afterClosed().subscribe((address) => {
      if (address) {
        address.userId = this.profileData.id;
        this.services.createAddress(address).subscribe((newAddress: any) => {
          console.log(newAddress);
          this.profileData.addresses.push(newAddress);
          this.snackBar.open(this.translate.instant("saved"), "", {duration: 1000});
        }, (err) => {
          this.snackBar.open(this.translate.instant("thereWasAnError"), "", {duration: 1000});
        })
      }
    });
  }

  changePassword() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'appointment-info-dialog';
    dialogConfig.data = {userId: this.profileData.id};
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    const dialogOpen = this.dialog.open(ChangePassModalComponent, dialogConfig);

    dialogOpen.afterClosed().subscribe((pass) => {
      if (pass) {
        const data = {"password": pass, userId: this.profileData.id};
        this.services.changePassword(data).subscribe(() => {
          this.snackBar.open(this.translate.instant("saved"), "", {duration: 1000});
        }, (err) => {
          this.snackBar.open(this.translate.instant("thereWasAnError"), "", {duration: 1000});
        })
      }
    });
  }

  getAddressTypeText(type) {
    if (type === 1) {
      return this.translate.instant("house");
    } else if (type === 2) {
      return this.translate.instant("work");
    } else if (type === 3) {
      return this.translate.instant("other");
    }
  }

  getTextGender(gender) {
    if (gender == 1) {
      return this.translate.instant('male');
    } else if (gender == 2) {
      return this.translate.instant('female');
    } else {
      return this.translate.instant('other');

    }
  }

  getDeviceTypeText(type) {
    if (type === 1) {
      return 'iOS';
    } else if (type === 2) {
      return 'Android';
    } else {
      return this.translate.instant('other');
    }
  }

  getFullCountry(country) {
    if (country == 'rs' || country == 'sr') {
      return this.translate.instant('serbia');
    } else if (country == 'ba' || country == 'be' || country == 'bh') {
      return this.translate.instant('bosnia');
    } else if (country == 'hr') {
      return this.translate.instant('croatia');
    } else if (country == 'ro') {
      return this.translate.instant('romania');
    } else if (country == 'bg') {
      return this.translate.instant('bulgaria');
    } else if (country == 'eu') {
      return this.translate.instant('eu');
    }
  }

  getStatus(status) {
    if (status === 0) {
      return this.translate.instant('inactive');
    } else if (status === 1) {
      return this.translate.instant('active');
    } else if (status === 2) {
      return this.translate.instant('loyal');
    }
  }

  goBack() {
    this.router.navigate(['./main', 'users']);
  }


  getRatings() {
    this.services.getRatingOptions().subscribe((data: any) => {
      this.ratingOptions = data.ratingTypes.reverse();
      this.ratingAdditionalOptions = data.additionalTypes;
    })
  }

  getIssueOptions() {
    this.services.getIssueOptions().subscribe((data: any) => {
      this.issueOptions = data;
    })
  }

  getIssues() {
    this.services.getIssues(this.profileId).subscribe((data) => {
      this.issues = data;
    })
  }

  getSurveys() {
    this.services.getUserSurveys(this.profileId).subscribe((data: any[]) => {
      this.surveys = data;
    })
  }

  getIssueOption(type, id) {
    let value = '';
    this.issueOptions[type].forEach(option => {
      if (option.id == id) {
        value = option;
      }
    })
    return value;
  }

  getOrder(id) {
    this.services.getOrderById(id).subscribe((data: any) => {
      this.selectedOrder = data;
    })
  }

  getDeviceImg(deviceId) {
    switch (deviceId) {
      case 1:
        return 'assets/ios.png';
      case 2:
        return 'assets/android.png';
      case 3:
        return 'assets/web.png';
      default:
        return '';
    }
  }

  changeMode(newMode) {
    if (newMode != this.mode) {
      this.mode = newMode;
      this.router.navigate([`main`, 'users', this.profileId, newMode]);
    }
  }

  openUsers() {
    this.router.navigate([`main`, 'users']);
  }

  getItems(order) {
    let items = "";
    order.items.forEach((item, index) => {
      const name = item.product.name;
      if (index == 0) {
        items = items + name;
      } else {
        items = items + " • " + name;
      }
    });
    return items;
  }

  getTranslation(value) {
    return this.translate.instant(value);
  }

  getStatusText(status) {
    const fullStatus = this.orderStatuses.find(s => s.value === status);
    if (fullStatus) {
      return this.translate.instant(fullStatus.name);
    } else {
      return "";
    }
  }

  getSatisfaction(satisfaction) {
    if (!satisfaction) {
      return "/";
    }
    const value = satisfaction.rating;
    const rating = this.ratingOptions.find(o => o.id == value);
    if (rating) {
      return rating.name;
    } else {
      return "/";
    }

  }


  openOrder(order) {
    this.mode = 'order';
    this.selectedOrder = order;
    this.router.navigate([`main`, 'users', this.profileId, this.mode, this.selectedOrder.id]);

  }

  getSatisfactionReason(satisfaction) {
    const value = satisfaction.additionalTypes && satisfaction.additionalTypes[0] ? satisfaction.additionalTypes[0].id : null;
    const rating = this.ratingAdditionalOptions.find(o => o.id == value);
    if (rating) {
      return rating.name;
    } else {
      return "/";
    }
  }

  openMessage(value) {
    const rating = this.getSatisfaction(value);
    const reason = this.getSatisfactionReason(value);
    const message = value.comment;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'no-info-dialog';
    dialogConfig.data = {rating, reason, message};
    dialogConfig.disableClose = false;

    dialogConfig.autoFocus = true;
    dialogConfig.width = '450px';
    dialogConfig.position = {
      top: '150px',
    };
    this.dialog.open(RatingCommentPopUpComponent, dialogConfig);
  }

  changeOrderStatus(event) {
    this.services.setOrderStatus(this.selectedOrder.id, this.selectedOrder.status).subscribe((res) => {
      this.snackBar.open(this.translate.instant("saved"), "", {duration: 1000});
    }, (err) => {
      this.snackBar.open(this.translate.instant("thereWasAnError"), "", {duration: 1000});
    })
  }

  changeOrderFree(event) {
    this.services.toggleOrderFree(this.selectedOrder.id, this.selectedOrder.is_free).subscribe((res: any) => {
      this.selectedOrder = res;
      this.snackBar.open(this.translate.instant("saved"), "", {duration: 1000});
    }, (err) => {
      this.snackBar.open(this.translate.instant("thereWasAnError"), "", {duration: 1000});
    })
  }

  changePayingMethod(event) {
    this.services.changePaymentType(this.selectedOrder.id, this.selectedOrder.payingMethod).subscribe((res: any) => {
      this.selectedOrder = res;
      this.snackBar.open(this.translate.instant("saved"), "", {duration: 1000});
    }, (err) => {
      this.snackBar.open(this.translate.instant("thereWasAnError"), "", {duration: 1000});
    })
  }

  changeOrderDelivery() {
    const data = {id: this.selectedOrder.id, delivery: this.selectedOrder.delivery};
    this.services.updateOrderDeliveryPrice(data).subscribe((res: any) => {
      this.selectedOrder = res;
      this.snackBar.open(this.translate.instant("saved"), "", {duration: 1000});
    }, (err) => {
      this.snackBar.open(this.translate.instant("thereWasAnError"), "", {duration: 1000});
    })
  }

  changeOrderPromoCode() {
    this.services.updateOrderPromo(this.selectedOrder).subscribe((res: any) => {
      this.selectedOrder = res;
      this.snackBar.open(this.translate.instant("saved"), "", {duration: 1000});
    }, (err) => {
      this.snackBar.open(this.translate.instant("thereWasAnError"), "", {duration: 1000});
    })
  }

  duplicateItem(item) {
    const openDeleteDialog = this.dialog.open(ConfirmModalComponent, {
      disableClose: true,
      width: '400px',
      height: '200px',
      position: {
        top: '150px',
      },
      data: {
        data: '',
        message: this.translate.instant('areYouSureYouWantToDuplicateAlbum'),
        mode: 'other',
      },
    });
    openDeleteDialog.afterClosed().subscribe((response) => {
      if (response) {
        this.services.copyAlbum(item.id).subscribe((res: any) => {
          this.snackBar.open(this.translate.instant("saved"), "", {duration: 1000});
          this.selectedOrder = res;
        }, (err) => {
          this.snackBar.open(this.translate.instant("thereWasAnError"), "", {duration: 1000});
        })
      }
    });
  }

  deleteItem(item) {
    const openDeleteDialog = this.dialog.open(ConfirmModalComponent, {
      disableClose: true,
      width: '400px',
      height: '200px',
      position: {
        top: '150px',
      },
      data: {
        data: '',
        message: this.translate.instant('areYouSureYouWantToDeleteAlbum'),
        mode: 'other',
      },
    });
    openDeleteDialog.afterClosed().subscribe((response) => {
      if (response) {
        this.services.deleteAlbum(item.id).subscribe((res) => {
          this.snackBar.open(this.translate.instant("saved"), "", {duration: 1000});
          const index = this.selectedOrder.items.findIndex(i => i.id == item.id);
          if (index != -1) {
            this.selectedOrder.items.splice(index, 1);
          }
        }, (err) => {
          this.snackBar.open(this.translate.instant("thereWasAnError"), "", {duration: 1000});
        })
      }
    });
  }

  deleteOrder() {
    const openDeleteDialog = this.dialog.open(ConfirmModalComponent, {
      disableClose: true,
      width: '400px',
      height: '200px',
      position: {
        top: '150px',
      },
      data: {
        data: '',
        message: this.translate.instant('areYouSureYouWantToDeleteOrder'),
        mode: 'other',
      },
    });
    openDeleteDialog.afterClosed().subscribe((response) => {
      if (response) {
        this.services.deleteOrder(this.selectedOrder.id).subscribe((res) => {
          this.snackBar.open(this.translate.instant("saved"), "", {duration: 1000});
          this.router.navigate([`main`, 'users', this.profileId, 'orders']);

        }, (err) => {
          this.snackBar.open(this.translate.instant("thereWasAnError"), "", {duration: 1000});
        })
      }
    });
  }

  copyOrder() {
    const openDeleteDialog = this.dialog.open(ConfirmModalComponent, {
      disableClose: true,
      width: '400px',
      height: '200px',
      position: {
        top: '150px',
      },
      data: {
        data: '',
        message: this.translate.instant('areYouSureYouWantToDuplicateOrder'),
        mode: 'other',
      },
    });
    openDeleteDialog.afterClosed().subscribe((response) => {
      if (response) {
        this.services.copyOrder(this.selectedOrder.id).subscribe((res: any) => {
          this.snackBar.open(this.translate.instant("saved"), "", {duration: 1000});
          this.router.navigate([`main`, 'users', this.profileId, 'order', res.id]);
          this.selectedOrder = res;

        }, (err) => {
          this.snackBar.open(this.translate.instant("thereWasAnError"), "", {duration: 1000});
        })
      }
    })
  }

  roundNumber(number, decimals) {
    return number.toFixed(decimals);
  }

  getOrderPrice() {
    let sum = 0;
    let sumEur = 0;
    this.selectedOrder.items.forEach(i => {
      sum = sum + i.price;
      sumEur = sumEur + i.priceEur;
    })
    return this.selectedOrder.currency + " " + this.roundNumber(sum, 2) + " " + "(€ " + this.roundNumber(sumEur, 2) + ")";
  }

  getDeliveryPrice() {
    return this.selectedOrder.currency + " " + this.selectedOrder.delivery + " " + "(€ " + this.selectedOrder.deliveryEur + ")";
  }

  getIsFree() {
    if (this.selectedOrder.is_free) {
      return this.translate.instant('Yes');
    } else {
      return this.translate.instant('No');
    }
  }

  getPaymentType() {
    const index = this.paymentTypes.findIndex(t => t.id == this.selectedOrder.payingMethod);

    if (index != -1) {
      return this.paymentTypes[index].name;
    }
  }

  changedItemPrice(item) {
    const data = {id: item.id, price: item.price, orderId: this.selectedOrder.id};
    this.services.changeAlbumPrices(data).subscribe((res: any) => {
      this.snackBar.open(this.translate.instant('saved'), '', {duration: 2000});
      this.selectedOrder = res;
    }, (err) => {
      this.snackBar.open(this.translate.instant('thereWasAnError'), '', {duration: 2000});
    });
  }

  updateOrderAddress() {
    this.services.updateOrder(this.selectedOrder).subscribe((res: any) => {
      this.snackBar.open(this.translate.instant('saved'), '', {duration: 2000});
      this.selectedOrder = res;
    }, (err) => {
      this.snackBar.open(this.translate.instant('thereWasAnError'), '', {duration: 2000});
    });
  }


  addNewIssue() {
    const openNewIssueDialog = this.dialog.open(NewIssueComponent, {
      disableClose: true,
      width: '450',
      position: {
        top: '150px',
      },
      data: {
        issueOptions: this.issueOptions
      }
    });
    openNewIssueDialog.afterClosed().subscribe((issue) => {
      if (issue) {
        issue.user_id = this.profileData.id;
        this.services.createIssue(issue).subscribe((res) => {
          this.issues.push(res);
          this.issues = [...this.issues];
          this.snackBar.open(this.translate.instant("saved"), "", {duration: 1000});

        }, (err) => {
          this.snackBar.open(this.translate.instant("thereWasAnError"), "", {duration: 1000});
        })
      }
    })
  }

  openMore(row) {
    const seeMoreDialog = this.dialog.open(MoreTextComponent, {
      disableClose: true,
      width: '450',
      position: {
        top: '150px',
      },
      data: {
        text: row.type_explanation
      }
    });
  }

  changedResolutionDate(value, row) {
    row.resolution_date = value;
    this.updateIssue(row);
  }

  updateIssue(issue) {
    this.services.updateIssue(issue).subscribe((res) => {
      this.issues.push(res);
      this.snackBar.open(this.translate.instant("saved"), "", {duration: 1000});

    }, (err) => {
      this.snackBar.open(this.translate.instant("thereWasAnError"), "", {duration: 1000});
    })
  }

  toggleBlock() {
    let message = this.translate.instant('areYouSureYouWishToBlock');

    if (this.profileData.blocked) {
      message = this.translate.instant('areYouSureYouWishToUnblock');

    }
    const openDeleteDialog = this.dialog.open(ConfirmModalComponent, {
      disableClose: true,
      width: '400px',
      height: '200px',
      position: {
        top: '150px',
      },
      data: {
        data: '',
        message: message,
        mode: 'other',
      },
    });
    openDeleteDialog.afterClosed().subscribe((response) => {
      if (response) {
        this.services.toggleBlock(this.profileId).subscribe(() => {
          this.snackBar.open(this.translate.instant("saved"), "", {duration: 1000});
          this.profileData.blocked = !this.profileData.blocked;
        }, (err) => {
          this.snackBar.open(this.translate.instant("thereWasAnError"), "", {duration: 1000});

        })
      }
    })
  }

  showSurvey(survey) {
    const mode = 1
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'no-info-dialog';
    dialogConfig.data = {survey, mode};
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    const dialogOpen = this.dialog.open(SurveyPopupComponent, dialogConfig);

  }

  getRowClass(row): any {

    if (row) {
      if (row.status == 4)
        return 'issue-active';
      if (row.status == 5)
        return 'issue-resolved';
    }


  }

  protected readonly countries = countries;
}
