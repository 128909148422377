<div class="statistics-box-data" *ngIf="!loading">
  <canvas baseChart height="400"
          [datasets]="data"
          [labels]="labels"
          [legend]="true"
          [options]="options"
          [chartType]="'line'">

  </canvas>

</div>
<app-loading height="200px" *ngIf="loading"></app-loading>
