<div class="statistics-box-data" *ngIf="!loading">
  <div class="toggle-box">
    <mat-slide-toggle [(ngModel)]="toggle" (change)="toggleChanged($event)"><span [ngClass]="{'selected-toggle': toggle}">Live</span></mat-slide-toggle>
  </div>
  <div class="product-select">
  <mat-select multiple [(ngModel)]="selectedProducts" (selectionChange)="selectedProductsChanged()" placeholder="{{'selectProduct' | translate}}">
    <mat-option [value]="p.type" *ngFor="let p of products">{{p.name}}</mat-option>
  </mat-select>
  </div>
  <canvas baseChart height="100"
          [datasets]="data"
          [labels]="labels"
          [legend]="false"
          [colors]="colors"
          [options]="barChartOptions"
          [chartType]="'horizontalBar'">

  </canvas>
  <div class="statistic-box-legend">
    <div *ngFor="let l of labels; let i = index" class="flex-legend">
      <div class="legend-box" [ngStyle]="{backgroundColor: colors[0].backgroundColor[i]}"></div>
      <span>{{l}} | {{numbers[i]}} </span>
    </div>
  </div>
</div>
<app-loading height="462px"  *ngIf="loading"></app-loading>
