<section class="add-new-cover">
  <div>
    <h2 class="margin-title">{{'addNewCoverCategory' | translate}}</h2>
  </div>
  <article class="picture-container">
    <mat-label>{{"frontPic" | translate}}</mat-label>
    <div class="image-wrapper">
      <div class="image">
        <input type="file" name="file" id="front-image" class="input-file"
               (change)="readImageURL($event, 'front');" accept="image/*"/>
        <img class="image-preview" [src]="frontPic" alt="your image" *ngIf="frontPic"/>
        <div class="upload-text-holder">
          <span class="update-text">{{'choosePic' | translate}}</span>
        </div>
      </div>
    </div>
  </article>
  <mat-form-field >
  <mat-label>{{"name" | translate}}</mat-label>
  <input matInput type="text" [(ngModel)]="name">
  </mat-form-field>
  <mat-form-field class="details-box__user_field">
    <mat-label>{{"selectProduct" | translate}}</mat-label>
    <mat-select [(value)]="selectedType">
      <mat-option *ngFor="let type of types" [value]="type.type">{{type.name}}</mat-option>
    </mat-select>
  </mat-form-field>
  <div class="buttons-container">
    <app-button text="{{'save' | translate}}" [color]="colors.PINK" [textColor]="colors.WHITE" height="48px" width="205px" (click)="saveCoverCategory()">
    </app-button>
  </div>
</section>
