import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from "../api.service";
import {MatDialog, MatDialogConfig } from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Admin, Product} from "../models";
import {ProductdialogComponent} from "../productdialog/productdialog.component";


@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css'],
  providers: [ApiService]
})
export class ProductsComponent implements OnInit{
 user: Admin;
 showHeader = true;
 currentPage = 0;
 products: Array<object> = [];
 loading = true;
  private highlight: any;
  constructor(private service: ApiService, private snackBar: MatSnackBar, public dialog: MatDialog) {
  }

  ngOnInit() {
    window.addEventListener('scroll', this.scroll, true);
    this.user = JSON.parse(localStorage.getItem('adminYolo'));

    try {
      if (localStorage.getItem('products')) {
        this.products = JSON.parse(localStorage.getItem('products'));
      } else {
        this.loading = true;
      }
      this.getProducts();
    } catch (e) {
      this.loading = true;
      this.getProducts();
    }
  }


  toTop(id) {
    document.getElementById(id).scrollIntoView({behavior: "smooth", block: "start"});
  }

  getProducts() {
    this.service.getProducts({isActive: 1, country: localStorage.getItem('country')}).subscribe((res: Product[]) => {
      this.products = res;
      this.loading = false;
      localStorage.setItem('products', JSON.stringify(this.products));
    });
  }


  scroll = (): void => {
    if (window.pageYOffset > 110) {
      this.showHeader = false;
    } else {
      this.showHeader = true;
    }
  };

  onInViewportChange(type, event){
    if (event == true){
      this.currentPage = type;
      this.toCenter(type + 'nav');
    }
  }

  toCenter(id){
    if (document.getElementById(id) != null){
      document.getElementById(id).scrollIntoView({ inline: 'center',behavior: "smooth"});
    }
  }

  open(product){
      const oldProduct = JSON.stringify(product);
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = product;
      dialogConfig.panelClass = 'product-info-dialog';
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      const productDialog = this.dialog.open(ProductdialogComponent, dialogConfig);
      productDialog.afterClosed().subscribe((data: any) => {
        if (data) {
          this.products.forEach((p: any) => {
            if (p.id == product.id) {
              p.image = data.image;
              return;
            }
            p.children.forEach((s: any) => {
              if (s.id == product.id) {
                s.image = data.image;
                return;
              }
              s.children.forEach((ss: any) => {
                if (ss.id == product.id) {
                  ss.image = data.image;
                  return;
                }
              })
            })
          })

          this.products = [...this.products];
          localStorage.setItem('products', JSON.stringify(this.products));
        } else {
          const newOldProduct = JSON.parse(oldProduct);
          const productIndex = this.products.findIndex((p: any) => {
            return p.id == product.id;
          });
          if (productIndex != -1) {
            this.products[productIndex] = newOldProduct;
          }
        }
      })

  }


  changeProductActive(product, state) {
      product.active = state;
      this.service.changeProductActiveState(product).subscribe((data: any) => {
        if (data) {
          this.products.forEach((p: any) => {
            if (p.id == product.id) {
              p.active = data.active;
              return;
            }
            p.sub.forEach((s: any) => {
              if (s.id == product.id) {
                s.active = data.active;
                return;
              }
              s.sub.forEach((ss: any) => {
                if (ss.id == product.id) {
                  ss.active = data.active;
                  return;
                }
              })
            })
          })

          this.products = [...this.products];
          localStorage.setItem('products', JSON.stringify(this.products));
        }
      });
  }
}


