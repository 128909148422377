<div class="big" style="padding:20px; overflow: auto; height: 93.5vh;">
  <h3 *ngIf="data === null && data['mode'] !== 'view'"> {{'editEmployee' | translate}}</h3>
  <h3 *ngIf="data['mode'] == 'new'"> {{'addNewEmployee' | translate}}</h3>
  <div class="viewEmployee" *ngIf="data['mode'] == 'view'" style="text-align: center">
    <h3><b>{{admin.username}} </b></h3>
    <br>
    <img src="assets/avatar.svg" *ngIf="!admin.image"
         style="border-radius: 50%; width: 150px; height: 150px; object-fit: cover; position: relative; margin-left: auto; margin-right: auto; display: block;">
    <img [src]="admin.image" *ngIf="admin.image"
         style="border-radius: 50%; width: 150px; height: 150px; object-fit: cover; margin-left: auto; margin-right: auto; display: block;">
    <br>
    <h3> {{admin.fullName}}</h3>
    {{admin.sector}} <br>
    {{admin.position}} <br>
    {{admin.dateOfBirth}} <br>
    {{admin.email}} <br>
    {{admin.phone}} <br>
    {{admin.address}} <br>

  </div>
  <hr>
  <mat-tab-group #tabGroup *ngIf="data['mode'] !== 'view'">
    <mat-tab label="Info">
      <div class="row" style="margin-top: 20px; margin-left: 0px !important; margin-right: 0px !important;">
        <div class="col-md-6">
          <mat-form-field style="float: left; width: 90%">
            <input matInput type="text" [(ngModel)]="admin.username" placeholder="{{'username' | translate}}">
          </mat-form-field>
          <br>
          <mat-form-field style="float: left; width: 90%">
            <input matInput type="text" [(ngModel)]="admin.fullName" placeholder="{{'fullName' | translate}}">
          </mat-form-field>
          <br>
          <mat-form-field style="float: left; width: 90%">
            <input #fromInput matInput [matDatepicker]="pickerFrom" [(ngModel)]="admin.dateOfBirth"
                   placeholder="{{'birthDate' | translate}}">
            <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
            <mat-datepicker #pickerFrom></mat-datepicker>
          </mat-form-field>
          <br>
          <mat-form-field style="float: left; width: 90%">
            <input matInput type="email" [(ngModel)]="admin.email" placeholder="{{'email' | translate}}">
          </mat-form-field>
          <br>
          <mat-form-field style="float: left; width: 90%">
            <input matInput type="text" [(ngModel)]="admin.address" placeholder="{{'address' | translate}}">
          </mat-form-field>
          <br>
          <mat-form-field style="float: left; width: 90%">
            <input matInput type="text" [(ngModel)]="admin.phone" placeholder="{{'phone' | translate}}">
          </mat-form-field>
          <br>
        </div>
        <div class="col-md-6">
          Avatar
          <hr>
          <img [src]="admin.image" *ngIf="admin.image"
               style="width:150px;height:150px;border-radius: 50%; object-fit: cover;">
          <img src="assets/avatar.svg" *ngIf="!admin.image"
               style="width:150px;height:150px;border-radius: 50%; object-fit: cover;">

          <input type='file' (change)="onSelectFile($event.target.files)" style="margin-top:20px;">
          <br>
          <div *ngIf="canChangeSector()">
            <mat-select [(value)]="admin.positionId" placeholder="{{'position' | translate}}"
                        style="font-size:18px; margin-top:10px;">
              <mat-option *ngFor="let p of positions" [value]="p.id"> {{p.name}}</mat-option>
            </mat-select>
            <br>
            <mat-select [(value)]="admin.sectorId" placeholder="{{'sector' | translate}}"
                        (selectionChange)="changeSector()" style="font-size:18px; margin-top:10px;">
              <mat-option *ngFor="let p of sectors" [value]="p.id"> {{p.name}}</mat-option>
            </mat-select>
            <mat-select [(value)]="admin.country" placeholder="{{'country' | translate}}"
                        style="font-size:18px; margin-top:10px;">
              <mat-option *ngFor="let p of contries" [value]="p.value"> {{p.name}}</mat-option>
            </mat-select>
          </div>
          <mat-form-field *ngIf="data['mode'] != 'new'" style="float: left; width: 90%">
            <input matInput type="password" [(ngModel)]="admin.password" placeholder="{{'changePassword' | translate}}">
          </mat-form-field>
          <mat-form-field *ngIf="data['mode'] != 'new'" style="float: left; width: 90%">
            <input matInput type="password" [(ngModel)]="repeatPassword" placeholder="{{'repeatPassword' | translate}}">
          </mat-form-field>
          <div style="width:100%; text-align:center; color:red;"> {{message | translate}} </div>
          <div class="buttn" style="text-align: center !important;">
            <button
              style="margin-right: 10px; padding-left: 4% !important; margin-bottom: 10px; text-align: center !important; display: inline-flex; border:none !important; vertical-align: middle; float: right; background-color: #d92a73;; border-radius:24px; color:white; font-weight: bold; font-size:18px; padding: 10px; cursor: pointer; width: 130px; text-align: center;"
              (click)="confirm()">  {{'confirm' | translate }} </button>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="{{'permissions' | translate}}" [disabled]="disablePermission" *ngIf="this.data['mode'] != 'noPerm'">
      <ngx-datatable style="width: 600px; box-shadow: none !important;"
                     #table
                     class="material expandable"
                     [rows]="permissions"
                     [columnMode]="'force'"
                     [headerHeight]="50"
                     [footerHeight]="75"
                     [rowHeight]="'auto'"
                     [scrollbarH]="true"
                     [scrollbarV]="false">
        <ngx-datatable-column sortable="false" prop="id" [width]="30">
          <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
            <span>{{value}}</span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column sortable="false" prop="page" name="{{'page' | translate}}" [width]="30">
          <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
            <span>{{value | translate}}</span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column sortable="false" prop="view" name="{{'view' | translate}}" [width]="30">
          <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
            <mat-checkbox [(ngModel)]="value" (change)="update(row, 'view', value)"></mat-checkbox>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column sortable="false" prop="edit" name="{{'edit' | translate}}" [width]="30">
          <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
            <mat-checkbox [(ngModel)]="value" (change)="update(row, 'edit', value)"></mat-checkbox>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
      <div class="buttn" style="text-align: center !important;" *ngIf="data ['mode'] !== 'new'">
        <button
          style="margin-right: 10px; padding-left: 4% !important; margin-bottom: 10px; text-align: center !important; display: inline-flex; border:none !important; vertical-align: middle; float: right; background-color: #d92a73;; border-radius:24px; color:white; font-weight: bold; font-size:18px; padding: 10px; cursor: pointer; width: 130px; text-align: center;"
          (click)="confirmPermission();">  {{'save' | translate }} </button>
      </div>
      <div class="buttn" style="text-align: center !important;" *ngIf="data['mode'] === 'new'">
        <button
          style="margin-right: 10px; padding-left: 4% !important; margin-bottom: 10px; text-align: center !important; display: inline-flex; border:none !important; vertical-align: middle; float: right; background-color: #d92a73;; border-radius:24px; color:white; font-weight: bold; font-size:18px; padding: 10px; cursor: pointer; width: 130px; text-align: center;"
          (click)="confirm()">  {{'confirm' | translate }} </button>
      </div>
    </mat-tab>
  </mat-tab-group>
  <div class="row">

  </div>
</div>
