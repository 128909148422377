<div style="height: 93vh; background-color: #eceff1;">
  <div class="header" style="background-color: #d92a73" [ngClass]="{'hiddenHeader' : !showHeader}">
    <span style="font-size:1.7em; color:white; padding-left: 20px; font-weight: 500; ">  </span>
  </div>
  <div class="menu" *ngIf="showHeader" style="background-color: #d92a73; color:white; font-size:18px; font-weight: 500; padding-left: 20px; padding-top: 15px; padding-bottom:10px;">
    <span class="menuItem" [ngClass]="{'active' : currentPage === 'about'}" (click)="toTop('about')" style="margin-right: 14px; cursor: pointer;"> {{'findUs' | translate}} </span>


  </div>

  <div class="stickyMenu" *ngIf="!showHeader" style="background-color: #d92a73; color:white; font-size:18px;
 font-weight: 500; padding-left: 20px; padding-top: 15px; padding-bottom:10px; position: fixed; z-index: 3; width: 100%; top:0;">
    <app-sticky-header class="stickyHeader" style="right: 0px; position: fixed;"></app-sticky-header>
    <span class="menuItem" [ngClass]="{'active' : currentPage === 'profile'}"  (click)="toTop('about')"  style="margin-right: 14px; cursor: pointer;"> {{'findUs' | translate}} </span>

  </div>


  <div class="about" id="about">
    <div class="bigHeader" style="background-color: #b61052; height: 30vh;">
      <div style="
          margin: auto; height: 27vh; padding-top: 1.5vh; width: 40%; display: flex; justify-content: center;">
        <img style= "object-fit: contain; height: 27vh; display: inline;" src="assets/phone1.png">
        <img style= "object-fit: contain; height: 27vh; display: inline;" src="assets/phone2.png">
        <img style= "object-fit: contain; height: 27vh; display: inline;" src="assets/phone3.png">
      </div>
    </div>
    <div class="social" style="margin-top: 6vh; background-color: #eceff1; height: 100%;">
      <div class="row" style="margin-left: 0px !important; margin-right: 0px !important;">
        <div class="col-xl-3 offset-xl-1 col-md-6">
          <mat-card class="socialMenu" style="height: 38vh; background-color: white; border-radius: 13px; padding: 0px !important;">
            <a [href]="social['facebook']" target="_blank">
              <div class="facebook row" style="height: 9.5vh; cursor:pointer; border-bottom: 2px solid #747474; padding: 10px; margin-left: 0 !important; margin-right: 0 !important; width: 100%;" >
                <div class="col-4">
                  <div style="width: 70px; height: 70px; border-radius: 50%;">
                    <img src="assets/facebook.svg" style="object-fit: contain; border-radius: 50%; width: 50px; height: 50px;">
                  </div>
                </div>
                <div class="col-7">
                  <span style="color: #747474; font-size: 1.5em;"> Facebook </span>
                  <span style="color: #606060; font-size: 16px;"> </span>
                </div>
              </div>
            </a>
            <a  [href]="social['instagram']" target="_blank">
              <div class="instagram row" style="height: 9.5vh; cursor:pointer; border-bottom: 2px solid #747474; padding: 10px; margin-left: 0 !important; margin-right: 0 !important; width: 100%;">
                <div class="col-4">
                  <div style="width: 70px; height: 70px; border-radius: 50%;">
                    <img src="assets/instagram.png" style="object-fit: contain; border-radius: 50%; width: 50px; height: 50px;">
                  </div>
                </div>
                <div class="col-7">
                  <span style="color: #747474; font-size: 1.5em;"> Instagram </span>
                  <span style="color: #606060; font-size: 16px;"> </span>
                </div>
              </div>
            </a>
            <a [href]="social['snapchat']" target="_blank">
              <div class="snapchat row" style="height: 9.5vh; cursor:pointer; border-bottom: 2px solid #747474; padding: 10px; margin-left: 0 !important; margin-right: 0 !important; width: 100%;">
                <div class="col-4">
                  <div style="width: 70px; height: 70px; border-radius: 50%;">
                    <img src="assets/snapchat.svg" style="object-fit: contain; border-radius: 50%; width: 50px; height: 50px;">
                  </div>
                </div>
                <div class="col-7">
                  <span style="color: #747474; font-size: 1.5em;"> Snapchat </span>
                  <span style="color: #606060; font-size: 16px;"> </span>
                </div>
              </div>
            </a>
            <a [href]="social['youtube']" target="_blank">
              <div class="youtube row" style="height: 9.5vh; cursor:pointer; padding: 10px; margin-left: 0 !important; margin-right: 0 !important; width: 100%;">
                <div class="col-4">
                  <div style="width: 70px; height: 70px; border-radius: 50%;">
                    <img src="assets/youtube.svg" style="object-fit: contain; border-radius: 50%; width: 50px; height: 50px;">
                  </div>
                </div>
                <div class="col-7">
                  <span style="color: #747474; font-size: 1.5em;"> Youtube </span>
                  <span style="color: #606060; font-size: 16px;"> </span>
                </div>
              </div>
            </a>
          </mat-card>
        </div>
        <div class="col-xl-6 offset-xl-1 col-md-6">
          <mat-card class="video" style=" border-radius: 13px; height: 38vh; padding:0px !important;">
            <iframe style="width: 100%; height: 38vh; border-radius: 13px;" [src]="social['about_us_video'] | safe" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </mat-card>
        </div>
      </div>
    </div>
  </div>

</div>
