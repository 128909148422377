<div class="header" style="background-color: #d92a73; z-index: 5 !important; position: relative; padding-bottom: 18px"
     [ngClass]="{'hiddenHeader' : !showHeader}">
  <span
          style="font-size:1.7em; color:white; padding-left: 20px; font-weight: 500; margin-bottom:10px;"> {{'users' | translate}} </span>
</div>

<div class="main-sub-header">
    <div class="main-sub-header__button" *ngFor="let m of modes"
         [ngClass]="{'main-sub-header__button--selected': mode == m.id}"
         (click)="changeMode(m.id); getApproximateCost(totalCount)">
        {{m.name | translate}}
    </div>

    <div [ngStyle]="{'position': 'absolute', 'right': '450px', 'top': '12px', 'font-size': '20px', 'text-align': 'center', 'color': 'white', 'font-weight': 'bold'}" class="desktop-only">
      <div [ngStyle]=" {'font-size': '16px', 'color': '#DA719B', 'text-align': 'right' }">
        {{'campaignMoneySpent' | translate}}
      </div>
      {{moneySpent}}
    </div>

    <div [ngStyle]="{'position': 'absolute', 'right': '200px', 'top': '12px', 'font-size': '20px', 'text-align': 'center', 'color': 'white', 'font-weight': 'bold'}" class="desktop-only">
      <div [ngStyle]=" {'font-size': '16px', 'color': '#DA719B', 'text-align': 'right' }">
        {{'approximateCost' | translate}}
      </div>
      {{approximateCost}}
    </div>

    <div class="infobip-balance desktop-only">
        <div class="infobip-balance-text">
            {{'balance' | translate}}
        </div>
        {{balance}}
    </div>
</div>
<div class="campaign-main-container">


  <div [ngStyle]="{'font-size': '14px', 'color': '#484848', 'text-align': 'left', 'position': 'absolute', 'left': '30px', 'top': '260px'}" class="mobile-only">
    <div [ngStyle]="{'font-size': '12px', 'color': '#8B888C'}">
      {{'campaignMoneySpent' | translate}}
    </div>
    {{moneySpent}}
  </div>

  <div [ngStyle]="{'font-size': '14px', 'color': '#484848', 'text-align': 'left', 'position': 'absolute', 'left': '30px', 'top': '219px'}" class="mobile-only">
    <div [ngStyle]="{'font-size': '12px', 'color': '#8B888C'}">
      {{'approximateCost' | translate}}
    </div>
    {{approximateCost}}
  </div>
  <br><br><br><br>
  <div class="breadcrumb desktop-only-flex">
    <span class="breadcrumb__main" (click)="openUsers()"> {{'users' | translate}} </span> <span
    class="breadcrumb__sign">></span><span class="breadcrumb__name"> {{'campaign' | translate}}</span>
  </div>
  <div class="infobip-balance-mobile mobile-only">
    <div class="infobip-balance-text">
      {{'balance' | translate}}
    </div>
    {{balance}}
  </div>

    <div class="campaign-total">
        {{'total' | translate}} ({{totalCount}})
    </div>
    <div class="campaign-audience-details" *ngFor="let a of audiences">
        {{a.name}} ({{a.userCount}})
    </div>
    <div class="campaign-box">
<!--      separated components, because we want separate data for each campaign mode -->
        <app-campaign-form [hidden]="mode != 1" (campaignChange)="savedCampaign()" isPopup="0" [audiences]="audiences" [mode]="mode" [width]="'450px'" [buttons]="buttons" isNew="1"
                           [audienceIds]="audienceIds"></app-campaign-form>
      <app-campaign-form [hidden]="mode != 2" (campaignChange)="savedCampaign()" isPopup="0" [audiences]="audiences" [mode]="mode" [width]="'450px'" [buttons]="buttons" isNew="1"
                         [audienceIds]="audienceIds"></app-campaign-form>
      <app-campaign-form [hidden]="mode != 3" (campaignChange)="savedCampaign()" isPopup="0" [audiences]="audiences" [mode]="mode" [width]="'450px'" [buttons]="buttons" isNew="1"
                         [audienceIds]="audienceIds"></app-campaign-form>
    </div>
</div>


