import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material";
import {MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

import {ApiService} from "../api.service";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {DataService} from "../data-service.service";
export interface DialogData {
  name: any;
  time: any;
  start: '';
  end: '';
  date: '';
}

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.css']
})


export class AppointmentComponent implements OnInit {

employees: Object = [];
  date = '';
  selectedEmployee = 0;
  description = '';
  message = '';
  time = '';
  event: Object;

  // tslint:disable-next-line:max-line-length
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, private translate: TranslateService, private dataService: DataService, public snackBar: MatSnackBar, private service: ApiService, public dialogRef: MatDialogRef<AppointmentComponent>) { }

  ngOnInit() {
    if (this.data !== null){
      if (this.data.date !== ''){
        this.date = this.data.date;
      }
    }
    this.service.getEmployeesForAppointments().subscribe(
      data => {
        this.employees = data;
      }
    )
  }

  onCancel($event) {
    this.dialogRef.close(null);
  }

  onConfirm($event) {
    if (this.selectedEmployee === 0 ){
      this.message = 'pleaseChooseAnEmployee';
      return;
    }
    if (this.date === '' ){
      this.message = 'pleaseSelectADate';
      return;
    }
    if (this.description === '' ){
      this.message = 'pleaseAddDescription';
      return;
    }
    this.service.addAppointment(this.date, this.time, this.selectedEmployee, this.description).subscribe(
      data => {
        this.event = data;
        console.log(data);
        this.dataService.event.next(data);
      }, err => {
        this.snackBar.open(this.translate.instant('thereWasAnError'), null, {
          duration : 3000
        });
      },
      () => {
        this.dataService.addEvent(this.event);
        this.snackBar.open(this.translate.instant('appointmentScheduled'), null, {
          duration : 3000
        });
        this.dialogRef.close(true);
      }
    );
  }
}
