import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-price-combined',
  templateUrl: './price-combined.component.html',
  styleUrls: ['./price-combined.component.css']
})
export class PriceCombinedComponent implements OnInit {
  selectedMode = 1;
  modes = [
    {id: 1, title: 'price'},
    {id: 2, title: 'promo-codes'},
    {id: 3, title: 'upselling'}

  ]


  constructor(private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit() {
    const selectedModeTitle = this.route.snapshot.paramMap.get('mode');

    const mode = this.modes.find(m => m.title === selectedModeTitle);
    if (mode) {
      this.selectedMode = mode.id;
    }
  }

  changeMode(mode) {
    this.router.navigate([`main`, 'prices', mode.title]);

    this.selectedMode = mode.id;
  }

}
