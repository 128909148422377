import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {formatDate} from "@angular/common";

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent implements OnInit {
  title = 'Obriši';
  message = 'Da li ste sigurni da želite da obrišete album?';
  country: String;
  button = 'Obriši';

  constructor(public dialogRef: MatDialogRef<ConfirmComponent>) {
  }

  dateTime: string;

  ngOnInit() {
    this.dateTime = new Date().toUTCString();
  }


  changedDate() {
    const date = formatDate(this.dateTime, 'y-MM-dd', 'en');
    localStorage.setItem('pickupDate', date);
  }

  onCancel($event) {
    this.dialogRef.close(null);
  }

  onConfirm($event) {
    this.dialogRef.close(true);
  }
}
