<div class="header" style="background-color: #d92a73" [ngClass]="{'hiddenHeader' : !showHeader}">
  <span
          style="font-size:1.7em; color:white; padding-left: 20px; font-weight: 500; margin-bottom:10px;"> {{'orders' | translate}} </span>
</div>

<div class="searchHeader"
     style="background-color: #b61052; height: 5vh; display: flex; flex-wrap: wrap; font-size: 18px; color:white; font-weight: bold; align-content: stretch; justify-content: space-evenly;">
    <div class="flex-grow-3 menuField" [matMenuTriggerFor]="filtered"
         style=" vertical-align: middle; flex:1.7; border-right: solid 2px rgba(45, 53, 71, 0.2); cursor:pointer;">
        <div class="filterText">
     <span class="d-none d-lg-block">
              <img src="assets/ic-search.svg" alt="search">
         {{'filter' | translate}}
         <img src="assets/ic-keyboard-arrow-down.svg" alt="ic-keyboard-arrow-down">
     </span>
            <figure class="d-block d-sm-block d-md-block d-lg-none noMargin">
                <img src="assets/ic_pretrazi po.svg" alt="pretrazi">
            </figure>
        </div>
    </div>
    <div class="menuField" [matMenuTriggerFor]="idfilter"
         style="flex:1.4; border-right: solid 2px rgba(45, 53, 71, 0.2); cursor:pointer;">
        <div class="filterText">
      <span class="d-none d-lg-block">{{'idRange' | translate }}
          <img src="assets/ic-keyboard-arrow-down.svg" alt="keyboard-arrow-down">
      </span>
            <figure class="d-block d-sm-block d-md-block d-lg-none noMargin">
                <img src="assets/ic_id raspon.svg" alt="raspon">
            </figure>
        </div>
    </div>
    <div class="menuField" [matMenuTriggerFor]="dateFilter"
         style="flex:1;  border-right: solid 2px rgba(45, 53, 71, 0.2); cursor:pointer;">
        <div class="filterText">
      <span class="d-none d-lg-block">{{'dateRange' | translate }}
          <img src="assets/ic-keyboard-arrow-down.svg" alt="keyboard-arrow-down">
      </span>
            <figure class="d-block d-sm-block d-md-block d-lg-none noMargin">
                <img src="assets/ic_datum.svg" alt="datum">
            </figure>
        </div>
    </div>
    <div class="menuField" [matMenuTriggerFor]="statusFiltered"
         style="flex:1;  border-right: solid 2px rgba(45, 53, 71, 0.2); cursor:pointer;">
        <div class="filterText" (click)="openStatus()">
      <span class="d-none d-lg-block">{{'status' | translate }}
          <img src="assets/ic-keyboard-arrow-down.svg" alt="keyboard-arrow-down">
      </span>
            <figure class="d-block d-sm-block d-md-block d-lg-none noMargin">
                <img src="assets/ic_status.svg" alt="status">
            </figure>
        </div>
    </div>
    <div class="menuField" [matMenuTriggerFor]="typeFiltered"
         style="flex:1;  border-right: solid 2px rgba(45, 53, 71, 0.2); cursor:pointer;">
        <div class="filterText">
      <span class="d-none d-lg-block">{{'type' | translate }}
          <img src="assets/ic-keyboard-arrow-down.svg" alt="keyboard-arrow-down">
      </span>
            <figure class="d-block d-sm-block d-md-block d-lg-none noMargin">
                <img src="assets/ic_tip.svg" alt="tip">
            </figure>
        </div>
    </div>
    <div class="menuField" style="flex:1; cursor:pointer;"
         (click)="onSearch($event)">
        <div class="filterText">
            <span class="d-none d-lg-block">{{'search' | translate }}</span>
            <figure class="d-block d-sm-block d-md-block d-lg-none  noMargin">
                <img src="assets/ic_pretraga.svg" alt="pretraga">
            </figure>
        </div>
    </div>
    <div class="menuField" style="flex:0.6; background-color: #a30e49 !important;  cursor:pointer;">
        <div class="menuField" style="flex:1; cursor:pointer; text-align: center;">
            <mat-icon (click)="onReset($event)">close</mat-icon>
            <mat-icon (click)="onRefresh($event)">refresh</mat-icon>
            <br>
        </div>
    </div>
</div>

<mat-menu #filtered="matMenu" yPosition="below">
    <div style="padding:10px;" (click)="stopPropagation($event)">
        <span style="font-weight: bold;">{{'filterData' | translate}}</span>
        <mat-form-field style="width: 80%; margin: 8px">
            <input type="text" id="name" matInput [(ngModel)]="filter.name"
                   (submit)="onSearch($event)"
                   (keyup.enter)="onSearch($event)"
                   placeholder="{{'firstNameAndLastName' | translate}}"
            />
            <label for="name" class="labels"></label>
        </mat-form-field>


        <mat-form-field style="width: 80%; margin: 8px">
            <input type="text" id="phone" matInput placeholder="{{'phone' | translate}}"
                   [(ngModel)]="filter.phone"
                   (submit)="onSearch($event)"
                   (keyup.enter)="onSearch($event)"/>
            <label for="phone" class="labels"></label>
        </mat-form-field>


        <mat-form-field style="width: 80%; margin: 8px">
            <input type="text" id="email" matInput [(ngModel)]="filter.email"
                   (submit)="onSearch($event)"
                   (keyup.enter)="onSearch($event)"
                   placeholder="{{'email' | translate}}"
            />
            <label for="email" class="labels"></label>
        </mat-form-field>


        <mat-form-field style="width: 80%; margin: 8px">
            <input type="text" matInput id="promoCode" [(ngModel)]="filter.promo_text"
                   (submit)="onSearch($event)"
                   (keyup.enter)="onSearch($event)"
                   placeholder="{{'promoCode' | translate}}"
            />
            <label for="promoCode" class="labels"></label>
        </mat-form-field>

        <mat-form-field style="width: 80%; margin: 8px">
            <input type="tel" matInput id="price"
                   [(ngModel)]="filter.price"
                   (keyup.enter)="onSearch($event)"
                   placeholder="{{'price' | translate}}"
            />
            <label for="price" class="labels"></label>
        </mat-form-field>

        <form style="margin-left: 8px;" [formGroup]="selectFilterForm">
            <mat-form-field style="width: 80%">
                <label for="device" class="labels position"></label>
                <mat-select id="device" [value]="getFilterStatusValue()"
                            (selectionChange)="filter.device = $event.value"
                            placeholder="{{'device' | translate}}"
                            formControlName="device"
                >
                    <mat-option value="1">
                        Ios
                    </mat-option>
                    <mat-option value="2">
                        Android
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>

        <div style="margin-left: 8px;">
            <mat-checkbox [(ngModel)]="filter.isFree">{{'free' | translate}}</mat-checkbox>
            <br>
            <mat-checkbox [(ngModel)]="filter.isFast">{{'fastDelivery' | translate}}</mat-checkbox>
            <br>
            <mat-checkbox [(ngModel)]="filter.isPromo">Promo</mat-checkbox>
            <br>
            <mat-checkbox [(ngModel)]="filter.giftPackage">{{'giftPackage' | translate}}</mat-checkbox>
            <br>
            <mat-checkbox [(ngModel)]="filter.econt">Econt office</mat-checkbox>
            <br>
        </div>

    </div>
</mat-menu>

<mat-menu #idfilter="matMenu">
    <div style="padding:10px;" (click)="stopPropagation($event)">
        <span style="font-weight: bold;"> {{'idRange' | translate}} </span> <br>
        <mat-form-field style="width: 80%; margin: 8px">
            <input type="tel" matInput placeholder="ID" [(ngModel)]="filter.id"
                   (keyup.enter)="onSearch($event)"/>
        </mat-form-field>
        <mat-form-field style="width: 80%; margin: 8px">
            <input type="text" matInput placeholder="{{'fromId' | translate}}" [(ngModel)]="filter.fromId"
                   (submit)="onSearch($event)" (keyup.enter)="onSearch($event)"/>
        </mat-form-field>
        <mat-form-field style="width: 80%; margin: 8px">
            <input type="tel" matInput placeholder="{{'toId' | translate}}" [(ngModel)]="filter.toId"
                   (keyup.enter)="onSearch($event)"/>
        </mat-form-field>
        <mat-form-field style="width: 80%; margin: 8px">
            <input type="tel" matInput placeholder="Album ID" [(ngModel)]="filter.album"
                   (keyup.enter)="onSearch($event)"/>
        </mat-form-field>
        <mat-form-field style="width: 80%; margin: 8px">
            <input type="tel" matInput placeholder="{{'withoutId' | translate}}" [(ngModel)]="filter.withoutId"
                   (keyup.enter)="onSearch($event)"/>
        </mat-form-field>
        <mat-form-field style="width: 80%; margin: 8px">
            <input type="tel" matInput placeholder="{{'extraId' | translate}}" [(ngModel)]="filter.extraId"
                   (keyup.enter)="onSearch($event)"/>
        </mat-form-field>
    </div>
</mat-menu>

<mat-menu #dateFilter="matMenu">
    <form style="padding:10px;" (click)="stopPropagation($event)" [formGroup]="dateFilterForm">
        <span style="font-weight: bold;"> {{'dateRange' | translate}} </span> <br>
        <mat-form-field style="float: left; width: 80%">
            <input #fromInput matInput [matDatepicker]="pickerFrom" placeholder="{{'fromDate' | translate}}"
                   (dateChange)="filter.fromDate = $event.value.toISOString()" formControlName="fromDate">
            <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
            <mat-datepicker #pickerFrom></mat-datepicker>
        </mat-form-field>
        <mat-form-field style="float: left; width: 80%">
            <input #toInput matInput [matDatepicker]="pickerTo" placeholder="{{'toDate' | translate}}"
                   (dateChange)="filter.toDate = $event.value.toISOString()" formControlName="toDate">
            <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
            <mat-datepicker #pickerTo></mat-datepicker>
        </mat-form-field>
    </form>
</mat-menu>

<mat-menu #statusFiltered="matMenu">
    <form style="padding:10px;" (click)="stopPropagation($event)" [formGroup]="statusFilterForm">
        <span style="font-weight: bold;"> {{'status' | translate}}</span>
        <mat-form-field style="width: 80%; margin: 8px">
            <mat-select multiple placeholder="{{'status' | translate}}" formControlName="status">
                <mat-option *ngFor="let s of statusFilter" [value]="s.value" (click)="selectedStatus(s)">
                    {{ s.name | translate}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field style="width: 80%; margin: 8px">
            <mat-select placeholder="{{'payingStatus' | translate}}"
                        formControlName="payingStatus"
                        (selectionChange)="filter.payingStatus = $event.value">
                <mat-option [value]=1>
                    {{ 'orderMade' | translate}}
                </mat-option>
                <mat-option [value]=2>
                    {{ 'reserved' | translate}}
                </mat-option>
                <mat-option [value]=3>
                    {{ 'refunded' | translate}}
                </mat-option>
                <mat-option [value]=4>
                    {{ 'voided' | translate}}
                </mat-option>
                <mat-option [value]=5>
                    {{ 'error' | translate}}
                </mat-option>
                <mat-option [value]=6>
                    {{ 'submittedForSettlement' | translate}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</mat-menu>

<mat-menu #typeFiltered="matMenu">
    <form style="padding:10px;" (click)="stopPropagation($event)" [formGroup]="typeFilterForm">
        <span style="font-weight: bold;"> {{'type' | translate}}</span> <br>
        <mat-form-field style="width: 80%; margin: 8px">
            <mat-select multiple placeholder="{{'type' | translate}}" formControlName="type">
                <mat-option *ngFor="let s of types" [value]="s.value" (onSelectionChange)="selectedType(s.value)">
                    {{ s.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>


        <mat-form-field style="width: 80%; margin: 8px">
            <mat-select placeholder="{{'payingMethod' | translate}}" formControlName="payingMethod"
                        (selectionChange)="filter.payingMethod = $event.value">
                <mat-option [value]=1>
                    {{ 'cash' | translate }}
                </mat-option>
                <mat-option [value]=2>
                    {{ 'card' | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</mat-menu>

<mat-card class="col-12">
    <div class="row">
        <div class="col-md-8">
            <div>
                <mat-chip-list>
                    <mat-chip *ngIf="filter.name !== ''" style="margin-bottom: 5px; font-size: 15px;">
                        {{filter.name}}
                        <mat-icon style="cursor: pointer; color:#424242; margin-left: 3px;" (click)="filter.name = ''">
                            cancel
                        </mat-icon>
                    </mat-chip>

                    <mat-chip *ngIf="filter.device != null" style="margin-bottom: 5px; font-size: 15px;">
                        <span *ngIf="filter.device == 1"> Ios </span> <span *ngIf="filter.device == 2"> Android </span>
                        <mat-icon style="cursor: pointer; color:#424242; margin-left: 3px;"
                                  (click)="resetSelectFormFilter()">
                            cancel
                        </mat-icon>
                    </mat-chip>

                    <mat-chip *ngIf="filter.phone!==''" style="margin-bottom: 5px;">
                        {{filter.phone}}
                        <mat-icon style="cursor: pointer; color:#424242; margin-left: 3px;" (click)="filter.phone = ''">
                            cancel
                        </mat-icon>
                    </mat-chip>

                    <mat-chip *ngIf="filter.email!==''" style="margin-bottom: 5px;">
                        {{filter.email}}
                        <mat-icon style="cursor: pointer; color:#424242; margin-left: 3px;" (click)="filter.email = ''">
                            cancel
                        </mat-icon>
                    </mat-chip>

                    <mat-chip *ngIf="filter.promo_text!==''" style="margin-bottom: 5px;">
                        {{filter.promo_text}}
                        <mat-icon style="cursor: pointer; color:#424242; margin-left: 3px;"
                                  (click)="filter.promo_text = ''">
                            cancel
                        </mat-icon>
                    </mat-chip>

                    <mat-chip *ngIf="filter.price!=null" style="margin-bottom: 5px;">
                        {{filter.price}}
                        <mat-icon style="cursor: pointer; color:#424242; margin-left: 3px;"
                                  (click)="filter.price = null">
                            cancel
                        </mat-icon>
                    </mat-chip>

                    <mat-chip *ngIf="filter.isFree" style="margin-bottom: 5px;">
                        {{'free' | translate}}
                        <mat-icon style="cursor: pointer; color:#424242; margin-left: 3px;"
                                  (click)="filter.isFree = null">
                            cancel
                        </mat-icon>
                    </mat-chip>

                    <mat-chip *ngIf="filter.isFast" style="margin-bottom: 5px;">
                        {{'fastDelivery' | translate}}
                        <mat-icon style="cursor: pointer; color:#424242; margin-left: 3px;"
                                  (click)="filter.isFast = null">
                            cancel
                        </mat-icon>
                    </mat-chip>

                    <mat-chip *ngIf="filter.isPromo" style="margin-bottom: 5px;">
                        Promo
                        <mat-icon style="cursor: pointer; color:#424242; margin-left: 3px;"
                                  (click)="filter.isPromo = null">
                            cancel
                        </mat-icon>
                    </mat-chip>

                    <mat-chip *ngIf="filter.giftPackage" style="margin-bottom: 5px;">
                        {{'giftPackage' | translate}}
                        <mat-icon style="cursor: pointer; color:#424242; margin-left: 3px;"
                                  (click)="filter.giftPackage = null">
                            cancel
                        </mat-icon>
                    </mat-chip>

                    <mat-chip *ngIf="filter.id !== null" style="margin-bottom: 5px;">
                        ID {{filter.id}}
                        <mat-icon style="cursor: pointer; color:#424242; margin-left: 3px;" (click)="filter.id = null">
                            cancel
                        </mat-icon>
                    </mat-chip>

                    <mat-chip *ngIf="filter.fromId !== null" style="margin-bottom: 5px;">
                        {{'fromId' | translate}} {{filter.fromId}}
                        <mat-icon style="cursor: pointer; color:#424242; margin-left: 3px;"
                                  (click)="filter.fromId = null">
                            cancel
                        </mat-icon>
                    </mat-chip>

                    <mat-chip *ngIf="filter.toId !== null" style="margin-bottom: 5px;">
                        {{'toId' | translate}} {{filter.toId}}
                        <mat-icon style="cursor: pointer; color:#424242; margin-left: 3px;"
                                  (click)="filter.toId = null">
                            cancel
                        </mat-icon>
                    </mat-chip>

                    <mat-chip *ngIf="filter.withoutId && filter.withoutId !== null" style="margin-bottom: 5px;">
                        {{'withoutId' | translate}} {{filter.withoutId}}
                        <mat-icon style="cursor: pointer; color:#424242; margin-left: 3px;"
                                  (click)="filter.withoutId = null">
                            cancel
                        </mat-icon>
                    </mat-chip>

                    <mat-chip *ngIf="filter.extraId && filter.extraId !== null" style="margin-bottom: 5px;">
                        {{'extraId' | translate}} {{filter.extraId}}
                        <mat-icon style="cursor: pointer; color:#424242; margin-left: 3px;"
                                  (click)="filter.extraId = null">
                            cancel
                        </mat-icon>
                    </mat-chip>

                    <mat-chip *ngIf="filter.album !== null" style="margin-bottom: 5px;">
                        Album ID {{filter.album}}
                        <mat-icon style="cursor: pointer; color:#424242; margin-left: 3px;"
                                  (click)="filter.album = null">
                            cancel
                        </mat-icon>
                    </mat-chip>

                    <mat-chip *ngIf="filter.fromDate !==''" style="margin-bottom: 5px;">
                        {{'fromDate' | translate}} {{filter.fromDate | date: 'd.M.y.' }}
                        <mat-icon style="cursor: pointer; color:#424242; margin-left: 3px;"
                                  (click)="resetFromDateFilter()">
                            cancel
                        </mat-icon>
                    </mat-chip>

                    <mat-chip *ngIf="filter.toDate !==''" style="margin-bottom: 5px;">
                        {{'toDate' | translate}} {{filter.toDate | date: 'd.M.y.' }}
                        <mat-icon style="cursor: pointer; color:#424242; margin-left: 3px;"
                                  (click)="resetToDateFilter()">
                            cancel
                        </mat-icon>
                    </mat-chip>


                    <mat-chip *ngIf="filteredStatus?.length !== 0" style="margin-bottom: 5px;">
            <span *ngFor="let status of filteredStatus; let i = index">
                {{status.name | translate}},&nbsp;
              <span *ngIf="i !== 0"></span>
            </span>
                        <mat-icon style="cursor: pointer; color:#424242; margin-left: 3px;"
                                  *ngIf="filteredStatus?.length !== 0"
                                  (click)="resetStatusFilter()"
                        >
                            cancel
                        </mat-icon>
                    </mat-chip>

                    <mat-chip *ngIf="filter.typeArray != null" style="margin-bottom: 5px;">
            <span *ngFor="let type of filter.typeArray; let i = index">
                {{findType(type)}}
                <span *ngIf="i != filter.typeArray.length - 1">,&nbsp; </span>
            </span>
                        <mat-icon style="cursor: pointer; color:#424242; margin-left: 3px;" (click)="resetTypeFilter()">
                            cancel
                        </mat-icon>
                    </mat-chip>

                    <mat-chip *ngIf="filter.payingMethod" style="margin-bottom: 5px;">
                        <span *ngIf="filter.payingMethod === 1"> {{'cash' | translate}} </span>
                        <span *ngIf="filter.payingMethod === 2"> {{'card' | translate}} </span>
                        <mat-icon style="cursor: pointer; color:#424242; margin-left: 3px;"
                                  (click)="filter.payingMethod = null"
                                  (click)="resetPayingMethodFilter()">
                            cancel
                        </mat-icon>
                    </mat-chip>
                    <mat-chip *ngIf="filter.payingStatus !== null" style="margin-bottom: 5px;">
                        <span *ngIf="filter.payingStatus === 1"> {{'orderMade' | translate}} </span>
                        <span *ngIf="filter.payingStatus === 2"> {{'reserved' | translate}} </span>
                        <span *ngIf="filter.payingStatus === 3"> {{'refunded' | translate}} </span>
                        <span *ngIf="filter.payingStatus === 4"> {{'voided' | translate}} </span>
                        <span *ngIf="filter.payingStatus === 5"> {{'error' | translate}} </span>
                        <span *ngIf="filter.payingStatus === 6"> {{'submittedForSettlement' | translate}} </span>
                        <mat-icon style="cursor: pointer; color:#424242; margin-left: 3px;"
                                  (click)=" resetPayingStatusFilter()"
                                  *ngIf="filter.payingStatus !== null">
                            cancel
                        </mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </div>
        </div>
        <div class="col-md-4 rightFloats">
            <div class="floatings" [matMenuTriggerFor]="tableFilter"
                 style="float: right !important; margin-left: 10px; background-color: #6e92ad; border-radius:24px; color:white; font-weight: bold; font-size:14px; padding: 10px; cursor: pointer; width: 110px; text-align: center;">
                {{'showTable' | translate}}
            </div>
        </div>
    </div>

    <mat-menu #tableFilter="matMenu">
        <div style="padding:10px;" (click)="stopPropagation($event)">
            <span style="font-weight: bold;"> {{'showTable' | translate}}</span> <br> <br>
            <div class="col">
                <mat-checkbox [(ngModel)]="collums.delivery">{{'delivery' | translate}}</mat-checkbox>
            </div>
            <div class="col">
                <mat-checkbox [(ngModel)]="collums.download">Download</mat-checkbox>
            </div>
            <div class="col">
                <mat-checkbox [(ngModel)]="collums.remove">{{'delete' | translate}}</mat-checkbox>
            </div>
            <div class="col">
                <mat-checkbox [(ngModel)]="collums.country">{{'country' | translate}}</mat-checkbox>
            </div>
            <div class="col">
                <mat-checkbox [(ngModel)]="collums.price">{{'price' | translate}}</mat-checkbox>
            </div>
            <div class="col">
                <mat-checkbox [(ngModel)]="collums.status">{{'status' | translate}}</mat-checkbox>
            </div>
            <div class="col">
                <mat-checkbox [(ngModel)]="collums.name">{{'name' | translate}}</mat-checkbox>
            </div>
            <div class="col">
                <mat-checkbox [(ngModel)]="collums.econt">Econt office</mat-checkbox>
            </div>
            <div class="col">
                <mat-checkbox [(ngModel)]="collums.address">{{'address' | translate}}</mat-checkbox>
            </div>
            <div class="col">
                <mat-checkbox [(ngModel)]="collums.note">{{'note' | translate}}</mat-checkbox>
            </div>
            <div class="col">
                <mat-checkbox [(ngModel)]="collums.promo">Promo</mat-checkbox>
            </div>
            <div class="col">
                <mat-checkbox [(ngModel)]="collums.email">{{'email' | translate}}</mat-checkbox>
            </div>
            <div class="col">
                <mat-checkbox [(ngModel)]="collums.free">{{'free' | translate}}</mat-checkbox>
            </div>
            <div class="col">
                <mat-checkbox [(ngModel)]="collums.fast">{{'fastDelivery' | translate}}</mat-checkbox>
            </div>
            <div class="col">
                <mat-checkbox [(ngModel)]="collums.giftPackage">{{'giftPackage' | translate}}</mat-checkbox>
            </div>
            <div class="col">
                <mat-checkbox [(ngModel)]="collums.sendingsExtra">{{'sendingsExtra' | translate}}</mat-checkbox>
            </div>
            <div class="col">
                <mat-checkbox (change)="onAppChecked($event)"
                              [checked]="collums.appVersion">{{'appVersion' | translate}}</mat-checkbox>
            </div>
            <div class="col">
                <mat-checkbox [(ngModel)]="collums.payingMethod">{{'payingMethod' | translate}}</mat-checkbox>
            </div>
            <div class="col">
                <mat-checkbox [(ngModel)]="collums.payingStatus">{{'payingStatus' | translate}}</mat-checkbox>
            </div>
          <div class="col">
            <mat-checkbox [(ngModel)]="collums.upsellingAction">{{'upsellingAction' | translate}}</mat-checkbox>
          </div>
          <div class="col">
            <mat-checkbox [(ngModel)]="collums.refund">{{'refund' | translate}}</mat-checkbox>
          </div>
        </div>
    </mat-menu>

    <mat-menu #limitFilter="matMenu">
        <div style="padding:10px;" (click)="stopPropagation($event)">
            <mat-form-field style="margin: 8px">
                <input type="text" matInput placeholder="Limit" [(ngModel)]="limit"/>
            </mat-form-field>
        </div>
    </mat-menu>
    <div style="clear: both;"></div>

    <hr>

    <div class="row" *ngIf="orders && orders.length>0">
        <div class="col-md-6 col-12" *ngIf="ybDiff > -1">
            <div style="font-size: 13px; margin-bottom: 5px; margin-top: 15px">
        <span class="roundSpan"
              style="background-color: blue; color: white; margin: 2px;">
          Yolobook Mini Selektovano: {{yolobook8itemsToDownload.length}} Razlika: {{ybDiff}}
        </span>
            </div>
        </div>

        <div class="col-md-6 col-12" *ngIf="ybDiffS > -1">
            <div style="font-size: 13px; margin-bottom: 5px; margin-top: 15px">
        <span class="roundSpan"
              style="background-color: dodgerblue; color: white; margin: 2px;">
          Yolobook Mini S Selektovano: {{yolobookS8itemsToDownload.length}} Razlika: {{ybDiffS}}
        </span>
            </div>
        </div>

        <div class="col-md-6 col-12" *ngIf="ybCalDiff > -1">
            <div style="font-size: 13px; margin-bottom: 5px; margin-top: 15px">
        <span class="roundSpan"
              style="background-color: #212a49; color: white; margin: 2px;">
          YoloCalendar (Svi) Selektovano : {{yoloCalendarItemsToDownload.length}} Razlika: {{ybCalDiff}}
        </span>
            </div>
        </div>

        <div class="col-md-6 col-12" *ngIf="ybCalBasicDiff > -1">
            <div style="font-size: 13px; margin-bottom: 5px; margin-top: 15px">
        <span class="roundSpan" style="background-color: #212a49; color: white; margin: 2px;">
          YoloCalendar (Basic) Selektovano: {{yoloCalendarBasicItemsToDownload.length}} Razlika: {{ybCalBasicDiff}}
        </span>
                <span class="roundSpan"
                      style="background-color: #212a49; color: white; margin: 2px">

        </span>
            </div>
        </div>

        <div class="col-md-6 col-12" *ngIf="ybCalKidsDiff > -1">
            <div style="font-size: 13px; margin-bottom: 5px; margin-top: 15px">
        <span class="roundSpan" style="background-color: #212a49; color: white; margin: 2px;">
          YoloCalendar (Kids) Selektovano: {{yoloCalendarKidsItemsToDownload.length}} Razlika: {{ybCalKidsDiff}}
        </span>
            </div>
        </div>

        <div class="col-md-6 col-12" *ngIf="ybDiffXl > -1">
            <div style="font-size: 13px; margin-bottom: 5px; margin-top: 15px">
        <span class="roundSpan"
              style="background-color: deeppink; color: white; margin: 2px;">
          Yolobook Maxi Selektovano: {{yolobook8XlitemsToDownload.length}} Razlika: {{ybDiffXl}}
        </span>
            </div>
        </div>

        <div class="col-md-6 col-12" *ngIf="ybDiffXlS > -1">
            <div style="font-size: 13px; margin-bottom: 5px; margin-top: 15px">
        <span class="roundSpan"
              style="background-color: hotpink; color: white; margin: 2px;">
          Yolobook Maxi S Selektovano: {{yolobookS8XlitemsToDownload.length}} Razlika: {{ybDiffXlS}}
        </span>
            </div>
        </div>

        <div class="col-md-6 col-12" *ngIf="yhmini > -1">
            <div style="font-size: 13px; margin-bottom: 5px; margin-top: 15px">
        <span class="roundSpan"
              style="background-color: deeppink; color: white; margin: 2px;">
          YoloHard Square Selektovano: {{yoloHardMini.length}} Razlika: {{yhmini}}
        </span>
            </div>
        </div>

      <div class="col-md-6 col-12" *ngIf="yStories > -1">
        <div style="font-size: 13px; margin-bottom: 5px; margin-top: 15px">
        <span class="roundSpan"
              style="background-color: deeppink; color: white; margin: 2px;">
          YoloStories Selektovano: {{yoloStories.length}} Razlika: {{yStories}}
        </span>
        </div>
      </div>

        <div class="col-md-6 col-12" *ngIf="yhmaxi > -1">
            <div style="font-size: 13px; margin-bottom: 5px; margin-top: 15px">
        <span class="roundSpan" style="background-color: deeppink; color: white; margin: 2px;">
          YoloHard Landscape Selektovano: {{yoloHardMaxi.length}} Razlika: {{yhmaxi}}
        </span>
            </div>
        </div>

        <div class="col-md-6 col-12" *ngIf="yhminiS > -1">
            <div style="font-size: 13px; margin-bottom: 5px; margin-top: 15px">
        <span class="roundSpan"
              style="background-color: deeppink; color: white; margin: 2px;">
          YoloHard Square S Selektovano: {{yoloHardMiniS.length}} Razlika: {{yhminiS}}
        </span>
            </div>
        </div>

        <div class="col-md-6 col-12" *ngIf="yhmaxiS > -1">
            <div style="font-size: 13px; margin-bottom: 5px; margin-top: 15px">
        <span class="roundSpan" style="background-color: deeppink; color: white; margin: 2px;">
          YoloHard Landscape S Selektovano: {{yoloHardMaxiS.length}} Razlika: {{yhmaxiS}}
        </span>
            </div>
        </div>

        <div class="col-md-6 col-12" *ngIf="infoObj && infoObj['firstId'] != 0 && infoObj['lastId'] != 0">
            <div style="font-size: 13px; margin-bottom: 5px; margin-top: 15px">
                <mat-chip-list>

                    <mat-chip class="roundSpan" style="background-color: #212a49; color: white; margin: 2px;"
                              *ngIf="infoObj">
                        #{{infoObj['lastId']}} - #{{infoObj['firstId']}}</mat-chip>
                    <mat-chip class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">
                        Ukupno: {{getOrderCount()}} / {{getAlbumCount()}}</mat-chip>
                    <mat-chip class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">
                        Book: {{getBookCounts()}}</mat-chip>
                    <mat-chip class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">Book
                        S: {{infoObj['bookSCount']}}</mat-chip>
                    <mat-chip class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">
                        Frame: {{getFrameCounts()}}</mat-chip>
                    <mat-chip class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">
                        Frame XL: {{getFrameXLCounts()}}</mat-chip>
                    <mat-chip class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">
                        Box: {{getBoxCounts()}}</mat-chip>
                    <mat-chip class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">Book
                        Maxi: {{getBookXLCounts()}}</mat-chip>
                    <mat-chip class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">Book Maxi
                        S: {{infoObj['bookXLSCount']}}</mat-chip>
                    <mat-chip class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">Memory
                        Box: {{infoObj['memoryBoxCount']}}</mat-chip>

                    <mat-chip class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">YoloPrint: {{infoObj['yoloPrintCount']}}</mat-chip>
                    <mat-chip class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">YoloPrintClassic: {{infoObj['yoloPrintClassicCount']}}</mat-chip>
                    <mat-chip class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">YoloPrintOriginal: {{infoObj['yoloPrintOriginalCount']}}</mat-chip>
                    <mat-chip class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">YoloPrintRegular: {{infoObj['yoloPrintRegularCount']}}</mat-chip>
                    <mat-chip class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">YoloPrintSquare: {{infoObj['yoloPrintSquareCount']}}</mat-chip>
                    <mat-chip class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">YoloPrintSquareMini: {{infoObj['yoloPrintSquareMiniCount']}}</mat-chip>
                    <mat-chip class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">YoloPrintSquareMaxi: {{infoObj['yoloPrintSquareMaxiCount']}}</mat-chip>


                  <mat-chip class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">
                        Calendars: {{infoObj['calendarCount']}}</mat-chip>
                    <mat-chip class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">Hard
                        Square: {{infoObj['hardMiniCount']}}</mat-chip>
                    <mat-chip class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">Hard
                        Landscape: {{infoObj['hardMaxiCount']}}</mat-chip>
                    <mat-chip class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">Hard Square
                        S: {{infoObj['hardMiniSCount']}}</mat-chip>
                    <mat-chip class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">Hard
                        Landscape
                        S: {{infoObj['hardMaxiSCount']}}</mat-chip>
                    <mat-chip class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">YoloTiles
                        Square: {{infoObj['tilesSquareCount']}}</mat-chip>
                    <mat-chip class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">YoloTiles
                        Hexa: {{infoObj['tilesHexCount']}}</mat-chip>
                    <mat-chip class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">
                        YoloPlanner: {{infoObj['plannerCount']}}</mat-chip>
                    <mat-chip class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">
                        YoloNotes: {{infoObj['notesCount']}}</mat-chip>
                    <mat-chip class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">
                        YoloPremium: {{infoObj['premiumCount']}}</mat-chip>
                  <mat-chip class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">
                    YoloStories: {{infoObj['storiesCount']}}</mat-chip>
                  <mat-chip class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">
                    YoloCanvas: {{infoObj['canvasCount']}}</mat-chip>
                  <mat-chip class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">
                    YoloCanvas 30x30: {{infoObj['canvas30x30Count']}}</mat-chip>
                  <mat-chip class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">
                    YoloCanvas 30x30 Text: {{infoObj['canvas30x30TextCount']}}</mat-chip>
                  <mat-chip class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">
                    YoloCanvas 20x30: {{infoObj['canvas20x30Count']}}</mat-chip>
                  <mat-chip class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">
                    YoloCanvas 30x40: {{infoObj['canvas30x40Count']}}</mat-chip>

                  <mat-chip class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">KK Partizan Stories: {{infoObj['kkPartizanStoriesCount']}}</mat-chip>
                  <mat-chip class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">KK Partizan HCL: {{infoObj['hclPartizanCount']}}</mat-chip>
                  <mat-chip class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">KK Partizan (Aba, Euroleague): {{infoObj['hclAbaEuPartizanCount']}}</mat-chip>

                    <mat-chip class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">Upselling
                        Total Count: {{infoObj['upsellingCount']}}</mat-chip>
                    <div *ngFor="let u of infoObj['arrangedUpselling']">
                        <mat-chip class="roundSpan" style="background-color: #212a49; color: white; margin: 2px">
                            Upselling Count {{u.name}}: {{u.count}}</mat-chip>
                    </div>


                </mat-chip-list>
            </div>
        </div>

        <div class="col-12 actionsGroupButton">
            <button *ngIf="allowedEdit" mat-raised-button mat-button [ngClass]="{'disabledButton' : allDataLoading}"
                    [disabled]="allDataLoading" [matMenuTriggerFor]="actionsMenu"
                    style="margin-top: 8px; float:right; width: 20px; background-color: #d92a73; color:white;">
                <mat-icon>menu</mat-icon>
            </button>
            <button mat-raised-button mat-button [ngClass]="{'disabledButton' : allDataLoading}"
                    [disabled]="allDataLoading"
                    (click)="getAllOrders()"
                    style="margin-top: 8px; margin-right: 10px; float:right; width: 20px; background-color: #6e92ad; color:white;">
                {{'allOrders' | translate}}
            </button>
        </div>
        <mat-menu #actionsMenu="matMenu" yPosition="below" xPosition="before" style="float:right;">
            <div class="col-md-12 col-12 actionsGroup" *ngIf="allowedEdit" (click)="stopPropagation($event)">
                <div class="row">

                    <div class="col-12 col-md">
                        <button mat-raised-button color="primary" [matMenuTriggerFor]="appMenu"
                                style="margin-top: 8px; width: 100%">
                            {{'download' | translate | uppercase}}
                        </button>
                        <mat-menu #appMenu="matMenu">
                            <div class="scrollable-menu">
                                <button mat-menu-item [disabled]="ybDiff != 0" (click)="onDownloadYoloBook8()">YoloBook
                                    Mini- Po 8
                                </button>
                                <button mat-menu-item [disabled]="ybDiffS != 0" (click)="onDownloadYoloBookS8()">
                                    YoloBook Mini S- Po 8
                                </button>
                                <button mat-menu-item [disabled]="ybDiffXl != 0" (click)="onDownloadYoloBook8Xl()">
                                    YoloBook Maxi - Po
                                    6
                                </button>
                                <button mat-menu-item [disabled]="ybDiffXlS != 0" (click)="onDownloadYoloBook8XlS()">
                                    YoloBook Maxi S -
                                    Po 6
                                </button>
                                <button mat-menu-item (click)="onDownloadByIdClick()">YoloBook Mini ById - Po 8</button>
                                <button mat-menu-item (click)="onDownloadByIdClickS()">YoloBook Mini S ById - Po 8
                                </button>
                                <button mat-menu-item (click)="onDownloadByIdClickXl()">YoloBook Maxi ById - Po 6
                                </button>
                                <button mat-menu-item (click)="onDownloadByIdClickXlS()">YoloBook Maxi S ById - Po 6
                                </button>
                                <button mat-menu-item (click)="onDownloadYoloBook()">YoloBook Mini - Sve</button>
                                <button mat-menu-item (click)="onDownloadYoloBookS()">YoloBook Mini S- Sve</button>
                                <button mat-menu-item (click)="onDownloadYoloBookXl()">YoloBook Maxi - Sve</button>
                                <button mat-menu-item (click)="onDownloadYoloBookXlS()">YoloBook Maxi S - Sve</button>
                                <button mat-menu-item (click)="onDownloadYoloBox()">YoloBox - Sve</button>
                                <button mat-menu-item (click)="onDownloadYoloFrame()">YoloFrame - Sve</button>
                                <button mat-menu-item (click)="onDownloadYoloVintage()">YoloVintage</button>
                                <button mat-menu-item (click)="onDownloadYoloSquareMini()">YoloSquare Mini</button>
                                <button mat-menu-item (click)="onDownloadYoloClassic()">YoloClassic</button>
                                <button mat-menu-item (click)="onDownloadYoloOriginal()">YoloOriginal</button>
                                <button mat-menu-item (click)="onDownloadYoloSquare()">YoloSquare</button>
                                <button mat-menu-item (click)="onDownloadYoloRegular()">YoloRegular</button>
                                <button mat-menu-item (click)="onDownloadYoloSquareMaxi()">YoloSquare Maxi</button>
                                <button mat-menu-item (click)="onDownloadYoloCalendar()">YoloCalendar - Sve</button>

                                <button mat-menu-item [disabled]="ybCalDiff != 0" (click)="onDownloadYoloCalendar2()">
                                    YoloCalendar - Sve
                                    - Po 2
                                </button>
                                <button mat-menu-item (click)="onDownloadYoloCalendarBasic()">YoloCalendar - Basic
                                </button>
                                <button mat-menu-item [disabled]="ybCalBasicDiff != 0"
                                        (click)="onDownloadYoloCalendar2Basic()">
                                    YoloCalendar - Basic - Po 2
                                </button>
                                <button mat-menu-item (click)="onDownloadYoloCalendarKids()">YoloCalendar - Kids
                                </button>
                                <button mat-menu-item [disabled]="ybCalKidsDiff != 0"
                                        (click)="onDownloadYoloCalendar2Kids()">
                                    YoloCalendar - Kids - Po 2
                                </button>

                                <button mat-menu-item (click)="onDownloadYoloHardMini()" [disabled]="yhmini !=0">
                                    YoloHard Square - Po
                                    2
                                </button>
                                <button mat-menu-item (click)="onDownloadYoloHardMaxi()" [disabled]="yhmaxi !=0">
                                    YoloHard Landscape - Po
                                    2
                                </button>

                                <button mat-menu-item (click)="onDownloadYoloHardMiniS()" [disabled]="yhminiS !=0">
                                    YoloHard Square S -
                                    Po 2
                                </button>
                                <button mat-menu-item (click)="onDownloadYoloHardMaxiS()" [disabled]="yhmaxiS !=0">
                                    YoloHard Landscape S
                                    - Po 2
                                </button>
                                <button mat-menu-item (click)="onDownloadYoloTilesSquare()">YoloTiles Square - Sve
                                </button>
                                <button mat-menu-item (click)="onDownloadYoloTilesHex()">YoloTiles Hex - Sve</button>
                                <button mat-menu-item (click)="onDownloadYoloPremium()">YoloPremium - Sve</button>
                              <button mat-menu-item (click)="onDownloadYoloStories()" [disabled]="yStories !=0">
                                YoloStories - Po 2
                              </button>
                              <button mat-menu-item (click)="onDownloadYoloCanvas()">
                                YoloCanvas - Sve
                              </button>
                            </div>
                        </mat-menu>
                    </div>

                    <div class="col-12">
                        <button mat-raised-button style="margin-top: 8px; width: 100%; background-color: mediumseagreen"
                                (click)="onExcell()">
                            {{'sendings' | translate | uppercase}}
                        </button>
                    </div>
                    <div class="col-12">
                        <button mat-raised-button
                                style="margin-top: 8px; width: 100%; background-color: darkred; color: white;"
                                (click)="onSendInvoice()">
                            {{'sendInvoices' | translate | uppercase}}
                        </button>
                    </div>
                    <div class="col-12">
                        <button mat-raised-button
                                style="margin-top: 8px; width: 100%; background-color: cornflowerblue;"
                                (click)="exportOrders()">
                            {{'exportOrders' | translate | uppercase}}
                        </button>
                    </div>
                    <div class="col-12">
                        <button mat-raised-button style="margin-top: 8px; width: 100%; background-color: #ffda84"
                                (click)="file.click()">
                            {{'tracking' | translate | uppercase}}
                        </button>
                        <input type="file" accept=".xlsx, .xls, .csv, .pdf" #file
                               style="display: none; width: 0; height: 0;"
                               (change)="onExcelUpload($event)">
                    </div>
                    <div class="col-12">
                        <button mat-raised-button style="margin-top: 8px; width: 100%; background-color: dodgerblue"
                                (click)="onCustomsClick()">
                            {{'customs' | translate | uppercase}}
                        </button>
                    </div>
                    <div class="col-12">
                        <button mat-raised-button style="margin-top: 8px; width: 100%; background-color: lightcoral"
                                (click)="onPrintOrdersClick()">
                            {{'paymentSlips' | translate | uppercase}}
                        </button>
                    </div>
                    <div class="col-12">
                        <button mat-raised-button style="margin-top: 8px; width: 100%; background-color: darkgrey"
                                (click)="onPrintOrdersPDFClick()">
                            {{'pdfReports' | translate | uppercase}}
                        </button>
                    </div>
                    <div class="col-12">
                        <button mat-raised-button style="margin-top: 8px; width: 100%; background-color: darkseagreen"
                                (click)="getCombinations()">
                            {{'combinations' | translate | uppercase}}
                        </button>
                    </div>
                    <div class="col-12">
                        <button mat-raised-button style="margin-top: 8px; width: 100%; background-color: lightblue"
                                (click)="openMerge()">
                            {{'mergeOrders' | translate | uppercase}}
                        </button>
                    </div>
                    <div class="col-12 col-md-12">
                        <button mat-raised-button style="margin-top: 8px; width: 100%; background-color: coral"
                                (click)="facture()">
                            {{'invoices' | translate | uppercase}}
                        </button>
                    </div>
                </div>
            </div>
        </mat-menu>
    </div>

    <mat-spinner *ngIf="dataLoading" [diameter]="30"></mat-spinner>
    <br>
    <ngx-datatable
        #table
        class="material expandable"
        [rows]="orders"
        [columnMode]="'force'"
        [headerHeight]="50"
        [footerHeight]="75"
        [rowHeight]="'auto'"
        [scrollbarH]="true"
        [loadingIndicator]="isLoading"
        [scrollbarV]="false"
        [rowClass]="getRowClass"
        [messages]="messages"
        [selectionType]="'checkbox'"
        [selectAllRowsOnPage]="false"
        [displayCheck]="displayCheck"
        [rowIdentity]="getRowId"
        [selected]="selectedOrders"
        (select)='onSelect($event)'>


        <ngx-datatable-row-detail [rowHeight]="getRowDetailHeight" #myDetailRow (toggle)="onDetailToggle($event)">
            <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
                <div class="container-fluid no-gutters">
                    <div class="row" *ngFor="let item of row.items" [ngClass]="getRowClass(item)" style="padding: 12px">
                        <div class="col-6">
                            <div class="row">
                                <div class="col-auto">
                                    <mat-checkbox
                                            *ngIf="item.status === 3 || item.status === 4 || item.status === 5 "
                                            [checked]="getIfItemIsChecked(item)"
                                            [disabled]="checkDisabledItemYolobook(item)"
                                            (change)="onItemSelect(item, row, $event)">
                                    </mat-checkbox>
                                </div>
                                <span class="col-auto">
                    <mat-icon *ngIf="allowedEdit" style="cursor: pointer;" [hidden]="item.downloading"
                              (click)="onDownloadItemClick(item)">
                      file_download
                    </mat-icon>
                    <mat-spinner style="float: right;" [hidden]="!item.downloading" [diameter]="30"
                                 [mode]="indeterminate">
                    </mat-spinner>
                  </span>
                                <span *ngIf="isEditable(row, 'deleteAlbum')"><mat-icon class="clickable"
                                                                                       title="{{'delete' | translate}}"
                                                                                       (click)="deleteAlbum(item.id)">close</mat-icon></span>
                                <span *ngIf="isEditable(row, 'copyAlbum')"><mat-icon class="clickable"
                                                                                     title="{{'copy' | translate}}"
                                                                                     (click)="copyAlbum(item.id)">content_copy</mat-icon></span>

                                <span class="col" style="font-weight: bold !important;" *ngIf="item.error">
                  {{'error' | translate}} : {{item.error}}
                </span>
                                <span class="col">ID: {{item.id}}</span>
                                <span class="col">


                  <mat-form-field class="price-input">
                    <mat-label>{{'price' | translate}}</mat-label>
                    <input matInput type="number" *ngIf="allowedEdit" [value]="item.price" min="0"
                           (blur)="updateProductPrice($event, item, row.id)">
                    <span matSuffix>{{item.currency}}</span>
                  </mat-form-field>

                </span>

                                <span class="col">
                  {{'discount' | translate}}: {{item.discount}}%
                </span>

                                <span *ngIf="item.product.type !== 2" class="col-2">
                  {{'product' | translate}}: {{item.product.name}}
                </span>
                                <span *ngIf="item.product.type == 2" class="col-2">
                  {{'product' | translate}}: {{item.product.name + " " + item.product.id}}
                </span>
                                <span *ngIf="item.status >= 1" class="col-1">
                  {{'status' | translate}}: {{status[item.status - 1].name | translate}}
                </span>
                                <span *ngIf="item.coverId === null" class="col">{{'coverID' | translate}}: {{item.coverId}}</span>

                              <mat-form-field *ngIf="item.coverId !== null" class="price-input">
                                <mat-label>{{'coverID' | translate}}</mat-label>
                                <input matInput type="number" *ngIf="allowedEdit" [value]="item.coverId" min="0" (blur)="updateAlbumCover($event, item.id)">
                              </mat-form-field>

                                <span class="col">{{'numberOfPhotos' | translate}}: {{item.count}}</span>

                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ngx-datatable-row-detail>

        <ngx-datatable-column
                [width]="50"
                [sortable]="false"
                [canAutoResize]="false"
                [draggable]="false"
                [resizeable]="false"
                [headerCheckboxable]="true"
                [checkboxable]="true">
        </ngx-datatable-column>

        <ngx-datatable-column
                [width]="50"
                [resizeable]="false"
                [sortable]="false"
                [draggable]="false"
                [canAutoResize]="false">
            <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                <a
                        href="javascript:void(0)"
                        [class.datatable-icon-right]="!expanded"
                        [class.datatable-icon-down]="expanded"
                        title="Expand/Collapse Row"
                        (click)="toggleExpandRow(row)">
                </a>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="" *ngIf="collums.download && allowedEdit" sortable="false" prop="id" [width]="60">
            <ng-template #unverifiedBtn let-row="row" let-rowIndex="rowIndex" let-value="value"
                         ngx-datatable-cell-template>
                <mat-icon *ngIf="orders[rowIndex] && allowedEdit" style="cursor: pointer;"
                          [hidden]="orders[rowIndex].downloading"
                          (click)="onDownloadClick(orders[rowIndex])">file_download
                </mat-icon>
                <mat-spinner *ngIf="orders[rowIndex]" style="float: right;" [hidden]="!orders[rowIndex].downloading"
                             [diameter]="30" [mode]="indeterminate">
                </mat-spinner>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="" *ngIf="collums.remove && allowedEdit" sortable="false" prop="id" [width]="60">
            <ng-template #unverifiedBtn let-row="row" let-rowIndex="rowIndex" let-value="value"
                         ngx-datatable-cell-template>
                <mat-icon *ngIf="allowedEdit" style="cursor: pointer;"
                          (click)="onDeleteClick(orders[rowIndex].id, row)">close
                </mat-icon>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="allowedOrderCopy()" [width]="60">
            <ng-template let-row="row" ngx-datatable-cell-template>
                <mat-icon class="clickable" title="{{'copy' | translate}}" (click)="copyOrder(row.id)">content_copy
                </mat-icon>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="{{'country' | translate}}"
                              *ngIf="collums.country && (country == 'all' || country == 'eu')" sortable="false"
                              prop="country" [width]="60">
            <ng-template #unverifiedBtn let-row="row" let-rowIndex="rowIndex" let-value="value"
                         ngx-datatable-cell-template>
        <span>
          <img [src]="imageUrl(value)" alt="" style="border-radius: 50%; object-fit: cover; margin-right: 10px;"
               width="25"
               height="25"/>
        </span>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="ID" sortable="false" prop="id" [width]="80">

            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                <span (click)="openOrderHistory(row.id)">{{value}}</span>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [name]="getTranslation('number')" [width]="50" sortable="false" prop="items.length">

        </ngx-datatable-column>

        <ngx-datatable-column [name]="getTranslation('price')" *ngIf="collums.price" sortable="true" prop="price">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
            <span *ngIf="orders[rowIndex] && !editing[rowIndex + '-price']">
              {{value}} {{orders[rowIndex].currency}}
            </span>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="getTranslation('delivery')" *ngIf="collums.delivery" sortable="true"
                              prop="delivery">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
            <span *ngIf="orders[rowIndex]">
            <mat-form-field class="price-input">
                    <mat-label>{{'delivery' | translate}}</mat-label>
                    <input matInput type="number" *ngIf="allowedEdit" [value]="row.delivery" min="0"
                           (blur)="updateDeliveryPrice($event, row.id)">
                    <span matSuffix>{{orders[rowIndex].currency}}</span>
                  </mat-form-field>
            </span>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="canRefund()" [name]="getTranslation('refund')">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                <button mat-stroked-button color="accent" [disabled]="!row.invoice || row.invoice.isDeleted === true"
                        (click)="openRefund(row)">{{"refundInvoice" | translate}}</button>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [name]="getTranslation('date')" sortable="false" prop="created_at">
            <ng-template let-value="value" ngx-datatable-cell-template>u
                {{value | date: 'dd.MM.yyyy HH:mm':'+GMT'}}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [name]="getTranslation('payingMethod')" *ngIf="collums.payingMethod" sortable="true"
                              prop="payingMethod">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                <select *ngIf="isEditable(row, 'payment')" class="custom-select" placeholder="Type"
                        [value]="orders[rowIndex].payingMethod"
                        (change)="changePaymentType(orders[rowIndex].id, $event.target.value,row)">
                    <option *ngFor="let s of paymentTypes" [value]="s.value">
                        {{ s.name  | translate}}
                    </option>
                </select>
                <span *ngIf="!isEditable(row, 'payment')">
        <span *ngIf="value === 1">{{'cash' | translate }}</span>
        <span *ngIf="value === 2">{{'card' | translate }}</span>
        <span *ngIf="value === 3">{{'bonusPoints' | translate }}</span>
          </span>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [name]="getTranslation('payingStatus')" *ngIf="collums.payingStatus" sortable="true"
                              prop="payingStatus" style="padding: 0">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                <div style="height: 101%; width: 100%;">
          <span class='span-cell' *ngIf="value === 1" [style.color]="getColorOfOrders(row)"
                [class.payingStatus]="value === getColorForPayingMethod(row)"
          >{{'orderMade' | translate }}</span>
                    <span class='span-cell' *ngIf="value === 2" [style.color]="getColorOfOrders(row)"
                          [class.payingStatus]="value === getColorForPayingMethod(row)"
                    >{{'reserved' | translate }}</span>
                    <span class='span-cell' *ngIf="value === 3" [style.color]="getColorOfOrders(row)"
                          [class.payingStatus]="value === getColorForPayingMethod(row)"
                    >{{'refunded' | translate }}</span>
                    <span class='span-cell' *ngIf="value === 4" [style.color]="getColorOfOrders(row)"
                          [class.payingStatus]="value === getColorForPayingMethod(row)"
                    >{{'voided' | translate }}</span>
                    <span class='span-cell' *ngIf="value === 5" [style.color]="getColorOfOrders(row)"
                          [class.payingStatus]="value === getColorForPayingMethod(row)"
                    >{{'error' | translate }}</span>
                    <span class='span-cell' *ngIf="value === 6" [style.color]="getColorOfOrders(row)"
                          [class.payingStatus]="value === getColorForPayingMethod(row)"
                    >{{'submittedForSettlement' | translate }}</span>
                    <span class='span-cell' *ngIf="value === 7" [style.color]="getColorOfOrders(row)"
                          [class.payingStatus]="value === getColorForPayingMethod(row)"
                    >{{'authorizationExpired' | translate }}</span>
                </div>

            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [name]="getTranslation('status')" *ngIf="collums.status" sortable="false" prop="status"
                              [width]="220">

            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                <select *ngIf="allowedEdit" class="custom-select" placeholder="Status" [disabled]="!isEditable(row)"
                        [value]="status[orders[rowIndex].status-1].value"
                        (change)="onSelected(orders[rowIndex].id, $event.target.value,row)">
                    <option *ngFor="let s of status" [value]="s.value">
                        {{ s.name  | translate}}
                    </option>
                </select>
                <span *ngIf="!allowedEdit"> {{findStatus(value)}}</span>

            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [name]="getTranslation('name')" *ngIf="collums.name" sortable="true"
                              prop="address.fullName"
                              [width]="200"
        >
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
            <span
                    title="Dupli klik za izmenu"
                    (click)="editing[rowIndex + '-address.fullName'] = true; isEditing = true;"
                    *ngIf="!editing[rowIndex + '-address.fullName'] && isEditable(row)"
            >
              {{value}}
                <span *ngIf="value == ''">
                <mat-icon style="font-size: 18px; cursor: pointer;"
                          *ngIf="isEditable(row)">edit</mat-icon>
              </span>
            </span>
                <span *ngIf="!isEditable(row)" (click)="snackBarMessage()">{{value}}</span>
                <input
                        autofocus
                        (blur)="updateValue($event, 'address.fullName', rowIndex, row)"
                        *ngIf="editing[rowIndex+ '-address.fullName'] && allowedEdit"
                        type="text"
                        [value]="value"
                />
                <span *ngIf="!allowedEdit && editing[rowIndex+ '-address.fullName'] && isEditable(row)">
          {{value}}
        </span>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Econt office" *ngIf="collums.econt" sortable="false" prop="econtOffice">
            <ng-template let-value="value">
                {{value}}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [name]="getTranslation('address')" *ngIf="collums.address" sortable="false" prop="address"
                              [width]="275">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
           <span
                   title="Dupli klik za izmenu"
                   (click)="editing[rowIndex + '-address.address'] = true; isEditing = true;"
                   *ngIf="!editing[rowIndex + '-address.address'] && isEditable(row)">
              {{value.address}}
               <span *ngIf="value.address == ''">
               <mat-icon style="font-size: 18px; cursor: pointer;"
                         *ngIf="isEditable(row)  && allowedEdit">edit</mat-icon>
             </span>
           </span>
                <span *ngIf="!isEditable(row)" (click)="snackBarMessage()">{{value.address}}</span>
                <input
                        autofocus
                        (blur)="updateValue($event, 'address.address', rowIndex)"
                        *ngIf="editing[rowIndex+ '-address.address'] && allowedEdit"
                        type="text"
                        [value]="value.address"
                />
                <span
                        *ngIf="!allowedEdit && editing[rowIndex+ '-address.address'] && isEditable(row)"> {{value.address}}</span>

                <span
                        title="Dupli klik za izmenu"
                        (click)="editing[rowIndex + '-address.number'] = true; isEditing = true;"
                        *ngIf="!editing[rowIndex + '-address.number']">
              {{value.number}}
                    <span *ngIf="value.number == ''">
            <mat-icon style="font-size: 18px; cursor: pointer;" *ngIf="isEditable(row) && allowedEdit">edit</mat-icon>
          </span>
            </span>
                <input
                        autofocus
                        (blur)="updateValue($event, 'address.number', rowIndex)"
                        *ngIf="editing[rowIndex+ '-address.number'] && allowedEdit  && isEditable(row)"
                        type="text"
                        [value]="value.number"
                />
                <span *ngIf="!allowedEdit && editing[rowIndex+ '-address.number'] && isEditable(row)"> {{value.number}}</span>

                <span
                        title="Dupli klik za izmenu"
                        (click)="editing[rowIndex + '-address.place'] = true; isEditing = true;"
                        *ngIf="!editing[rowIndex + '-address.place'] && isEditable(row)">
               / {{value.place}}
                    <span *ngIf="value.place == null">
            <mat-icon style="font-size: 18px; cursor: pointer;" *ngIf="isEditable(row) && allowedEdit">edit</mat-icon>
          </span>
            </span>
                <input
                        autofocus
                        (blur)="updateValue($event, 'address.place', rowIndex)"
                        *ngIf="editing[rowIndex+ '-address.place'] && allowedEdit && isEditable(row)"
                        type="text"
                        [value]="value.place"
                />
                <span *ngIf="!allowedEdit && editing[rowIndex+ '-address.place'] && isEditable(row)"> / {{value.place}}</span>

            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [name]="getTranslation('phone')" *ngIf="collums.address" sortable="false"
                              prop="address.phone" [width]="160">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
    <span
            title="Dupli klik za izmenu"
            (click)="editing[rowIndex + '-address.phone'] = true; isEditing = true;"
            *ngIf="!editing[rowIndex + '-address.phone'] && isEditable(row)">
              {{value}}
        <span *ngIf="value == ''">
        <mat-icon
                style="font-size: 18px; cursor: pointer;" *ngIf="isEditable(row)">edit</mat-icon>
      </span>
            </span>
                <span *ngIf="!isEditable(row)" (click)="snackBarMessage()">{{value}}</span>
                <input
                        autofocus
                        (blur)="updateValue($event, 'address.phone', rowIndex)"
                        *ngIf="editing[rowIndex+ '-address.phone'] && allowedEdit"
                        type="text"
                        [value]="value"
                />
                <span *ngIf="!allowedEdit && editing[rowIndex + '-address.phone'] && isEditable(row)"> {{value}}</span>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [name]="getTranslation('municipality')" *ngIf="collums.address" sortable="false"
                              prop="address" [width]="230">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
        <span
                title="Dupli klik za izmenu"
                (click)="editing[rowIndex + '-address.estate'] = true; isEditing = true;"
                *ngIf="!editing[rowIndex + '-address.estate'] && isEditable(row)">
              {{value.estate}}
            <span *ngIf="value.estate == ''">
            <mat-icon
                    style="font-size: 18px; cursor: pointer;" *ngIf="isEditable(row)">edit</mat-icon>
          </span>
        </span>
                <span *ngIf="!isEditable(row)" (click)="snackBarMessage()">{{value.estate}}</span>
                <input
                        autofocus
                        (blur)="updateValue($event, 'address.estate', rowIndex)"
                        *ngIf="editing[rowIndex+ '-address.estate'] && allowedEdit"
                        type="text"
                        [value]="value.estate"
                />
                <span *ngIf="!allowedEdit && editing[rowIndex+ '-address.estate'] && isEditable(row) "> {{value.estate}}</span>

                <span
                        title="Dupli klik za izmenu"
                        (click)="editing[rowIndex + '-address.postalCode'] = true; isEditing = true;"
                        *ngIf="!editing[rowIndex + '-address.postalCode'] && isEditable(row)">
              {{value.postalCode}}
                    <span *ngIf="value.postalCode == ''">
            <mat-icon
                    style="font-size: 18px; cursor: pointer;" *ngIf="isEditable(row)">edit</mat-icon>
            </span>
        </span>
                <span *ngIf="!isEditable(row)" (click)="snackBarMessage()">{{value.postalCode}}</span>
                <input
                        autofocus
                        (blur)="updateValue($event, 'address.postalCode', rowIndex)"
                        *ngIf="editing[rowIndex+ '-address.postalCode'] && allowedEdit"
                        type="text"
                        [value]="value.postalCode"
                />
                <span
                        *ngIf="!allowedEdit && editing[rowIndex+ '-address.postalCode'] && isEditable(row)"> {{value.postalCode}}</span>
                <span
                        title="Dupli klik za izmenu"
                        (click)="editing[rowIndex + '-address.county'] = true; isEditing = true;"
                        *ngIf="!editing[rowIndex + '-address.county'] && country === 'ro' && isEditable(row)">
              {{value.county}}
                    <span *ngIf="value.county == ''">
            <mat-icon
                    style="font-size: 18px; cursor: pointer;" *ngIf="isEditable(row)">edit</mat-icon>
          </span>
        </span>
                <span *ngIf="!isEditable(row)" (click)="snackBarMessage()">{{value.county}}</span>
                <input
                        autofocus
                        (blur)="updateValue($event, 'address.county', rowIndex)"
                        *ngIf="editing[rowIndex+ '-address.county'] && allowedEdit  && country === 'ro'"
                        type="text"
                        [value]="value.county"
                />
                <span
                        *ngIf="!allowedEdit && editing[rowIndex+ '-address.county']  && country === 'ro' && isEditable(row)"> {{value.postalCode}}</span>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [name]="getTranslation('city')" *ngIf="collums.address" sortable="false"
                              prop="address.city"
                              [width]="190">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
            <span style="min-width: 10px"
                  title="Dupli klik za izmenu"
                  (click)="editing[rowIndex + '-address.city'] = true; isEditing = true;"
                  *ngIf="!editing[rowIndex + '-address.city'] && isEditable(row)">
              {{value}}
                <span *ngIf="value == ''">
                <mat-icon
                        style="font-size: 18px; cursor: pointer;" *ngIf="isEditable(row)">edit</mat-icon>
              </span>
            </span>
                <span *ngIf="!isEditable(row)" (click)="snackBarMessage()">{{value}}</span>
                <input
                        autofocus
                        (blur)="updateValue($event, 'address.city', rowIndex)"
                        *ngIf="editing[rowIndex+ '-address.city'] && allowedEdit"
                        type="text"
                        [value]="value"
                />
                <span *ngIf="!allowedEdit && editing[rowIndex+ '-address.city'] && isEditable(row)"> {{value}}</span>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [name]="getTranslation('note')" *ngIf="collums.note" sortable="false" prop="address.note"
                              [width]="250">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
            <span style="width: 150px"
                  title="Dupli klik za izmenu"
                  (click)="editing[rowIndex + '-address.note'] = true; isEditing = true;"
                  *ngIf="!editing[rowIndex + '-address.note'] && isEditable(row)">
               Napomena: {{value}}
            </span>
                <span *ngIf="!isEditable(row)" (click)="snackBarMessage()">Napomena:{{value}}</span>
                <input style="min-width: 150px"
                       autofocus
                       (blur)="updateValue($event, 'address.note', rowIndex)"
                       *ngIf="editing[rowIndex+ '-address.note'] && allowedEdit"
                       type="text"
                       [value]="value"
                />
                <span *ngIf="!allowedEdit && editing[rowIndex+ '-address.note'] && isEditable(row)"> Napomena: {{value}}</span>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Promo" *ngIf="collums.promo" sortable="false" prop="promo_text" [width]="160">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
            <span *ngIf="!isEditing">
              {{value}}
            </span>

                <span (click)="editing[rowIndex + '-promo_text'] = true; isEditing = true;"
                      *ngIf="!editing[rowIndex + '-promo_text'] && !isEditing && isEditable(row)"
                      style="padding: 8px; cursor: pointer">
            <mat-icon style="font-size: 18px" *ngIf="isEditable(row) && allowedEdit">edit</mat-icon>
        </span>

                <input style="min-width: 50px"
                       autofocus
                       (blur)="updateValue($event, 'promo_text', rowIndex)"
                       *ngIf="editing[rowIndex+ '-promo_text'] && allowedEdit"
                       type="text"
                       [value]="value"
                />
                <span *ngIf="!allowedEdit && editing[rowIndex+ '-promo_text'] && isEditable(row)"> {{value}}</span>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [name]="getTranslation('upsellingAction')" *ngIf="collums.upsellingAction" sortable="false"
                              prop="upsellingActionName" [width]="160">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                {{value}}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [name]="getTranslation('email')" *ngIf="collums.email" sortable="false"
                              prop="address.email"
                              [width]="240">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
            <span
                    title="Dupli klik za izmenu"
                    (click)="editing[rowIndex + '-address.email'] = true; isEditing = true;"
                    *ngIf="!editing[rowIndex + '-address.email'] && isEditable(row)">
              {{value}}
                <span *ngIf="value == ''">
                <mat-icon
                        style="font-size: 18px; cursor: pointer;" *ngIf="isEditable(row)">edit</mat-icon>
              </span>
            </span>
                <span *ngIf="!isEditable(row)" (click)="snackBarMessage()">{{value}}</span>
                <input
                        autofocus
                        (blur)="updateValue($event, 'address.email', rowIndex)"
                        *ngIf="editing[rowIndex+ '-address.email'] && allowedEdit"
                        type="text"
                        [value]="value"
                />
                <span *ngIf="!allowedEdit && editing[rowIndex+ '-address.email'] && isEditable(row)"> {{value}}</span>

            </ng-template>
        </ngx-datatable-column>
<!--        <ngx-datatable-column [name]="getTranslation('sendingsExtra')" *ngIf="collums.sendingsExtra" sortable="false"-->
<!--                              prop="sendingsExtra" [width]="80">-->
<!--            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">-->
<!--                <mat-slide-toggle [checked]="value > 0" [disabled]="!isEditable(row) || !allowedEdit"-->
<!--                                  (click)="snackBarMessage(row.status)"-->
<!--                                  (change)="onSendingsExtraChanged(orders[rowIndex], $event)">-->
<!--                </mat-slide-toggle>-->
<!--                &lt;!&ndash;        <mat-checkbox [(ngModel)]="value" *ngIf="!allowedEdit && isEditable(row)" disabled></mat-checkbox>&ndash;&gt;-->
<!--            </ng-template>-->
<!--        </ngx-datatable-column>-->

        <ngx-datatable-column [name]="getTranslation('free')" *ngIf="collums.free" sortable="false" prop="is_free"
                              [width]="80">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                <mat-slide-toggle [checked]="value>0" [disabled]="!isEditable(row) || !allowedEdit"
                                  (click)="snackBarMessage(row.status)"
                                  (change)="onFreeChanged(orders[rowIndex], $event)">
                </mat-slide-toggle>
                <!--        <mat-checkbox [(ngModel)]="value" *ngIf="!allowedEdit" disabled></mat-checkbox>-->
            </ng-template>
        </ngx-datatable-column>

<!--        <ngx-datatable-column [name]="getTranslation('fastDelivery')" *ngIf="collums.fast" sortable="false"-->
<!--                              prop="is_fast"-->
<!--                              [width]="120">-->
<!--            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">-->
<!--                <mat-slide-toggle [checked]="value>0" [disabled]="!isEditable(row) || !allowedEdit"-->
<!--                                  (click)="snackBarMessage(row.status)"-->
<!--                                  (change)="onFastChanged(orders[rowIndex], $event)">-->
<!--                </mat-slide-toggle>-->
<!--                &lt;!&ndash;        <mat-checkbox [(ngModel)]="value" *ngIf="!allowedEdit" disabled=""></mat-checkbox>&ndash;&gt;-->
<!--            </ng-template>-->
<!--        </ngx-datatable-column>-->

<!--        <ngx-datatable-column [name]="getTranslation('giftPackage')" *ngIf="collums.giftPackage" sortable="false"-->
<!--                              prop="gift_package" [width]="120">-->
<!--            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">-->
<!--                <mat-slide-toggle [checked]="value>0" [disabled]="!isEditable(row) || !allowedEdit"-->
<!--                                  (click)="snackBarMessage(row.status)"-->
<!--                                  (change)="onGiftChanged(orders[rowIndex], $event)">-->
<!--                </mat-slide-toggle>-->
<!--                &lt;!&ndash;        <mat-checkbox [(ngModel)]="value" *ngIf="!allowedEdit" disabled=""></mat-checkbox>&ndash;&gt;-->
<!--            </ng-template>-->
<!--        </ngx-datatable-column>-->

        <ngx-datatable-column [name]="getTranslation('receivedNumber')" sortable="false" prop="tracking_id"
                              [width]="120">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                <span (click)="onTrackingClick(orders[rowIndex].id)">{{value}}</span>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [name]="getTranslation('appVersion')" *ngIf="collums.appVersion" sortable="true"
                              prop="user.appVersion">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                <span>{{value}}</span>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [name]="getTranslation('refund')" sortable="false" *ngIf="allowedEdit && collums.refund"
                              [width]="130">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                <div *ngIf="isEditable(row)">
                    <button *ngIf="row.payingStatus == 2 || row.payingStatus == 6" mat-flat-button
                            style=" background-color: indianred; color: white;"
                            (click)="refund(row)">
                        {{'refund' | translate | uppercase}}
                    </button>
                </div>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [name]="getTranslation('payAgain')" sortable="false" *ngIf="allowedEdit" [width]="130">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                <div *ngIf="isEditable(row)">
                    <button
                            *ngIf="hideButtonPayAgain(row)"
                            mat-flat-button style=" background-color: chocolate; color: white;"
                            (click)="payAgainOrder(row)">
                        {{'payAgain' | translate | uppercase}}
                    </button>
                </div>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="OS" sortable="false" prop="user.device" [width]="64">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                <img *ngIf="value == 1" src="/assets/ios.png" alt="/assets/ios.png"
                     style="width: 28px; height: 32px; opacity: 0.6"/>
                <img *ngIf="value == 2" src="/assets/android.png" alt="/assets/android.png"
                     style="width: 32px; height: 32px; opacity: 0.6;"/>
                <img *ngIf="value == 3" src="/assets/web.png" style="width: 32px; height: 32px; opacity: 0.6;"
                     alt="/assets/web.png"/>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
    <br>
    <div style="text-align: center" *ngIf="orders && orders.length > 0 && !noMoreData">
        <button
                style="background-color: #6e92ad; cursor: pointer; color:white; border-radius: 24px; border:0 !important; padding: 10px;"
                (click)="loadNewOrders()">
            {{'loadNextPage' | translate}}
        </button>
    </div>
</mat-card>

