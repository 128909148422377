import {Component, OnInit} from '@angular/core';
import {Admin, Product, Promo} from "../models";
import {ApiService} from "../api.service";
import {FormControl} from "@angular/forms";
import {DataService} from "../data-service.service";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-promo',
  templateUrl: './promo.component.html',
  styleUrls: ['./promo.component.css'],
  providers: [ApiService]
})
export class PromoComponent implements OnInit {
  user: Admin;
  promo: Promo = {
    name: '', from: '', sendBill: 1, max: 100000, onePrice: 890, twoPrice: 1490, threePrice: 1990, id: 0,
    createdAt: '', count: 0, to: '', updatedAt: '', discount: 0, products: [], productsRaw: [], minAmount: 0
  };
  promoList: Promo[] = [];
  page = 0;
  allowMax = false;
  isLoading = false;
  allowedEdit = false;
  country = '';
  editMode = false;
  products: Product[] = [];

  constructor(private router: Router, private apiService: ApiService, private dataService: DataService, private snackBar: MatSnackBar,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.page = 0;
    this.country = localStorage.getItem('country');
    this.promo.country = this.country;
    this.user = JSON.parse(localStorage.getItem('adminYolo'));
    if (!this.checkPermission('promo')) {
      this.router.navigate(['/']);
    }
    this.dataService.country.subscribe((country) => {
      this.page = 0;
      this.country = country;
      this.promo.country = country;
      this.getAllPromos();
      this.getProducts();
    });
  }

  pushProductToProducts(product: Product) {
    if (product.children && product.children.length > 0 ) {
      if (product.active == 1) {
        product.children.forEach(sub => sub.active == 1 ? this.pushProductToProducts(sub) : null);
      }
    } else {
      if (product.active == 1) {
        for (let i = 0; i < 5; i++) {
          this.products.push(product);
        }
      }
    }
  }


  getProducts(): void {
    this.apiService.getProducts({isActive: 1}).subscribe((res: Product[]) => {
      this.products = [];
      res.forEach(product => this.pushProductToProducts(product));
    });
  }

  getAllPromos() {
    this.isLoading = true;
    this.apiService.getAllPromos(this.page).subscribe((promos: Promo[]) => {
      this.promoList = promos;
      this.isLoading = false;
    });
  }

  stringToArray(string: string) {
    return string.split(',', 10);
  }

  onScrollDown() {
    this.page++;
    this.isLoading = true;
    this.apiService.getAllPromos(this.page).subscribe((promos: Promo[]) => {
      this.promoList = [...this.promoList, ...promos];
      this.isLoading = false;
    });
  }

  getRowClass(row) {
    const toDate = new Date(row.to);
    const fromDate = new Date(row.from);
    const now = new Date();
    if (toDate > now && fromDate < now) {
      return 'working23';
    }
  }

  checkPermission(page) {
    const permissions = this.user.permissions;
    const rightPermission = permissions.find((x) => x.page === page);
    if (rightPermission) {
      if (rightPermission.edit === 1) {
        this.allowedEdit = true;
      } else {
        this.allowedEdit = false;
      }
      if (rightPermission.view === 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  selectionChange(index, condition) {
    const preparedCondition = [];
    condition.forEach((c) => {
      preparedCondition.push(c);
    });


    console.log('preparedCondition', preparedCondition);
    this.promo.products[index] = preparedCondition;
    this.promo.products = [...this.promo.products];
  }

  getTitle(value) {
    let title = '';

    value.forEach((v, i) => {
      if (v == 1) {
        title = title + 'YoloBook';
      } else if (v == 2) {
        title = title + 'YoloBox';
      } else if (v == 3) {
        title = title + 'YoloFrame';
      } else if (v == 4) {
        title = title + 'YoloBook XL';
      } else if (v == 20) {
        title = title + 'YoloCalendar Basic';
      } else if (v == 21) {
        title = title + 'YoloCalendar Kids';
      }

      if (i != 0) {
        title = title + '  ' + this.translate.instant('or') + ' ';
      }


      if (v.length > 1) {
        v.forEach((m, ind) => {
          if (m == 1) {
            title = title + 'YoloBook';
          } else if (m == 2) {
            title = title + 'YoloBox';
          } else if (m == 3) {
            title = title + 'YoloFrame';
          } else if (m == 4) {
            title = title + 'YoloBook XL';
          }

          if (ind != v.length - 1) {
            title = title + ', ';
          }
        });


      }
    });

    return title;
  }

  onActivate(event) {
    if (event.type == 'click') {

      if (this.allowedEdit) {
        const row = event.row;

        this.editMode = true;
        this.promo = row;
        this.promo.productsRaw = [];
        if (row.max == -1) {
          this.promo.max = 100000;
          this.allowMax = false;
        } else {
          this.allowMax = true;
        }

        this.promo.from = new Date(row.from).toISOString();
        this.promo.to = new Date(row.to).toISOString();

        let countBook = 0;
        let countBox = 0;
        let countFrame = 0;
        let bookXl = 0;

        row.products.forEach((p) => {
          p = p + '';
          const products = [];
          const sliced = p.split(",", 5);
          sliced.forEach((s) => {
            if (s == 1) {
              countBook = countBook + 1;
            }
            if (s == 2) {
              countBox = countBox + 1;
            }
            if (s == 3) {
              countFrame = countFrame + 1;
            }
            if (s == 4) {
              bookXl = bookXl + 1;
            }

            if (s == 1 && countBook > 1) {
              s = countBook + 10;
            }

            if (s == 4 && bookXl > 1) {
              s = bookXl + 10;
            }

            if (s == 2 && countBox > 1) {
              s = countBox + 20;
            }

            if (s == 3 && countFrame > 1) {
              s = countFrame + 30;
            }

            products.push(parseFloat(s));
          });


          this.promo.productsRaw.push(products);

        });
      } else {
        this.snackBar.open(this.translate.instant('notAllowed'), 'OK', {duration: 2000});
      }
    }
  }

  changeConditions() {
    if (this.promo.productsRaw.length == 0) {
      this.promo.productsRaw.push([]);
    } else {
      this.promo.productsRaw = [];
    }

  }

  imageUrl(country) {
    return "https://flagpedia.net/data/flags/small/" + country + ".png";
  }

  addNew() {
    this.promo = {
      name: '', from: '', sendBill: 1, max: 100000, onePrice: 890, twoPrice: 1490, threePrice: 1990, id: 0,
      createdAt: '', count: 0, to: '', updatedAt: '', discount: 0, products: [], productsRaw: [], minAmount: 0
    };
    this.editMode = false;
  }

  deletePromo(promo: Promo) {
    this.apiService.deletePromo(promo).subscribe((res) => {
      this.snackBar.open(this.translate.instant('deletedP'), "OK", {duration: 3000});
      this.promo = {
        name: '', from: '', sendBill: 1, max: 100000, onePrice: 890, twoPrice: 1490, threePrice: 1990, id: 0,
        createdAt: '', count: 0, to: '', updatedAt: '', discount: 0, products: [], productsRaw: [], minAmount: 0
      };
      const index = this.promoList.findIndex((x) => x.id == promo.id);
      this.promoList.splice(index, 1);
      this.promoList = [...this.promoList];
      this.editMode = false;
    }, (err) => {
      this.snackBar.open(this.translate.instant('thereWasAnError'), "OK", {duration: 3000});
    });
  }


  addOrCondition() {
    if (this.allowedEdit) {
      this.promo.productsRaw.push([]);
    } else {
      this.snackBar.open(this.translate.instant('notAllowed'), 'OK', {duration: 2000});
    }
  }

  updatePromo(promo: Promo) {
    if (this.allowedEdit) {
      this.apiService.updatePromo(promo).subscribe((res: Promo) => {
        this.promoList.forEach((p, i) => {
          if (promo.id === p.id) {
            this.promoList[i] = res;
            this.promoList = [...this.promoList];
            this.snackBar.open(this.translate.instant('saved'), "OK", {duration: 3000});
            this.editMode = false;
          }
        });
      }, (err) => {
        this.getAllPromos();
        this.snackBar.open(this.translate.instant('thereWasAnError'), "OK", {duration: 3000})
      });
    } else {
      this.snackBar.open(this.translate.instant('notAllowed'), 'OK', {duration: 2000});
    }

  }

  clickOutside() {
    console.log('clicked outside');
    this.editMode = false;
  }

  createPromo() {
    if (this.allowedEdit) {
      const promoToSend = this.promo;
      if (!this.allowMax) {
        promoToSend.max = -1;
      }
      this.apiService.createPromo(promoToSend).subscribe((res: Promo) => {
        res.count = 0;
        this.promoList = [...[res], ...this.promoList];
        this.snackBar.open(this.translate.instant('saved'), "OK", {duration: 3000});
        this.promo = {
          name: '', from: '', sendBill: 1, max: 100000, onePrice: 890, twoPrice: 1490, threePrice: 1990, id: 0,
          createdAt: '', count: 0, to: '', updatedAt: '', discount: 0, products: [], productsRaw: [], minAmount: 0
        };
      }, (err) => {
        this.snackBar.open(this.translate.instant('thereWasAnError'), "OK", {duration: 3000});
      });
    } else {
      this.snackBar.open(this.translate.instant('notAllowed'), 'OK', {duration: 2000});
    }
  }

  onMailChanged(promo: Promo, $event) {
    if (promo.id === 0) {
      if ($event.checked) {
        this.promo.sendBill = 1;
      } else {
        this.promo.sendBill = 0;
      }
    } else {
      this.promoList.forEach((p, i) => {
        if ($event.checked) {
          this.promoList[i].sendBill = 1;
        } else {
          this.promoList[i].sendBill = 0;
        }
      });
    }
  }

  onFromChanged(promo: Promo, $event) {
    console.log($event);
    promo.from = $event.value;
  }

  onToChanged(promo: Promo, $event) {
    console.log($event);
    promo.to = $event.value;
  }

  getDate(date: string) {
    return new FormControl(new Date(date));
  }

}
