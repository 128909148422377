<div class="dates-row">

  <mat-form-field>
    <input #fromInput matInput [matDatepicker]="pickerFrom" placeholder="{{'from' | translate}}"
           (dateChange)="onDateChanged($event.value)" [(ngModel)]="start">
    <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
    <mat-datepicker #pickerFrom></mat-datepicker>
  </mat-form-field>

  <mat-form-field>
    <input #fromInput matInput [matDatepicker]="pickerTo" placeholder="{{'to' | translate}}"
           (dateChange)="onDateChanged($event.value)" [(ngModel)]="end">
    <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
    <mat-datepicker #pickerTo></mat-datepicker>
  </mat-form-field>

</div>
<div class="statistics-row">
  <div class="statistics-box statistics-box--full">
    <div class="statistics-box-title">
      {{'registrationSteps' | translate}}
    </div>
    <app-registration-steps [datesChanged]="datesChanged.asObservable()" [start]="start" [end]="end"></app-registration-steps>

  </div>
</div>
<div class="statistics-row">
  <div class="statistics-box statistics-box--full">
    <div class="statistics-box-title">
      {{'sessionSteps' | translate}}
    </div>
    <app-session [datesChanged]="datesChanged.asObservable()" [start]="start" [end]="end"></app-session>

  </div>
</div>
<div class="statistics-row">
  <div class="statistics-box three-piece-row-box">
    <div class="statistics-box-title">
      {{'earningBonusPoints' | translate}}
    </div>
    <app-bonus-points [datesChanged]="datesChanged.asObservable()" [start]="start" [end]="end"></app-bonus-points>
  </div>

  <div class="statistics-box three-piece-row-box">
    <div class="statistics-box-title">
      {{'deletingApp' | translate}}
    </div>
    <app-delete-reasons [datesChanged]="datesChanged.asObservable()" [start]="start" [end]="end"></app-delete-reasons>
  </div>
</div>
