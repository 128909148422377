import { Component, OnInit } from '@angular/core';
import {Admin} from "../models";
import {ApiService} from "../api.service";
import {MatDialog} from "@angular/material/dialog";
import {MatDialogConfig} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

import {HttpClient} from "@angular/common/http";
import {DataService} from "../data-service.service";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {EmployeeComponent} from "../employee/employee.component";
import {SupplyHistoryComponent} from "../supply-history/supply-history.component";

@Component({
  selector: 'app-supplies',
  templateUrl: './supplies.component.html',
  styleUrls: ['./supplies.component.css']
})
export class SuppliesComponent implements OnInit {
  user: Admin;
  isLoading = false;
  supplies = [];
  change = [];
  allowedEdit = false;
  constructor(private apiService: ApiService,
              private snackBar: MatSnackBar,
              private mdDialog: MatDialog,
              private http: HttpClient,
              private dataService: DataService,
              private translate: TranslateService,
              private router: Router,
              private dialog: MatDialog) {
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('adminYolo'));
    if (!this.checkPermission('supplies')) {
      this.router.navigate(['/']);
    }
    this.apiService.getSupplies().subscribe(
      data => {
        const p = [];
        p['supplies'] = data;
        this.supplies = p['supplies'];
      }
    )
  }

  checkPermission(page){
    const permissions = this.user.permissions;
    const rightPermission = permissions.find( (x) => x.page === page);
    if (rightPermission){
      if (rightPermission.edit === 1){
        this.allowedEdit = true;
      } else {
        this.allowedEdit = false;
      }
      if (rightPermission.view === 1){
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  openHistory(event){
    if (event.type === 'click' && event.cellIndex !== 4){
      const dialogConfig = new MatDialogConfig();
      dialogConfig.panelClass = 'appointment-info-dialog';
      dialogConfig.data = {id: event.row.id};
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      this.dialog.open(SupplyHistoryComponent, dialogConfig);
    }
  }

  addChange(id){
    this.apiService.addSupplyHistory(id, this.change[id]).subscribe(
      data1 => {
        this.apiService.getSupplies().subscribe(
          data => {
            const p = [];
            p['supplies'] = data;
            this.supplies = p['supplies'];
          }
        );
      }
    );
    this.change[id] = 0;
  }

}
