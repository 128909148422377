<div class="campaigns-statistics">

  <mat-form-field>
    <mat-select placeholder="{{'type' | translate}}" (selectionChange)="filterCampaigns()" [(ngModel)]="selectedType" >
      <mat-option *ngFor="let t of campaignTypes" [value]="t.id">
        {{t.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <br>

  <mat-form-field>
    <mat-select placeholder="{{'campaign' | translate}}" (selectionChange)="getStatistics($event.value)" [disabled]="selectedType == null"  >
      <mat-option *ngFor="let c of filteredCampaigns" [value]="c">
        {{c.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="campaign-details" *ngIf="selectedCampaign">
    <div class="campaign-text" [innerHTML]="selectedCampaign.text"></div>
    <img class="campaign-image" [src]="selectedCampaign.imageUrl">
  </div>
</div>


<div class="statistics-box" *ngIf="selectedCampaign">
  <div class="statistics-box-title">
    {{'totalNumberOfUsers' | translate}}
  </div>
  <div class="statistics-total">
    {{selectedCampaign.sent}}
  </div>
  <canvas baseChart height="200"
          [datasets]="data"
          [labels]="labels"
          [legend]="false"
          [colors]="colors"
          [options]="barChartOptions"
          [chartType]="'horizontalBar'">

  </canvas>
</div>

