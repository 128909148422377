<div class="campaign-popup">
    <mat-icon class="modal-close" (click)="dialogRef.close()">close</mat-icon>
    <div class="campaign-info" *ngIf="data.mode == 1">
        <div class="campaign-status">
            <span *ngIf="data.survey.status == 0" style="color:#F0CB15">{{'waiting' | translate}}</span>
            <span *ngIf="data.survey.status == 1" style="color:#FF9D56">{{'inProgress' | translate}}</span>
            <span *ngIf="data.survey.status == 2" style="color:#2AC090">{{'done' | translate}}</span>
            <span *ngIf="data.survey.status == 3" style="color:#F44C4C">{{'error' | translate}}</span>
        </div>
        <div class="campaign-name">
            {{data.survey.name}}
        </div>
        <div class="campaign-details" style="border-bottom: 0 !important;">
           <span class="campaign-details-first">
        {{'completed' | translate}}:
      </span>
            {{data.survey.answered}} / {{data.survey.user_count}}
        </div>
    </div>
    <div class="survey-embed" *ngIf="data.mode == 3">
        <div class="campaign-popup-title">{{'editSurvey' | translate}}</div>
        <app-survey-form (surveyChange)="sendSurvey($event)" isNew="0" [survey]="data.survey" ></app-survey-form>
    </div>

    <div class="survey-embed" *ngIf="data.mode == 2">
        <div class="campaign-popup-title">{{'duplicateSurvey' | translate}}</div>
        <app-survey-form (surveyChange)="sendSurvey($event)" isNew="1" [survey]="data.survey" ></app-survey-form>
    </div>

    <div class="survey-embed" *ngIf="data.mode == 1">
        <app-survey-form (surveyChange)="sendSurvey($event)" isNew="1" viewOnly="1" [survey]="data.survey" ></app-survey-form>
    </div>
</div>
