<div class="container">
  <div class="row">
    <div class="col-12">
      <mat-form-field>
        <input matInput type="text" [(ngModel)]="facture.responsible_person" placeholder="Odgovorno lice">
      </mat-form-field>
      <br>
      <mat-form-field>
        <input matInput type="number" [(ngModel)]="facture.sack" placeholder="Broj Dzakova">
      </mat-form-field><br>
      <mat-form-field>
        <input matInput type="text" [(ngModel)]="facture.account_number" placeholder="Broj Racuna">
      </mat-form-field><br>
      <input type="button" class="btn btn-dark" (click)="sendFacture()" value="Potvrdi">
    </div>
  </div>
</div>
