import {Component, OnInit} from '@angular/core';
import {Admin, Banner} from "../models";
import {ApiService} from "../api.service";
import {DataService} from "../data-service.service";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {UploadBannerComponent} from "../upload-banner/upload-banner.component";
import {Router} from "@angular/router";

@Component({
  selector: 'app-get-banners',
  templateUrl: './get-banners.component.html',
  styleUrls: ['./get-banners.component.css']
})
export class GetBannersComponent implements OnInit {

  banners: Banner[] = [];
  allowedEdit = false;
  user: Admin;

  constructor(private apiService: ApiService,
              private dataService: DataService,
              private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private translate: TranslateService,
              private router: Router
  ) {
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('adminYolo'));
    if (!this.checkPermission('banners')) {
      this.router.navigate(['/']);
    }

    this.dataService.country.subscribe((country) => {
      this.getBannerer(country);
    });
  }

  checkPermission(page) {
    const permissions = this.user.permissions;
    const rightPermission = permissions.find((x) => x.page === page);
    if (rightPermission) {
      if (rightPermission.edit === 1) {
        this.allowedEdit = true;
      } else {
        this.allowedEdit = false;
      }
      if (rightPermission.view === 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  getBannerer(country) {
    this.apiService.getBanners(country).subscribe(
      (response: Banner[]) => {
        this.banners = response

      }
    )
  }

  public uploadBanner() {
    if (this.allowedEdit) {
      const dialogRef = this.dialog.open(UploadBannerComponent, {});

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.banners.unshift(result);
          this.banners.sort((a, b) => b.priority - a.priority);
          this.banners.sort((a, b) => b.is_active - a.is_active);
        }
      });
    } else {
      this.snackBar.open(this.translate.instant('notAllowed'), 'OK', {duration: 2000})
    }
  }


  deactive(banner) {
    if (this.allowedEdit) {
      this.apiService.changeActivityFalse(banner).subscribe(
        (response) => {
          banner = response;
          const index = this.banners.findIndex((b) => b.id == banner.id);
          if (index != -1) {
            this.banners[index] = banner;
            this.banners.sort((a, b) => b.priority - a.priority);
            this.banners.sort((a, b) => b.is_active - a.is_active);
          }
          this.snackBar.open(this.translate.instant('Deactive'), 'OK', {duration: 3000});

        }
      )
    } else {
      this.snackBar.open(this.translate.instant('notAllowed'), 'OK', {duration: 2000})
    }
  }

  activate(banner) {
    if (this.allowedEdit) {
      this.apiService.changeActivityTrue(banner).subscribe(
        (response: Banner) => {
          banner = response;
          const index = this.banners.findIndex((b) => b.id == banner.id);
          if (index != -1) {
            this.banners[index] = banner;
            this.banners.sort((a, b) => b.priority - a.priority);
            this.banners.sort((a, b) => b.is_active - a.is_active);
          }
          this.snackBar.open(this.translate.instant('Active'), 'OK', {duration: 3000});
        }
      )
    } else {
      this.snackBar.open(this.translate.instant('notAllowed'), 'OK', {duration: 2000})
    }
  }

  public changePriority(event: Event, banner) {
    if (this.allowedEdit) {
      banner.priority = (event.target as HTMLInputElement).value;
      this.apiService.changePriority(banner).subscribe(
        (response) => {
          banner = response;
          const index = this.banners.findIndex((b) => b.id == banner.id);
          if (index != -1) {
            this.banners[index] = banner;
            this.banners.sort((a, b) => b.priority - a.priority);
            this.banners.sort((a, b) => b.is_active - a.is_active);
          }
          this.snackBar.open(this.translate.instant('Success'), 'OK', {duration: 3000});

        }
      )
    } else {
      this.snackBar.open(this.translate.instant('notAllowed'), 'OK', {duration: 2000})
    }
  }

  public savePriority(banner) {
    if (this.allowedEdit) {
      this.apiService.changePriority(banner).subscribe(
        (response) => {
          banner = response;
          const index = this.banners.findIndex((b) => b.id == banner.id);
          if (index != -1) {
            this.banners[index] = banner;
            this.banners.sort((a, b) => b.priority - a.priority);
            this.banners.sort((a, b) => b.is_active - a.is_active);
          }
          this.snackBar.open(this.translate.instant('Success'), 'OK', {duration: 3000});

        }
      )
    } else {
      this.snackBar.open(this.translate.instant('notAllowed'), 'OK', {duration: 2000})
    }
  }

}
