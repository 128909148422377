import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material";
import {MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from "@ngx-translate/core";
import {DataService} from "../data-service.service";
import {ApiService} from "../api.service";

export interface DialogData {
  type: string;
}


@Component({
  selector: 'app-confirm-price-change-dialog',
  templateUrl: './confirm-price-change-dialog.component.html',
  styleUrls: ['./confirm-price-change-dialog.component.css']
})
export class ConfirmPriceChangeDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, private translate: TranslateService, private dataService: DataService, public snackBar: MatSnackBar, private service: ApiService, public dialogRef: MatDialogRef<ConfirmPriceChangeDialogComponent>) { }

  ngOnInit() {
  }

}
