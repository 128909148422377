import { Injectable } from '@angular/core';
import {main, Theme} from './theme/theme';


@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private active: Theme = main;
  private availableThemes: Theme[] = [main];

  getAvailableThemes(): Theme[] {
    return this.availableThemes;
  }

  getActiveTheme(): Theme {
    return this.active;
  }

  setActiveTheme(theme: Theme): void {
    this.active = theme;

    Object.keys(this.active.properties).forEach(property => {
      document.documentElement.style.setProperty(
        property,
        this.active.properties[property]
      );
    });
  }
}
