<div class="statistics-row">
  <div class="statistics-box user-status-box">
    <div class="statistics-box-title">
      {{'totalNumberOfUsers' | translate}}
    </div>
    <app-user-per-status  [datesChanged]="refreshData.asObservable()"></app-user-per-status>
  </div>

  <div class="statistics-box user-country-box">
    <div class="statistics-box-title statistics-box-title--margin">
      {{'totalNumberOfUsers' | translate}}
    </div>
    <app-user-per-country  [refreshData]="refreshData.asObservable()"></app-user-per-country>
  </div>
</div>
<div class="general-all-time">
  <div class="statistics-box">
    <div class="statistics-box-title">
      {{'totalNumberOfOrders' | translate}}
    </div>
    <div class="all-time-data">
      <div *ngIf="!generalLoading">{{generalStatistics?.totalOrders}}</div>
      <div class="for-dots-loader" *ngIf="generalLoading">
        <app-dots-loader height="50px" color="#606060"></app-dots-loader>
      </div>
    </div>
  </div>

  <div class="statistics-box">
    <div class="statistics-box-title">
      {{'aov' | translate}}
    </div>
    <div class="all-time-data">
      <div *ngIf="!generalLoading">€ {{generalStatistics?.averageOrderPrice}}</div>
      <div class="for-dots-loader" *ngIf="generalLoading">
        <app-dots-loader height="50px" color="#606060"></app-dots-loader>
      </div>
    </div>
  </div>

  <div class="statistics-box">
    <div class="statistics-box-title">
      {{'averageNumberOfOrders' | translate}}
    </div>
    <div class="all-time-data">
      <div *ngIf="!generalLoading">{{generalStatistics?.averageNumberOfOrders}}</div>
      <div class="for-dots-loader" *ngIf="generalLoading">
        <app-dots-loader height="50px" color="#606060"></app-dots-loader>
      </div>
    </div>
  </div>

  <div class="statistics-box">
    <div class="statistics-box-title">
      {{'totalNumberOfPhotos' | translate}}
    </div>
    <div class="all-time-data">
      <div *ngIf="!generalLoading">{{generalStatistics?.totalNumberOfPhotos}}</div>
      <div class="for-dots-loader" *ngIf="generalLoading">
        <app-dots-loader height="50px" color="#606060"></app-dots-loader>
      </div>
    </div>
  </div>
</div>
<div class="statistics-row">
  <div class="statistics-box bottom-box">
    <div class="statistics-box-title statistics-box-title--margin">
      {{'productPercentage' | translate}}
    </div>
    <app-albums-per-product [refreshData]="refreshData.asObservable()"></app-albums-per-product>
  </div>

  <div class="statistics-box bottom-box">
    <div class="statistics-box-title statistics-box-title--margin">
      {{'devicePercentage' | translate}}
    </div>
    <app-users-per-device [refreshData]="refreshData.asObservable()"></app-users-per-device>
  </div>
</div>
