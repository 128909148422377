<div class="header desktop-only" style="background-color: #d92a73; z-index: 5 !important; position: relative; padding-bottom: 18px"
     [ngClass]="{'hiddenHeader' : !showHeader}">
  <span
    style="font-size:1.7em; color:white; padding-left: 20px; font-weight: 500; margin-bottom:10px;"> {{'campaigns' | translate}} </span>
</div>
<div class="campaigns-container">
  <input class="campaign-search" [(ngModel)]="searchText" placeholder="{{'searchCampaign' | translate}}" (ngModelChange)="getCampaigns()">
  <div class="table-container">
    <ngx-datatable
      #table
      class="material users-orders-list"
      [rows]="campaigns"
      [columnMode]="'force'"
      [headerHeight]="50"
      [footerHeight]="75"
      [rowHeight]="'auto'"
      [scrollbarH]="true"
      [scrollbarV]="false"
      *ngIf="campaigns.length > 0"
    >
      <ngx-datatable-column [name]="getTranslation('name') | titlecase" sortable="false" prop="name" width="100">
        <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
          {{value}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [name]="getTranslation('audience') | titlecase" sortable="false" prop="audience_ids"
                            width="100">
        <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
          {{getAudiencesText(value)}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [name]="getTranslation('start') | titlecase" sortable="false" prop="start" width="100">
        <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
          {{value | date : 'dd.MM.yyyy HH:mm'}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [name]="getTranslation('type') | titlecase" sortable="false" prop="type" width="100">
        <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
          <span *ngIf="value == 1">{{'email' | translate}}</span>
          <span *ngIf="value == 2">{{'notifications' | translate}}</span>
          <span *ngIf="value == 3">{{'viber' | translate}}</span>

        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [name]="getTranslation('numbers') | titlecase" sortable="false" prop="status" width="100">
        <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
          <span title="{{'sent'| translate}}">{{row.sent}}</span> / <span title="{{'delivered'| translate}}">{{row.delivered}}</span> / <span title="{{'opened'| translate}}">{{row.open}}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [name]="getTranslation('sum') | titlecase" sortable="false" prop="moneySpent" width="100">
        <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
          €{{value}}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column [name]="getTranslation('status') | titlecase" sortable="false" prop="status" width="100">
        <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
          <span *ngIf="value == 0" style="color:#F0CB15">{{'waiting' | translate}}</span>
          <span *ngIf="value == 1" style="color:#FF9D56">{{'inProgress' | translate}}</span>
          <span *ngIf="value == 2" style="color:#2AC090">{{'done' | translate}}</span>
          <span *ngIf="value == 3" style="color:#F44C4C">{{'error' | translate}}</span>

        </ng-template>
      </ngx-datatable-column>

        <ngx-datatable-column  sortable="false" width="100">
          <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
            <img class="campaigns-more-button" src="assets/ic_more2.svg"  [matMenuTriggerFor]="options">
            <mat-menu class="campaigns-menu" #options="matMenu" yPosition="below" style="padding-top: 0 !important;padding-bottom: 0px !important;">
              <div class="campaigns-option" (click)="openPopup(row,1)">{{'info' | translate}}</div>
              <div class="campaigns-option" (click)="openPopup(row,2)">{{'duplicate' | translate}}</div>
              <div class="campaigns-option" *ngIf="row.status == 0"  (click)="openPopup(row,3)">{{'updateCampaign' | translate}}</div>
              <div class="campaigns-option" (click)="deleteCampaign(row)" *ngIf="row.status == 0">{{'delete' | translate}}</div>
            </mat-menu>
          </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
  </div>

</div>
