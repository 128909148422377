import {Component, OnInit} from '@angular/core';
import {Admin} from "../models";
import {ApiService} from "../api.service";
import {MatDialog } from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HttpClient} from "@angular/common/http";
import {DataService} from "../data-service.service";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.css']
})
export class PermissionsComponent implements OnInit {
  user: Admin;
  sectors: [];
  permissions: [];
  isLoading = false;
  allowedEdit = false;
  chosenSector = 1;

  constructor(private apiService: ApiService,
              private snackBar: MatSnackBar,
              private mdDialog: MatDialog,
              private http: HttpClient,
              private dataService: DataService,
              private translate: TranslateService,
              private router: Router) {
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('adminYolo'));
    if (!this.checkPermission('permissions')) {
      this.router.navigate(['/']);
    }
    this.apiService.sectorsList().subscribe(
      data => {
        const s = [];
        s['sectors'] = data;
        this.sectors = s['sectors'];
      }
    )
    this.apiService.getPermissions(this.chosenSector).subscribe(
      data => {
        const p = [];
        p['permissions'] = data;
        this.permissions = p['permissions'];
      }
    )
  }

  update(row, type, value) {
    if (type === 'view') {
      row.view = value;
    } else if (type === 'edit') {
      row.edit = value;
    }
    this.apiService.updatePermissions(row.id, row).subscribe(
      data => console.log(data)
    );
  }

  changeSector(id) {
    this.chosenSector = id;
    this.apiService.getPermissions(this.chosenSector).subscribe(
      data => {
        const p = [];
        p['permissions'] = data;
        this.permissions = p['permissions'];
      }
    )

  }

  checkPermission(page) {
    const permissions = this.user.permissions;
    const rightPermission = permissions.find((x) => x.page === page);
    if (rightPermission) {
      if (rightPermission.edit === 1) {
        this.allowedEdit = true;
      } else {
        this.allowedEdit = false;
      }
      if (rightPermission.view === 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
