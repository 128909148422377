import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable} from "rxjs/Observable";
import {ApiService} from "../../../api.service";
import {TranslateService} from "@ngx-translate/core";
import {DatePipe} from "@angular/common";
import {Product} from "../../../models";

@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.css']
})
export class SessionComponent implements OnInit, OnDestroy {
  @Input() start;
  @Input() end;
  @Input() datesChanged: Observable<void>;
  loading = false;
  interval: any;
  data = [];
  labels = [];
  total = 0;
  toggle = false;
  numbers = [];
  colors = [
    {backgroundColor: ["#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF"]}
  ];
  public barChartOptions = {
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return value + '%' ;
        }
      }
    },
    scales: {
      xAxes: [{
        display: true,
        min: 0,
        ticks: {
          min: 0,
          max: 100,
          beginAtZero: true
        },
        max: 100
      }],
      yAxes: [{
        display: true,
        min: 0,
        ticks: {
          min: 0,
          max: 100,
          beginAtZero: true
        },
        max: 100
      }]
    },
    barThickness: 2
  };
  lastKnownStart = null;
  lastKnownEnd = null;
  products = [];
  selectedProducts = [];
  height = 100;

  constructor(private service: ApiService, private translate: TranslateService, private datePipe: DatePipe) {
  }

  ngOnInit() {
    if (window.innerWidth <= 900) {
      this.height = 200;
    }
    window.onresize = (e) => {
      if (window.innerWidth <= 900) {
        this.height = 200;
      }
    }
    this.getStatistics();
    this.getProducts();

    this.datesChanged.subscribe((data: any) => {
      if (data) {
        this.toggle = false;
        this.start = data.start;
        this.end = data.end;
        this.getStatistics();
      }
    });
  }

  getProducts(): void {
    this.service.getProducts({isActive: 1}).subscribe((res: Product[]) => {
      this.products = [];
      res.forEach(product => this.pushProductToProducts(product));
    });
  }

  toggleChanged(value) {
    if (value.checked) {
      this.lastKnownStart = this.start;
      this.lastKnownEnd = this.end;
      const currentDate = new Date();
      const offset = Math.abs(new Date().getTimezoneOffset());
      const thirtyMinutesAgo = new Date(currentDate.getTime() - (30 * 60 * 1000) - (offset * 60 * 1000));
      console.log("thirty minutes  ", thirtyMinutesAgo);
      this.start = thirtyMinutesAgo;
      this.end = new Date();
      this.interval = setInterval(() => {
        this.getStatistics(false);
      }, 30000);
    } else {
      this.start = this.lastKnownStart;
      this.end = this.lastKnownEnd;
      clearInterval(this.interval);
    }
    this.getStatistics();

  }

  getStatistics(toResetLoading = true) {
    if (toResetLoading) {
      this.loading = true;
    }
    const start = this.datePipe.transform(this.start, 'yyyy-MM-dd h:mm');
    const end = this.datePipe.transform(this.end, 'yyyy-MM-dd h:mm');
    let products = 'none';
    if (this.selectedProducts.length > 0) {
      products = this.selectedProducts.join(",");
    }
    this.service.getSessionStatistics(start, end, products).subscribe((apiData: any) => {
      this.labels = apiData.labels;
      this.data = [{data: apiData.data, barPercentage: 0.5}];
      this.total = apiData.total;
      this.numbers = apiData.numbers;
      this.loading = false;
    })
  }

  pushProductToProducts(product: Product) {
    if (product.children && product.children.length > 0) {
      if (product.active == 1) {
        product.children.forEach(sub => sub.active == 1 ? this.pushProductToProducts(sub) : null);
      }
    } else {
      if (product.active == 1) {
        this.products.push(product);

      }
    }
  }

  selectedProductsChanged() {
    console.log(this.selectedProducts);
    this.getStatistics();
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);

  }


}
