<div class="header" style="background-color: #d92a73">
  <span style="font-size:1.7em; color:white; padding-left: 20px; font-weight: 500; margin-bottom:10px;"> {{'companyStructure' | translate}} </span>
</div>
<div class="container" style="margin-top: 50px;">
  <div  style="position: fixed;
       left: 60%;
    width: 80vw;
    top: 55%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);">
  <google-chart
    [type]="'OrgChart'"
    [data]="data"
    (select)="select($event)"
    [options] = "options"
  >

  </google-chart>
</div>

</div>
