<div class="header desktop-only"
     style="background-color: #d92a73; z-index: 5 !important; position: relative; padding-bottom: 18px"
     [ngClass]="{'hiddenHeader' : !showHeader}">
  <span
    style="font-size:1.7em; color:white; padding-left: 20px; font-weight: 500; margin-bottom:10px;"> {{'users' | translate}} </span>
</div>
<div class="bottom-button-container mobile-only-flex">
  <div (click)="createSurvey()" >
    <app-button text="{{'createSurvey' | translate}}"   [width]="'150px'" fontSize="16px"
                [height]="'48px'"  [color]="colors.PINK"
                [textColor]="colors.WHITE"
    ></app-button>
  </div>
  <div (click)="showAll()" >
    <app-button [text]="translate.instant('showAll')"   [width]="'150px'" fontSize="16px"
                [height]="'48px'"  [color]="colors.WHITE"
                [textColor]="colors.PINK"
    ></app-button>
  </div>

</div>

<div class="mobile-filter mobile-only-flex">
  <div class="for-filter-container">
    <div class="filter-only-mobile filter-only-mobile__full" (click)="openMobileFilter()">{{'filters' | translate}}</div>
<!--    <div class="saved-filter-mobile" *ngIf="savedFilter">{{savedFilter.name}}-->
<!--      <mat-icon (click)="savedFilter = null">close</mat-icon>-->
<!--    </div>-->
  </div>
  <div class="for-boxes-container">
    <div class="filter-options-mobile mini-box-mobile" (click)="filterMenuOption = 0; getFilters(); getAudiences()"
         [matMenuTriggerFor]="filterOptions"
         #filterOpener>
      <img src="assets/filter-list.svg" class="filter-save-image">
    </div>
    <div class="settings-box-mobile mini-box-mobile" [matMenuTriggerFor]="tableFilter">
      <img src="assets/ic_settings.svg" class="tableSettings"/>
    </div>
  </div>

  <div class="filters-full-size mobile-only" *ngIf="filtersFullscreen">
    <div class="filters-full-size--title" (click)="closeMobileFilter()">
      <img src="assets/ic_back.svg">
      {{'filters' | translate}}
    </div>
    <div style="height: calc(100dvh - 70px - 92px ); overflow: scroll">
      <div *ngFor="let f of mobileFilters; let i = index">
        <div class="filters-full-size--sub-title" (click)="toggleMobileFilter(i)">
          <img class="filters-full-size--closed" src="assets/ic_chavron.svg"
               [ngClass]="{'filters-full-size--opened': mobileFilters[i].opened}">
          {{f.name | translate}}
        </div>
        <div class="filters-full-size--data" *ngIf="mobileFilters[0].opened && i == 0">
          <mat-form-field style="width: 80%; margin: 8px">
            <input
              type="text"
              matInput
              placeholder="{{'name' | translate}}"
              [(ngModel)]="filter.firstName"
              (keyup.enter)="_getUsers(null, true)"/>
          </mat-form-field>
          <mat-form-field style="width: 80%; margin: 8px">
            <input
              type="text"
              matInput
              placeholder="{{'lastName' | translate}}"
              [(ngModel)]="filter.lastName"
              (keyup.enter)="_getUsers(null, true)"/>
          </mat-form-field>
          <mat-form-field style="width: 80%; margin: 8px">
            <input
              type="tel"
              matInput
              placeholder="ID"
              [(ngModel)]="filter.id"
              (keyup.enter)="_getUsers(null, true)"/>
          </mat-form-field>
          <mat-form-field style="width: 80%; margin: 8px">
            <input
              type="text"
              matInput
              placeholder="Email"
              [(ngModel)]="filter.email"
              (keyup.enter)="_getUsers(null, true)"/>
          </mat-form-field>
          <mat-form-field style="width: 80%; margin: 8px">
            <input
              type="text"
              matInput
              placeholder="{{'username' | translate}}"
              [(ngModel)]="filter.username"
              (keyup.enter)="_getUsers(null, true)"/>
          </mat-form-field>
          <mat-form-field style="width: 80%; margin: 8px">
            <input
              type="text"
              matInput
              placeholder="{{'phone' | translate}}"
              [(ngModel)]="filter.phone"
              (keyup.enter)="_getUsers(null, true)"/>
          </mat-form-field>
          <mat-form-field style="width: 80%; margin: 8px">
            <input
              type="text"
              matInput
              placeholder="{{'cityOrPostal' | translate}}"
              [(ngModel)]="filter.city"
              (keyup.enter)="_getUsers(null, true)"/>
          </mat-form-field>
        </div>
        <div class="filters-full-size--data" *ngIf="mobileFilters[1].opened && i == 1">
          <mat-checkbox [(ngModel)]="filter.inactive">{{"inactiveUser" | translate}}</mat-checkbox>
          <br>
          <mat-checkbox [(ngModel)]="filter.active">{{"activeUser" | translate}}</mat-checkbox>
          <br>
          <mat-checkbox [(ngModel)]="filter.deleted">{{"deletedUser" | translate}}</mat-checkbox>
          <br>
          <mat-checkbox [(ngModel)]="filter.blocked">{{"blockedUser" | translate}}</mat-checkbox>

        </div>
        <div class="filters-full-size--data" *ngIf="mobileFilters[2].opened && i == 2">

          <div class="slider-title">{{'ordersCount' | translate}}</div>
          <div class="slider-container slider-size">
            <span class="slider-container slider-limit">0</span>
            <ngx-slider [(value)]="filter.ordersCount[0]" [(highValue)]="filter.ordersCount[1]"
                        [options]="options"></ngx-slider>
            <span class="slider-limit">100</span>
          </div>

          <div class="slider-title">{{'averageSpent' | translate}} (EUR)</div>
          <div class="slider-container slider-size">
            <span class="slider-container slider-limit">0</span>
            <ngx-slider [(value)]="filter.averageSpent[0]" [(highValue)]="filter.averageSpent[1]"
                        [options]="options"></ngx-slider>
            <span class="slider-limit">100</span>
          </div>

          <div class="slider-title">{{'totalSpent' | translate}} (EUR)</div>
          <div class="slider-container slider-size">
            <span class="slider-container slider-limit">0</span>
            <ngx-slider [(value)]="filter.totalSpent[0]" [(highValue)]="filter.totalSpent[1]"
                        [options]="options"></ngx-slider>
            <span class="slider-limit">100</span>
          </div>

          <div class="slider-title">{{'promoCode' | translate}} (%)</div>
          <div class="slider-container slider-size">
            <span class="slider-container slider-limit">0</span>
            <ngx-slider [(value)]="filter.promoPercentage[0]" [(highValue)]="filter.promoPercentage[1]"
                        [options]="options"></ngx-slider>
            <span class="slider-limit">100</span>
          </div>
          <mat-form-field style="width: 44%; margin: 8px">
            <input
              [value]="filter.lastOrderFrom"
              matInput
              [matDatepicker]="lastOrderFrom"
              placeholder="{{'lastOrderFrom' | translate}}"
              (dateChange)="filter.lastOrderFrom = $event.value.toISOString()">
            <mat-datepicker-toggle matSuffix [for]="lastOrderFrom"></mat-datepicker-toggle>
            <mat-datepicker #lastOrderFrom></mat-datepicker>
          </mat-form-field>
          <mat-form-field style="width: 44%; margin: 8px">
            <input
              [value]="filter.loginTo"
              matInput
              [matDatepicker]="lastOrderTo"
              placeholder="{{'lastOrderTo' | translate}}"
              (dateChange)="filter.lastOrderTo = $event.value.toISOString()">
            <mat-datepicker-toggle matSuffix [for]="lastOrderTo"></mat-datepicker-toggle>
            <mat-datepicker #lastOrderTo></mat-datepicker>
          </mat-form-field>
          <mat-form-field style="width: 44%; margin: 8px">
            <input
              [value]="filter.loginFrom"
              matInput
              [matDatepicker]="loginFrom"
              placeholder="{{'loginDateFrom' | translate}}"
              (dateChange)="filter.loginFrom = $event.value.toISOString()">
            <mat-datepicker-toggle matSuffix [for]="loginFrom"></mat-datepicker-toggle>
            <mat-datepicker #loginFrom></mat-datepicker>
          </mat-form-field>
          <mat-form-field style="width: 44%; margin: 8px">
            <input
              [value]="filter.loginTo"
              matInput
              [matDatepicker]="loginTo"
              placeholder="{{'loginDateTo' | translate}}"
              (dateChange)="filter.loginTo = $event.value.toISOString()">
            <mat-datepicker-toggle matSuffix [for]="loginTo"></mat-datepicker-toggle>
            <mat-datepicker #loginTo></mat-datepicker>
          </mat-form-field>
          <mat-form-field style="width: 44%; margin: 8px">
            <input
              [value]="filter.fromDate"
              matInput
              [matDatepicker]="pickerFrom"
              placeholder="{{'registrationDateFrom' | translate}}"
              (dateChange)="filter.fromDate = $event.value.toISOString()">
            <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
            <mat-datepicker #pickerFrom></mat-datepicker>
          </mat-form-field>
          <mat-form-field style="width: 44%; margin: 8px">
            <input
              [value]="filter.toDate"
              matInput
              [matDatepicker]="pickerTo"
              placeholder="{{'registrationDateTo' | translate}}"
              (dateChange)="filter.toDate = $event.value.toISOString()">
            <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
            <mat-datepicker #pickerTo></mat-datepicker>
          </mat-form-field>


        </div>
        <div class="filters-full-size--data" *ngIf="mobileFilters[3].opened && i == 3">
          <div class="slider-title">{{'gender' | translate}}</div>
          <div>
            <br>
            <mat-checkbox [(ngModel)]="filter.isMale">{{"male" | translate}}</mat-checkbox>
            <br>
            <mat-checkbox [(ngModel)]="filter.isFemale">{{"female" | translate}}</mat-checkbox>
            <br>
            <mat-checkbox [(ngModel)]="filter.genderNotKnown">{{"genderNotKnown" | translate}}</mat-checkbox>
          </div>
          <br>
          <br>
          <div class="slider-title">{{'yearOfBirth' | translate}}</div>
          <div class="slider-container slider-size">
            <span class="slider-container slider-limit">1920</span>
            <ngx-slider [(value)]="filter.dateOfBirth[0]" [(highValue)]="filter.dateOfBirth[1]"
                        [options]="dateOfBirthOptions"></ngx-slider>
            <span class="slider-limit">{{getActualYear()}}</span>
          </div>
          <br>
          <hr class="slider-size">

          <mat-checkbox [(ngModel)]="filter.isUnknown">{{'unknown' | translate}}</mat-checkbox>
          <br>
          <mat-checkbox [(ngModel)]="filter.isIos">Ios</mat-checkbox>
          <br>
          <mat-checkbox [(ngModel)]="filter.isAndroid">Android</mat-checkbox>
<!--          <br>-->
<!--          <mat-checkbox [(ngModel)]="filter.isWeb">Web</mat-checkbox>-->
        <br>
          <mat-form-field style="width: 80%; margin: 8px">
            <input
              type="text"
              matInput
              placeholder="{{'appVersion' | translate}}"
              [(ngModel)]="filter.appVersion"
              (keyup.enter)="_getUsers(null, true)"/>
          </mat-form-field>


        </div>
        <div class="filters-full-size--data" *ngIf="mobileFilters[4].opened && i == 4">
          <mat-form-field style="width: 80%; margin: 8px">
            <input
              [value]="filter.satisfactionFromDate"
              matInput
              [matDatepicker]="pickerFromSatisfaction"
              placeholder="{{'dateFrom' | translate}}"
              (dateChange)="filter.satisfactionFromDate = $event.value.toISOString()">
            <mat-datepicker-toggle matSuffix [for]="pickerFromSatisfaction"></mat-datepicker-toggle>
            <mat-datepicker #pickerFromSatisfaction></mat-datepicker>
          </mat-form-field>
          <mat-form-field style="width: 80%; margin: 8px">
            <input
              [value]="filter.satisfactionToDate"
              matInput
              [matDatepicker]="pickerToSatisfaction"
              placeholder="{{'dateTo' | translate}}"
              (dateChange)="filter.satisfactionToDate = $event.value.toISOString()">
            <mat-datepicker-toggle matSuffix [for]="pickerToSatisfaction"></mat-datepicker-toggle>
            <mat-datepicker #pickerToSatisfaction></mat-datepicker>
          </mat-form-field>
          <div *ngFor="let r of ratingOptions" class="satisfaction-options">
            <div class="option-checkbox">
              <mat-checkbox [checked]="!optionDisabled(r.id)"
                            (change)="changeSatisfactionOption($event, r.id, null)">{{r.name}}</mat-checkbox>
            </div>
            <div *ngIf="r.id < 3" style="margin-left: 21px">
              <div class="option-checkbox" *ngFor="let a of ratingAdditionalOptions">
                <mat-checkbox [checked]="!optionDisabled(r.id + '---' + a.id)" [disabled]="optionDisabled(r.id)"
                              (change)="changeSatisfactionOption($event, r.id, a.id)"><span
                  style="text-transform: capitalize">{{getAdditionalText(a.name)}}</span></mat-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="filters-full-size--data" *ngIf="mobileFilters[5].opened && i == 5">
          <div *ngFor="let s of surveys">
            <mat-checkbox [checked]="isSurveyChecked(s.id)"
                          (change)="changeFilterSurvey($event, s.id)">{{s.name}}</mat-checkbox>
            <br>
          </div>
        </div>
      </div>

    </div>

    <div class="filters-full-size--buttons-container">
      <app-button [text]="translate.instant('resetSearch')" [textColor]="colors.PINK" [color]="colors.WHITE"
                  [width]="'150px'" fontSize="16px"
                  [height]="'48px'" (click)="_resetFilter()">
      </app-button>
      <app-button [text]="translate.instant('search')" [textColor]="colors.WHITE" [color]="colors.PINK"
                  [width]="'150px'" fontSize="16px"
                  [height]="'48px'" (click)="_getUsers(0, true)">
      </app-button>
    </div>
  </div>

</div>
<div class="searchHeader desktop-only"
     style="background-color: #b61052;  z-index: 5 !important; position: relative; display: flex; flex-wrap: wrap; font-size: 18px; color:white; font-weight: bold; align-content: stretch; justify-content: space-between;">
  <div class="menuFieldContainer">
    <div class="flex-grow-3 menuField" [matMenuTriggerFor]="filtered"
         style=" vertical-align: middle; min-width:17%; border-right: solid 2px rgba(45, 53, 71, 0.2); cursor:pointer;height: 100%;">
      <div class="filterText">
       <span class="d-none d-lg-block">
              <img src="assets/ic-search.svg">  {{'filter' | translate}} <img src="assets/ic-keyboard-arrow-down.svg">
       </span>
        <figure class="d-block d-sm-block d-md-block d-lg-none noMargin">
          <img class="hidden-lg" src="assets/ic_pretrazi po.svg">
        </figure>
      </div>

    </div>
    <div class="flex-grow-3 menuField" [matMenuTriggerFor]="status"
         style=" vertical-align: middle; min-width:17%; border-right: solid 2px rgba(45, 53, 71, 0.2); cursor:pointer;height: 100%;">
      <div class="filterText">
       <span class="d-none d-lg-block">
         {{'status' | translate}} <img src="assets/ic-keyboard-arrow-down.svg">
       </span>
        <figure class="d-block d-sm-block d-md-block d-lg-none noMargin">
          <img class="hidden-lg" src="assets/ic_pretrazi po.svg">
        </figure>
      </div>

    </div>
    <div class="menuField" [matMenuTriggerFor]="order"
         style="min-width:14%;  border-right: solid 2px rgba(45, 53, 71, 0.2); cursor:pointer; height: 100%;">
      <div class="filterText">
      <span class="d-none d-lg-block"> {{'orders' | translate }}  <img src="assets/ic-keyboard-arrow-down.svg">
      </span>
        <figure class="d-block d-sm-block d-md-block d-lg-none noMargin">
          <img src="assets/ic_narudzbine.svg">
        </figure>
      </div>
    </div>
    <div class="menuField" [matMenuTriggerFor]="profile"
         style="min-width:14%;  border-right: solid 2px rgba(45, 53, 71, 0.2); cursor:pointer; height: 100%;">
      <div class="filterText">
        <span class="d-none d-lg-block"> {{'profile' | translate }} <img
          src="assets/ic-keyboard-arrow-down.svg"></span>
        <figure class="d-block d-sm-block d-md-block d-lg-none noMargin">
          <img src="assets/ic_datum.svg">
        </figure>
      </div>
    </div>
    <div class="menuField" [matMenuTriggerFor]="satisfaction"
         style="min-width:14%;  border-right: solid 2px rgba(45, 53, 71, 0.2); cursor:pointer; height: 100%;">
      <div class="filterText">
      <span class="d-none d-lg-block"> {{'satisfaction' | translate }}  <img src="assets/ic-keyboard-arrow-down.svg">
      </span>
        <figure class="d-block d-sm-block d-md-block d-lg-none noMargin">
          <img src="assets/ic_narudzbine.svg">
        </figure>
      </div>
    </div>
    <div class="menuField" [matMenuTriggerFor]="surveysMenu"
         style="min-width:14%;  border-right: solid 2px rgba(45, 53, 71, 0.2); cursor:pointer; height: 100%;">
      <div class="filterText">
        <span class="d-none d-lg-block">{{'surveys' | translate }} <img src="assets/ic-keyboard-arrow-down.svg"></span>
        <figure class="d-block d-sm-block d-md-block d-lg-none noMargin">
          <img src="assets/ic_uredjaji.svg">
        </figure>
      </div>
    </div>

  </div>
  <div class="search-right-list">
    <app-button [text]="translate.instant('search')" [textColor]="colors.PINK" [color]="colors.WHITE"
                [width]="'111px'"
                [height]="'46px'" (click)="_getUsers(0, true)">
    </app-button>
    <div style="width: 20px"></div>
    <div class="filter-save" (click)="filterMenuOption = 0; getFilters(); getAudiences()"
         [matMenuTriggerFor]="filterOptions"
         #filterOpener>
      <img src="assets/filter-list.svg" class="filter-save-image">
    </div>
    <mat-menu #filterOptions="matMenu" yPosition="below">
      <div (click)="stopPropagation($event)">
        <div *ngIf="filterMenuOption == 0">
          <div class="filter-option" (click)="filterMenuOption = 1">{{'saveFilter' | translate}}</div>
          <div class="filter-option" (click)="filterMenuOption = 2">{{'listOfFilters' | translate}}</div>
          <div class="filter-option" (click)="filterMenuOption = 3">{{'saveAudience' | translate}}</div>
          <div class="filter-option" (click)="filterMenuOption = 4">{{'listOFAudience' | translate}}</div>

        </div>
        <div class="new-filter" *ngIf="filterMenuOption == 1">
          <mat-form-field style="width: 80%; margin: 8px">
            <input
              type="text"
              matInput
              [(ngModel)]="newFilterName"
              placeholder="{{'filterName' | translate}}"
            />
          </mat-form-field>
          <app-button [text]="translate.instant('save')" [textColor]="colors.WHITE" [color]="colors.PINK"
                      [width]="'60px'" [fontSize]="'12px'"
                      [height]="'30px'" (click)="saveFilter()">
          </app-button>
        </div>

        <div class="saved-filter-list" *ngIf="filterMenuOption == 2">
          <div class="saved-filter-title">{{'savedFilterList' | translate}}</div>
          <div class="filter-option filter-option-saved filter-option-audience" *ngFor="let f of savedFilters"
               (click)="selectFilter($event, f)">
            {{f.name}} <img class="filter-option-trash" src="assets/ic_trash.svg" (click)="deleteFilter(f)">
          </div>
        </div>
        <div class="new-filter" *ngIf="filterMenuOption ==3">
          <mat-form-field style="width: 80%; margin: 8px">
            <input
              type="text"
              matInput
              [(ngModel)]="newAudienceName"
              placeholder="{{'audienceName' | translate}}"
            />
          </mat-form-field>
          <app-button [text]="translate.instant('save')" [textColor]="colors.WHITE" [color]="colors.PINK"
                      [width]="'60px'" [fontSize]="'12px'"
                      [height]="'30px'" (click)="saveAudience()">
          </app-button>
        </div>
        <div class="saved-filter-list" *ngIf="filterMenuOption == 4">
          <div style="display: flex; justify-content: space-between; align-items: center">
            <div class="saved-filter-title">{{'savedAudiencesList' | translate}}</div>
            <div class="saved-filter-action" [ngClass]="{'disabled-button': selectedAudiences.length == 0}" (click)="createCampaign()">{{'createCampaign' | translate}}</div>
          </div>
          <div class="filter-option filter-option-saved filter-option-audience" *ngFor="let a of audiences">

            <mat-checkbox [checked]="isAudienceChecked(a)" (change)="toggleAudience(a)">{{a.name}} ({{a.element_count}})</mat-checkbox>
            <img class="filter-option-trash" src="assets/ic_trash.svg" (click)="deleteAudience(a)">
          </div>
        </div>


      </div>
    </mat-menu>
    <div class="settings-box" [matMenuTriggerFor]="tableFilter">
      <mat-menu #tableFilter="matMenu" yPosition="below">
        <div style="padding:10px;" (click)="stopPropagation($event)">
          <span style="font-weight: bold;">{{'showTable' | translate}}</span>
          <br>
          <div class="col">
            <mat-checkbox (change)="saveCollumns()"
                          [(ngModel)]="collumns.image">{{'image' | translate}}</mat-checkbox>
          </div>
          <div class="col">
            <mat-checkbox (change)="saveCollumns()" [(ngModel)]="collumns.id">Id</mat-checkbox>
          </div>
          <div class="col">
            <mat-checkbox (change)="saveCollumns()"
                          [(ngModel)]="collumns.username">{{'username' | translate}}</mat-checkbox>
          </div>
          <div class="col">
            <mat-checkbox (change)="saveCollumns()"
                          [(ngModel)]="collumns.fullName">{{'fullName' | translate}}</mat-checkbox>
          </div>
          <div class="col">
            <mat-checkbox (change)="saveCollumns()" [(ngModel)]="collumns.email">Email</mat-checkbox>
          </div>
          <div class="col">
            <mat-checkbox (change)="saveCollumns()" [(ngModel)]="collumns.phone">Phone</mat-checkbox>
          </div>
          <div class="col">
            <mat-checkbox (change)="saveCollumns()"
                          [(ngModel)]="collumns.satisfaction">{{'satisfaction' | translate}}</mat-checkbox>
          </div>
          <div class="col">
            <mat-checkbox (change)="saveCollumns()"
                          [(ngModel)]="collumns.satisfactionReason">{{'satisfactionReason' | translate}}</mat-checkbox>
          </div>
          <div class="col">
            <mat-checkbox (change)="saveCollumns()"
                          [(ngModel)]="collumns.appVersion">{{'appVersion' | translate}}</mat-checkbox>
          </div>
          <div class="col">
            <mat-checkbox (change)="saveCollumns()"
                          [(ngModel)]="collumns.ordersCount">{{'ordersCount' | translate}}</mat-checkbox>
          </div>
          <div class="col">
            <mat-checkbox (change)="saveCollumns()"
                          [(ngModel)]="collumns.promoPercentage">{{'promoPercentage' | translate}}</mat-checkbox>
          </div>
          <div class="col">
            <mat-checkbox (change)="saveCollumns()"
                          [(ngModel)]="collumns.averageSpent">{{'averageSpent' | translate}}</mat-checkbox>
          </div>
          <div class="col">
            <mat-checkbox (change)="saveCollumns()"
                          [(ngModel)]="collumns.totalSpent">{{'totalSpent' | translate}}</mat-checkbox>
          </div>
          <div class="col">
            <mat-checkbox (change)="saveCollumns()"
                          [(ngModel)]="collumns.lastOrder">{{'lastOrder' | translate}}</mat-checkbox>
          </div>
          <div class="col">
            <mat-checkbox (change)="saveCollumns()"
                          [(ngModel)]="collumns.lastLogin">{{'lastLogin' | translate}}</mat-checkbox>
          </div>
          <div class="col">
            <mat-checkbox (change)="saveCollumns()"
                          [(ngModel)]="collumns.registrationDate">{{'registrationDate' | translate}}</mat-checkbox>
          </div>
          <div class="col">
            <mat-checkbox (change)="saveCollumns()"
                          [(ngModel)]="collumns.device">{{'device' | translate}}</mat-checkbox>
          </div>
        </div>
      </mat-menu>

      <img src="assets/ic_settings.svg" class="tableSettings"/>
    </div>
  </div>
</div>

<mat-menu #status="matMenu" yPosition="below">
  <div style="padding:10px;" (click)="stopPropagation($event)">
    <mat-checkbox [(ngModel)]="filter.inactive">{{"inactiveUser" | translate}}</mat-checkbox>
    <br>
    <mat-checkbox [(ngModel)]="filter.active">{{"activeUser" | translate}}</mat-checkbox>
    <br>
    <mat-checkbox [(ngModel)]="filter.deleted">{{"deletedUser" | translate}}</mat-checkbox>
    <br>
    <mat-checkbox [(ngModel)]="filter.blocked">{{"blockedUser" | translate}}</mat-checkbox>

  </div>
</mat-menu>

<mat-menu #filtered="matMenu" yPosition="below">
  <div style="padding:10px;width: 300px" (click)="stopPropagation($event)">
    <mat-form-field style="width: 80%; margin: 8px">
      <input
        type="text"
        matInput
        placeholder="{{'name' | translate}}"
        [(ngModel)]="filter.firstName"
        (keyup.enter)="_getUsers(null, true)"/>
    </mat-form-field>
    <mat-form-field style="width: 80%; margin: 8px">
      <input
        type="text"
        matInput
        placeholder="{{'lastName' | translate}}"
        [(ngModel)]="filter.lastName"
        (keyup.enter)="_getUsers(null, true)"/>
    </mat-form-field>
    <mat-form-field style="width: 80%; margin: 8px">
      <input
        type="tel"
        matInput
        placeholder="ID"
        [(ngModel)]="filter.id"
        (keyup.enter)="_getUsers(null, true)"/>
    </mat-form-field>
    <mat-form-field style="width: 80%; margin: 8px">
      <input
        type="text"
        matInput
        placeholder="Email"
        [(ngModel)]="filter.email"
        (keyup.enter)="_getUsers(null, true)"/>
    </mat-form-field>
    <mat-form-field style="width: 80%; margin: 8px">
      <input
        type="text"
        matInput
        placeholder="{{'username' | translate}}"
        [(ngModel)]="filter.username"
        (keyup.enter)="_getUsers(null, true)"/>
    </mat-form-field>
    <mat-form-field style="width: 80%; margin: 8px">
      <input
        type="text"
        matInput
        placeholder="{{'phone' | translate}}"
        [(ngModel)]="filter.phone"
        (keyup.enter)="_getUsers(null, true)"/>
    </mat-form-field>
    <mat-form-field style="width: 80%; margin: 8px">
      <input
        type="text"
        matInput
        placeholder="{{'cityOrPostal' | translate}}"
        [(ngModel)]="filter.city"
        (keyup.enter)="_getUsers(null, true)"/>
    </mat-form-field>
  </div>
</mat-menu>
<mat-menu #device="matMenu" yPosition="below">
  <div style="padding:10px;" (click)="stopPropagation($event)">

  </div>
</mat-menu>
<mat-menu #order="matMenu" yPosition="below">
  <div style="padding:10px; width: 600px" (click)="stopPropagation($event)">

    <div class="slider-title">{{'ordersCount' | translate}}</div>
    <div class="slider-container">
      <span class="slider-container slider-limit">0</span>
      <ngx-slider [(value)]="filter.ordersCount[0]" [(highValue)]="filter.ordersCount[1]"
                  [options]="options"></ngx-slider>
      <span class="slider-limit">100</span>
    </div>

    <div class="slider-title">{{'averageSpent' | translate}} (EUR)</div>
    <div class="slider-container">
      <span class="slider-container slider-limit">0</span>
      <ngx-slider [(value)]="filter.averageSpent[0]" [(highValue)]="filter.averageSpent[1]"
                  [options]="options"></ngx-slider>
      <span class="slider-limit">100</span>
    </div>

    <div class="slider-title">{{'totalSpent' | translate}} (EUR)</div>
    <div class="slider-container">
      <span class="slider-container slider-limit">0</span>
      <ngx-slider [(value)]="filter.totalSpent[0]" [(highValue)]="filter.totalSpent[1]"
                  [options]="options"></ngx-slider>
      <span class="slider-limit">100</span>
    </div>

    <div class="slider-title">{{'promoCode' | translate}} (%)</div>
    <div class="slider-container">
      <span class="slider-container slider-limit">0</span>
      <ngx-slider [(value)]="filter.promoPercentage[0]" [(highValue)]="filter.promoPercentage[1]"
                  [options]="options"></ngx-slider>
      <span class="slider-limit">100</span>
    </div>

    <mat-form-field style="width: 45%; margin: 8px">
      <input
        type="number"
        matInput
        placeholder="{{'numberOfPhotosOrderedFrom' | translate}}"
        [(ngModel)]="filter.numberOfPhotosOrderedFrom"
      />
    </mat-form-field>

    <mat-form-field style="width: 45%; margin: 8px">
      <input
        type="number"
        matInput
        placeholder="{{'numberOfPhotosOrderedTo' | translate}}"
        [(ngModel)]="filter.numberOfPhotosOrderedTo"
      />
    </mat-form-field>

    <mat-form-field style="width: 45%; margin: 8px">
      <input
        [value]="filter.lastOrderFrom"
        matInput
        [matDatepicker]="lastOrderFrom"
        placeholder="{{'lastOrderFrom' | translate}}"
        (dateChange)="filter.lastOrderFrom = $event.value.toISOString()">
      <mat-datepicker-toggle matSuffix [for]="lastOrderFrom"></mat-datepicker-toggle>
      <mat-datepicker #lastOrderFrom></mat-datepicker>
    </mat-form-field>
    <mat-form-field style="width: 45%; margin: 8px">
      <input
        [value]="filter.loginTo"
        matInput
        [matDatepicker]="lastOrderTo"
        placeholder="{{'lastOrderTo' | translate}}"
        (dateChange)="filter.lastOrderTo = $event.value.toISOString()">
      <mat-datepicker-toggle matSuffix [for]="lastOrderTo"></mat-datepicker-toggle>
      <mat-datepicker #lastOrderTo></mat-datepicker>
    </mat-form-field>
    <mat-form-field style="width: 45%; margin: 8px">
      <input
        [value]="filter.loginFrom"
        matInput
        [matDatepicker]="loginFrom"
        placeholder="{{'loginDateFrom' | translate}}"
        (dateChange)="filter.loginFrom = $event.value.toISOString()">
      <mat-datepicker-toggle matSuffix [for]="loginFrom"></mat-datepicker-toggle>
      <mat-datepicker #loginFrom></mat-datepicker>
    </mat-form-field>
    <mat-form-field style="width: 45%; margin: 8px">
      <input
        [value]="filter.loginTo"
        matInput
        [matDatepicker]="loginTo"
        placeholder="{{'loginDateTo' | translate}}"
        (dateChange)="filter.loginTo = $event.value.toISOString()">
      <mat-datepicker-toggle matSuffix [for]="loginTo"></mat-datepicker-toggle>
      <mat-datepicker #loginTo></mat-datepicker>
    </mat-form-field>
    <mat-form-field style="width: 45%; margin: 8px">
      <input
        [value]="filter.fromDate"
        matInput
        [matDatepicker]="pickerFrom"
        placeholder="{{'registrationDateFrom' | translate}}"
        (dateChange)="filter.fromDate = $event.value.toISOString()">
      <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
      <mat-datepicker #pickerFrom></mat-datepicker>
    </mat-form-field>
    <mat-form-field style="width: 45%; margin: 8px">
      <input
        [value]="filter.toDate"
        matInput
        [matDatepicker]="pickerTo"
        placeholder="{{'registrationDateTo' | translate}}"
        (dateChange)="filter.toDate = $event.value.toISOString()">
      <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
      <mat-datepicker #pickerTo></mat-datepicker>
    </mat-form-field>

    <p>Product Group</p>
    <hr>
    <div [ngStyle]="{'display': 'flex', 'flex-wrap': 'wrap', 'gap': '10px'}">
      <div *ngFor="let p of productGroups; index as i" [ngStyle]="{'width': 'calc(50% - 5px)', 'display': 'flex', 'align-items': 'center'}">
        <mat-checkbox [checked]="isProductGroupChecked(p.id)" (change)="changeFilterProductGroup($event, p.id)">{{p.name}}</mat-checkbox>
      </div>
    </div>
  </div>
</mat-menu>

<mat-menu #surveysMenu="matMenu" yPosition="below">
  <div style="padding:10px;width: 300px; max-height: 200px" (click)="stopPropagation($event)">
    <div *ngFor="let s of surveys">
      <mat-checkbox [checked]="isSurveyChecked(s.id)"
                    (change)="changeFilterSurvey($event, s.id)">{{s.name}}</mat-checkbox>
      <br>
    </div>
  </div>
</mat-menu>

<mat-menu #profile="matMenu" yPosition="below">
  <div style="padding:10px;width: 300px" (click)="stopPropagation($event)">
    <div class="slider-title">{{'gender' | translate}}</div>
    <div>
      <br>
      <mat-checkbox [(ngModel)]="filter.isMale">{{"male" | translate}}</mat-checkbox>
      <br>
      <mat-checkbox [(ngModel)]="filter.isFemale">{{"female" | translate}}</mat-checkbox>
      <br>
      <mat-checkbox [(ngModel)]="filter.genderNotKnown">{{"genderNotKnown" | translate}}</mat-checkbox>
    </div>
    <br>
    <br>
    <div class="slider-title">{{'yearOfBirth' | translate}}</div>
    <div class="slider-container">
      <span class="slider-container slider-limit">1920</span>
      <ngx-slider [(value)]="filter.dateOfBirth[0]" [(highValue)]="filter.dateOfBirth[1]"
                  [options]="dateOfBirthOptions"></ngx-slider>
      <span class="slider-limit">{{getActualYear()}}</span>
    </div>
    <br>
    <hr>

    <mat-checkbox [(ngModel)]="filter.isUnknown">{{'unknown' | translate}}</mat-checkbox>
    <br>
    <mat-checkbox [(ngModel)]="filter.isIos">Ios</mat-checkbox>
    <br>
    <mat-checkbox [(ngModel)]="filter.isAndroid">Android</mat-checkbox>
<!--    <br>-->
<!--    <mat-checkbox [(ngModel)]="filter.isWeb">Web</mat-checkbox>-->

    <mat-form-field style="width: 80%; margin: 8px">
      <input
        type="text"
        matInput
        placeholder="{{'appVersion' | translate}}"
        [(ngModel)]="filter.appVersion"
        (keyup.enter)="_getUsers(null, true)"/>
    </mat-form-field>


  </div>
</mat-menu>
<mat-menu #satisfaction="matMenu" yPosition="below">
  <div style="padding:10px;" (click)="stopPropagation($event)">
    <mat-form-field style="width: 80%; margin: 8px">
      <input
        [value]="filter.satisfactionFromDate"
        matInput
        [matDatepicker]="pickerFromSatisfaction"
        placeholder="{{'dateFrom' | translate}}"
        (dateChange)="filter.satisfactionFromDate = $event.value.toISOString()">
      <mat-datepicker-toggle matSuffix [for]="pickerFromSatisfaction"></mat-datepicker-toggle>
      <mat-datepicker #pickerFromSatisfaction></mat-datepicker>
    </mat-form-field>
    <mat-form-field style="width: 80%; margin: 8px">
      <input
        [value]="filter.satisfactionToDate"
        matInput
        [matDatepicker]="pickerToSatisfaction"
        placeholder="{{'dateTo' | translate}}"
        (dateChange)="filter.satisfactionToDate = $event.value.toISOString()">
      <mat-datepicker-toggle matSuffix [for]="pickerToSatisfaction"></mat-datepicker-toggle>
      <mat-datepicker #pickerToSatisfaction></mat-datepicker>
    </mat-form-field>
    <div *ngFor="let r of ratingOptions" class="satisfaction-options">
      <div class="option-checkbox">
        <mat-checkbox [checked]="!optionDisabled(r.id)"
                      (change)="changeSatisfactionOption($event, r.id, null)">{{r.name}}</mat-checkbox>
      </div>
      <div *ngIf="r.id < 3" style="margin-left: 21px">
        <div class="option-checkbox" *ngFor="let a of ratingAdditionalOptions">
          <mat-checkbox [checked]="!optionDisabled(r.id + '---' + a.id)" [disabled]="optionDisabled(r.id)"
                        (change)="changeSatisfactionOption($event, r.id, a.id)"><span
            style="text-transform: capitalize">{{getAdditionalText(a.name)}}</span></mat-checkbox>
        </div>
      </div>
    </div>

  </div>
</mat-menu>

<div *ngIf="loading">
  <app-loading></app-loading>
</div>
<div *ngIf="!loading" style="position: relative">
  <div class="listFlex">
    <div class="selected-filters mobile-only" *ngIf="!savedFilter" (click)="openSelectedFilters()">{{'selectedFilters' | translate}}</div>

    <div class="tag mobile-only-flex" *ngIf="savedFilter">
      {{savedFilter.name}}
      <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
           (click)="savedFilter = null">
        &#10005;

      </div>
    </div>
    <div class="tagList desktop-only-flex">

      <div class="tag" *ngIf="filter.firstName">
        {{filter.firstName}}
        <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
             (click)="filter.firstName = ''"> &#10005;
        </div>
      </div>

      <div class="tag" *ngIf="filter.lastName">
        {{filter.lastName}}
        <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
             (click)="filter.lastName = ''"> &#10005;
        </div>
      </div>

      <div class="tag" *ngIf="filter.username">
        {{filter.username}}
        <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
             (click)="filter.username = ''"> &#10005;
        </div>
      </div>


      <div class="tag" *ngIf="filter.username">
        {{filter.username}}
        <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
             (click)="filter.username = ''"> &#10005;
        </div>
      </div>
      <div *ngFor="let r of ratingOptions">
        <div class="tag"
             *ngIf="filter.satisfactionOptions && filter.satisfactionOptions.length > 0 && !optionDisabled(r.id)">
          {{r.name}} {{findChildren(r.id)}}
          <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
               (click)="filter.satisfactionOptions = []"> &#10005;
          </div>
        </div>
      </div>
      <div class="tag" *ngIf="filter.fullName">
        {{filter.fullName}}
        <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
             (click)="filter.fullName = ''"> &#10005;
        </div>
      </div>
      <div class="tag" *ngIf="filter.surveys && filter.surveys.length > 0">
        {{'surveys' | translate}}: {{getSurveyNames()}}
        <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
             (click)="filter.surveys = []"> &#10005;
        </div>
      </div>

      <div class="tag" *ngIf="filter.productGroups && filter.productGroups.length > 0">
        {{'productGroup' | translate}}: {{getProductGroupsNames()}}
        <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
             (click)="filter.productGroups = []"> &#10005;
        </div>
      </div>

      <div class="tag" *ngIf="filter.selectedAudienceIds && filter.selectedAudienceIds.length > 0">
        {{'audienceName' | translate}}: {{getAudienceNames()}}
        <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
             (click)="filter.selectedAudienceIds = []; selectedAudiences = []"> &#10005;
        </div>
      </div>

      <div class="tag" *ngIf="filter.email">
        {{filter.email}}
        <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
             (click)="filter.email = ''">
          &#10005;
        </div>
      </div>
      <div class="tag" *ngIf="filter.id">
        Id: {{filter.id}}
        <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
             (click)="filter.id = ''">
          &#10005;
        </div>
      </div>
      <div class="tag" *ngIf="filter.appVersion">
        {{'appVersion' | translate}} {{filter.appVersion}}
        <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
             (click)="filter.appVersion = ''"> &#10005;
        </div>
      </div>
      <div class="tag" *ngIf="filter.isUnknown">
        {{'unknown' | translate}}
        <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
             (click)="filter.isUnknown = false"> &#10005;
        </div>
      </div>

      <div class="tag" *ngIf="filter.isIos">
        Ios
        <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
             (click)="filter.isIos = false"> &#10005;
        </div>
      </div>

      <div class="tag" *ngIf="filter.isAndroid">
        Android
        <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
             (click)="filter.isAndroid = false"> &#10005;
        </div>
      </div>

      <div class="tag" *ngIf="filter.isWeb">
        Web
        <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
             (click)="filter.isWeb = false"> &#10005;
        </div>
      </div>

      <div class="tag" *ngIf="filter.isFemale">
        {{"female" | translate}}
        <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
             (click)="filter.isFemale = false"> &#10005;
        </div>
      </div>

      <div class="tag" *ngIf="filter.isMale">
        {{"male" | translate}}
        <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
             (click)="filter.isMale = false"> &#10005;
        </div>
      </div>

      <div class="tag" *ngIf="filter.genderNotKnown">
        {{"genderNotKnown" | translate}}
        <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
             (click)="filter.genderNotKnown = false"> &#10005;
        </div>
      </div>

      <div class="tag" *ngIf="filter.deleted">
        {{'deletedUser' | translate}}
        <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
             (click)="filter.deleted = false"> &#10005;
        </div>
      </div>

      <div class="tag" *ngIf="filter.active">
        {{'activeUser' | translate}}
        <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
             (click)="filter.active = false"> &#10005;
        </div>
      </div>

      <div class="tag" *ngIf="filter.inactive">
        {{'inactiveUser' | translate}}
        <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
             (click)="filter.inactive = false"> &#10005;
        </div>
      </div>

      <div class="tag" *ngIf="filter.blocked">
        {{'blockedUser' | translate}}
        <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
             (click)="filter.blocked = false"> &#10005;
        </div>
      </div>

      <div class="tag" *ngIf="filter.isDeleted != null">
        <span *ngIf="filter.isDeleted == 0"> {{'all' | translate }} </span> <span
        *ngIf="filter.isDeleted == 1"> {{'undeleted' | translate }} </span> <span
        *ngIf="filter.isDeleted == 2"> {{'deleted' | translate }} </span>
        <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
             (click)="filter.isDeleted = null"> &#10005;
        </div>
      </div>
      <div class="tag" *ngIf="filter.fromDate">
        {{'registrationDateFrom' | translate}} {{filter.fromDate | date : 'shortDate' : 'Belgrade/Europe'}}
        <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
             (click)="filter.fromDate = ''"> &#10005;
        </div>
      </div>
      <div class="tag" *ngIf="filter.toDate">
        {{'registrationDateTo' | translate}} {{filter.toDate | date : 'shortDate' : 'Belgrade/Europe'}}
        <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
             (click)="filter.toDate = ''">
          &#10005;
        </div>
      </div>
      <div class="tag" *ngIf="filter.satisfactionToDate">
        {{'satisfactionToDate' | translate}} {{filter.satisfactionToDate | date : 'shortDate' : 'Belgrade/Europe'}}
        <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
             (click)="filter.satisfactionToDate = ''">
          &#10005;
        </div>
      </div>
      <div class="tag" *ngIf="filter.satisfactionFromDate">
        {{'satisfactionFromDate' | translate}} {{filter.satisfactionFromDate | date : 'shortDate' : 'Belgrade/Europe'}}
        <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
             (click)="filter.satisfactionFromDate = ''">
          &#10005;
        </div>
      </div>
      <div class="tag" *ngIf="filter.loginFrom">
        {{'loginDateFrom' | translate}} {{filter.loginFrom | date : 'shortDate' : 'Belgrade/Europe'}}
        <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
             (click)="filter.loginFrom = ''"> &#10005;
        </div>
      </div>
      <div class="tag" *ngIf="filter.loginTo">
        {{'loginDateTo' | translate}} {{filter.loginTo | date : 'shortDate' : 'Belgrade/Europe'}}
        <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
             (click)="filter.loginTo = ''"> &#10005;
        </div>
      </div>
      <div class="tag" *ngIf="filter.ordersCount[0] != sliderMin || filter.ordersCount[1] != sliderMax">
        {{'ordersCount' | translate}}:
        <span> {{'between' | translate}} {{filter.ordersCount[0]}} {{'and' | translate }} {{filter.ordersCount[1]}}</span>

        <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
             (click)="filter.ordersCount = [sliderMin, sliderMax]"> &#10005;
        </div>

      </div>

      <div class="tag" *ngIf="filter.dateOfBirth[0] != 1920 ">
        {{'yearOfBirth' | translate}}:
        {{filter.dateOfBirth[0]}} -  {{filter.dateOfBirth[1]}}

        <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
             (click)="filter.dateOfBirth = [1920, getActualYear()]"> &#10005;
        </div>

      </div>
      <div class="tag" *ngIf="filter.averageSpent[0] != sliderMin || filter.averageSpent[1] != sliderMax">
        {{'averageSpent' | translate}} (EUR):
        <span> {{'between' | translate}} {{filter.averageSpent[0]}} EUR {{'and' | translate}} {{filter.averageSpent[1]}} EUR</span>

        <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
             (click)="filter.averageSpent = [sliderMin, sliderMax]"> &#10005;
        </div>

      </div>
      <div class="tag" *ngIf="filter.totalSpent[0] != sliderMin || filter.totalSpent[1] != sliderMax">
        {{'totalSpent' | translate}} (EUR):
        <span> {{'between' | translate}} {{filter.totalSpent[0]}} EUR  {{'and' | translate}} {{filter.totalSpent[1]}} EUR</span>

        <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
             (click)="filter.totalSpent = [sliderMin, sliderMax]"> &#10005;
        </div>

      </div>

      <div class="tag" *ngIf="filter.promoPercentage[0] != sliderMin || filter.promoPercentage[1] != sliderMax">
        {{'promoCode' | translate}} (%):
        <span> {{'between' | translate}} {{filter.promoPercentage[0]}} % {{'and' | translate}} {{filter.promoPercentage[1]}} %</span>

        <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
             (click)="filter.promoPercentage = [sliderMin, sliderMax]"> &#10005;
        </div>

      </div>
      <div class="tag" *ngIf="filter.ordersType">
        {{'type' | translate}}:
        <div *ngFor="let type of filter.ordersType; let i = index">
          <span *ngIf="type == 0">  YoloBook <span *ngIf="i != filter.ordersType.length - 1">, </span></span>
          <span *ngIf="type== 1"> YoloBox <span *ngIf="i != filter.ordersType.length - 1">, </span></span>
          <span *ngIf="type == 2"> YoloFrame <span *ngIf="i != filter.ordersType.length - 1">, </span></span>
        </div>
        <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
             (click)="filter.ordersType = null"> &#10005;
        </div>
      </div>
      <div class="tag" *ngIf="filter.lastOrderFrom">
        {{'lastOrderFrom' | translate}} {{filter.lastOrderFrom | date : 'shortDate' : 'Belgrade/Europe'}}
        <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
             (click)="filter.lastOrderFrom = ''"> &#10005;
        </div>
      </div>
      <div class="tag" *ngIf="filter.lastOrderTo">
        {{'lastOrderTo' | translate}} {{filter.lastOrderTo | date : 'shortDate' : 'Belgrade/Europe'}}
        <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
             (click)="filter.lastOrderTo = ''"> &#10005;
        </div>
      </div>

      <div class="tag" *ngIf="filter.numberOfPhotosOrderedFrom">
        {{'numberOfPhotosOrderedFrom' | translate}}:  {{filter.numberOfPhotosOrderedFrom}}
        <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;" (click)="filter.numberOfPhotosOrderedFrom = ''"> &#10005;</div>
      </div>

      <div class="tag" *ngIf="filter.numberOfPhotosOrderedTo">
        {{'numberOfPhotosOrderedTo' | translate}}:  {{filter.numberOfPhotosOrderedTo}}
        <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;" (click)="filter.numberOfPhotosOrderedTo = ''"> &#10005;</div>
      </div>

    </div>
    <div (click)="_resetFilter()" class="desktop-only">
      <app-button [text]="translate.instant('resetSearch')" [color]="colors.WHITE"
                  [textColor]="colors.DARK"
                  [width]="'174px'" [height]="'46px'"></app-button>
    </div>
  </div>
</div>
<div class="bottom-sheet-backdrop mobile-only" *ngIf="selectedFiltersOpened" (click)="closeSelectedFilters()"></div>
<div class="bottom-sheet-container mobile-only"  *ngIf="selectedFiltersOpened">

<div class="bottom-sheet mobile-only" id="bottom-sheet" >
  <mat-icon class="bottom-sheet-close" (click)="closeSelectedFilters()">close</mat-icon>
  <div class="bottom-sheet-title">{{'selectedFilters' | translate}}</div>
  <div class="tagList ">
    <div class="tag" *ngIf="filter.username">
      {{filter.username}}
      <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
           (click)="filter.username = ''"> &#10005;
      </div>
    </div>
    <div *ngFor="let r of ratingOptions">
      <div class="tag"
           *ngIf="filter.satisfactionOptions && filter.satisfactionOptions.length > 0 && !optionDisabled(r.id)">
        {{r.name}} {{findChildren(r.id)}}
        <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
             (click)="filter.satisfactionOptions = []"> &#10005;
        </div>
      </div>
    </div>
    <div class="tag" *ngIf="filter.fullName">
      {{filter.fullName}}
      <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
           (click)="filter.fullName = ''"> &#10005;
      </div>
    </div>
    <div class="tag" *ngIf="filter.surveys && filter.surveys.length > 0">
      {{'surveys' | translate}}: {{getSurveyNames()}}
      <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
           (click)="filter.surveys = []"> &#10005;
      </div>
    </div>
    <div class="tag" *ngIf="filter.email">
      {{filter.email}}
      <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
           (click)="filter.email = ''">
        &#10005;
      </div>
    </div>
    <div class="tag" *ngIf="filter.id">
      Id: {{filter.id}}
      <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
           (click)="filter.id = ''">
        &#10005;
      </div>
    </div>
    <div class="tag" *ngIf="filter.appVersion">
      {{'appVersion' | translate}} {{filter.appVersion}}
      <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
           (click)="filter.appVersion = ''"> &#10005;
      </div>
    </div>
    <div class="tag" *ngIf="filter.isUnknown">
      {{'unknown' | translate}}
      <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
           (click)="filter.isUnknown = false"> &#10005;
      </div>
    </div>

    <div class="tag" *ngIf="filter.isIos">
      Ios
      <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
           (click)="filter.isIos = false"> &#10005;
      </div>
    </div>

    <div class="tag" *ngIf="filter.isAndroid">
      Android
      <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
           (click)="filter.isAndroid = false"> &#10005;
      </div>
    </div>

<!--    <div class="tag" *ngIf="filter.isWeb">-->
<!--      Web-->
<!--      <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"-->
<!--           (click)="filter.isWeb = false"> &#10005;-->
<!--      </div>-->
<!--    </div>-->

    <div class="tag" *ngIf="filter.isFemale">
      {{"female" | translate}}
      <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
           (click)="filter.isFemale = false"> &#10005;
      </div>
    </div>

    <div class="tag" *ngIf="filter.isMale">
      {{"male" | translate}}
      <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
           (click)="filter.isMale = false"> &#10005;
      </div>
    </div>

    <div class="tag" *ngIf="filter.genderNotKnown">
      {{"genderNotKnown" | translate}}
      <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
           (click)="filter.genderNotKnown = false"> &#10005;
      </div>
    </div>

    <div class="tag" *ngIf="filter.deleted">
      {{'deletedUser' | translate}}
      <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
           (click)="filter.deleted = false"> &#10005;
      </div>
    </div>

    <div class="tag" *ngIf="filter.active">
      {{'activeUser' | translate}}
      <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
           (click)="filter.active = false"> &#10005;
      </div>
    </div>

    <div class="tag" *ngIf="filter.blocked">
      {{'blockedUser' | translate}}
      <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
           (click)="filter.blocked = false"> &#10005;
      </div>
    </div>

    <div class="tag" *ngIf="filter.isDeleted != null">
      <span *ngIf="filter.isDeleted == 0"> {{'all' | translate }} </span> <span
      *ngIf="filter.isDeleted == 1"> {{'undeleted' | translate }} </span> <span
      *ngIf="filter.isDeleted == 2"> {{'deleted' | translate }} </span>
      <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
           (click)="filter.isDeleted = null"> &#10005;
      </div>
    </div>
    <div class="tag" *ngIf="filter.fromDate">
      {{'registrationDateFrom' | translate}} {{filter.fromDate | date : 'shortDate' : 'Belgrade/Europe'}}
      <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
           (click)="filter.fromDate = ''"> &#10005;
      </div>
    </div>
    <div class="tag" *ngIf="filter.toDate">
      {{'registrationDateTo' | translate}} {{filter.toDate | date : 'shortDate' : 'Belgrade/Europe'}}
      <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
           (click)="filter.toDate = ''">
        &#10005;
      </div>
    </div>
    <div class="tag" *ngIf="filter.satisfactionToDate">
      {{'satisfactionToDate' | translate}} {{filter.satisfactionToDate | date : 'shortDate' : 'Belgrade/Europe'}}
      <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
           (click)="filter.satisfactionToDate = ''">
        &#10005;
      </div>
    </div>
    <div class="tag" *ngIf="filter.satisfactionFromDate">
      {{'satisfactionFromDate' | translate}} {{filter.satisfactionFromDate | date : 'shortDate' : 'Belgrade/Europe'}}
      <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
           (click)="filter.satisfactionFromDate = ''">
        &#10005;
      </div>
    </div>
    <div class="tag" *ngIf="filter.loginFrom">
      {{'loginDateFrom' | translate}} {{filter.loginFrom | date : 'shortDate' : 'Belgrade/Europe'}}
      <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
           (click)="filter.loginFrom = ''"> &#10005;
      </div>
    </div>
    <div class="tag" *ngIf="filter.loginTo">
      {{'loginDateTo' | translate}} {{filter.loginTo | date : 'shortDate' : 'Belgrade/Europe'}}
      <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
           (click)="filter.loginTo = ''"> &#10005;
      </div>
    </div>
    <div class="tag" *ngIf="filter.ordersCount[0] != sliderMin || filter.ordersCount[1] != sliderMax">
      {{'ordersCount' | translate}}:
      <span> {{'betweeen' | translate}} {{filter.ordersCount[1]}}  {{'and' | translate}} {{filter.ordersCount[0]}}</span>

      <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
           (click)="filter.ordersCount = [sliderMin, sliderMax]"> &#10005;
      </div>

    </div>

    <div class="tag" *ngIf="filter.dateOfBirth[0] != 1920 ">
      {{'yearOfBirth' | translate}}:
      {{filter.dateOfBirth[0]}} -  {{filter.dateOfBirth[1]}}

      <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
           (click)="filter.dateOfBirth = [1920, getActualYear()]"> &#10005;
      </div>

    </div>
    <div class="tag" *ngIf="filter.averageSpent[0] != sliderMin || filter.averageSpent[1] != sliderMax">
      {{'averageSpent' | translate}} (EUR):
      <span> {{'between' | translate}} {{filter.averageSpent[0]}} EUR {{'and' | translate}} {{filter.averageSpent[1]}} EUR</span>

      <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
           (click)="filter.averageSpent = [sliderMin, sliderMax]"> &#10005;
      </div>

    </div>
    <div class="tag" *ngIf="filter.totalSpent[0] != sliderMin || filter.totalSpent[1] != sliderMax">
      {{'totalSpent' | translate}} (EUR):
      <span> {{'between' | translate}} {{filter.totalSpent[0]}} EUR  {{'and' | translate}} {{filter.totalSpent[1]}} EUR</span>

      <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
           (click)="filter.totalSpent = [sliderMin, sliderMax]"> &#10005;
      </div>

    </div>

    <div class="tag" *ngIf="filter.promoPercentage[0] != sliderMin || filter.promoPercentage[1] != sliderMax">
      {{'promoCode' | translate}} (%):
      <span> {{'between' | translate}} {{filter.promoPercentage[0]}} %  {{'and' | translate}} {{filter.promoPercentage[1]}} %</span>

      <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
           (click)="filter.promoPercentage = [sliderMin, sliderMax]"> &#10005;
      </div>

    </div>
    <div class="tag" *ngIf="filter.ordersType">
      {{'type' | translate}}:
      <div *ngFor="let type of filter.ordersType; let i = index">
        <span *ngIf="type == 0">  YoloBook <span *ngIf="i != filter.ordersType.length - 1">, </span></span>
        <span *ngIf="type== 1"> YoloBox <span *ngIf="i != filter.ordersType.length - 1">, </span></span>
        <span *ngIf="type == 2"> YoloFrame <span *ngIf="i != filter.ordersType.length - 1">, </span></span>
      </div>
      <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
           (click)="filter.ordersType = null"> &#10005;
      </div>
    </div>
    <div class="tag" *ngIf="filter.lastOrderFrom">
      {{'lastOrderFrom' | translate}} {{filter.lastOrderFrom | date : 'shortDate' : 'Belgrade/Europe'}}
      <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
           (click)="filter.lastOrderFrom = ''"> &#10005;
      </div>
    </div>
    <div class="tag" *ngIf="filter.lastOrderTo">
      {{'lastOrderTo' | translate}} {{filter.lastOrderTo | date : 'shortDate' : 'Belgrade/Europe'}}
      <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
           (click)="filter.lastOrderTo = ''"> &#10005;
      </div>
    </div>

    <div class="tag" *ngIf="filter.productGroups && filter.productGroups.length > 0">
      {{'productGroups' | translate}}: {{getProductGroupNames()}}
      <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
           (click)="filter.productGroups = []"> &#10005;
      </div>
    </div>

  </div>

</div>
</div>
  <div class="users-mobile-divider mobile-only">
  </div>
  <div *ngIf="!loading" class="d-flex flex-column">
  <div class="bottomList">
    <div class="total-list">
      <div class="savedFilter desktop-only" *ngIf="savedFilter">{{savedFilter.name}} <span style="cursor: pointer"
                                                                              (click)="_resetFilter();">&#10005;</span>
      </div>
      <div class="totalNumber">{{'total' | translate}}
        : {{totalQueryResults}}</div>
      <div class="totalNumber">{{'shown' | translate}}
        : {{rows?.length}}</div>
      <div class="selected" *ngIf="selectedUsers.length > 0">{{'selected' | translate}}
        : {{selectedUsers?.length}}</div>
    </div>
    <div class="buttons-list">
      <div (click)="showAll()" class="desktop-only">
        <app-button [text]="translate.instant('showAll')" [width]="'121px'" [height]="'48px'" [color]="colors.WHITE"
                    [textColor]="colors.PINK"
        ></app-button>
      </div>
      <div (click)="createSurvey()" class="desktop-only">
        <app-button text="{{'createSurvey' | translate}}" [width]="'155px'" [height]="'48px'" [color]="colors.WHITE"
                    [textColor]="colors.PINK"
        ></app-button>
      </div>
      <div (click)="exportUsers()" class="desktop-only">
        <app-button [text]="'Export'" [width]="'101px'" [height]="'48px'" [color]="colors.WHITE"
                    [textColor]="colors.PINK"
        ></app-button>
      </div>
      <div (click)="exportUsers()" class="mobile-only">
        <app-button [text]="'Export'" [width]="'77px'" fontSize="16px" [height]="'48px'" [color]="colors.WHITE"
                    [textColor]="colors.PINK"
        ></app-button>
      </div>
      <!--      <div (click)="campaignList()">-->
      <!--        <app-button [text]="translate.instant('campaignList')" *ngIf="allowedEdit" [width]="'165px'"-->
      <!--                    [height]="'48px'" [color]="colors.PINK" [textColor]="colors.WHITE"></app-button>-->
      <!--      </div>-->
    </div>
  </div>
</div>
<br style="float: unset; clear: both">
<div *ngIf="!loading" class="main-users">
  <ngx-datatable [ngClass]="{'moveDownTable' : selectedUsers.length > 0}" style="transition: all .25s linear;"
                 class="material expandable"
                 infiniteScroll style="height: 93vh; overflow-y: scroll;"
                 [scrollWindow]="false"
                 [infiniteScrollDistance]="5"
                 [infiniteScrollThrottle]="50"
                 (scrolled)="onScrollDown()"
                 [rows]="rows"
                 [columnMode]="'force'"
                 [headerHeight]="50"
                 [footerHeight]="75"
                 [scrollbarH]="true"
                 [rowHeight]="'auto'"
                 [messages]="messages"
                 [loadingIndicator]="loading"
                 [offset]="pageNumber"
                 [reorderable]="true"
                 [externalSorting]="true"
                 (sort)="onSort($event)"
                 [selected]="selectedUsers"
                 [selectionType]="'checkbox'"
                 (activate)='onActivate($event)'
                 (select)="onSelect($event)"
  >

    <ngx-datatable-column
      [width]="50"
      [sortable]="false"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="false"
      [headerCheckboxable]="true"
      [checkboxable]="true">
    </ngx-datatable-column>
    <ngx-datatable-column
      *ngIf="collumns.id"
      [width]="80"
      [canAutoResize]="false"
      [draggable]="true"
      [resizeable]="true"
      prop="id"
      name="ID">
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="collumns.username"
      [sortable]="true"
      [canAutoResize]="false"
      [draggable]="true"
      [resizeable]="true"
      prop="username"
      name="{{'username' | translate}}">
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="collumns.fullName"
      [sortable]="true"
      [canAutoResize]="false"
      [draggable]="true"
      [resizeable]="true"
      prop="fullName"
      name="{{'fullName' | translate}}">
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="collumns.email"
      [width]="250"
      [sortable]="true"
      [canAutoResize]="false"
      [draggable]="true"
      [resizeable]="true"
      prop="email"
      name="Email">
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="collumns.phone"
      [width]="250"
      [sortable]="true"
      [canAutoResize]="false"
      [draggable]="true"
      [resizeable]="true"
      prop="phone"
      name="Phone">
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="collumns.satisfaction"
      [sortable]="true"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="true"
      prop="country"
      name="{{'satisfaction' | translate}}">
      <ng-template #unverifiedBtn let-row="row" let-rowIndex="rowIndex" let-value="value"
                   ngx-datatable-cell-template>
        {{getSatisfaction(row)}}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="collumns.satisfactionReason"
      [sortable]="true"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="true"
      prop="country"
      name="{{'satisfactionReason' | translate}}">
      <ng-template #unverifiedBtn let-row="row" let-rowIndex="rowIndex" let-value="value"
                   ngx-datatable-cell-template>
        {{getSatisfactionReason(row)}}
        <img (click)="openMessage(row)" class="rating-comment" *ngIf="row.ratingComment"
             src="assets/ic_message.svg">
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="collumns.appVersion"
      [sortable]="true"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="true"
      prop="appVersion"
      name="{{'appVersion' | translate}}">
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="collumns.ordersCount"
      [sortable]="true"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="true"
      prop="ordersCount"
      name="{{'ordersCount' | translate}}">
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="collumns.averageSpent"
      [sortable]="true"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="true"
      prop="averageSpent"
      name="{{'averageSpent' | translate}}">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        {{ value }} EUR
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="collumns.totalSpent"
      [sortable]="true"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="true"
      prop="totalSpent"
      name="{{'totalSpent' | translate}}">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        {{ value }} EUR
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="collumns.promoPercentage"
      [sortable]="true"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="true"
      prop="promoPercentage"
      name="{{'promoPercentage' | translate}}">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        {{ value }}%
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="collumns.lastOrder"
      [sortable]="true"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="true"
      prop="lastOrder"
      name="{{'lastOrder' | translate}}">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        {{ value| DMYPipe }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="collumns.birthday"
      [sortable]="true"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="true"
      prop="birthday"
      name="{{'birthday' | translate}}">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        {{ value| DMYPipe }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="collumns.gender"
      [sortable]="true"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="true"
      prop="gender"
      name="{{'gender' | translate}}">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        <span *ngIf="value == 2">{{'female' | translate}}</span>
        <span *ngIf="value == 1">{{'male' | translate}}</span>
        <span *ngIf="value != 1 && value != 2">{{'genderUnknown' | translate}}</span>

      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="collumns.lastLogin"
      [sortable]="true"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="true"
      prop="lastLogin"
      name="{{'lastLogin' | translate}}">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        {{ value| DMYPipe }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="collumns.registrationDate"
      [sortable]="true"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="true"
      prop="createdAt"
      name="{{'registrationDate' | translate}}">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        {{ value| DMYPipe }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="collumns.device"
      [sortable]="false"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="true"
      prop="device"
      name="{{'device' | translate}}">
      [width]="65">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        <img
          [src]="getDeviceImage(row.device)"
          [ngClass]="{deviceImg: row.device !== 0}"/>
      </ng-template>
    </ngx-datatable-column>

  </ngx-datatable>
</div>
