import {Component, OnInit, ViewChild} from '@angular/core';
import {Admin, Invoice, InvoiceFilter, OrderList} from "../models";
import {MatInput} from "@angular/material/input";
import {MatSnackBar} from "@angular/material/snack-bar";

import {ApiService} from "../api.service";
import {DataService} from "../data-service.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {saveAs} from "file-saver";
import {environment} from "../../environments/environment";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {DatePipe} from "@angular/common";
import {Router} from "@angular/router";

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css']
})
export class InvoicesComponent implements OnInit {
  dataLoading = false;
  page = 0;
  noMoreData = false;
  BASE_API_URL = environment.url;
  messages = {emptyMessage: 'Nema rezultata', totalMessage: 'Trenutno'};
  statusFilter: Array<any> = [{name: 'Postojeći', value: 1}, {name: 'Storno', value: 2}, {name: 'Svi', value: null}];
  filter: InvoiceFilter = {
    id: null,
    orderId: null,
    isDeleted: 3,
    fromDate: null,
    toDate: null,
    fromOrderId: null,
    toOrderId: null,
    firstView: true
  };
  invoices = [];
  selectedInvoices: Invoice[] = [];

  date: Date = new Date();
  isLoading = false;
  country = "";
  message = '';
  count = 0;

  user: Admin;
  allowedEdit = false;


  @ViewChild('fromInput', {
    static: true,
    read: MatInput
  }) fromInput: MatInput;

  @ViewChild('toInput', {
    static: true,
    read: MatInput
  }) toInput: MatInput;

  @ViewChild('table', {static: true}) table: any;

  constructor(private  api: ApiService,
              private translate: TranslateService,
              private dataService: DataService,
              private snackBar: MatSnackBar,
              private http: HttpClient,
              private datePipe: DatePipe,
              private router: Router) {
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('adminYolo'));
    if (!this.checkPermission('invoices')) {
      console.log('USER', this.user)
      this.router.navigate(['/']);
    }

    this.dataService.country.subscribe((country) => {
      this.country = country;
      this.onReset('');
    });
  }

  checkPermission(page) {
    const permissions = this.user.permissions;
    const rightPermission = permissions.find((x) => x.page === page);
    if (rightPermission) {
      if (rightPermission.edit === 1) {
        this.allowedEdit = true;
      } else {
        this.allowedEdit = false;
      }
      if (rightPermission.view === 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  onSelectRow(row: any): void {
    if (row.isDeleted === false) {
      const date = new Date();
      const finalDate = this.datePipe.transform(date, 'MM.dd.yyyy');
      this.api.cancelBill(row.orderId).subscribe(x => {
        const findIndexOfCancelBill = this.invoices.findIndex(index => index.orderId === row.orderId)
        if (findIndexOfCancelBill !== -1) {
          this.invoices[findIndexOfCancelBill].isDeleted = true;
          this.invoices[findIndexOfCancelBill].deletedAt = finalDate;
          this.snackBar.open(this.translate.instant('cancelInfoText'), 'OK', {duration: 5000});
        }
      });
    } else if (row.isDeleted === true) {
      console.log('CREATE');
      this.api.createBill(row.orderId).subscribe(x => console.log('CREATE', x));
    }
  }

  getInvoices() {
    this.isLoading = true;
    this.dataLoading = true;
    this.api.getInvoicesNew(this.filter, 0).subscribe((invoicesRes) => {
      console.log('OBJEKAT', invoicesRes);
      this.isLoading = false;
      this.dataLoading = false;
      this.invoices = invoicesRes['data'];
      console.log(this.invoices);
      this.count = invoicesRes['count'];
      this.noMoreData = false;
    }, (err) => {
      this.isLoading = false;
      this.dataLoading = false;
      this.snackBar.open("Greška!", 'OK', {duration: 3000});
    });
  }

  getFilterStatusValue() {
    if (this.filter.isDeleted)
      return this.statusFilter[this.filter.isDeleted - 1].value;
    else
      return null;
  }

  getRowClass(row): any {
    if (row.isCancelled) {
      return 'failed';
    }
    if (row.isDeleted)
      return 'upload';
    else
      return 'proccesed';
  }

  onSearch($event) {
    this.filter.firstView = null;
    this.getInvoices();
  }

  onReset($event) {
    this.filter = {
      id: null,
      orderId: null,
      isDeleted: null,
      fromDate: null,
      toDate: null,
      fromOrderId: null,
      toOrderId: null,
      firstView: true
    };
    this.fromInput.value = '';
    this.toInput.value = '';
    this.getInvoices();
  }

  onRefresh($event) {
    this.getInvoices();
  }

  onSelect($event) {
    this.selectedInvoices = [];

    $event.selected.forEach((invoice: Invoice) => {
      if (!invoice.isDeleted)
        this.selectedInvoices.push(invoice);
    });
  }

  getRowId(row) {
    return row.id;
  }

  onBook() {
    if (this.allowedEdit) {
      this.api.printInvoicesBook(this.filter).subscribe(
        data => {
        }
      );
      const text = this.translate.instant('youWillReceiveNotificationWhenFileGenerated');
      this.snackBar.open(text, null, {
        duration: 2000
      });
    } else {
      this.snackBar.open(this.translate.instant('notAllowed'), 'OK', {duration: 2000});
    }
  }

  onBils() {
    let ids: number[] = [];
    this.invoices.forEach((invoice) => {
      ids.push(invoice.orderId);
    });

    let body = this.filter;

    if (this.allowedEdit) {
      this.downloadBilsNew(body);
    } else {
      this.snackBar.open(this.translate.instant('notAllowed'), 'OK', {duration: 2000});
    }
  }

  uploadReport(files: FileList) {
    if (this.allowedEdit) {
      const fileToUpload = files.item(0);
      const formData = new FormData();
      formData.append('excell', fileToUpload);
      this.api.uploadReport(formData).subscribe((data) => {
          this.snackBar.open(this.translate.instant('notificationWhenProcessed'), "OK", {duration: 2000});
        },
        err => {
          this.snackBar.open(this.translate.instant('thereWasAnError'), "OK", {duration: 2000});
        })
    } else {
      this.snackBar.open(this.translate.instant('notAllowed'), 'OK', {duration: 2000});
    }
  }

  onSummary(buttonTitle: string) {
    this.dataLoading = true;
    let ids: number[] = [];
    this.invoices.forEach((invoice) => {
      ids.push(invoice.orderId);
    });

    let body = this.filter;
    if (this.allowedEdit) {
      this.downloadSummaryNew(body, buttonTitle).subscribe((res) => {
        console.log('res', res)
        saveAs(res, 'summary.pdf');
        this.dataLoading = false;
      });
    } else {
      this.dataLoading = false;
      this.snackBar.open(this.translate.instant('notAllowed'), 'OK', {duration: 2000});
    }
  }

  downloadBils(body) {
    this.dataLoading = true;
    let url = this.BASE_API_URL + '/panel/generateBills';
    console.log(JSON.stringify(body));

    const currentUser = JSON.parse(localStorage.getItem('adminYolo'));
    const headers = new HttpHeaders({'Authorization': currentUser.apiKey, 'Country': this.country});
    return this.http.post(url, body, {headers: headers, responseType: 'blob'}).map(
      (res) => {
        return new Blob([res], {type: ' application/zip'});
        this.dataLoading = false;
      });
  }

  downloadBilsNew(body) {
    this.dataLoading = true;
    console.log(JSON.stringify(body));
    this.api.downloadInvoices(body).subscribe(
      (res) => {
        this.dataLoading = false;
        saveAs(res, 'racuni-' + new Date().toISOString() + '.zip');
      });
  }

  downloadSummary(body) {
    let url = this.BASE_API_URL + '/panel/invoice/summary';
    console.log(JSON.stringify(body));

    const currentUser = JSON.parse(localStorage.getItem('adminYolo'));
    const headers = new HttpHeaders({'Authorization': currentUser.apiKey, 'Country': this.country});
    return this.http.post(url, body, {headers: headers, responseType: 'blob'}).map(
      (res) => {
        return new Blob([res], {type: ' application/pdf'});
      });
  }

  downloadSummaryNew(body, typeButton: string) {
    let url = '';
    if (typeButton === 'recapitulation') {
      url = this.BASE_API_URL + '/panel/invoice/summaryNew';
    } else if (typeButton === 'summaryCash') {
      url = this.BASE_API_URL + '/panel/invoice/cash/summaryNew';
    } else if (typeButton === 'summaryCards') {
      url = this.BASE_API_URL + '/panel/invoice/card/summaryNew';
    }

    console.log(this.country);
    const currentUser = JSON.parse(localStorage.getItem('adminYolo'));
    const headers = new HttpHeaders({'Authorization': currentUser.apiKey, 'Country': this.country});
    return this.http.post(url, body, {headers: headers, responseType: 'blob'}).map(
      (res) => {
        return new Blob([res], {type: ' application/pdf'});
      });
  }

  loadNewOrders(addNew = 1) {
    if (addNew == 1) {
      this.page = this.page + 1;
    }
    this.isLoading = true;
    this.dataLoading = true;
    this.api.getInvoicesNew(this.filter, this.page).subscribe((ordersRes) => {
      this.isLoading = false;
      this.dataLoading = false;
      if (ordersRes['data'].length < 10) {
        this.noMoreData = true;
      }
      if (addNew == 1) {
        if (ordersRes['data'].length > 0) {
          this.invoices = [...this.invoices, ...ordersRes['data']];
        } else {
          this.noMoreData = true;
        }
      } else {
        for (let i = 0; i <= this.page; i++) {
          this.api.getInvoicesNew(this.filter, i).subscribe((ordersRes2) => {
            this.isLoading = false;
            this.dataLoading = false;
            if (ordersRes2['data'].length < 10) {
              this.noMoreData = true;
            }
            if (ordersRes2['data'].length > 0) {
              if (i == 0) {
                this.invoices = ordersRes2['data'];
              } else {
                this.invoices = [...this.invoices, ...ordersRes2['data']];
              }

            }
          });
        }
      }

    }, (err) => {
      this.isLoading = false;
      this.dataLoading = false;
      this.snackBar.open("Greška!", 'OK', {duration: 3000});
    });
  }


}
