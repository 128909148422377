import { Component, OnInit } from '@angular/core';
import {MessagingService} from "../shared/messaging.service";
import {
  fadeInOnEnterAnimation,
  fadeOutOnLeaveAnimation,
  slideInUpAnimation,
  slideInUpOnEnterAnimation,
  slideOutDownAnimation, slideOutDownOnLeaveAnimation
} from 'angular-animations';
import {DataService} from "../data-service.service";


@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
  data: object;
  constructor(private messagingService: MessagingService, private dataService: DataService) {
  }

  parser(data){
    return JSON.parse(data);
  }

  close(){
    this.dataService.close.next('close');
  }

  ngOnInit() {

   /* this.messagingService.currentMessage.subscribe(
      data => {
        this.data = data;
      });*/

  }

}
