import {Component, Inject, OnInit} from '@angular/core';
import {ApiService} from "../api.service";
import {MAT_DIALOG_DATA} from "@angular/material";
import {MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";import {DialogData} from "../supply-history/supply-history.component";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.css']
})

export class NotificationModalComponent implements OnInit {

  constructor(private dialog: MatDialogRef<NotificationModalComponent>, private apiService: ApiService, @Inject(MAT_DIALOG_DATA) public data: DialogData,  private SnackBar: MatSnackBar,private translate: TranslateService ) { }

  ngOnInit() {
  }


  close() {
    this.dialog.close();
  }

  confirm() {
    this.apiService.sendNotifications(this.data['users'], this.data['notif']['title'], this.data['notif']['body'],
      this.data['notif']['imageUrl'], this.data['notif']['imagePath'],
       this.data['filter']).subscribe(
      data => {
        data;
      }
    );
    this.dialog.close();
    this.SnackBar.open(this.translate.instant("notificationSent"), 'OK',{duration : 3000});

  }

}
