import {Component, Input, OnInit} from '@angular/core';
import {ApiService} from "../../../api.service";
import {TranslateService} from "@ngx-translate/core";
import {Observable} from "rxjs/Observable";

@Component({
  selector: 'app-albums-per-product',
  templateUrl: './albums-per-product.component.html',
  styleUrls: ['./albums-per-product.component.css']
})
export class AlbumsPerProductComponent implements OnInit {
  loading = false;
  data = [];
  labels = [];
  options = {
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          const label = data.labels[tooltipItem.index];

          return value + '% - ( ' + label + ' )';

        }
      }
    }
  };
  colors = [
    {backgroundColor: [
        "#2D99FF", "#FF7300", "#2CD9C5", "#826AF9", "#FF3399", "#00CC66", "#FFCC00", "#9933FF", "#FF9966", "#66CCCC",
        "#FF3333", "#66FF66", "#FF6600", "#3399FF", "#FFCC33", "#FF00FF", "#66FFCC", "#FF6666", "#0099CC", "#FF9933",
        "#CC33FF", "#33CC33", "#FF3366", "#66CCFF", "#FF9900", "#339966", "#FF0066", "#99CC33", "#FF6666", "#0066CC",
        "#4EABFF", "#FF9E47", "#3EE2DA", "#9275FF", "#FF55B3", "#24D279", "#FFD340", "#B266FF", "#FFAD7C", "#7FDFE6",
        "#FF6666", "#7EFF7E", "#FF9933", "#66AFFF", "#FFDD66", "#FF33FF", "#80FFA6", "#FF8080", "#33AFFF", "#FFA366",
        "#D366FF", "#80FF80", "#FF8080", "#80FFD3", "#FFA366", "#66FF80", "#FF8080", "#80C3FF", "#FF8080", "#80A3FF",
        "#6AABFF", "#FFB85E", "#5BF7E9", "#A284FF", "#FF77CC", "#42E993", "#FFDF40", "#C999FF", "#FFC08F", "#99E6FF",
        "#FF9999", "#99FF99", "#FFCC66", "#99CFFF", "#FFE066", "#FF66FF", "#99FFCC", "#FF9999", "#66CFFF", "#FFB366",
        "#E699FF", "#99FF99", "#FF9999", "#99FFDD", "#FFB366", "#66FF99", "#FF9999", "#99D5FF", "#FF9999", "#99BFFF"
      ]}
  ];
  @Input() refreshData: Observable<void>;

  constructor(private service: ApiService, private translate: TranslateService) { }

  ngOnInit() {
    this.getAlbumsPerProduct();

    this.refreshData.subscribe((data: any) => {
      if (data) {
        this.getAlbumsPerProduct();
      }
    });
  }

  getAlbumsPerProduct() {
    this.loading = true;
    this.service.getStatisticsAlbumsPerProduct().subscribe((apiData: any) => {
      this.labels = apiData.labels;
      this.data = [{data: apiData.data}];
      this.loading = false;
    })


  }


}
