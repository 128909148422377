<div style="width:900px;">
  <ngx-datatable
    #table
    class="material expandable" style="cursor: pointer;"
    [rows]="history"
    [columnMode]="'force'"
    [headerHeight]="50"
    [footerHeight]="75"
    [rowHeight]="'auto'"
    [scrollbarH]="true"
    [scrollbarV]="false">
    <ngx-datatable-column sortable="false" prop="id" [width]="30">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        <span>{{value}}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column sortable="false" prop="admin" name="Admin" [width]="30">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        <span>{{value}}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column sortable="false" prop="change" [width]="30" name="{{'change' | translate}}">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        <span> {{value}}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column sortable="false" prop="created_at" [width]="30" name="{{'date' | translate}}">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        <span> {{value}}</span>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>

  <hr>
  <div class="add-new">
    <mat-form-field style="margin-left: 20px">
      <input matInput type="number" placeholder="{{'addMore' | translate}}" [(ngModel)]="change"
             (keyup.enter)="toAddChange(data.id)" [disabled]="hide === true">
    </mat-form-field>
  </div>
</div>

