<div class="statistics-box-data" *ngIf="!loading">
  <canvas baseChart height="200"
          [datasets]="data"
          [labels]="labels"
          [legend]="false"
          [colors]="colors"
          [options]="options"
          [chartType]="'pie'">

  </canvas>
  <div class="statistic-box-legend">
    <div *ngFor="let l of labels; let i = index" class="flex-legend">
      <div class="legend-box" [ngStyle]="{backgroundColor: colors[0].backgroundColor[i]}"></div>
      <span style="text-transform: capitalize">{{l}}  </span>
    </div>
  </div>
</div>
<app-loading height="462px"  *ngIf="loading"></app-loading>
