<mat-card class="main" style="padding: 30px !important;">
  <h3 *ngIf="data['type'] == 'new'"> {{'addNewMachine' | translate}}</h3>
  <h3 *ngIf="data['type'] == 'edit'"> {{name}}</h3>

  <mat-form-field>
    <input matInput type="text" placeholder="{{'name' | translate}}" [(ngModel)]="machine.name">
  </mat-form-field> <br>
  <mat-form-field>
    <input matInput type="text" placeholder="{{'description' | translate}}" [(ngModel)]="machine.description">
  </mat-form-field> <br>
  <mat-form-field>
    <input matInput type="text" placeholder="{{'dateOfProduct' | translate}}" [(ngModel)]="machine.age">
  </mat-form-field> <br>
  <mat-select [(ngModel)]="machine.status">
    <mat-option [value]="1"> {{'inRepair' | translate}}</mat-option>
    <mat-option [value]="2"> {{'broken' | translate}}</mat-option>
    <mat-option [value]="0"> {{'good' | translate}}</mat-option>
  </mat-select> <br>

  <div style="margin-top: 20px; text-align: center">
    <button mat-button  *ngIf="data['type'] == 'new'" (click)="add()">{{'save' | translate}}</button>
    <button mat-button  *ngIf="data['type'] == 'edit'" (click)="edit()">{{'save' | translate}}</button>

  </div>

</mat-card>
