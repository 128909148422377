<div class="container">
  <div class="row">
   <div class="col-12">
       <form class="example-form">
         <mat-form-field class="example-full-width">
           <mat-label>{{"priority" | translate}}</mat-label>
           <input matInput [(ngModel)]="banner.priority" type="number" name="priority">
         </mat-form-field>
         <br>
         <input type="file" (change)="onSelectFile($event.target.files)" ><br>
         <img [src]="banner?.url" *ngIf="banner?.url" class="w-100" alt="iamge">
         <br><br> <button type="button" class="btn btn-outline-dark" (click)="saveBanner()">{{"save" | translate}}</button>
       </form>
   </div>
  </div>
</div>
