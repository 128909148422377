<div class="header desktop-only"
     style="background-color: #d92a73; z-index: 5 !important; position: relative; padding-bottom: 18px">
  <span
    style="font-size:1.7em; color:white; padding-left: 20px; font-weight: 500; margin-bottom:10px;"> {{'statistics' | translate}} </span>
</div>

<div class="main-sub-header">
  <div class="main-sub-header__button main-sub-header__button--statistics" *ngFor="let m of modes"
       [ngClass]="{'main-sub-header__button--selected': mode == m.id}"
       (click)="changeMode(m.id)">
    {{m.name | translate}}
  </div>
</div>

<app-all-time-statistics *ngIf="mode == 1"></app-all-time-statistics>
<app-customer-statistics *ngIf="mode == 2"></app-customer-statistics>
<app-sales-statistics *ngIf="mode == 3"></app-sales-statistics>
<app-customer-journey *ngIf="mode == 4"></app-customer-journey>
<app-campaign-statistics *ngIf="mode == 5"></app-campaign-statistics>
