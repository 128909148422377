import {Component, OnInit} from '@angular/core';
import {ApiService} from "../api.service";
import {Admin} from "../models";
import {ActivatedRoute, Route, Router} from "@angular/router";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {SupplyHistoryComponent} from "../supply-history/supply-history.component";
import {FullImageComponent} from "../full-image/full-image.component";
import {CoverHistoryComponent} from "../cover-history/cover-history.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {CoverAddNewComponent} from "../cover-add-new/cover-add-new.component";
import {Colors} from "../Colors"
import {Location} from '@angular/common';
import {CoverCategoryAddNewComponent} from "../cover-category-add-new/cover-category-add-new.component";


@Component({
  selector: 'app-covers',
  templateUrl: './covers.component.html',
  styleUrls: ['./covers.component.css']
})
export class CoversComponent implements OnInit {
  coverCategories = [];
  selectedCategory: any = 0;
  allowedEdit = false;
  user: Admin;
  addChange = [];
  change = [];
  totalCount = 0;
  coverCount = 0;
  covers = [];
  loadingSpecifics = false;
  forLoadingBoxes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21];
  colors = Colors;
  loading = true;
  categoryId: number;
  img: string | undefined;
  types = [];
  selectedType = null;
  date = new Date();
  fromD = new Date(this.date.getFullYear(), 0, 1).toISOString();
  toD = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0).toISOString();

  constructor(private apiService: ApiService,
              private router: Router,
              private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private translate: TranslateService,
              private route: ActivatedRoute,
              private location: Location) {
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('adminYolo'));
    if (!this.checkPermission('covers')) {
      this.router.navigate(['/']);
    }
    this.getCoversFirst();

  }

  onDateChanged($event) {
    this.fromD = new Date(this.fromD).toISOString();
    this.toD = new Date(this.toD).toISOString();
    if (this.selectedCategory) {
      this.getCovers(this.selectedCategory);
    } else {
      this.getCoversFirst();
    }
  }

  getCoversFirst() {
    this.apiService.getCoverTypes().subscribe(
      (data: any) => {
        this.types = data;
        const type = this.route.snapshot.paramMap.get('type');
        if (type) {
          const index = this.types.findIndex((t) => t['name'] == type);
          if (index != -1) {
            this.selectedType = this.types[index];
            this.getCoverCategories(true);
          }
        } else {
          this.loading = false;
        }
      }
    );
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  getCoverCategories(checkRoute = false) {
    this.apiService.getCoverCategories(this.selectedType['type'], this.fromD, this.toD).subscribe(
      (data: any) => {
        this.coverCategories = data;
        this.totalCount = 0;
        this.coverCategories.forEach(c => this.totalCount += c.lager);
        if (checkRoute) {
          const categoryId = this.route.snapshot.paramMap.get('category');
          if (!categoryId) {
            this.loading = false;
          }
          const index = this.coverCategories.findIndex(c => c.id == categoryId);
          if (index !== -1) {
            const category = this.coverCategories[index];
            this.selectedCategory = category;
            this.getCovers(category);
          }
        } else {
          this.loading = false;
        }
      }
    )
  }

  selectType(type) {
    this.selectedType = type
    this.location.replaceState("/main/covers/" + this.selectedType['name']);
    this.loading = true;
    this.getCoverCategories();
  }

  checkPermission(page) {
    const permissions = this.user.permissions;
    const rightPermission = permissions.find((x) => x.page === page);
    if (rightPermission) {
      if (rightPermission.edit === 1) {
        this.allowedEdit = true;
      } else {
        this.allowedEdit = false;
      }
      if (rightPermission.view === 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  toggleCover(id) {
    this.apiService.toggleCover(id).subscribe(
      data => console.log(data)
    );
  }

  toggleCoverCategory(id) {
    this.apiService.toggleCoverCategory(id).subscribe(
      data => console.log(data)
    );
  }

  getDropdownText() {
    if (this.selectedType) {
      return this.selectedType.name;
    } else {
      return this.translate.instant("selectProduct");
    }
  }

  openHistory(id) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'covers-info-dialog';
    dialogConfig.data = {id: id, hide: this.allowedEdit};
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    this.dialog.open(CoverHistoryComponent, dialogConfig);
  }

  toAddChange(id) {
    this.apiService.changeCoversCount(id, this.change[id]).subscribe(
      data => {
        function isRightId(element) {
          return element.id === id;
        }

        const index = this.covers.findIndex(isRightId);
        this.covers[index] = data;
        this.coverCount = 0;
        this.covers.forEach((h) => {
          this.coverCount = this.coverCount + +h.lager;
        })
        this.addChange[id] = false;
      }
    )
  }

  showFullImage(url) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'appointment-info-dialog';
    dialogConfig.data = {url: url};
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    this.dialog.open(FullImageComponent, dialogConfig);
  }

  selectCategory(category) {
    this.loading = true;
    this.categoryId = category.id;
    this.loadingSpecifics = true;
    this.selectedCategory = category;
    this.location.replaceState("/main/covers/" + this.selectedType['name'] + "/" + category.id);

    this.getCovers(category);
  }

  unselectCategory() {
    this.categoryId = 0;
    this.loadingSpecifics = true;
    this.selectedCategory = 0;
    this.location.replaceState("/main/covers/" + this.selectedType['name']);

  }

  getCovers(category) {
    this.loading = true;
    this.apiService.getCoverByCategory(category.id, this.fromD, this.toD).subscribe(
      data => {
        const c = [];
        c['c'] = data;
        this.covers = c['c'];
        this.coverCount = 0;
        this.covers.forEach((h) => {
          this.coverCount = this.coverCount + +h.lager;
        });
        this.loadingSpecifics = false;
        this.loading = false;


      }
    )
  }


  editCover(c) {
    if (this.allowedEdit) {
      const data = {
        categoryId: this.selectedCategory.id,
        categoryType: this.selectedCategory.type,
        cover: c,
        type: 2,
        categories: this.coverCategories
      }
      const addNewCover = this.dialog.open(CoverAddNewComponent, {
        position: {
          top: '30px',
        },
        data,
      });
      addNewCover.afterClosed().subscribe(response => {
        if (response) {
          this.getCovers(this.selectedCategory);

        }
      });
    } else {
      this.snackBar.open(this.translate.instant('notAllowed'), 'OK', {duration: 2000});
    }
  }

  openAddDialog() {
    if (this.allowedEdit) {
      const data = {
        categoryId: this.selectedCategory.id,
        categoryType: this.selectedCategory.type,
        type: 1
      }
      const addNewCover = this.dialog.open(CoverAddNewComponent, {
        position: {
          top: '30px',
        },
        data,
      });
      addNewCover.afterClosed().subscribe(response => {
        if (response) {
          this.getCovers(this.selectedCategory);
        }
      });
    } else {
      this.snackBar.open(this.translate.instant('notAllowed'), 'OK', {duration: 2000});
    }
  }

  openAddDialogCategory() {
    if (this.allowedEdit) {
      const data = {
        type: this.selectedType,
        types: this.types
      }
      const addNewCoverCategory = this.dialog.open(CoverCategoryAddNewComponent, {
        position: {
          top: '30px',
        },
        data,
      });
      addNewCoverCategory.afterClosed().subscribe(response => {
        if (response) {
          if (response.type === this.selectedType.type) {
            this.coverCategories.push(response);
            this.coverCategories = [...this.coverCategories];
          }
        }
      });
    } else {
      this.snackBar.open(this.translate.instant('notAllowed'), 'OK', {duration: 2000});
    }
  }
}
