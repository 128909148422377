import {Country} from "./models";

export const countries: Country[] = [
  {name: 'Srbija', value: 'rs'},
  {name: 'Hrvatska', value: 'hr'},
  {name: 'Bosna', value: 'ba'},
  {name: 'Romania', value: 'ro'},
  {name: 'Bulgaria', value: 'bg'},
  {name: 'Turkey', value: 'tr'},
  {name: 'Hungary', value: 'hu'},
  {name: 'EU', value: 'eu'},
  {name: 'Sve', value: 'all'}
];
