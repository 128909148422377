import {Component, OnDestroy, OnInit} from '@angular/core';
import {DatePipe} from "@angular/common";
import {DataService} from "../data-service.service";
import {ApiService} from "../api.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.css']
})
export class SurveyComponent implements OnInit, OnDestroy {

  pieChartLabel: string[] = ["", ""];
  pieChartData: number[] = [];
  albumPercentageOptions = {
    height: 300,
    legend: {
      position: 'top',
    }
  };

  dailyLoginsData: any[] = [];
  dailyLoginsLabels: any[] = [];

  date: any;
  fromDate: string | undefined;
  toDate: string | undefined;
  fromD: string | undefined;
  toD: string | undefined;
  chartReady: boolean;

  forAllDaysSubscription: Subscription;
  surveyDateSubscription: Subscription;

  constructor(private datePipe: DatePipe,
              private dataService: DataService,
              private apiService: ApiService) {
  }

  ngOnInit() {
    this.date = new Date();
    this.fromDate = new Date(this.date.getFullYear(), this.date.getMonth(), 1).toDateString();
    this.toDate = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0).toDateString();
    this.fromD = new Date(this.date.getFullYear(), this.date.getMonth(), 1).toISOString();
    this.toD = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0).toISOString();

    this.fromDate = this.datePipe.transform(this.fromD, 'yyyy-MM-dd');
    this.toDate = this.datePipe.transform(this.toD, 'yyyy-MM-dd');


    const date = {
      startDate: this.fromDate,
      endDate: this.toDate
    }
    this.apiMethodForSurveyChart(date);

    this.dataService.country.subscribe((country) => {
      const date = {
        startDate: this.fromDate,
        endDate: this.toDate
      }
      this.apiMethodForSurveyChart(date);
    });
  }

  onDateChanged(value: any, statusOfTime: string): void {
    if (value) {
      if (statusOfTime === 'start') {
        this.fromDate = value;
        this.fromDate = this.datePipe.transform(this.fromD, 'yyyy-MM-dd');
        const date = {
          startDate: this.fromDate,
          endDate: this.toDate
        }
        this.apiMethodForSurveyChart(date);
      } else if (statusOfTime === 'end') {
        this.toDate = value;
        this.toDate = this.datePipe.transform(this.toD, 'yyyy-MM-dd');
        const date = {
          startDate: this.fromDate,
          endDate: this.toDate,
        }
        this.apiMethodForSurveyChart(date);
      }
    }
  }

  apiMethodForSurveyChart(date): void {
    const dateForSend = date;
    if (dateForSend) {
      this.surveyDateSubscription = this.apiService.getSurvey(dateForSend).subscribe(dates => {
        console.log('Dates', dates);
        this.pieChartLabel = [];
        this.pieChartData = [];
        dates.data.map(val => this.pieChartData.push(val));
        setTimeout(() => {
          this.pieChartLabel = dates.label.map(val => val)
        });
      })

      this.forAllDaysSubscription = this.apiService.getSurveyForAllDays(dateForSend).subscribe(survey => {
        this.chartReady = true;
        this.dailyLoginsData = [];
        this.dailyLoginsLabels = [];
        setTimeout(() => {
          this.dailyLoginsLabels = survey[1];
        })
        survey[0].forEach(dataArray => {
          const label = dataArray[1];
          const data = dataArray[0];
          this.dailyLoginsData.push({data, label});
        });
      });
    }
  }

  ngOnDestroy(): void {
    this.surveyDateSubscription.unsubscribe();
    this.forAllDaysSubscription.unsubscribe();
  }
}
