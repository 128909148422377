import {Component, Input, OnInit} from '@angular/core';
import {Observable} from "rxjs/Observable";
import {ApiService} from "../../../api.service";
import {TranslateService} from "@ngx-translate/core";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-notifications-per-status',
  templateUrl: './notifications-per-status.component.html',
  styleUrls: ['./notifications-per-status.component.css']
})
export class NotificationsPerStatusComponent implements OnInit {
  @Input() start;
  @Input() end;
  @Input() datesChanged: Observable<void>;
  @Input() refreshData: Observable<void>;

  loading = false;
  labels = [];
  data = [{}];
  options = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          suggestedMin: 0,
        },
      }],
    },
  };
  numbers = [];
  colors = [
    {backgroundColor: ["#2CD9C5", "#E7BC4B"]}
  ];
  total = 0;

  constructor(private service: ApiService, public translate: TranslateService, private datePipe: DatePipe) {
  }

  ngOnInit() {
    this.getNotificationsPerStatus();
    this.datesChanged.subscribe((data: any) => {
      this.start = data.start;
      this.end = data.end;
      this.getNotificationsPerStatus();
    });
  }

  getNotificationsPerStatus() {
    this.loading = true;
    const start = this.datePipe.transform(this.start, 'yyyy-MM-dd');
    const end = this.datePipe.transform(this.end, 'yyyy-MM-dd');
    this.service.getNotificationsPerStatus(start, end).subscribe((apiData: any) => {
      this.data = [{data: apiData.numbers.map(n => Number(n)), barPercentage: 0.2}];
      console.log(this.data);
      this.labels = apiData.labels.map(l => this.translate.instant(l));
      console.log(this.labels);
      this.total = apiData.total;
      this.numbers = apiData.data;
      this.loading = false;
    })
  }


}
