import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {DataService} from "../data-service.service";
import {ApiService} from "../api.service";
import {TranslateService} from "@ngx-translate/core";
import {Admin} from "../models";

@Component({
  selector: 'app-sticky-header',
  templateUrl: './sticky-header.component.html',
  styleUrls: ['./sticky-header.component.css']
})
export class StickyHeaderComponent implements OnInit {
  private address: any;
  country = "rs";
  unreadNotifications = 0;
   selectedLanguage: any;
user: Admin;
allChecked = false;
reports = [];
  allCoutnries = this.translate.instant('allCountries');

  constructor(private router: Router, private dataService: DataService, private service: ApiService, private translate: TranslateService) {
    JSON.parse(localStorage.getItem('adminYolo')).locale !== null ? this.selectedLanguage = JSON.parse(localStorage.getItem('adminYolo')).locale : this.selectedLanguage = 'en';
    JSON.parse(localStorage.getItem('adminYolo')).locale !== null ? translate.use(JSON.parse(localStorage.getItem('adminYolo')).locale) : translate.use('en');

    const index = this.contries.findIndex((x) => x.value == 'all');
    if (this.contries[index]) {
      this.contries[index].name = this.allCoutnries;
    }
  }
  contries: Array<any> = [{name: this.allCoutnries, value: 'all'}, {name: 'Srbija', value: 'rs'}, {name: 'Bosna', value: 'ba'}, {name: 'Hrvatska', value: 'hr'}, {name: 'Rumunija', value: 'ro'}, {name: 'EU', value: 'eu'}, {name: 'Turska', value: 'tr'}, {name: 'Bugarska', value: 'bg'}];
  ngOnInit() {
    this.dataService.allChecked.subscribe((data) => {
      this.allChecked = data;
    });
    this.user = JSON.parse(localStorage.getItem('adminYolo'));
    if (localStorage.getItem('country')) {
      this.country = localStorage.getItem('country');
    }
    if (this.user) {
      this.dataService.changeLanguage(this.user.locale);
      if (!this.user.fullView) {
        localStorage.setItem('country', this.user.country);
        this.country = this.user.country;
        this.dataService.changeCountry(this.user.country);

        console.log(this.user);
      }
    }

    this.service.getUnreadNotifications().subscribe(
      data => {
        const n = [];
        n['unread'] = data;
        this.unreadNotifications = n['unread'];
      }
    );
  }


  onNavClick(address: string) {
    this.address = address;
    this.router.navigate([this.address]);
  }

  onClosed() {
    if (this.address) {
      this.router.navigate([this.address]);
    }
  }

  onOpened(){
    this.service.readNotifications().subscribe(
      data => {this.unreadNotifications = 0;}
    );
  }

  onCountrySelected(event) {
    this.country = event;
    console.log(this.country);
    localStorage.setItem('country', event);
    this.dataService.changeCountry(event);
  }

  onLanguageSelected(locale){
    this.dataService.changeLanguage(locale);
    localStorage.setItem('locale', locale);
    this.selectedLanguage = locale;
    this.translate.use(locale);
  }

  openNavBar(data) {
    this.service.readNotifications().subscribe(
      data2 => {this.unreadNotifications = 0;}
    );
    this.dataService.openNavbar(data);
  }
}
