import { Component, OnInit } from '@angular/core';
import {ApiService} from "../../api.service";
import {TranslateService} from "@ngx-translate/core";
import {DataService} from "../../data-service.service";
import {Subject} from "rxjs/Subject";

@Component({
  selector: 'app-all-time-statistics',
  templateUrl: './all-time-statistics.component.html',
  styleUrls: ['./all-time-statistics.component.css']
})
export class AllTimeStatisticsComponent implements OnInit {
  generalStatistics = null;
  generalLoading = false;
  refreshData: Subject<any> = new Subject<any>();


  constructor(private service: ApiService, private translate: TranslateService, private dataService: DataService) {
  }

  ngOnInit() {
    this.getGeneralStatistics();
    this.dataService.country.subscribe((country) => {
      if (country) {
        this.refresh();
      }
    });
  }

  refresh() {
    this.refreshData.next(true);
    this.getGeneralStatistics();
  }


  getGeneralStatistics() {
    console.log("started loading");
    this.generalLoading = true;
    this.service.getGeneralStatisticsAllTime().subscribe((apiData: any) => {
      console.log("got data   ", apiData)
      this.generalStatistics = apiData;
      this.generalLoading = false;
    }, (err) => {
      console.log("got err ", err);
    })
  }
}
