import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material";
import {MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {ApiService} from "../api.service";
import {LoginComponent} from "../login/login.component";
import {DataService} from "../data-service.service";
import {DialogData} from "../vacation/vacation.component";

@Component({
  selector: 'app-merge-orders',
  templateUrl: './merge-orders.component.html',
  styleUrls: ['./merge-orders.component.css']
})
export class MergeOrdersComponent implements OnInit {
  ordersArray = '';
  price = '';
  promo = '';

  // tslint:disable-next-line:max-line-length
  constructor(private dataService: DataService,
              private dialogRef: MatDialogRef<MergeOrdersComponent>,
              private apiService: ApiService, private snackBar: MatSnackBar,
              private translate: TranslateService, @Inject(MAT_DIALOG_DATA)
              public data: DialogData) {
  }

  ngOnInit() {
    this.ordersArray = this.data['array'];
    if (localStorage.getItem('mergePrice')) {
      this.price = localStorage.getItem('mergePrice');
    }
    if (localStorage.getItem('mergePromo')) {
      this.promo = localStorage.getItem('mergePromo');
    }
  }

  merge() {
    if (this.price == '' || this.ordersArray == '') {
      this.snackBar.open(this.translate.instant('pleaseInsertOrdersAndPrice'), 'OK', {duration: 3000});
      return;
    }

    localStorage.setItem('mergePrice', this.price);
    localStorage.setItem('mergePromo', this.promo);

    let promoCorrection = this.promo;
    if (promoCorrection == '') {
      promoCorrection = null;
    }

    let arrayOfOrders = this.ordersArray.split(',');
    arrayOfOrders = arrayOfOrders.sort((n1, n2) => +n2 - +n1);
    const dataObject = [];


    this.apiService.mergeOrdersNew(arrayOfOrders, promoCorrection, this.price).subscribe(
      data => {
        this.dialogRef.close();
        dataObject['first'] = data;
        const specificOrders = arrayOfOrders;
        specificOrders.splice(0, 1);
        dataObject['others'] = specificOrders;
        this.dataService.mergedOrders.next(dataObject);
        this.snackBar.open(this.translate.instant('merged'), 'OK', {duration: 3000});

      },
      err => {
        this.snackBar.open(this.translate.instant('thereWasAnError'), 'OK', {duration: 3000});
      }
    );

  }

}
