<div class="upselling-modal" [ngClass]="{'upselling-modal-delete': data.mode == 'delete'}">
  <img class="upselling-modal-close" (click)="dialogRef.close(false)" src="assets/ic-close-dark.svg">
  <div class="upselling-modal-title" *ngIf="data.mode == 'edit'">{{'editAction' | translate}}</div>
  <div class="upselling-modal-title" *ngIf="data.mode == 'duplicate'">{{'duplicateAction' | translate}}</div>
  <div class="upselling-modal-title" *ngIf="data.mode == 'delete'">{{'deleteAction' | translate}}</div>

  <app-upselling-form [products]="data.products" (sendUpsellingAction)="dialogRef.close($event)" [upsellingActionFromParent]="data.upsellingAction" *ngIf="data.mode =='edit' || data.mode == 'duplicate'"></app-upselling-form>

  <div class="upselling-modal-text" *ngIf="data.mode == 'delete'">
    {{'areYouSureYouWantToDelete' | translate}} <b>{{data.upsellingAction.name}}</b>?
  </div>
  <div class="upselling-modal-actions" *ngIf="data.mode == 'delete'">
      <app-button text="{{'delete' | translate}}" [textColor]="colors.WHITE" [color]="colors.PINK" width="205px" height="48px" (click)="dialogRef.close(true)" ></app-button>
  </div>

</div>
