<div class="refund-main">
  <h3>{{'refundInvoiceForOrder' | translate: translateParams}}</h3>

  <div class="refund-text">
      <mat-label>{{'pleaseSelectRefundType' | translate}}</mat-label>
      <br>
      <br>
      <br>

        <mat-form-field>
          <mat-select [(ngModel)]="selectedRefundType">
            <mat-option *ngFor="let type of refundTypes"
                        [value]="type.id">{{type.name | translate}}</mat-option>
          </mat-select>
        </mat-form-field>

  </div>

  <mat-dialog-actions>
    <button mat-raised-button color="warn" (click)="this.dialogRef.close(selectedRefundType)" >{{"refundInvoice" | translate}}</button>

  </mat-dialog-actions>
</div>
