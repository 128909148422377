import {AfterViewInit, Component, Inject, OnInit, Renderer2} from '@angular/core';
import {countries} from "../../../Countries";
import {MAT_DIALOG_DATA} from "@angular/material";
import {MatDialogRef} from "@angular/material/dialog";
import {Colors} from "../../../Colors";

class DialogData {
  issueOptions: any = [];
}

@Component({
  selector: 'app-new-issue',
  templateUrl: './new-issue.component.html',
  styleUrls: ['./new-issue.component.css']
})
export class NewIssueComponent implements OnInit {
    issue: any = {};
    colors = Colors;

  ngOnInit() {
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, public dialogRef: MatDialogRef<NewIssueComponent>) {
  }

  changedInitiationDate(value) {
    this.issue.initiation_date = value;
  }

  changedResolutionDate(value) {
    this.issue.resolution_date = value;
  }

  protected readonly countries = countries;
  protected readonly Colors = Colors;
}
