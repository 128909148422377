<div class="header desktop-only" style="background-color: #d92a73; z-index: 5 !important; position: relative; padding-bottom: 18px"
     [ngClass]="{'hiddenHeader' : !showHeader}">
  <span
          style="font-size:1.7em; color:white; padding-left: 20px; font-weight: 500; margin-bottom:10px;"> {{'surveys' | translate}} </span>
</div>
<div class="campaigns-container">
    <input class="campaign-search" [(ngModel)]="searchText" placeholder="{{'searchSurvey' | translate}}" (ngModelChange)="getSurveys()">
    <div class="table-container">
        <ngx-datatable
            #table
            class="material users-orders-list"
            [rows]="surveys"
            [columnMode]="'force'"
            [headerHeight]="50"
            [footerHeight]="75"
            [rowHeight]="'auto'"
            [scrollbarH]="true"
            [scrollbarV]="false"
            *ngIf="surveys.length > 0"
        >
            <ngx-datatable-column [name]="getTranslation('name') | titlecase" sortable="false" prop="name" width="100">
                <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                    {{value}}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [name]="getTranslation('start') | titlecase" sortable="false" prop="start" width="100">
                <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                    {{value | date : 'dd.MM.yyyy'}}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [name]="getTranslation('end') | titlecase" sortable="false" prop="end" width="100">
                <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                    {{value | date : 'dd.MM.yyyy'}}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [name]="getTranslation('completed') | titlecase" sortable="false" prop="name" width="100">
                <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                    {{row.answered}} / {{row.user_count}}
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [name]="getTranslation('status') | titlecase" sortable="false" prop="status" width="100">
                <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                    <span *ngIf="value == 0" style="color:#F0CB15">{{'waiting' | translate}}</span>
                    <span *ngIf="value == 1" style="color:#2AC090">{{'inProgress' | translate}}</span>
                    <span *ngIf="value == 2" style="color:#FF9D56">{{'done' | translate}}</span>

                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column  sortable="false" width="100">
                <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                    <img class="campaigns-more-button" src="assets/ic_more2.svg"  [matMenuTriggerFor]="surveyOptions">
                    <mat-menu class="campaigns-menu" #surveyOptions="matMenu" yPosition="below" style="padding-top: 0 !important;padding-bottom: 0px !important;">
                        <div class="campaigns-option" (click)="openPopup(row,1)">{{'info' | translate}}</div>
                        <div class="campaigns-option" (click)="openPopup(row,2)">{{'duplicate' | translate}}</div>
                        <div class="campaigns-option" (click)="openPopup(row,3)"  *ngIf="row.status == 0">{{'updateCampaign' | translate}}</div>
                        <div class="campaigns-option" (click)="deleteSurvey(row)" *ngIf="row.status == 0">{{'delete' | translate}}</div>
                    </mat-menu>
                </ng-template>
            </ngx-datatable-column>
          <ngx-datatable-column  sortable="false" width="0.2">
            <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
              <app-button text="{{'show' | translate}}" (click)="showSurvey(row)"
                          [color]="colors.WHITE" [textColor]="colors.ULTRA_PINK" fontSize="14px" height="30px" width="103px"></app-button>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
    </div>

</div>
