import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material";
import {MatDialogRef} from "@angular/material/dialog";
import {ApiService} from "../api.service";
import {MatDialog} from "@angular/material/dialog";

export interface DialogData {
  images: any[];
}


@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})


export class GalleryComponent implements OnInit {

  constructor (
    public dialogRef: MatDialogRef<GalleryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private apiService: ApiService, private dialog: MatDialog
  ) {

  }

  ngOnInit() {
  }

}
