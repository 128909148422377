<div class="header" style="background-color: #d92a73; padding-left: 20px;" *ngIf="{'hiddenHeader' : !showHeader}">
  <span style="font-size:1.7em; color:white; font-weight: 500; "> {{user.fullName}} / {{user.position}} </span>
</div>

<div class="menu" [ngClass]="{'hiddenHeader' : !showHeader}"
     style="background-color: #d92a73; color:white; width: 100% !important; font-size:18px; font-weight: 500; padding-top: 15px;">
  <div class="drawer" style="overflow: auto; white-space: nowrap; padding-bottom: 10px; ">
    <span class="menuItem" [ngClass]="{'active' : currentPage === 'profile'}" (click)="toTop('info')"
          style="padding-left: 10px; margin-right: 14px; cursor: pointer;"> {{'myProfile' | translate}} </span>
  </div>
</div>

<div class="stickyMenu" *ngIf="!showHeader" style="background-color: #d92a73; color:white; font-size:18px;
 font-weight: 500; padding-top: 15px; position: fixed; z-index: 3; width: 100%; top:0; display:inline;">
  <app-sticky-header class="stickyHeader" style="right: 0px; position: fixed; top: 8px;"></app-sticky-header>
  <div class="drawer" style="overflow: auto; white-space: nowrap; padding-bottom: 10px;">
    <span class="menuItem" id="profilenav" [ngClass]="{'active' : currentPage === 'profile'}" (click)="toTop('info')"
          style="padding-left: 10px; margin-right: 14px; cursor: pointer;"> {{'myProfile' | translate}} </span>
  </div>
</div>

<div class="cont" style="background-color: #eceff1; height: 100%; padding-left:5vw; padding-right:5vw;"
     [ngClass]="{'higherMargin' : !showHeader}">
  <div class="row">
    <div class="col-md-8 " style="margin-top: 3vh;" id="info" snInViewport
         (inViewportChange)="onInViewportChange('profile',$event)">
      <span style="color:#747474; font-size:18px;"> {{'myProfile' | translate }}</span>
      <mat-card class="info"
                style="padding: 10px;background-color: white;  margin-top: 2vh; border-radius: 30px; min-height: 432px;">
        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-4 offset-md-4" style="margin-top: 5vh;">
                <img src="assets/avatar.svg" *ngIf="!user.image"
                     style="border-radius: 50%; width: 150px; height: 150px; object-fit: cover; position: relative; margin-left: auto; margin-right: auto; display: block;">
                <img [src]="user.image" *ngIf="user.image"
                     style="border-radius: 50%; width: 150px; height: 150px; object-fit: cover; margin-left: auto; margin-right: auto; display: block;">
                <div style="width: 100%; text-align: center;">
                  <div
                    style="width: 150px; height: 4px; background-color: #dfe0df; margin-top: 3vh; margin-bottom: 2vh;  margin-left: auto; margin-right: auto; display: block;">
                  </div>
                  <div
                    style="text-align: center; margin: auto; left: 0; right: 0; position: relative; width: 150px; color:black; font-size: 18px; font-weight: bold;">{{user.fullName}}</div>
                </div>
                <br>
                <div
                  style="text-align: center; margin: auto; left: 0; right: 0; position: relative; width: 150px; color: #606060; font-size: 16px; font-weight: bold;">
                  {{user.sector}} <br>
                  {{user.position}}
                </div>
                <br>
                <div
                  style="width: 150px; height: 4px; background-color: #dfe0df; margin-bottom: 2vh;  margin-left: auto; margin-right: auto; display: block;">
                </div>

              </div>
            </div>
          </div>
          <div class="col-md-6" style="line-height: 40px;">
            <div style="margin-top: 5vh; color: #606060;   font-size: 16px; font-weight: bold;">
              <img src="assets/ic-sms.svg"> {{user.dateOfBirth | date }} <br>
            </div>

            <div style="margin-top: 10vh; color: #606060;   font-size: 16px; font-weight: bold;">
              <img src="assets/ic-sms.svg"> {{user.email }} <br>
              <img src="assets/ic-local-phone.svg">  {{user.phone }} <br>
              <img src="assets/ic-place.svg"> {{user.address }} <br>
            </div>
          </div>
        </div>
        <mat-icon style="position: absolute; right: 20px; bottom: 20px; cursor: pointer" (click)="updateEmployee()">
          edit
        </mat-icon>
      </mat-card>
    </div>
    <div class="col-md-4 " id="sector" style="margin-top: 3vh;">
      <span style="color:#747474; font-size:18px;"> {{'sector' | translate }}</span>
      <mat-card class="sector"
                style="background-color: #d92a73; margin-top: 2vh; border-radius: 30px; min-height: 432px;">
        <div
          style="position: absolute; left: 50%; top: 50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%);">
          <img src="assets/logo-main-white.png"
               style=" margin-left: auto; margin-right: auto; display: block; margin-bottom: 3vh;">
          <span
            style=" text-align: center;  margin-top: 5vh; font-size: xx-large; font-weight: 600; color:white;">{{user.sector | uppercase}}</span>
        </div>

      </mat-card>
    </div>
  </div>
</div>
