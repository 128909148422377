import {Component, Inject, OnInit} from '@angular/core';
import {ApiService} from "../api.service";
import {MAT_DIALOG_DATA} from "@angular/material";
import {MatDialogRef} from "@angular/material/dialog";
import {MatDialogConfig} from "@angular/material/dialog";
import {MatDialog} from "@angular/material/dialog";

import {DialogData} from "../supply-history/supply-history.component";
import {MachineInfoComponent} from "../machine-info/machine-info.component";
import {DataService} from "../data-service.service";
import { DatePipe } from '@angular/common';
import {MachineDeleteComponent} from "../machine-delete/machine-delete.component";

@Component({
  selector: 'app-machine-repair',
  templateUrl: './machine-repair.component.html',
  styleUrls: ['./machine-repair.component.css']
})
export class MachineRepairComponent implements OnInit {
  repairs = [];
  machine = [];
  editable = false;
  repair = {service: '', date: '', machineId: 0, description: ''};
  constructor(public datepipe: DatePipe, private dataService: DataService, private dialog: MatDialog, private apiService: ApiService, @Inject(MAT_DIALOG_DATA) public data: DialogData, private dialogRef: MatDialogRef<MachineRepairComponent>) { }

  ngOnInit() {
    this.dataService.removedRepair.subscribe(
      data => {
        if (data !== null){
          const index = this.repairs.findIndex((x) => x.id == data['id']);
          this.repairs.splice(index, 1);
          this.repairs = [...this.repairs];
        }
      }
    );
    this.repair.machineId = this.data.id;
    this.apiService.getMachineRepair(this.data.id).subscribe(
      data => {
        const c = [];
        c['c'] = data;
        this.repairs = c['c'];
      }
    );
    this.apiService.getMachineDetails(this.data.id).subscribe(
      data => {
        const c = [];
        c['c'] = data;
        this.machine = c['c'];
        console.log(this.machine);
      }
    );
  }

  edit(id) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'machines-info-dialog';
    dialogConfig.data = {type: 'edit', machine: this.machine};
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    this.dialog.open(MachineInfoComponent, dialogConfig);
    this.dialogRef.close();
  }

  delete(data) {
  this.apiService.deleteMachine(data.id).subscribe(
    data2 => {
      this.dialogRef.close();
      this.dataService.newRefresh('hardRefresh');
    }
  )
  }

  save() {
    this.repair.date = new Date(this.repair.date).toLocaleDateString();
  this.apiService.addRepair(this.repair).subscribe(
    data2 => {
      this.editable = false;
      this.apiService.getMachineRepair(this.data.id).subscribe(
        data => {
          const c = [];
          c['c'] = data;
          this.repairs = c['c'];
        }
      );
    }
  )
  }

  open(event) {
    if (event.type == 'click'){
      console.log(event);
      const dialogConfig = new MatDialogConfig();
      dialogConfig.panelClass = 'machines-info-dialog';
      dialogConfig.data = {data: event.row};
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      this.dialog.open(MachineDeleteComponent, dialogConfig);
    }

  }

}
