<mat-card class="col-md-8 offset-md-2 col-12">
  <div class="container">
    <div class="row">
      <div class="col">
        <mat-form-field style="width: 100%">
          <input type="text" matInput placeholder="OD narudžbine" #from (keyup.enter)="to.focus()"/>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field style="width: 100%">
          <input type="text" matInput placeholder="DO narudžbine" #to (keyup.enter)="onPrintAddress(from.value, to.value)"/>
        </mat-form-field>
      </div>
      <div class="col-2" style="height: 100%">
        <button mat-raised-button class="align-middle" color="primary" style="width: 100%" (click)="onPrintAddress(from.value, to.value)">Pošalji</button>
      </div>
    </div>

  </div>


</mat-card>
