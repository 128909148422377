import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-error-dialog',
  template: `
    <h1>
      Error of type {{data.status}}
    </h1>
    <p>
      {{ data.statusText }}
    </p>
  `,
  styles: [`

  `]
})
export class ErrorDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any){}
  ngOnInit(){}
}
