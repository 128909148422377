<div class="statistics-box-data" *ngIf="!loading">
  <div class="statistics-total">
    {{total}}
  </div>
  <canvas baseChart height="100"
          [datasets]="data"
          [labels]="labels"
          [legend]="false"
          [colors]="colors"
          [options]="barChartOptions"
          [chartType]="'horizontalBar'">

  </canvas>
</div>
<app-loading height="462px"  *ngIf="loading"></app-loading>
