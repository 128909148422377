import {Component, ElementRef, OnChanges, OnInit, Renderer2, ViewChild} from '@angular/core';
import {Admin, RESTUserModel} from '../models';
import {DataService} from '../data-service.service';
import {ApiService} from '../api.service';
import {MatDialog} from '@angular/material/dialog';
import {MatDialogConfig} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Params} from '@angular/router';
import {Router} from '@angular/router';
import {setCorespondingProperties} from '../utils';
import {SupplyHistoryComponent} from "../supply-history/supply-history.component";
import {NotificationModalComponent} from "../notification-modal/notification-modal.component";
import {TranslateService} from "@ngx-translate/core";
import {EmailModalComponent} from "../email-modal/email-modal.component";
import {SendNotificationComponent} from "../send-notification/send-notification.component";
import {saveAs} from "file-saver";
import {Colors} from "../Colors";
import {FormGroup} from "@angular/forms";
import {RatingCommentPopUpComponent} from "../rating-comment-pop-up/rating-comment-pop-up.component";
import {Options, LabelType} from '@angular-slider/ngx-slider';
import {MatMenuTrigger} from '@angular/material/menu';
import {ConfirmModalComponent} from "../confirm-modal/confirm-modal.component";


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css'],
  providers: [ApiService]
})
export class UsersComponent implements OnInit {
  @ViewChild('filterOpener', {static: false}) filterOptions: ElementRef | undefined;

  rows: RESTUserModel[];
  country: string;
  error = false;
  mobileFilters = [
    {name: 'filter', opened: false},
    {name: 'status', opened: false},
    {name: 'orders', opened: false},
    {name: 'profile', opened: false},
    {name: 'satisfaction', opened: false},
    {name: 'surveys', opened: false},
  ];
  selectedFiltersOpened = false;
  filtersFullscreen = false;
  options: Options = {
    floor: 0,
    ceil: 100,

    getPointerColor: () => {
      return '#5C5C5C';
    },
    getTickColor: () => {
      return '#D8D8D8'
    },
    getSelectionBarColor: () => {
      return '#5C5C5C'
    },
    pushRange : false,
    draggableRange: false
  };
  dateOfBirthOptions: Options = {
    floor: 1920,
    ceil: this.getActualYear(),
    getPointerColor: () => {
      return '#5C5C5C';
    },
    getTickColor: () => {
      return '#D8D8D8'
    },
    getSelectionBarColor: () => {
      return '#5C5C5C'
    },
  };
  pageNumber = 0;
  moreThanCount = 0;
  lessThanCount = 0;
  moreThanAvg = 0;
  lessThanAvg = 0;
  morePromo = 0;
  lessPromo = 0;
  showHeader = true;
  totalPages = 0;
  pageSize = 0; // number of elements/r0ws in the page
  totalQueryResults = 0;
  colors = Colors;
  collumns = {
    image: true,
    id: true,
    username: true,
    fullName: true,
    phone: true,
    email: true,
    appVersion: true,
    satisfaction: true,
    satisfactionReason: true,
    ordersCount: true,
    lastOrder: true,
    lastLogin: true,
    registrationDate: true,
    device: true,
    gender: true,
    birthday: true,
    averageSpent: true,
    totalSpent: true,
    promoPercentage: true,
    productGroups: true,
    selectedUsersIds: true,
    selectedAudienceIds: true,
    numberOfPhotosOrderedFrom: true,
    numberOfPhotosOrderedTo: true
  };
  selectedUsers = [];
  loading = false;
  notificationBody = '';
  notificationTitle = '';
  sliderMin = 0;
  sliderMax = 50;
  messages = {emptyMessage: 'Nema rezultata', totalMessage: 'Ukupno'}
  filter: any = {
    satisfactionOptions: [],
    active: false,
    inactive: false,
    loyal: false,
    ordersCount: [this.sliderMin, this.sliderMax],
    averageSpent: [this.sliderMin, this.sliderMax],
    totalSpent: [this.sliderMin, this.sliderMax],
    promoPercentage: [this.sliderMin, this.sliderMax],
    dateOfBirth: [1720, this.getActualYear()],
    productGroups: [],
    selectedUsersIds: [],
    selectedAudienceIds: []
  };
  filterMenuOption = 0;
  newFilterName = '';
  satisfactionOptions: any;
  sort: any = {};
  filterOptionsDropdown = [
    {label: 'ID', value: 'id'},
    {label: 'Datum Registrovanja', value: 'regDate'},
    {label: 'Datum Logovanja', value: 'logDate'},
    {label: 'Uređaj i Verzija', value: 'device'},
    {label: 'Ostalo', value: 'isDeleted'},
    {label: 'Drzava', value: 'country'}
  ];
  showFilterOptions = {
    id: false,
    regDate: false,
    logDate: false,
    device: false,
    isDeleted: false,
    country: false
  };
  dropdownVals: string[];
  // Table logic schema
  devices = [
    {name: 'nepoznato', value: '0'},
    {name: 'iOs', value: '1'},
    {name: 'android', value: '2'},
    {name: 'web', value: '3'}
  ];
  countries = [
    {name: 'Srbija', value: 'rs'},
    {name: 'Hrvatska', value: 'hr'},
    {name: 'Bosna', value: 'ba'},
    {name: 'Romania', value: 'ro'},
    {name: 'Turkey', value: 'tr'},
    {name: 'Sve', value: ''}
  ];
  isDeleted = [
    {msg: 'Sve', value: "0"},
    {msg: 'Obrisane', value: "2"},
    {msg: 'Neobrisane', value: "1"}
  ];
  ratingOptions: any[] = [];
  ratingAdditionalOptions: any[] = [];
  savedFilters: any = [];
  savedFilter: any;
  newAudienceName = '';
  audiences: any;
  selectedAudiences = [];
  surveys = [];
  productGroups = [];
  selectedUsersIds= [];
  selectedAudienceIds = [];
  constructor(private renderer: Renderer2, private dataService: DataService, private service: ApiService, private dialog: MatDialog,
              private router: Router, private SnackBar: MatSnackBar, public translate: TranslateService, private route: ActivatedRoute,
              private snackBar: MatSnackBar) {
  }

  user: Admin;
  allowedEdit = false;

  ngOnInit() {
    if (localStorage.getItem('userCollumns')) {
      this.collumns = JSON.parse(localStorage.getItem('userCollumns'));
    }

    this.dataService.country.subscribe((country) => {
      this.filter.country = country;
      this.country = country;
      this._resetFilter();
      this.getRatings();
      this.getFilterFromParams();
      this._getUsers();
      this.getSurveys();
      this.getProductGroups();
    });

    window.addEventListener('scroll', this.scroll, true);
    this.user = JSON.parse(localStorage.getItem('adminYolo'));
    if (!this.checkPermission('users')) {
      this.router.navigate(['/']);
    }
    this.checkPermission('users');
    this.getFilterFromParams();
    // If there are no cachedUsers it will fetch them.
    // If there are cached Users it will set properties, and then empty the cache.
    window.cachedUsers ? setCorespondingProperties.call(this, window.cachedUsers) || delete window.cachedUsers : this._getUsers();
    console.log('country   ' + this.country);
  }

  openMobileFilter() {
    this.filtersFullscreen = true;
    this.renderer.addClass(document.body, 'mobile-fullscreen-on');
  }

  closeMobileFilter() {
    this.filtersFullscreen = false;
    this.renderer.removeClass(document.body, 'mobile-fullscreen-on');
  }

  openSelectedFilters() {
    this.selectedFiltersOpened = true;
    this.renderer.addClass(document.body, 'full-overlay-on');
    setTimeout(() => {
      this.renderer.addClass(document.getElementById('bottom-sheet'), 'showable-bottom-sheet');
    }, 100);
  }

  closeSelectedFilters() {
    this.renderer.removeClass(document.getElementById('bottom-sheet'), 'showable-bottom-sheet');
    this.renderer.removeClass(document.body, 'full-overlay-on');
    setTimeout(() => {
      this.selectedFiltersOpened = false;
    }, 100);
  }

  getActualYear() {
    return new Date().getFullYear();
  }

  getSurveys() {
    this.service.getSurveys("*").subscribe((data: any[]) => {
      this.surveys = data;
    }, (err) => {
    })
  }

  getProductGroups() {
    this.service.getProductGroups().subscribe((data: any[]) => {
      this.productGroups = data;
    }, (err) => {
    })
  }

  getSatisfaction(row) {
    const value = row.rating;
    const rating = this.ratingOptions.find(o => o.id == value);
    if (rating) {
      return rating.name;
    } else {
      return "/";
    }
  }

  openMessage(row) {
    const rating = this.getSatisfaction(row);
    const reason = this.getSatisfactionReason(row);
    const message = row.ratingComment;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'no-info-dialog';
    dialogConfig.data = {rating, reason, message};
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    this.dialog.open(RatingCommentPopUpComponent, dialogConfig);
  }

  getSatisfactionReason(row) {
    let reason = "/";
    if (row.additionalRating) {
      const values = JSON.parse(row.additionalRating);
      values.forEach(v => {
        const rating = this.ratingAdditionalOptions.find(o => o.id == v);
        if (rating) {
          reason = rating.name;
        }
      });
    }

    return reason;
  }

  getFilterFromParams() {
    const filter = this.route.snapshot.queryParamMap.get('filter');
    //console.log("filter is   ", filter);
    if (filter)
      try {
        //console.log("filter   ", this.filter);
        this.filter = JSON.parse(filter);
      } catch {
        //console.log("params filter err");
      }
  }

  setFilterParams() {
    const queryParams: Params = {filter: JSON.stringify(this.filter)};

    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      }
    );
  }

  saveFilter() {
    this.service.saveFilter({name: this.newFilterName, filter: this.filter}).subscribe((filter) => {
      this.newFilterName = '';
      if (this.filterOptions) {
        this.filterOptions.nativeElement.click();
      }
      this.savedFilter = filter;
      this.snackBar.open(this.translate.instant('saved'), '', {duration: 2000});
    }, (err) => {
      this.newFilterName = '';
      if (this.filterOptions) {
        this.filterOptions.nativeElement.click();
      }
      this.snackBar.open(this.translate.instant('thereWasAnError'), '', {duration: 2000});
    });
  }

  saveAudience() {
    this.service.saveAudience({name: this.newAudienceName, filter: this.filter, user_ids: this.selectedUsers.map(u => u.id)}).subscribe((audience) => {
      this.newAudienceName = '';

      if (this.filterOptions)
      {
        this.filterOptions.nativeElement.click();
      }

      this.snackBar.open(this.translate.instant('saved'), '', {duration: 2000});

      }, (err) => {
      this.newAudienceName = '';
      if (this.filterOptions) {
        this.filterOptions.nativeElement.click();
      }
      this.snackBar.open(this.translate.instant('thereWasAnError'), '', {duration: 2000});
    });
  }

  selectFilter(event, filter) {
    if (!event.target.className.includes("filter-option-trash")) {
      this.filter = JSON.parse(filter.filter);
      //console.log("filter:  ", this.filter);
      this._getUsers(0, true);
      this.savedFilter = filter;
    }
  }

  deleteFilter(filter) {
    const openDeleteDialog = this.dialog.open(ConfirmModalComponent, {
      disableClose: true,
      width: '400px',
      height: '200px',
      position: {
        top: '150px',
      },
      data: {
        data: '',
        message: this.translate.instant('deleteFilter'),
        mode: 'other',
      },
    });

    openDeleteDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.service.deleteFilter(filter.id).subscribe(() => {
          this.snackBar.open(this.translate.instant('deleted'), '', {duration: 2000});

          const index = this.savedFilters.findIndex(f => f.id == filter.id);
          if (index != -1) {
            this.savedFilters.splice(index, 1);
          }
        })
      }
    });
  }

  deleteAudience(audience) {
    const openDeleteDialog = this.dialog.open(ConfirmModalComponent, {
      disableClose: true,
      width: '400px',
      height: '200px',
      position: {
        top: '150px',
      },
      data: {
        data: '',
        message: this.translate.instant('deleteAudience'),
        mode: 'other',
      },
    });

    openDeleteDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.service.deleteAudience(audience.id).subscribe(() => {
          this.snackBar.open(this.translate.instant('deleted'), '', {duration: 2000});

          const index = this.audiences.findIndex(f => f.id == audience.id);
          if (index != -1) {
            this.audiences.splice(index, 1);
          }
        })
      }
    });
  }


  getFilters() {
    this.service.getFilters().subscribe((filters) => {
      this.savedFilters = filters;
    })
  }

  getAudiences() {
    this.service.getAudiences().subscribe((audiences) => {
      this.audiences = audiences;
    })
  }

  showAll() {
    this.loading = true;
    this.service.getUsersAll({
      page: this.pageNumber,
      sort: this.sort,
      filter: this.filter
    }).subscribe(response => {
      const r = [];
      r['response'] = response;
      const {results, total} = r['response'];
      this.totalQueryResults = total;
      this.rows = results;
      this.pageSize = results.length;
      this.totalPages = total > results.length && Math.ceil(total / results.length) || 0;
      this.loading = false;
    }, err => {
      this.SnackBar.open(this.translate.instant('thereWasAnError'), 'OK', {duration: 3000});
      this.loading = false;
    });
  }

  _getUsers(page = null, setParams = false) {
    if (setParams) {
      this.setFilterParams();
    }
    if (page !== null) {
      this.pageNumber = page;
    }
    this.loading = true;
    this.filtersFullscreen = false;

    this.filter.country = localStorage.getItem('country');

    this.service.getUsers({
      page: this.pageNumber,
      sort: this.sort,
      filter: this.filter
    }).subscribe(response => {
      const r = [];
      r['response'] = response;
      const {results, total} = r['response'];
      this.totalQueryResults = total;
      this.rows = results;
      this.pageSize = results.length;
      this.totalPages = total > results.length && Math.ceil(total / results.length) || 0;
      this.loading = false;
    }, err => {
      this.SnackBar.open(this.translate.instant('thereWasAnError'), 'OK', {duration: 3000});
      this.loading = false;
    });
  }

  getRatings() {
    this.service.getRatingOptions().subscribe((data: any) => {
      this.ratingOptions = data.ratingTypes.reverse();
      this.ratingAdditionalOptions = data.additionalTypes;
    })
  }

  sendToAll() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'no-info-dialog';
    dialogConfig.data = {total: this.totalQueryResults, filter: this.filter};
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    this.dialog.open(SendNotificationComponent, dialogConfig);
  }


  onScrollDown() {

    this.pageNumber++;
    this.filter.country = localStorage.getItem('country');

    this.service.getUsers({
      page: this.pageNumber,
      sort: this.sort,
      filter: this.filter
    }).subscribe(response => {
      const r = [];

      r['response'] = response;
      const {results, total} = r['response'];
      this.totalQueryResults = total;
      this.rows = [...this.rows, ...results];
      this.pageSize = results.length;
      this.totalPages = total > results.length && Math.ceil(total / results.length) || 0;
      this.loading = false;
    }, err => {
      this.SnackBar.open(this.translate.instant('thereWasAnError'), 'OK', {duration: 3000});
      this.loading = false;
    });
  }


  getAllusers() {
    //console.log(this.filter);
    this.loading = true;
    this.service.getAllUsers({
      page: this.pageNumber,
      sort: this.sort,
      filter: this.filter
    }).subscribe(response => {
      const r = [];
      r['response'] = response;
      const {results, total} = r['response'];
      this.totalQueryResults = total;
      this.rows = results;
      this.pageSize = results.length;
      this.totalPages = total > results.length && Math.ceil(total / results.length) || 0;
      this.loading = false;
      //console.log(this.rows[0], this.totalQueryResults);
    }, err => {
      this.SnackBar.open(this.translate.instant('thereWasAnError'), 'OK', {duration: 3000});
      this.loading = false;
    });
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  imageUrl(country) {
    return "https://flagpedia.net/data/flags/small/" + country + ".png";
  }


  getCurrencyBasedOnCountry(countryFromRow = "") {
    let country = "";
    // @ts-ignore
    if (this.country == 'all') {
      country = countryFromRow;
    } else {
      country = this.country;
    }

    if (country == "rs")
      return "RSD";
    else if (country == "ba")
      return "KM";
    else if (country == "hr")
      return "HRK";
    else if (country == "ro")
      return "RON";
    else
      return "";
  }

  getDeviceImage(device) {
    // console.log(device, typeof device);
    switch (device) {
      case 1:
        return '/assets/ios.png';
      case 2:
        return '/assets/android.png';
      case 3:
        return '/assets/web.png';
      default:
        return '';
    }
  }

  _resetFilter() {
    this.filter = {
      active: false,
      inactive: false,
      loyal: false,
      ordersCount: [this.sliderMin, this.sliderMax],
      averageSpent: [this.sliderMin, this.sliderMax],
      totalSpent: [this.sliderMin, this.sliderMax],
      promoPercentage: [this.sliderMin, this.sliderMax],
      dateOfBirth: [1720, this.getActualYear()],
      productGroups: [],
      selectedUsersIds: []
    };
    this.savedFilter = null;
    this.pageNumber = 0;
    this.sort = {};
    this.dropdownVals = [];
    this.showFilterOptions = {
      id: false,
      country: false,
      regDate: false,
      logDate: false,
      device: false,
      isDeleted: false,
    };
  }

  setPage(page) {
    const {offset} = page;
    if (offset === this.pageNumber)
      return;
    this.pageNumber = offset;
    this._getUsers();
  }

  saveCollumns() {
    localStorage.setItem('userCollumns', JSON.stringify(this.collumns));
  }


  onSort(event) {
    const {dir, prop} = event.sorts[0];
    //if sorting didnt change return
    if (dir === this.sort.dir && prop === this.sort.prop)
      return;
    this.sort = {
      dir,
      prop
    }
    this.loading = true;
    //console.log(this.sort);
    this._getUsers();
  }

  onActivate(event) {
    if (event.type == "click" && event.cellIndex !== 0 && event.cellIndex !== 5) {
      const id = event.row.id;
      window.cachedUsers = {
        totalPages: this.totalPages,
        pageSize: this.pageSize,
        rows: this.rows,
        totalQueryResults: this.totalQueryResults
      }
      this.router.navigate([`main`, 'users', id, 'summary']);
    }
  }

  // test() {
  //   console.log('test!!');
  // }

  scroll = (): void => {
    if (window.pageYOffset > 120) {
      this.showHeader = false;
    } else {
      this.showHeader = true;
    }
  }

  checkPermission(page) {
    const permissions = this.user.permissions;
    const rightPermission = permissions.find((x) => x.page === page);
    if (rightPermission) {
      if (rightPermission.edit === 1) {
        this.allowedEdit = true;
      } else {
        this.allowedEdit = false;
      }
      if (rightPermission.view === 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  getBeforeDash(value) {
    return value[0];
  }

  getAfterDash(value) {
    return value[1];

  }

  openModalForNotifications() {
    if (this.notificationTitle.trim() == '' || this.notificationBody.trim() == '') {
      this.SnackBar.open(this.translate.instant("completeAllFields"), 'OK', {duration: 3000});
      return;
    }
    const ids = [];
    this.selectedUsers.forEach(x => ids.push(x.id));
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'no-info-dialog';
    dialogConfig.data = {
      users: ids,
      message: this.notificationTitle,
      notif: {title: this.notificationTitle, body: this.notificationBody},
      filter: this.filter
    };
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    this.dialog.open(NotificationModalComponent, dialogConfig);
  }

  openModalForEmail() {
    if (this.notificationTitle.trim() == '' || this.notificationBody.trim() == '') {
      this.SnackBar.open(this.translate.instant("completeAllFields"), 'OK', {duration: 3000});
      return;
    }
    const ids = [];
    this.selectedUsers.forEach(x => ids.push(x.id));
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'no-info-dialog';
    dialogConfig.data = {
      users: ids,
      message: this.notificationTitle,
      notif: {title: this.notificationTitle, body: this.notificationBody}
    };
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    this.dialog.open(EmailModalComponent, dialogConfig);
  }

  onSelect({selected}) {
    this.selectedUsers.splice(0, this.selectedUsers.length);
    this.selectedUsers.push(...selected);
  }

  exportUsers() {
    this.service.exportUsers({
      page: this.pageNumber,
      sort: this.sort,
      filter: this.filter
    }).subscribe(
      (res) => {
          this.snackBar.open(this.translate.instant('userExport'), 'OK');
        }, (err) => {
          this.SnackBar.open(this.translate.instant('thereWasAnError'), "OK", {duration: 3000});
      }
    )
  }
  createSurvey()
  {

    if (this.savedFilter)
    {
      localStorage.setItem('user-saved-filter', JSON.stringify(this.savedFilter));
    }
    else
    {
      localStorage.removeItem('user-saved-filter');
    }

    if(this.selectedUsers.length > 0)
    {
      const usersIds = [];
      this.selectedUsers.forEach(x => usersIds.push(x.id));
      this.filter.selectedUsersIds = usersIds;
      localStorage.setItem('total-users', String(this.selectedUsers.length));
    }
    else
    {
      localStorage.setItem('total-users', String(this.totalQueryResults));
    }

    localStorage.setItem('user-filter', JSON.stringify(this.filter));

    this.router.navigate(['main/survey']);
  }

  campaignList() {
    this.router.navigate(['main/campaigns']);
  }

  getAdditionalText(text: string) {
    return text.replace("Dodatni tip", "");
  }

  changeFilterSurvey(event, id) {
    const checked = event.checked;

    if (!this.filter.surveys) {
      this.filter.surveys = [];
    }
    const index = this.filter.surveys.findIndex(s => s == id);
    //console.log("checked", checked, " index", index, "id", id);

    if (checked) {
      if (index == -1) {
        this.filter.surveys.push(id);
      }
    } else {
      if (index !== -1) {
        this.filter.surveys.splice(index, 1);
      }
    }
    //console.log(this.filter.surveys);
  }



  changeSatisfactionOption(event, rId, aId = null) {
    const checked = event.checked;
    let value: string = rId;
    if (aId) {
      value = rId + "---" + aId;
    }

    if (!this.filter.satisfactionOptions) {
      this.filter.satisfactionOptions = [];
    }
    if (checked) {
      this.filter.satisfactionOptions.push(value);
    } else {
      const index = this.filter.satisfactionOptions.findIndex((o) => o === value);
      if (index !== -1) {
        this.filter.satisfactionOptions.splice(index, 1);
      }

      if (value.toString().includes("---") === false) {
        const firstElement = value.toString().split("---")[0];

        const firstElementIndex = this.filter.satisfactionOptions.findIndex((o) => {
          return o.toString().split("---")[0] == value;
        });
        if (firstElementIndex !== -1) {
          this.filter.satisfactionOptions.splice(firstElementIndex, 1);
        }

      }
    }
    //console.log(this.filter);
  }

  optionDisabled(id) {
    if (!this.filter.satisfactionOptions) {
      return true;
    }
    const index = this.filter.satisfactionOptions.findIndex((o) => o === id);
    return index === -1;

  }

  isSurveyChecked(id) {
    if (!this.filter.surveys) {
      return false;
    }
    const index = this.filter.surveys.findIndex((o) => o === id);
    return index !== -1;

  }

  isAudienceChecked(audience) {
    const index = this.selectedAudiences.findIndex(a => a.id == audience.id);
    return index != -1;
  }

  toggleAudience(audience) {
    const index = this.selectedAudiences.findIndex(a => a.id == audience.id);

    if (index == -1)
    {
      this.selectedAudiences.push(audience);
    }
    else
    {
      this.selectedAudiences.splice(index, 1);
    }

    const audienceIdsFilter = this.selectedAudiences.map(a => {
      return a.id
    });

    this.filterAudience(audienceIdsFilter);

  }

  filterAudience(audience)
  {
    this.filter.selectedAudienceIds = audience;
  }

  createCampaign() {
    if (this.selectedAudiences.length == 0) {
      //console.log('test')
      return;
    }
    const audienceIds = this.selectedAudiences.map(a => {
      return a.id
    });
    const audienceIdsString = audienceIds.join(",");
    this.router.navigate(['main/campaign/', audienceIdsString]);

  }

  findChildren(id) {
    let names = "";
    this.filter.satisfactionOptions.forEach((o) => {
      if (o.toString().split("---")[0] == id) {
        const newId = o.toString().split("---")[1];
        const additional = this.ratingAdditionalOptions.find(o2 => o2.id == newId);
        if (additional) {
          if (names.length > 0) {
            names = names + ", " + this.getAdditionalText(additional.name);
          } else {
            names = names + this.getAdditionalText(additional.name);
          }
        }
      }
    })

    if (names != "") {
      names = " | " + names;
    }

    return names;
  }

  changeFilterProductGroup(event, id)
  {
    const checked = event.checked;

    if (!this.filter.productGroups)
    {
      this.filter.productGroups = [];
    }

    const index = this.filter.productGroups.findIndex(s => s == id);

    if (checked)
    {
      if (index == -1)
      {
        this.filter.productGroups.push(id);
      }
    }
    else
    {
      if (index !== -1)
      {
        this.filter.productGroups.splice(index, 1);
      }
    }

    //console.log("Filter: " + JSON.stringify(this.filter))
  }

  isProductGroupChecked(id)
  {
    if (!this.filter.productGroups) {
      return false;
    }
    const index = this.filter.productGroups.findIndex((o) => o === id);

    return index !== -1;
  }

  getProductGroupsNames() {
    return this.productGroups.filter(p => {
      return this.filter.productGroups.includes(p.id);
    }).map(p => p.name).join(", ");
  }

  getAudienceNames() {
    return this.audiences.filter(a => {
      return this.filter.selectedAudienceIds.includes(a.id);
    }).map(a => a.name).join(", ");
  }

  getSurveyNames() {
    return this.surveys.filter(s => {
      return this.filter.surveys.includes(s.id);
    }).map(s => s.name).join(",");
  }

  toggleMobileFilter(index) {
    const newStatus = !this.mobileFilters[index].opened;
    // first reset all filters
    this.mobileFilters = [
      {name: 'filter', opened: false},
      {name: 'status', opened: false},
      {name: 'orders', opened: false},
      {name: 'profile', opened: false},
      {name: 'satisfaction', opened: false},
      {name: 'surveys', opened: false},
    ];
    this.mobileFilters[index].opened = newStatus;
  }

  protected readonly close = close;
}


declare global {
  interface Window {
    cachedUsers: any;
  }
}
