<div>
  <h3 mat-dialog-title>
    Praćenje pošiljke
  </h3>
  <mat-dialog-content>
    <div style="width: 800px; padding: 16px">
      <ngx-datatable
        class="material striped"
        [rows]="trackings"
        [headerHeight]="50"
        [footerHeight]="75"
        [rowHeight]="50"
        [scrollbarH]="true"
        [loadingIndicator]="isLoading"
        [scrollbarV]="false"
        [limit]="100"
        [messages]="messages">


        <ngx-datatable-column name="Datum" sortable="false" prop="date" [width]="180">
          <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
            <span>{{value}}</span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Status" sortable="false" prop="status"  [width]="100">
          <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
            <span>{{value}}</span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Opis" sortable="false" prop="details"  [width]="475">
          <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
            <span>{{value}}</span>
          </ng-template>
        </ngx-datatable-column>

      </ngx-datatable>

    </div>
  </mat-dialog-content>
</div>
