import {Component, OnInit, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ApiService} from "../api.service";
import {TranslateService} from "@ngx-translate/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-temporali-price-modal',
  templateUrl: './temporal-price-modal.component.html',
  styleUrls: ['./temporal-price-modal.component.css']
})
export class TemporalPriceModalComponent implements OnInit {

  products: any;
  names: string[] = [];

  temporalGroup: FormGroup = this.fb.group({
    name: [''],
    price: [''],
    currency: [''],
    country: [''],
    info: [''],
    startDate: [''],
    endDate: [''],
  })

  countriesAndCurrency: Array<{ country: string, currency: string, code: string }> = [{
    country: 'Srbija', currency: 'RSD', code: 'rs'
  },
    {country: 'Bosna', currency: 'BAM', code: 'ba'},
    {country: 'Hrvatska', currency: 'HRK', code: 'hr'}, {country: 'Rumunija', currency: 'RON', code: 'ro'}, {
      country: 'Turska',
      currency: 'TRY', code: 'tr'
    }, {country: 'Bugarska', currency: 'BGN', code: 'bg'},
    {country: 'Slovakia', currency: 'EUR', code: 'sk'}, {country: 'Austria', currency: 'EUR', code: 'at'}, {country: 'Slovenia', currency: 'EUR', code: 'si'},
    {country: 'Germany', currency: 'EUR', code: 'de'}, {country: 'Belgium', currency: 'EUR', code: 'be'}, {country: 'Netherlands', currency: 'EUR', code: 'nl'},
    {country: 'Denmark', currency: 'EUR', code: 'dk'}, {country: 'Hungary', currency: 'EUR', code: 'hu'}, {country: 'Poland', currency: 'EUR', code: 'pl'},
    {country: 'Liechtenstein', currency: 'EUR', code: 'li'}, {country: 'Luxembourg', currency: 'EUR', code: 'lu'}, {country: 'Lithuania', currency: 'EUR', code: 'lt'},
    {country: 'San Marino', currency: 'EUR', code: 'sm'}, {country: 'Portugal', currency: 'EUR', code: 'pt'}, {country: 'Greece', currency: 'EUR', code: 'gr'},
    {country: 'Italy', currency: 'EUR', code: 'it'}, {country: 'Monaco', currency: 'EUR', code: 'mc'}, {country: 'Latvia', currency: 'EUR', code: 'lv'},
    {country: 'France', currency: 'EUR', code: 'fr'}, {country: 'Estonia', currency: 'EUR', code: 'ee'}, {country: 'Sweden', currency: 'EUR', code: 'se'}
  ];

  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<TemporalPriceModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private apiService: ApiService,
              public translate: TranslateService,
              private fb: FormBuilder,
              private datePipe: DatePipe,
              private snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.apiService.getTemporalPriceProducts().subscribe(data => {
      this.products = data;
      this.products.forEach(name => {
        this.names.push(name.name);
      })
    })

    console.log('MODAL', this.data.data);
    const start = this.data.data.startDate;
    const end = this.data.data.endDate;
    // const startDate = this.datePipe.transform(start, 'MM-dd-yyyy');
    // const endDate = this.datePipe.transform(end, 'MM-dd-yyyy');

    this.temporalGroup.patchValue({
      startDate: new Date(this.data.data.startDate),
      endDate: new Date(this.data.data.endDate),
      country: this.data.data.country,
      currency: this.data.data.currency,
      name: this.data.data.name,
      price: this.data.data.price,
      info: this.data.data.infoText,
      id: this.data.data.id
    })
  }

  closeDialog(data: any) {
    this.dialogRef.close(data);
  }

  save() {
    let productId = 0;

    const productIndex = this.products.findIndex(p => p.name == this.temporalGroup.controls.name.value);

    if (productIndex != -1) {
      productId = this.products[productIndex].id;
    }

    let country = 'rs';
    const countryIndex = this.countriesAndCurrency.findIndex(c => c.country == this.temporalGroup.controls.country.value);

    if (countryIndex != -1) {
      country = this.countriesAndCurrency[countryIndex].code;
    }

    const price = {
      id: this.data.data.id,
      country,
      currency: this.temporalGroup.controls.currency.value,
      info: this.temporalGroup.controls.info.value,
      name: this.temporalGroup.controls.name.value,
      price: this.temporalGroup.controls.price.value,
      startDate: this.temporalGroup.controls.startDate.value,
      endDate: this.temporalGroup.controls.endDate.value,
      productId
    }

    this.apiService.updateTemporalPrice(price).subscribe(
      (data) => {
        this.snackBar.open(this.translate.instant("saved"), "OK", {duration: 3000});
        this.dialogRef.close(data);
      }
    );

  }
}
