import {Component, Input, OnInit} from '@angular/core';
import {ApiService} from "../../../api.service";
import {TranslateService} from "@ngx-translate/core";
import {Observable} from "rxjs/Observable";

@Component({
  selector: 'app-users-per-device',
  templateUrl: './users-per-device.component.html',
  styleUrls: ['./users-per-device.component.css']
})
export class UsersPerDeviceComponent implements OnInit {
  loading = false;
  data = [];
  labels = [];
  options = {
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          const label = data.labels[tooltipItem.index];

          return value + '% - ( ' + label + ' )';

        }
      }
    }
  }
  @Input() refreshData: Observable<void>;

  constructor(private service: ApiService, private translate: TranslateService) { }

  ngOnInit() {
    this.getAlbumsPerDevice();

    this.refreshData.subscribe((data: any) => {
      if (data) {
        this.getAlbumsPerDevice();
      }
    });
  }

  getAlbumsPerDevice() {
    this.loading = true;
    this.service.getStatisticsUserPerDevice().subscribe((apiData: any) => {
      this.labels = apiData.labels;
      this.data = [{data: apiData.data}];
      this.loading = false;
    })


  }


}
