import {Component, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {TemporalPriceModalComponent} from "../temporali-price-modal/temporal-price-modal.component";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "../api.service";
import {ConfirmModalComponent} from "../confirm-modal/confirm-modal.component";
import {Router} from "@angular/router";
import {User} from "../models";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-temporal-price',
  templateUrl: './temporal-price.component.html',
  styleUrls: ['./temporal-price.component.css']
})
export class TemporalPriceComponent implements OnInit {
  selectedPrice: any[] = [];
  count = 0;
  products: any;
  names: string[] = [];
  user: any;
  allowedEdit = false;

  temporallyPriceForm: FormGroup = this.fb.group({
    name: ['', [Validators.required]],
    price: ['', [Validators.required]],
    currency: ['', [Validators.required]],
    country: ['', [Validators.required]],
    info: [''],
    startDate: ['', [Validators.required]],
    endDate: ['', [Validators.required]],
  });

  countriesAndCurrency: Array<{ country: string, currency: string, code: string }> = [{
    country: 'Srbija', currency: 'RSD', code: 'rs'
  },
    {country: 'Bosna', currency: 'BAM', code: 'ba'},
    {country: 'Hrvatska', currency: 'HRK', code: 'hr'}, {country: 'Rumunija', currency: 'RON', code: 'ro'}, {
      country: 'Turska',
      currency: 'TRY', code: 'tr'
    }, {country: 'Bugarska', currency: 'BGN', code: 'bg'},
    {country: 'Slovakia', currency: 'EUR', code: 'sk'}, {
      country: 'Austria',
      currency: 'EUR',
      code: 'at'
    }, {country: 'Slovenia', currency: 'EUR', code: 'si'},
    {country: 'Germany', currency: 'EUR', code: 'de'}, {
      country: 'Belgium',
      currency: 'EUR',
      code: 'be'
    }, {country: 'Netherlands', currency: 'EUR', code: 'nl'},
    {country: 'Denmark', currency: 'EUR', code: 'dk'}, {
      country: 'Hungary',
      currency: 'EUR',
      code: 'hu'
    }, {country: 'Poland', currency: 'EUR', code: 'pl'},
    {country: 'Liechtenstein', currency: 'EUR', code: 'li'}, {
      country: 'Luxembourg',
      currency: 'EUR',
      code: 'lu'
    }, {country: 'Lithuania', currency: 'EUR', code: 'lt'},
    {country: 'San Marino', currency: 'EUR', code: 'sm'}, {
      country: 'Portugal',
      currency: 'EUR',
      code: 'pt'
    }, {country: 'Greece', currency: 'EUR', code: 'gr'},
    {country: 'Italy', currency: 'EUR', code: 'it'}, {
      country: 'Monaco',
      currency: 'EUR',
      code: 'mc'
    }, {country: 'Latvia', currency: 'EUR', code: 'lv'},
    {country: 'France', currency: 'EUR', code: 'fr'}, {
      country: 'Estonia',
      currency: 'EUR',
      code: 'ee'
    }, {country: 'Sweden', currency: 'EUR', code: 'se'}
  ];

  data: any = [];
  page = 0;

  isExpanded: any;

  constructor(private dialog: MatDialog,
              public translate: TranslateService,
              private fb: FormBuilder,
              private apiService: ApiService,
              private router: Router,
              private snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('adminYolo'));
    this.count = this.data.length;
    this.apiService.getTemporalPriceProducts().subscribe(data => {
      this.products = data;
      this.products.forEach(name => {
        this.names.push(name.name);
      })
    });
    if (!this.checkPermission('TemporalPrice')) {
      this.router.navigate(['/']);
    }
    this.apiService.getTemporalPrices(this.page).subscribe(prices => {
      this.data = prices;
    });
  }


  expand() {
    this.isExpanded = !this.isExpanded;
    if (this.isExpanded === false) {
      this.temporallyPriceForm.reset();
    }
  }

  checkPermission(page) {
    const permissions = this.user.permissions;
    const rightPermission = permissions.find((x) => x.page === page);
    if (rightPermission) {
      if (rightPermission.edit === 1) {
        this.allowedEdit = true;
      } else {
        this.allowedEdit = false;
      }
      if (rightPermission.view === 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  onUserEvent(e) {
    if (e.type === "click") {
      if (this.allowedEdit) {
        const openTemporaryModal = this.dialog.open(TemporalPriceModalComponent, {
          disableClose: true,
          width: '400px',
          height: 'auto',
          position: {
            top: '100px',
          },
          data: {
            data: e.row,
            message: this.translate.instant('deleteInfoText'),
            mode: 'delete',
          },
        });

        openTemporaryModal.afterClosed().subscribe((result) => {
          if (result) {

            const index = this.data.findIndex(p => p.id == result.id);
            if (index != -1) {
              this.data[index] = result;
              this.data = [...this.data];
            }
          }
        });
      } else {
        this.snackBar.open(this.translate.instant('notAllowed'), 'OK', {duration: 2000});
      }
    }
  }

  createTemporallyPrice(): void {
    if (this.allowedEdit) {
      let productId = 0;

      const productIndex = this.products.findIndex(p => p.name == this.temporallyPriceForm.controls.name.value);

      if (productIndex != -1) {
        productId = this.products[productIndex].id;
      }

      let country = 'rs';
      const countryIndex = this.countriesAndCurrency.findIndex(c => c.country == this.temporallyPriceForm.controls.country.value);

      if (countryIndex != -1) {
        country = this.countriesAndCurrency[countryIndex].code;
      }

      const change = {
        country,
        currency: this.temporallyPriceForm.controls.currency.value,
        info: this.temporallyPriceForm.controls.info.value,
        name: this.temporallyPriceForm.controls.name.value,
        price: this.temporallyPriceForm.controls.price.value,
        startDate: this.temporallyPriceForm.controls.startDate.value,
        endDate: this.temporallyPriceForm.controls.endDate.value,
        productId
      }

      const openTemporallyPrice = this.dialog.open(ConfirmModalComponent, {
        disableClose: true,
        width: '400px',
        height: '200px',
        position: {
          top: '150px',
        },
        data: {
          data: change,
          message: this.translate.instant('temporallyInfoText'),
          mode: 'temporally-price',
        },
      });
      openTemporallyPrice.afterClosed().subscribe((result) => {
        if (result) {
          this.apiService.createTemporalPrice(change).subscribe(
            (data) => {
              this.data.unshift(data);
              this.data = [...this.data];
              this.closePanel();
            }
          );
        } else {
          this.closePanel();
        }
      });
    } else {
      this.snackBar.open(this.translate.instant('notAllowed'), 'OK', {duration: 2000});
    }
  }

  closePanel() {
    this.isExpanded = false;
    this.temporallyPriceForm.reset();
  }
}
