<div class="campaign-containers">
  <div class="campaign-box-form email-campaign" [style]="{width: width}">
    <mat-form-field class="campaign-box-form--text-field">
      <mat-label>{{"campaignName" | translate}}</mat-label>
      <input matInput type="text" [(ngModel)]="name">
    </mat-form-field>
    <mat-form-field class="campaign-box-form--text-field" *ngIf="mode != 3">
      <mat-label>{{"title" | translate}}</mat-label>
      <input matInput type="text" [(ngModel)]="title">
    </mat-form-field>
    <mat-form-field class="campaign-box-form--text-field" *ngIf="mode != 1">
      <mat-label>{{"text" | translate}}</mat-label>
      <textarea matInput [(ngModel)]="text"> </textarea>
    </mat-form-field>
    <angular-editor *ngIf="mode == 1" [(ngModel)]="text"></angular-editor>


    <mat-form-field class="campaign-box-form--text-field campaign-box-form--text-field__half" style="margin-right: 5%">
      <input
        [value]="startDate"
        matInput
        [matDatepicker]="startDatePicker"
        placeholder="{{'startDate' | translate}}"
        (focus)="startDatePicker.open()"
        (dateChange)="startDate = $event.value.toISOString()">
      <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #startDatePicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="campaign-box-form--text-field campaign-box-form--text-field__half">
      <input matInput [ngxTimepicker]="timePicker" (ngModelChange)="changeTime($event)" [format]="24"
             placeholder="{{'startTime' | translate}}" [(ngModel)]="startTime">
      <ngx-material-timepicker  #appendedToInput #timePicker></ngx-material-timepicker>
    </mat-form-field>
    <mat-form-field class="campaign-box-form--text-field" *ngIf="mode != 2">
      <mat-label>{{"buttonText" | translate}}</mat-label>
      <textarea matInput [(ngModel)]="buttonText"> </textarea>
    </mat-form-field>
    <mat-form-field class="campaign-box-form--text-field" *ngIf="mode != 2">
      <mat-label>{{"buttonUrl" | translate}}</mat-label>
      <textarea matInput [(ngModel)]="buttonUrl"> </textarea>
    </mat-form-field>
    <div *ngIf="mode == 1" class="buttons-container">
      <mat-label>{{"buttonStyle" | translate}}</mat-label>
      <br>
      <mat-radio-group [(ngModel)]="buttonOption">
        <mat-radio-button *ngFor="let b of buttons" class="button-option" [value]="b.id">
          <app-button [text]="b.id" [width]="'101px'" [height]="'48px'" [color]="b.background_color"
                      [textColor]="b.color"
          ></app-button>
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="campaign-box-form--image-container" [ngStyle]="{'display': 'flex', 'align-items': 'center', 'color': '#606060', 'font-size': '18px'}">

      <div
        [ngStyle]="{'margin-top': '20px', 'width': '400px', 'height': mode === 1 ? '200px' : '470px','border': '2px dashed #606060', 'cursor': 'pointer', 'border-radius': '8px', 'object-fit': 'contain'}"
            *ngIf="!image"
           (click)="fileUploader.click()"
      >
        <span
          [ngStyle]="{ 'display': 'table', 'margin-top': mode === 1 ? '75px' : '210px', 'margin-left': '140px'}"
          > {{'addImage' | translate}} </span>

      </div>
      <img
          [ngStyle]="{'margin-top': '20px', 'width': '400px', 'height': mode === 1 ? '200px' : '470px', 'cursor': 'pointer', 'border-radius': '8px', 'object-fit': 'contain'}"
          class="campaign-box-form--image-box"
          *ngIf="image"
          (click)="fileUploader.click()"
          [src]="image"
      >

      <div class="campaign-box-form--info-text">
        <mat-icon>info</mat-icon>
        <span
          *ngIf="mode == 2 || mode == 3">
          1080x1080 px
        </span>
        <span
          *ngIf="mode == 1">
          1200x628 px
        </span>
      </div>
    </div>
    <div *ngIf="isPopup == 1">
      <mat-label class="audience-label">{{'listOFAudience' | translate}} </mat-label>
      <div *ngFor="let audience of getAudienceArray()" class="audience-item">
        {{audience.name}} ({{audience.userCount}}) (user_ids.length)
        <span class="audience-remove" (click)="removeAudience(audience.id)">{{'remove' | translate}}</span>
      </div>
      <div class="audience-more" *ngIf="!audienceMode" (click)="audienceMode = true">
        <img src="assets/ic_plus.svg"> {{'addAudience' | translate}}
      </div>
      <mat-select *ngIf="audienceMode" placeholder="{{'selectAudience' | translate}}">
        <mat-option *ngFor="let a of getNotSelectedudiences()" (onSelectionChange)="selectedAudience(a)" [value]="a.id">
          {{a.name}}
        </mat-option>
      </mat-select>
    </div>
    <div class="campaign-box-form--button-container" (click)="saveCampaign()">
      <app-button text="{{'save' | translate}}" [width]="'101px'" [height]="'48px'" [color]="colors.PINK"
                  [textColor]="colors.WHITE"
      ></app-button>
    </div>
  </div>
</div>

<input type="file" #fileUploader style="visibility: hidden" (change)="onSelectFile($event.target.files)">
