<div style="min-height: 93vh; background-color: #eceff1;">
  <div class="header" style="background-color: #d92a73" [ngClass]="{'hiddenHeader' : !showHeader}">
    <span style="font-size:1.7em; color:white; padding-left: 20px; font-weight: 500; ">
    {{'tutorial' | translate}} / {{'employees' | translate}}
    </span>
  </div>
  <div class="menu" [ngClass]="{'hiddenHeader' : !showHeader}"
       style="background-color: #d92a73; color:white; width: 100% !important; font-size:18px; font-weight: 500; padding-top: 15px;">
    <div class="drawer">
      <span class="menuItem" *ngFor="let p of sectors;let i = index;" [ngClass]="{'active' : currentPage == p.id}"
            (click)="toTop(p.id)" style="margin-right: 14px; cursor: pointer;">
            <span *ngIf="i === 0" style="margin-left:10px;"> {{p.name}}</span>
            <span *ngIf="i > 0"> {{p.name}}</span>
      </span>
    </div>
  </div>

  <div class="stickyMenu" *ngIf="!showHeader" style="background-color: #d92a73; color:white; font-size:18px;
 font-weight: 500; padding-top: 15px; position: fixed; z-index: 3; width: 100%; top:0; display:inline;">
    <app-sticky-header class="stickyHeader" style="right: 0px; position: fixed; top: 8px;"></app-sticky-header>
    <div class="drawer">
    <span class="menuItem" *ngFor="let p of sectors;let i = index;" [ngClass]="{'active' : currentPage == p.id}"
          id="{{p.id}}nav" (click)="toTop(p.id)" style="margin-right: 14px; cursor: pointer;">
            <span *ngIf="i === 0" style="margin-left:10px;"> {{p.name}}</span>
            <span *ngIf="i > 0"> {{p.name}}</span>
      </span>
    </div>
  </div>

  <div class="main" style="margin-top: 4vh; margin-left: 4vw; margin-right: 4vw;">
    <div class="search" style="height: 5vh;">
      <div class="floats" style="float:left">
        <mat-chip-list>
          <mat-chip *ngIf="filterName" style="margin-bottom: 5px; font-size: 15px;">
            {{filterName}}
            <mat-icon style="cursor: pointer; color:#424242; margin-left: 3px;" (click)="filterName = ''"> cancel
            </mat-icon>
          </mat-chip>
          <mat-chip *ngIf="filterPhone" style="margin-bottom: 5px;">
            {{filterPhone}}
            <mat-icon style="cursor: pointer; color:#424242; margin-left: 3px;" (click)="filterPhone = ''"> cancel
            </mat-icon>
          </mat-chip>
          <mat-chip *ngIf="filterEmail" style="margin-bottom: 5px;">
            {{filterEmail}}
            <mat-icon style="cursor: pointer; color:#424242; margin-left: 3px;" (click)="filterEmail = ''"> cancel
            </mat-icon>
          </mat-chip>
          <mat-chip *ngIf="filterPosition" style="margin-bottom: 5px;">
            {{filterPosition}}
            <mat-icon style="cursor: pointer; color:#424242; margin-left: 3px;" (click)="filterPosition = ''"> cancel
            </mat-icon>
          </mat-chip>
        </mat-chip-list>
      </div>
      <div class="addEmpl" style="float:right" *ngIf="checkEditPermission('employees')">
        <div (click)="addNew()"
             style="margin-right: 10px; display: inline-flex; padding-left: 15px !important; vertical-align: middle;
             float: right; background-color: #d92a73;; border-radius:24px; color:white; font-weight: bold; font-size:18px;
              padding: 10px; cursor: pointer; width: 135px; text-align: center;">
          {{'addNew' | translate}}
          <mat-icon style="margin-left: 14px;"> add</mat-icon>
        </div>
      </div>
      <div class="searchButton" style="float:right">
        <div [matMenuTriggerFor]="filter"
             style="margin-right: 10px; display: inline-flex; padding-left: 15px !important; vertical-align: middle;
             float: right; background-color: #6e92ad; border-radius:24px; color:white; font-weight: bold; font-size:18px;
             padding: 10px; cursor: pointer; width: 135px; text-align: center;">
          {{'search' | translate}}
          <mat-icon style="margin-left: 14px;"> search</mat-icon>
        </div>
      </div>
      <mat-menu #filter="matMenu">
        <div style="padding:10px;" (click)="stopPropagation($event)">
          <span style="font-weight: bold;">{{'filterData' | translate}}</span>
          <mat-form-field style="width: 95%; margin: 8px">
            <input type="text" matInput placeholder="{{'firstNameAndLastName' | translate}}" [(ngModel)]="filterName"
                   (submit)="onSearch($event)" (keyup.enter)="onSearch($event)"/>
          </mat-form-field>
          <mat-form-field style="width: 95%; margin: 8px">
            <input type="text" matInput placeholder="{{'phone' | translate}}" [(ngModel)]="filterPhone"
                   (submit)="onSearch($event)" (keyup.enter)="onSearch($event)"/>
          </mat-form-field>


          <mat-form-field style="width: 95%; margin: 8px">
            <input type="text" matInput placeholder="{{'email' | translate}}" [(ngModel)]="filterEmail"
                   (submit)="onSearch($event)" (keyup.enter)="onSearch($event)"/>
          </mat-form-field>


          <mat-form-field style="width: 95%; margin: 8px">
            <input type="text" matInput placeholder="{{'position' | translate}}" [(ngModel)]="filterPosition"
                   (submit)="onSearch($event)" (keyup.enter)="onSearch($event)"/>
          </mat-form-field>
          <span (click)="onSearch($event)"
                style="font-weight: bold; margin-bottom: 10px; cursor: pointer; float:right; padding: 10px; color:#1e88e5; font-size: 18px;"> {{'search' | translate }}</span>
          <span (click)="onReset()"
                style="font-weight: bold; margin-bottom: 10px; cursor: pointer; float:right; padding: 10px; color:#747474; font-size: 18px;"> {{'reset' | translate }}</span>
        </div>

      </mat-menu>
    </div>
    <div class="sector" *ngFor="let s of sectors" style="margin-bottom:5vh;" snInViewport
         (inViewportChange)="onInViewportChange(s.id,$event)" [id]="s.id">
      <span style="color: #424242; font-size: 24px;">{{s.name}}</span>
      <br>
      <div class="employeesDiv">
        <div *ngFor="let e of filteredEmployees(s.id)" (click)="clickEmpl(e)">
          <mat-card class="employee" *ngIf="e.sectorId === s.id">
            <div *ngIf="isDeleteable(e)" class="deleteEmpl" (click)="onDelete(e)">
              <mat-icon>close</mat-icon>
            </div>
            <img src="assets/avatar.svg" *ngIf="!e.image"
                 style="border-radius: 50%; width: 150px; height: 150px; object-fit: cover; position: relative; margin-left: auto; margin-right: auto; display: block;">
            <img [src]="e.image" *ngIf="e.image"
                 style="border-radius: 50%; width: 150px; height: 150px; object-fit: cover; margin-left: auto; margin-right: auto; display: block;">
            <div style="width: 100%; text-align: center;">
              <div
                style="width: 150px; height: 4px; background-color: #dfe0df; margin-top: 3vh; margin-bottom: 2vh;  margin-left: auto; margin-right: auto; display: block;">
              </div>
              <div class="card-title" *ngIf="e.fullName">{{e.fullName}}</div>
              <div class="card-title" *ngIf="!e.fullName">{{e.username}}</div>
            </div>
            <br>
            <div
              style="text-align: center; margin: auto; left: 0; right: 0; position: relative; width: 150px; color: #606060; font-size: 16px; font-weight: bold;">
              {{e.sector}} <br>
              <span> {{e.position}} </span>
              <span *ngIf="!e.position"> <br> </span>
            </div>
            <br>
            <div
              style="width: 150px; height: 4px; background-color: #dfe0df; margin-bottom: 2vh;  margin-left: auto; margin-right: auto; display: block;">
            </div>

            <img src="assets/ic-next.svg"
                 style="width: 32px; margin-bottom: 2vh;  margin-left: auto; margin-right: auto; display: block;">

          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>
