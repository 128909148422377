import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {MAT_DIALOG_DATA} from "@angular/material";
import {ApiService} from "../../api.service";

class DialogData {
  survey: any;
  mode: any;
}

@Component({
  selector: 'app-survey-popup',
  templateUrl: './survey-popup.component.html',
  styleUrls: ['./survey-popup.component.css']
})
export class SurveyPopupComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SurveyPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData, private service: ApiService) { }


  ngOnInit() {
  }

  sendSurvey(survey) {
    this.dialogRef.close(survey);
  }

}
