<mat-card style="width: 70vw; height: 85vh; overflow: scroll;">
  <h4> {{'events' | translate}} </h4>
  <ngx-datatable
    style="margin-top: 20px;"
    #table
    class="material expandable"
    [rows]="requests"
    [columnMode]="'force'"
    [headerHeight]="50"
    [footerHeight]="75"
    [rowHeight]="'auto'"
    [scrollbarH]="true"
    [rowClass]="getRowClass"
    [externalPaging]="true"
    [scrollbarV]="false"
    [count]="page['totalElements']"
    [offset]="page['pageNumber']"
    limit="10"
    (page)="setPage($event)"
    (activate)="openHistory()">
    <ngx-datatable-column sortable="false" prop="id" [width]="10" >
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        <span>{{value}}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column sortable="false" prop="adminName" name="{{'employee' | translate}}" [width]="60">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        <span>{{value}}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column sortable="false" prop="name" name="{{'name' | translate}}" [width]="60">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        <span>{{value}}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column sortable="false" prop="description" name="{{'description' | translate}}" [width]="60">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        <span>{{value}}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column sortable="false" prop="status" [width]="60"  name="{{'status' | translate}}" >
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        <span *ngIf="value == 0">{{'pending' | translate}}</span>
        <span *ngIf="value == 1">{{'approved' | translate}}</span>
        <span *ngIf="value == -1">{{'declined' | translate}}</span>
        <span *ngIf="value == 3">{{'cancelled' | translate}}</span>

      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column sortable="false" prop="start" name="{{'period' | translate}}" [width]="60">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        <span>{{getPeriod(row)}}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column sortable="false" prop="type" name="{{'type' | translate}}" [width]="60">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        <span *ngIf="value == 0"> {{'dayOff' | translate}}</span>
        <span *ngIf="value == 1"> {{'appointment' | translate}}</span>

      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column sortable="false"  [width]="10" prop="createdAt"  name="{{'createdOn' | translate}}" >
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        <span> {{value | date : "short" : "Europe/Belgrade" }}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column sortable="false"  [width]="10"  name="{{'accept' | translate}}" *ngIf="user.roleId == 2">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        <span *ngIf="row['status'] == 0" style="cursor: pointer;" (click)="confirm(row)">
          <mat-icon style="vertical-align: middle !important;"> done </mat-icon>
        </span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column sortable="false"  [width]="10"  name="{{'decline' | translate}}"  *ngIf="user.roleId == 2">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        <span *ngIf="row['status'] == 0" style="cursor: pointer;" (click)="decline(row)">
          <mat-icon style="vertical-align: middle !important;">close</mat-icon>
        </span>


      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column sortable="false"  [width]="10"  name="{{'cancel' | translate}}" >
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        <span *ngIf="row['status'] == 1" style="cursor: pointer;" (click)="cancel(row)">
          <mat-icon style="vertical-align: middle !important;">cancel</mat-icon>
        </span>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</mat-card>
