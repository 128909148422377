import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.css']
})
export class NoteComponent implements OnInit {

  note = '';
  title = '';

  constructor(public dialogRef: MatDialogRef<NoteComponent>) { }

  ngOnInit() {
  }

}
