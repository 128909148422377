import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from "../api.service";
import {MatDialogRef, MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Admin} from "../models";
import {DataService} from "../data-service.service";
import {Router} from "@angular/router";
import {ProductdialogComponent} from "../productdialog/productdialog.component";
import {AppointmentComponent} from "../appointment/appointment.component";
import {VacationComponent} from "../vacation/vacation.component";
import {AllRequestsComponent} from "../all-requests/all-requests.component";
import {EmployeeComponent} from "../employee/employee.component";


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
  providers: [ApiService]
})
export class ProfileComponent implements OnInit, OnDestroy {
  user: Admin;
  showHeader = true;
  seminars = [];
  pHistory = [];
  currentPage = 'profile';
  seminarFilter = 3;
  positionFilter = 2;
  private timeoutHandler;

  constructor(private service: ApiService, private dataService: DataService, private snackBar: MatSnackBar, private router: Router, private dialog: MatDialog) {
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('adminYolo'));
    window.addEventListener('scroll', this.scroll, true);
    this.service.getAdminSeminars(this.user.id, 0).subscribe(
      data => {
        const s = [];
        s['seminars'] = data;
        this.seminars = s['seminars'];
      }
    );
    this.service.getAdminPositionHistory(this.user.id, 0).subscribe(
      data => {
        const h = [];
        h['position'] = data;
        this.pHistory = h['position'];

      }
    );
    this.dataService.changeDayOffs.subscribe(
      data => {
        this.user.dayOffs = data;
      }
    );
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }

  toCenter(id){
    if (document.getElementById(id) != null){
      console.log(id);
      document.getElementById(id).scrollIntoView({ inline: 'center',behavior: "smooth"});
    }
  }

  openRequests() {
    const dialogConfig = new MatDialogConfig();
    // dialogConfig.width = '80%';
    // dialogConfig.height = '40%';
    dialogConfig.panelClass = 'event-info-dialog';
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    this.dialog.open(AllRequestsComponent, dialogConfig);
  }

  toTop(id) {
    if (document.getElementById(id) != null){
      document.getElementById(id).scrollIntoView({behavior: "smooth"});

    }
  }

  filterSeminars() {
    if (this.seminarFilter === 0){
      return this.seminars;
    } else {
      return this.seminars.slice(0, this.seminarFilter);
    }
  }

  filterPosition() {
    if (this.positionFilter === 0){
      return this.pHistory;
    } else {
      return this.pHistory.slice(0, this.positionFilter);
    }
  }


  onInViewportChange(type, event){
    if (event == true){
      this.currentPage = type;
      this.toCenter(type + 'nav');
    }
  }

  addAppointment(){
    const dialogConfig = new MatDialogConfig();
    // dialogConfig.width = '80%';
    // dialogConfig.height = '40%';
    dialogConfig.panelClass = 'appointment-info-dialog';
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    this.dialog.open(AppointmentComponent, dialogConfig);
  }

  addVacation(){
    const dialogConfig = new MatDialogConfig();
    // dialogConfig.width = '80%';
    // dialogConfig.height = '40%';
    dialogConfig.panelClass = 'appointment-info-dialog';
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    this.dialog.open(VacationComponent, dialogConfig);
  }

  scroll = (): void => {
    if (window.pageYOffset > 110) {
      this.showHeader = false;
    } else {
      this.showHeader = true;
    }
  };

  updateEmployee() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'appointment-info-dialog';
    dialogConfig.data = {'admin' : this.user, 'mode' : 'noPerm'};
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    this.dialog.open(EmployeeComponent, dialogConfig);
  }

}
