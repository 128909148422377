<div class="upselling-main">

  <mat-card class="upselling-left">
    <app-upselling-form (sendUpsellingAction)="newActionCreated($event)" [gotHighestPriority]="highestPriority" [products]="products"></app-upselling-form>
  </mat-card>

  <mat-card class="upselling-right" *ngIf="!actionsLoading">
    <div class="upselling-right-no-actions" *ngIf="upsellingActions.length === 0">{{'noUpsellingActions' | translate}}</div>
    <ngx-datatable
      *ngIf="upsellingActions.length > 0"
      #table
      class="material expandable upselling-right-table"
      [rows]="upsellingActions"
      [columnMode]="'standard'"
      [cssClasses]="'upselling-table'"
      [headerHeight]="50"
      [footerHeight]="50"
      [rowHeight] = "100"
      [scrollbarH]="true"
      [scrollbarV]="true"
      (scroll)="scrolled($event)"
    >
      aaaaa
      <ngx-datatable-column  sortable="false" prop="id" [width]="50" name="#">
        <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
          <span>{{value}}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column sortable="false" prop="name" [width]="250" name="{{'name' | translate}}" >
        <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
          <span>{{value}}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column sortable="false" prop="targetProduct" [width]="250" name="{{'targetProduct' | translate}}">
        <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
          <span>{{value.name}}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column sortable="false" prop="priority" [width]="150" name="{{'priority' | translate}}">
        <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
          <span>{{value}}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column sortable="false" prop="products" [width]="200" name="{{'products' | translate}}" [cellClass]="'upselling-products-cell'">
        <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value"ngx-datatable-cell-template>
          <span [innerHTML]="row.productsString"></span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column sortable="false" prop="id" [width]="150" name="{{'date' | translate}}">
        <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
          <span>{{row.fromDate}} <br> {{row.toDate}}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column sortable="false" prop="usedCount" [width]="150" name="{{'usedCount' | translate}}">
        <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
          <span>{{value}}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column sortable="false" prop="id" [width]="210" name="">
        <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
          <div class="upselling-right-actions">
            <div class="upselling-right-action-button" (click)="editUpselling(row, rowIndex)"><img src="assets/ic_edit.svg"></div>
            <div class="upselling-right-action-button" (click)="duplicateUpselling(row)"><img src="assets/ic_duplicate.svg"></div>
            <div class="upselling-right-action-button" (click)="deleteUpselling(row, rowIndex)"><img src="assets/ic_trash.svg"></div>


          </div>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </mat-card>

  <div class="upselling-right" *ngIf="actionsLoading">
    <app-loading></app-loading>
  </div>

</div>
