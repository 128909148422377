<mat-sidenav-container style="min-height: 100vh !important; background-color: #eceff1;">
  <span id="openNav" #openNavBar (click)="sideNav2.open()" style="overflow: hidden !important;"></span>
  <mat-sidenav class="leftSide" #sideNav [mode]="mode" [opened]="navOpen"
               style="width:250px; background-color: #18212b; position: fixed; overflow: hidden;"
               (close)="onClosed()">

    <div class="container"
         style="max-height: 100px; background-color: #18212b; text-align: center; padding: 16px; overflow: hidden;">
      <div class="backIcon">
        <mat-icon (click)="toggleNavBar()"
                  style="float:right; right: -20px; z-index: 3; position: relative; color: #9e9e9e; cursor: pointer">
          arrow_back_ios
        </mat-icon>
      </div>
      <div class="col-12 col-md-12" style="text-align: center; margin: auto; width: 100%; cursor: pointer"
           (click)="onNavClick('main/orders')">
        <img src="assets/header_logo.png" alt="logo" style="max-width: 120px; max-height: 80px; margin-bottom: 16px">
      </div>
    </div>


    <mat-list
      style="width: 100%;color:white !important; font-size: 14px; line-height: 24px; font-weight: 600">
      <!--      <div class="navBlock"  style="padding: 10px 20px;" [ngClass]="{'activeBlock' : router.url === '/main/about'}">-->
      <!--        <ul style="color:#9e9e9e !important; margin-top:10px; cursor: pointer;">-->
      <!--          <li [ngClass]="{'activeLink' : router.url === '/main/about'}"><span (click)="onNavClick('main/about')" style="cursor: pointer;">{{'aboutUs' | translate }}</span></li>-->
      <!--        </ul>-->
      <!--      </div>-->
      <div class="d-flex flex-column justify-content-between" style="height: calc(100vh - 128px)">
        <div>
          <div class="myProfile row" style="border: solid 1px #2d3547; background-color: #262e3e; padding: 10px 20px;">
            <div class="col-3">
              <img src="assets/avatar.svg" alt="avatar" *ngIf="!user.image"
                   style="border-radius: 50%; width: 50px; height: 50px; object-fit: cover; position: relative; margin-left: auto; margin-right: auto; display: block;">
              <img [src]="user.image" *ngIf="user.image" alt=""
                   style="border-radius: 50%; width: 50px; height: 50px; object-fit: cover; margin-left: auto; margin-right: auto; display: block;">
            </div>
            <div class="col-8 offset-1">
          <span (click)="onNavClick('main/profile')" style="cursor: pointer;">
            {{'myProfile' | translate}}
          </span> <br>
              <span style=" color: #9e9e9e; cursor: pointer;" (click)="onLogout($event)">
            {{'logOut' | translate }}
          </span>
            </div>
          </div>
          <div class="navBlock" style="padding: 10px 20px; margin-top: 20px;"
               [ngClass]="{'activeBlock' : router.url === '/main/orders' ||  router.url === '/main/promo'|| router.url === '/main/machines'|| router.url === '/main/supplies'|| router.url === '/main/users' || router.url === '/main/permissions'|| router.url.includes('main/stat') || router.url === '/main/covers' || router.url === '/main/employees' || router.url === '/main/earnings' || router.url === '/main/cost' || router.url === '/main/invoices' || router.url === '/main/promocodes' || router.url === '/main/banners' || router.url === '/main/boxes' ||  router.url === '/main/survey' ||router.url === '/main/dynamic-links' || router.url === '/main/temporal-price' || router.url.includes('/main/users')}">
            <!--        <span *ngIf="user.sector != null" style="color:white !important;"> {{user.sector}}</span>-->
            <span> {{'dashboard' | translate}}</span>
            <ul style="color:#c5c5c5 !important; margin-top:20px; cursor: pointer;">
              <li *ngIf="checkPermission('orders')" [ngClass]="{'activeLink' : router.url === '/main/orders'}">
            <span (click)="onNavClick('main/orders')" style="cursor: pointer;">
              {{'orders' | translate }}
            </span>
              </li>
              <li *ngIf="checkPermission('finance')" [ngClass]="{'activeLink' : router.url === '/main/cost'}">
            <span (click)="onNavClick('main/cost')" style="cursor: pointer;">
              {{'finance' | translate }}
            </span>
              </li>
              <li *ngIf="checkPermission('invoices')" [ngClass]="{'activeLink' : router.url === '/main/invoices'}">
            <span (click)="onNavClick('main/invoices')" style="cursor: pointer;">
              {{'bills' | translate }}
            </span>
              </li>
              <li *ngIf="checkPermission('promo')" [ngClass]="{'activeLink' : router.url === '/main/promo'}">
            <span (click)="onNavClick('main/promo')" style="cursor: pointer;">
              {{'promoCodes' | translate }}
            </span>
              </li>
              <li *ngIf="checkPermission('supplies')" [ngClass]="{'activeLink' : router.url === '/main/machines'}">
            <span (click)="onNavClick('main/machines')" style="cursor: pointer;">
              {{'machines' | translate }}
            </span>
              </li>
              <li *ngIf="checkPermission('covers')" [ngClass]="{'activeLink' : router.url === '/main/covers'}">
            <span (click)="onNavClick('main/covers')" style="cursor: pointer;">
              {{'covers' | translate }}
            </span>
              </li>
              <li *ngIf="checkPermission('statistics')" [ngClass]="{'activeLink' : router.url.includes('main/stat')}">
            <span (click)="onNavClick('main/stat')" style="cursor: pointer;">
              {{'statistics' | translate }}
            </span>
              </li>
              <li *ngIf="checkPermission('statistics')"
                  [ngClass]="{'activeLink' : router.url === '/main/marketingStat'}">
            <span (click)="onNavClick('main/marketingStat')" style="cursor: pointer;">
              {{'marketingStatistics' | translate }}
            </span>
              </li>
              <li *ngIf="checkPermission('supplies')" [ngClass]="{'activeLink' : router.url === '/main/supplies'}">
            <span (click)="onNavClick('main/supplies')" style="cursor: pointer;">
              {{'supplies' | translate }}
            </span>
              </li>
              <li *ngIf="checkPermission('users')" [ngClass]="{'activeLink' : router.url === '/main/users' || router.url.includes('/main/users')}">
            <span (click)="onNavClick('main/users')" style="cursor: pointer;">
              {{'users' | translate }}
            </span>
              </li>
              <li *ngIf="checkPermission('employees')" [ngClass]="{'activeLink' : router.url === '/main/employees'}">
            <span (click)="onNavClick('main/employees')" style="cursor: pointer;">
              {{'employees' | translate }}
            </span>
              </li>
              <li *ngIf="checkPermission('permissions')"
                  [ngClass]="{'activeLink' : router.url === '/main/permissions'}">
            <span (click)="onNavClick('main/permissions')" style="cursor: pointer;">
              {{'permissions' | translate }}
            </span>
              </li>
              <li *ngIf="checkPermission('banners')" [ngClass]="{'activeLink' : router.url === '/main/banners'}">
            <span (click)="onNavClick('main/banners')" style="cursor: pointer;">
              {{'banners' | translate }}
            </span>
              </li>
              <li *ngIf="checkPermission('boxes')" [ngClass]="{'activeLink' : router.url === '/main/boxes'}">
            <span (click)="onNavClick('main/boxes')" style="cursor: pointer;">
              {{'boxes' | translate }}
            </span>
              </li>
              <li *ngIf="checkPermission('dynamicLinks')"
                  [ngClass]="{'activeLink' : router.url === '/main/dynamic-links'}">
            <span (click)="onNavClick('main/dynamic-links')" style="cursor: pointer;">
              {{'dynamicLinks' | translate}}
            </span>
              </li>
              <li *ngIf="checkPermission('TemporalPrice')"
                  [ngClass]="{'activeLink' : router.url === '/main/temporal-price'}">
            <span (click)="onNavClick('main/temporal-price')" style="cursor: pointer;">
              {{'temporalPrice' | translate}}
            </span>
              </li>
              <li *ngIf="checkPermission('prices')"
                  [ngClass]="{'activeLink' : router.url.startsWith('/main/prices/')}">
            <span (click)="onNavClick('main/prices/price')" style="cursor: pointer;">
              {{'prices' | translate}}
            </span>
              </li>
              <li *ngIf="checkPermission('products')"
                  [ngClass]="{'activeLink' : router.url === '/main/products'}">
            <span (click)="onNavClick('main/products')" style="cursor: pointer;">
              {{'products' | translate}}
            </span>
              </li>

              <li *ngIf="checkPermission('notifications')"
                  [ngClass]="{'activeLink' : router.url === '/main/notifications'}">
                  <span (click)="onNavClick('main/notifications')" style="cursor: pointer;">
                    {{'notifications' | translate}}
                  </span>
              </li>
              <li *ngIf="checkPermission('users')"
                  [ngClass]="{'activeLink' : router.url === '/main/issues'}">
                  <span (click)="onNavClick('main/issues')" style="cursor: pointer;">
                    {{'issues' | translate}}
                  </span>
              </li>
              <li *ngIf="checkPermission('users')"
                  [ngClass]="{'activeLink' : router.url.includes('campaign')}">
                  <span (click)="onNavClick('main/campaigns')" style="cursor: pointer;">
                    {{'campaigns' | translate}}
                  </span>
              </li>
              <li *ngIf="checkPermission('users')"
                  [ngClass]="{'activeLink' : router.url.includes('survey')}">
                  <span (click)="onNavClick('main/surveys')" style="cursor: pointer;">
                    {{'surveys' | translate}}
                  </span>
              </li>


            </ul>
          </div>
        </div>

        <div>
          <div class="navBlock" style="padding: 10px 20px"
               [ngClass]="{'activeBlock' : router.url === '/main/profile' || router.url === '/main/seminars' || router.url === '/main/position' || router.url === '/main/calendar'}">

            <div class='button-box'>
              <div *ngIf="user.roleId == 1">
                <button mat-button [matMenuTriggerFor]="envMenu" style="outline: none; width: auto;">{{type}}</button>
                <mat-menu #envMenu="matMenu">
                  <button mat-menu-item (click)="changeType('production')"> Production</button>
                  <button mat-menu-item (click)="changeType('development')"> Development</button>
                </mat-menu>
              </div>
              <div>
                <button mat-button
                        [matMenuTriggerFor]="appMenu" class="language-button">
                  <span *ngIf="selectedLanguage === 'sr'"> {{'serbian' | translate}}</span>
                  <span *ngIf="selectedLanguage === 'en'"> {{'english' | translate}}</span>
                  <span *ngIf="selectedLanguage === 'ro'"> {{'romanian' | translate}}</span>
                  <span *ngIf="selectedLanguage === 'bg'"> {{'bulgarian' | translate}}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </mat-list>
    <!--    <mat-form-field style="color: white; font-size: 11px !important; text-align: center; position: fixed; bottom:10px; left: 10%;" *ngIf="user.fullView">-->
    <!--      <mat-select class="country" [value]="country" (selectionChange)="onCountrySelected($event)">-->
    <!--        <mat-option *ngFor="let c of contries" [value]="c.value">-->
    <!--          {{ c.name }}-->
    <!--        </mat-option>-->
    <!--      </mat-select>-->
    <!--    </mat-form-field>-->
  </mat-sidenav>

  <mat-toolbar color="primary" class="toolbarMain"
               style=" display: flex; justify-content: space-between; align-items: center;   padding-right: 0 !important; background-color: #d92a73; height: 7vh; width: 100vw">
    <div style="display: flex">
    <mat-icon style="cursor: pointer;" *ngIf="!navOpen || mode === 'over'" (click)="toggleNavBar()">menu</mat-icon>
    <div class="page-name mobile-only">
        {{getPageName()}}
    </div>
    </div>
    <div style="  display: flex; padding-right: 0 !important;">
      <img *ngIf="unreadNotifications === 0" class="openNav" (click)="toggleNavbarNot()"
           src="assets/ic-notifications.svg" alt="notifications"
           style="cursor:pointer; float:right; margin-left: 10px; margin-right: 10px; width: 30px; height: 30px; ;">
      <img *ngIf="unreadNotifications > 0" class="openNav" (click)="toggleNavbarNot()"
           src="assets/ic-notifications-with.svg" alt="notifications-with"
           style="cursor:pointer; float:right; margin-left: 10px;  margin-right: 10px; width: 30px; height: 30px;;">
      <mat-menu #appMenu="matMenu" class="toolbarButton">
        <button mat-menu-item (click)="onLanguageSelected('en')">{{'english' | translate}}</button>
        <button mat-menu-item (click)="onLanguageSelected('sr')">{{'serbian' | translate}}</button>
        <button mat-menu-item (click)="onLanguageSelected('ro')">{{'romanian' | translate}}</button>
        <button mat-menu-item (click)="onLanguageSelected('bg')">{{'bulgarian' | translate}}</button>
      </mat-menu>
      <button mat-icon-button style="float:right !important; width: 70px; outline: none!important; margin-top: -4px"
              [matMenuTriggerFor]="matMenu2"
              *ngIf="user.fullView">
        <span *ngFor="let c of contries">
          <span *ngIf="country == c.value"> <b> {{c.name | translate}} </b> </span>
          </span>
      </button>
      <mat-menu #matMenu2="matMenu">

        <button *ngFor="let c of contries" mat-menu-item (click)="onCountrySelected(c.value)"
                style="padding-left: 5px !important; padding-right: 5px !important; "><b>
          <span *ngIf="c.value != 'all' && c.value != 'eu'">
            <img [src]="getCountryFlag(c.value)"
                 alt="srb"
                 style="border-radius: 50%; object-fit: cover; margin-right: 10px;"
                 width="25" height="25"/>
          </span>
          <span *ngIf="c.value == 'all'">
            <img src="assets/worldwide.png"
                 style="border-radius: 50%; object-fit: cover; margin-right: 10px;"
                 alt="worldwide"
                 width="25" height="25"/>
          </span>
          <span *ngIf="c.value == 'eu'">
            <img src="assets/eu.png"
                 style="border-radius: 50%; object-fit: cover; margin-right: 10px;"
                 alt="eu"
                 width="25" height="25"/>
          </span>
          {{c.name | translate}}
        </b>
        </button>
      </mat-menu>
    </div>
  </mat-toolbar>

  <mat-sidenav #sideNav2 class="notCont" mode="over" opened="false"
               style="width:344px; height: 100vh !important; position: fixed; top: 0; background-color: white;"
               position="end">
    <div *ngIf="navbarType==='not'">
      <mat-toolbar color="primary" style="background-color: #d92a73; height: 7vh !important;">
        <span style="color:white; font-size:18px;"> {{'notifications' | translate}}</span>
      </mat-toolbar>
      <div infiniteScroll [infiniteScrollDistance]="2" style="height: 93vh; overflow-y: scroll;"
           [scrollWindow]="false"
           [infiniteScrollUpDistance]="1.5"
           [infiniteScrollThrottle]="50"
           (scrolled)="onScrollDown()">
        <div *ngIf="notifications.length === 0"
             style="text-align: center; color:grey; width: 100% !important; margin-top: 20vh;"> {{'noNotifications' | translate }} </div>
        <div *ngFor="let n of notifications"
             style="font-size: 16px; color: #606060; border-bottom: 1px solid #606060;
             padding:10px; cursor:pointer; height: 102px !important; display: -webkit-box;
             -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden;"
             (click)="openNotification(n)">
          <div class="row">
            <div class="col-3">
              <img [src]="getImageOutOfObject(n.object)" alt="" *ngIf="getImageOutOfObject(n.object)"
                   style="margin-top: 10px; display: block; margin-left: auto; margin-right: auto;object-fit: cover; border-radius: 50%; width: 60px; height: 60px; ">
              <img src="assets/avatar.svg" alt="avatar" *ngIf="!getImageOutOfObject(n.object)"
                   style="margin-top: 10px; display: block; margin-left: auto; margin-right: auto;object-fit: cover; border-radius: 50%; width: 60px; height: 60px; ">
            </div>
            <div class="col-9">
              <span style="font-size: 16px !important;">{{n.title}}</span> <br>
              <span style="font-size: 14px; display: block; width: 90%; word-break: break-word;">
                {{n.description }}
              </span>
              <span style="float:right; color:gray;  font-size: 15px;"> {{n.timePass}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav>
  <router-outlet></router-outlet>
</mat-sidenav-container>

<div [@fadeInDownOnEnter] [@fadeOutUpOnLeave] style="position: fixed; top: 7vh; right: 10px; z-index: 3;"
     *ngIf="newNotification">
  <app-notification></app-notification>
</div>





