<div style="padding: 20px; width: 300px; height: 450px; position:relative;">
<span style="color:#747474; font-size:18px;"> {{'makeAnAppointment' | translate }}</span>

  <div style="margin-top: 50px;">
    <mat-form-field>
      <mat-select placeholder="{{'chooseEmployee' | translate}}" [(ngModel)]="selectedEmployee" >
        <mat-option *ngFor="let e of employees" [value]="e.id">
          <span *ngIf="e.fullName"> {{e.fullName}} </span>
          <span *ngIf="!e.fullName"> {{e.username}} </span>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field style="width: 100%">
      <input #dates matInput [matDatepicker]="dates" placeholder="{{'date' | translate}}" (dateChange)="date = $event.value.toISOString()" [(ngModel)]="date">
      <mat-datepicker-toggle matSuffix [for]="dates"></mat-datepicker-toggle>
      <mat-datepicker #dates></mat-datepicker>
    </mat-form-field>
    <mat-form-field style="width: 100%">
      <input matInput [ngxTimepicker]="picker" [format]="24" placeholder="{{'time' | translate}}" [(ngModel)]="time">
      <ngx-material-timepicker  #picker></ngx-material-timepicker>
    </mat-form-field>
    <mat-form-field style="width: 100%">
      <textarea matInput rows="0" placeholder="{{'description' | translate}}" [(ngModel)]="description" style="height: 32px;"></textarea>
    </mat-form-field>
  </div>
  <span style="color:red; left: 0px; position: absolute; text-align: center; width: 100%;" *ngIf="message !== ''"> {{ message | translate }} </span>
  <span (click)="onConfirm($event)" style="font-weight: bold; position: absolute; bottom: 10px; right: 10px; cursor: pointer; float:right; padding: 10px; color:#1e88e5; font-size: 18px;"> {{'confirm' | translate }}</span></div>

