<section style="position: relative; width: 100%; height: 100%">
  <span>{{data.message}}</span>
  <div class="buttons-container">
    <div class="buttons">
      <button mat-raised-button color="accent" class='button'
              (click)="closeDialog(null)">{{'cancel' | translate}}</button>
      <button mat-raised-button color="primary" class='button' (click)="crudLink()">{{'ok' | translate}}</button>
    </div>
  </div>
</section>
