<div class="row" style=" overflow: hidden !important; margin-left: 0px !important; margin-right: 0px !important;  padding: 20px; width: 500px">
  <h3>{{'sendNotification' | translate}} ({{data.total}})</h3>
  <hr>
  <div class="col-md-12 offset-md-1">
    <mat-form-field style="width: 45% !important;">
      <input matInput [(ngModel)]="notificationTitle" placeholder="{{'title' | translate}}" style="width: 100% !important;">
    </mat-form-field>
    <br>
    <mat-form-field style="width: 45% !important;">
      <input matInput [(ngModel)]="notificationBody" placeholder="{{'description' | translate}}" style="width: 100% !important;">
    </mat-form-field>
    <br>
    <input type="file" (change)="onSelectFile($event.target.files)" ><br>
    <img [src]="notificationImageUrl" *ngIf="notificationImageUrl" style="width: 300px; height: auto" alt="image">
  </div>
  <div class="col-md-12">
    <button class="notButton" [disabled]="notificationImagePath == ''" (click)="openModalForNotifications()" style="    margin-left: 8.333333% !important; ">{{'sendNotification'  | translate}}</button>
  </div>
</div>
