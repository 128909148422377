import {Component, OnInit} from '@angular/core';
import {ApiService} from "../api.service";
import {OrderNotification} from "../models";
import {Observable} from "rxjs";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {ConfirmModalComponent} from "../confirm-modal/confirm-modal.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-listing-notification',
  templateUrl: './listing-notification.component.html',
  styleUrls: ['./listing-notification.component.css']
})
export class ListingNotificationComponent implements OnInit {
  notifications$: Observable<OrderNotification[]>;
  activeIdArray: Array<number> = [];

  constructor(private apiService: ApiService,
              private snackBar: MatSnackBar,
              private translate: TranslateService,
              private dialog: MatDialog) {
  }

  ngOnInit() {
    this.notifications$ = this.apiService.listingNotification();
    this.notifications$.subscribe(n => {
    })
  }

  changeActive(active: OrderNotification): void {
    const activeIndex = this.activeIdArray.findIndex((n: number) => n === active.id);
    if (activeIndex === -1) {
      this.activeIdArray.push(active.id);
    } else {
      this.activeIdArray.splice(activeIndex, 1);
    }
  }

  Update(): void {
    if (this.activeIdArray.length > 0) {
      const openUpdateDialog = this.dialog.open(ConfirmModalComponent, {
        // disableClose: true,
        width: '400px',
        height: '200px',
        position: {
          top: '100px',
        },
        data: {
          data: this.activeIdArray,
          message: this.translate.instant('statusChangedText'),
          mode: 'active-status',
        },
      });
      openUpdateDialog.afterClosed().subscribe((result) => {
        if (result && result === true) {
          this.snackBar.open(this.translate.instant('statusChanged'), 'OK', {duration: 3000});
          this.activeIdArray = [];
        }
      });
    }
  }
}
