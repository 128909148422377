<div style="position: relative">
  {{'areYouSureYouWantToRefund' | translate}} {{roundNumber(data.order.priceEur)}} EUR {{'forOrder' | translate}} {{data.order.id}} ?

  <div mat-dialog-actions>
    <button mat-button (click)="confirm()" style="position: absolute; right: 20px;">{{'yes' | translate}}</button>
    <button mat-button (click)="no()" style="position: absolute; right: 100px;">{{'no' | translate}}</button>
  </div>

</div>

