import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-rating-comment-pop-up',
  templateUrl: './rating-comment-pop-up.component.html',
  styleUrls: ['./rating-comment-pop-up.component.css']
})
export class RatingCommentPopUpComponent implements OnInit {

  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<RatingCommentPopUpComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

}
