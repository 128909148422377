
<img  *ngIf="unreadNotifications === 0" (click)="openNavBar('not')" src="assets/ic-notifications.svg" style="cursor:pointer; float:right; margin-left: 10px; width: 30px; height: 30px;">
  <img  *ngIf="unreadNotifications > 0" (click)="openNavBar('not')" src="assets/ic-notifications-with.svg" style="cursor:pointer; float:right; margin-left: 10px; width: 30px; height: 30px;">

  <button mat-icon-button style="float:right !important; width: 70px;  margin-left: 10px;"  [matMenuTriggerFor]="matMenu2" *ngIf="user.fullView">
        <span *ngFor="let c of contries" >
          <span *ngIf="country == c.value"> <b> {{c.name}} </b></span>
          </span>
  </button>
<mat-menu #matMenu2="matMenu">

  <button *ngFor="let c of contries" mat-menu-item (click)="onCountrySelected(c.value)" style="padding-left: 5px !important; padding-right: 5px !important;"> <b>
    <span *ngIf="c.value == 'rs'"><img src="https://flagpedia.net/data/flags/small/rs.png" style="border-radius: 50%; object-fit: cover; margin-right: 10px;" width="25" height="25" /></span>
    <span *ngIf="c.value == 'ro'"> <img src="https://flagpedia.net/data/flags/small/ro.png" style="border-radius: 50%; object-fit: cover; margin-right: 10px;"  width="25" height="25" /></span>
    <span *ngIf="c.value == 'ba'"><img src="https://flagpedia.net/data/flags/small/ba.png" style="border-radius: 50%; object-fit: cover; margin-right: 10px;"  width="25" height="25" /></span>
    <span *ngIf="c.value == 'hr'"><img  src="https://flagpedia.net/data/flags/small/hr.png" style="border-radius: 50%; object-fit: cover; margin-right: 10px;"  width="25" height="25" /> </span>
    <span *ngIf="c.value == 'hu'"><img  src="https://flagpedia.net/data/flags/small/hu.png" style="border-radius: 50%; object-fit: cover; margin-right: 10px;"  width="25" height="25" /> </span>
    <span *ngIf="c.value == 'all'"><img  src="assets/worldwide.png" style="border-radius: 50%; object-fit: cover; margin-right: 10px;"  width="25" height="25" /></span>
    <span *ngIf="c.value == 'eu'"><img  src="assets/eu.png" style="border-radius: 50%; object-fit: cover; margin-right: 10px;"  width="25" height="25" /></span>

    {{c.name}} </b> </button>

</mat-menu>

