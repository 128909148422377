import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material";
import {MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {OrderList} from "../models";
import {ApiService} from "../api.service";
import {TranslateService} from "@ngx-translate/core";
import {DataService} from "../data-service.service";

export interface DialogData {
  order: OrderList;
}

@Component({
  selector: 'app-refund-dialog',
  templateUrl: './refund-dialog.component.html',
  styleUrls: ['./refund-dialog.component.css']
})


export class RefundDialogComponent implements OnInit {



  constructor(private dataService: DataService,private translate: TranslateService, private snackBar: MatSnackBar, private apiService: ApiService, private dialogRef: MatDialogRef<RefundDialogComponent>,
               @Inject(MAT_DIALOG_DATA) public data: DialogData,) { }

  ngOnInit() {
  }

  roundNumber(number: number) {
    return number.toFixed(2);
  }

  no(){
    this.dialogRef.close();
  }

  confirm() {
    this.apiService.refundPayment(this.data.order.id).subscribe(
      data => {
        this.dataService.changeOrderObject.next(data);
        this.snackBar.open(this.translate.instant('refundMade') , 'OK');
        this.dialogRef.close();
      },
      err => {
        this.snackBar.open(this.translate.instant('thereWasAnError') + ' : ' + this.data.order.id, 'OK');
        this.dialogRef.close();
      }
    )
  }

}
