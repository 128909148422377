import {Component, Inject, OnInit} from "@angular/core";
import {ApiService} from "../api.service";
import {MAT_DIALOG_DATA} from "@angular/material";
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {GalleryComponent} from "../gallery/gallery.component";
import {DomSanitizer} from "@angular/platform-browser";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {DetailImageComponent} from "../detail-image/detail-image.component";
import {types} from "util";

class DialogData {
  image: any;
  name: string;
  options: any;
  price: any;
  currency: any;
  dimensions: any;
  media: any[];
  mainImageChanged?: boolean;
  imagePath?: any;
  imageType?: number;
}

@Component({
  selector: 'app-productdialog',
  templateUrl: './productdialog.component.html',
  styleUrls: ['./productdialog.component.css'],
  providers: [ApiService]
})
export class ProductdialogComponent implements OnInit {

  existingImages: any[] = [];
  selectedIndex = 0;
  fileFormData: any;
  detailsChanged = false;
  progressBarValue = 0;
  loading = false;
  newDetail: any;
  private checkInterval: any;

  constructor(
    private dialogRef: MatDialogRef<ProductdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private translate: TranslateService, private apiService: ApiService, private snackBar: MatSnackBar, private dialog: MatDialog, private sanitize: DomSanitizer
  ) {

  }

  ngOnInit() {
    this.selectedIndex = 0;
    // add positions to images
    this.data.media.forEach((image: any) => {
      image.position = this.data.media.findIndex((i: any) => i.id == image.id) + 1;
    })
    this.apiService.getProductImages().subscribe((data: any[]) => {
      this.existingImages = data.sort((a: any, b: any) => {
        return b.time - a.time;
      });
    });
  }

  moveProgressBar(value: number) { // progress bar animation
    setInterval(() => {
      if (this.progressBarValue < value) {
        this.progressBarValue = this.progressBarValue + 1;
      }
    }, 10);
  }

  selectOldImage() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'product-info-dialog';
    dialogConfig.data = {images: this.existingImages};
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    const imageSelection = this.dialog.open(GalleryComponent, dialogConfig);
    imageSelection.afterClosed().subscribe((data: any) => {
      if (data) {
        this.data.mainImageChanged = true;
        this.data.imageType = 1;
        this.data.image = data.url;
        this.data.imagePath = data.path;
      }
    });
  }

  uploadNewImage() {
    const uploader = document.getElementById('upload-file');
    if (uploader) {
      uploader.click();
    }
  }

  onSelectFile(files: FileList) {
    const fileToUpload = files.item(0);
    const formData = new FormData();
    formData.append('image', fileToUpload, fileToUpload.name);
    this.fileFormData = formData;
    this.data.image = this.sanitize.bypassSecurityTrustUrl(URL.createObjectURL(files[0]));
    this.data.mainImageChanged = true;
    this.data.imageType = 2;
  }

  onSelectFile2(files: FileList) {
    const fileToUpload = files.item(0);
    const formData = new FormData();
    formData.append('image', fileToUpload, fileToUpload.name);
    const position = this.data.media.length + 1;
    const type = files.item(0).type;
    let image = this.sanitize.bypassSecurityTrustUrl(URL.createObjectURL(files[0]));
    let isVideo = false;
    if (type.includes("video")) {
      image = "assets/video.png";
      isVideo = true;
    }
    console.log("images    ", files.item(0).type);
    const newDetail = {
      id: 0,
      image,
      fileFormData: formData,
      position,
      positions: position,
      mode: 'new',
      isVideo
    };
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'product-info-dialog';
    dialogConfig.data = newDetail;
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    const imageSelection = this.dialog.open(DetailImageComponent, dialogConfig);
    imageSelection.afterClosed().subscribe((data: any) => {
      if (data) {
        const dataIndex = data.position - 1;
        this.data.media.splice(dataIndex, 0, data);
        this.selectedIndex = dataIndex;
        this.detailsChanged = true;
      }
    });
  }

  addDetailImage() {
    const uploader = document.getElementById('upload-file-2');
    if (uploader) {
      uploader.click();
    }
  }

  editDetailImage(detailImage) {
    detailImage.position = this.data.media.findIndex((i: any) => i.id == detailImage.id) + 1;
    detailImage.positions = this.data.media.length;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'product-info-dialog';
    dialogConfig.data = detailImage;
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    const imageSelection = this.dialog.open(DetailImageComponent, dialogConfig);
    imageSelection.afterClosed().subscribe((data: any) => {
      if (data) {
        const dataIndex = data.position - 1;
        const imageOnSelectedPosition = this.data.media[dataIndex];
        this.data.media.splice(dataIndex, 1, data);
        console.log(imageOnSelectedPosition);
        const newIndex = this.data.media.findIndex((i: any) => i.id == detailImage.id);
        if (newIndex != -1) {
          this.data.media.splice(newIndex, 1, imageOnSelectedPosition);
        }
        this.selectedIndex = dataIndex;
        this.detailsChanged = true;
      }
    });
  }

  deleteDetailImage(detailImage) {
    const realIndex = this.data.media.findIndex((i: any) => i.id == detailImage.id);
    if (realIndex != -1) {
      if (realIndex == this.data.media.length - 1) {
        this.selectedIndex = this.selectedIndex - 1;
      }
      this.data.media.splice(realIndex, 1);
    }
    this.detailsChanged = true;
  }

  save() {
    this.loading = true;
    if (this.data.imageType == 2) { //need to upload file first
      this.apiService.uploadProductImage(this.fileFormData).subscribe(
        (apiData2: any) => {
          this.data.imagePath = apiData2.path;
          this.updateProductImage();
        }
      ), (err) => {
        this.snackBar.open(this.translate.instant('thereWasAnError'), 'OK', {duration: 3000});
      }

    } else {
      this.updateProductImage();
    }
  }

  updateProductImage() {
    this.moveProgressBar(50);
    this.apiService.updateProductImage(this.data).subscribe(
      (apiData: any) => {
        this.moveProgressBar(100);
        this.dialogRef.close(apiData);
      }
    ), (err) => {
      this.snackBar.open(this.translate.instant('thereWasAnError'), 'OK', {duration: 3000});
    }
  }


  saveDetails() {
    console.log(this.data.media);
    this.loading = true;
    this.moveProgressBar(10);
    let percentageValue = 10;
    console.log("media   ", this.data.media);
    const imagesToUpload = this.data.media.filter((i: any) => {
      return i.fileFormData || i.thumbnailFormData;
    });
    const percentageAdd = 40 / (imagesToUpload.length * 2);
    imagesToUpload.forEach((i: any) => {
      if (i.fileFormData) {
        this.apiService.uploadProductImage(i.fileFormData).subscribe(
          (apiData2: any) => {
            console.log(apiData2);
            percentageValue = percentageAdd + percentageValue;
            this.moveProgressBar(percentageValue);
            const imageIndex = this.data.media.findIndex((image: any) => i.position == image.position);
            if (imageIndex != -1) {
              this.data.media[imageIndex].imagePath = apiData2.path;
              this.data.media[imageIndex].uploaded = true;
              this.data.media[imageIndex].isVideo = apiData2.isVideo;
            }
          }
        )
      } else if (i.thumbnailFormData) {
        this.apiService.uploadProductImage(i.thumbnailFormData).subscribe((thumbnailRes: any) => {
          console.log("thumbnailRes  ", thumbnailRes);
          this.moveProgressBar(percentageValue);
          const imageIndex = this.data.media.findIndex((image: any) => i.position == image.position);
          if (imageIndex != -1) {
            this.data.media[imageIndex].thumbnail = thumbnailRes.path;
            this.data.media[imageIndex].uploaded = true;
          }
        });

      }
    })

    if (imagesToUpload.length > 0) {
      this.checkInterval = setInterval(() => {
        const uploaded = this.data.media.filter((image: any) => {
          return image.uploaded;
        }).length;

        if (uploaded == imagesToUpload.length) {
          this.updatemedia();
        }
      }, 1000);
    } else {
      this.updatemedia();
    }

  }


  updatemedia() {
    this.moveProgressBar(50);
    this.apiService.updateProductDetailImages(this.data).subscribe(
      (apiData: any) => {
        this.moveProgressBar(100);
        this.loading = false;
        this.dialogRef.close(apiData);
        clearInterval(this.checkInterval);
      }
    ), (err) => {
      this.snackBar.open(this.translate.instant('thereWasAnError'), 'OK', {duration: 3000});
    }
  }

}
