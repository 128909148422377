import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material";
import {MatDialogRef} from "@angular/material/dialog";

import {DialogData} from "../supply-history/supply-history.component";
import {ApiService} from "../api.service";
import {DataService} from "../data-service.service";

@Component({
  selector: 'app-machine-delete',
  templateUrl: './machine-delete.component.html',
  styleUrls: ['./machine-delete.component.css']
})
export class MachineDeleteComponent implements OnInit {

  constructor(private dataService: DataService, public apiService: ApiService, @Inject(MAT_DIALOG_DATA) public data: DialogData, private dialogRef: MatDialogRef<MachineDeleteComponent>) { }

  ngOnInit() {
    console.log(this.data);
  }

  save() {
    this.apiService.deleteRepair(this.data['data'].id).subscribe(
      data => {
        this.dialogRef.close();
        this.dataService.removedRepair.next(this.data['data']);
      }
    )
  }

}
