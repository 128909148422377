
// deepCopies arrays and objects | or any combination of those
// tmpObj serves for imutability purpose
export const deepCopy = (object: Object) => {
  const tmpUtil = Array.isArray(object);
  const tmpObj: any = tmpUtil && [] || {};
  // tslint:disable-next-line: forin
  for(const prop in object) {
    tmpObj[prop] = object[prop];
    typeof object[prop] === 'object' && ( tmpObj[prop] = deepCopy(object[prop]) );
  }
  return tmpUtil && [...tmpObj] || {...tmpObj}
}

/**
 * Summary.
 * Traverses param object and copies its properties with values to the subject..
 *
 * Description.
 * Traverses param object and copies its properties with values to the subject.
 * Function must be called with .call or .apply method with the provided subject as param.
 *
 * @obj object that will be traversed.
 */
export function setCorespondingProperties(obj: Object) {
  const subject = this;
  // tslint:disable-next-line: forin
  for(const prop in obj) {
    subject[prop] = obj[prop];
  }
}
