import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material";
import {MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";

import {ApiService} from "../api.service";
import {DialogData} from "../supply-history/supply-history.component";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-email-modal',
  templateUrl: './email-modal.component.html',
  styleUrls: ['./email-modal.component.css']
})
export class EmailModalComponent implements OnInit {

  constructor(private dialog: MatDialogRef<EmailModalComponent>, private apiService: ApiService, @Inject(MAT_DIALOG_DATA) public data: DialogData,  private SnackBar: MatSnackBar,private translate: TranslateService ) { }

  ngOnInit() {
  }

  confirm() {
    const title = this.data['notif']['title'];
    const body = this.data['notif']['body'];
    const users = this.data['users'];

    this.apiService.sendInfoMail(users, title, body).subscribe( data => {
      this.dialog.close();
      this.SnackBar.open(this.translate.instant("emailSent"), 'OK',{duration : 3000});
    },
      err => {
      console.log(err);
        this.dialog.close();
        this.SnackBar.open(this.translate.instant("thereWasAnError"), 'OK',{duration : 3000});
      })
  }

}
