import {Component, Input, OnInit} from '@angular/core';
import {Observable} from "rxjs/Observable";
import {ApiService} from "../../../api.service";
import {TranslateService} from "@ngx-translate/core";
import {DatePipe} from "@angular/common";
import {LoginData} from "../../../models";

@Component({
  selector: 'app-hourly',
  templateUrl: './hourly.component.html',
  styleUrls: ['./hourly.component.css']
})
export class HourlyComponent implements OnInit {
  @Input() start;
  @Input() end;
  @Input() datesChanged: Observable<void>;
  @Input() refreshData: Observable<void>;

  loading = false;
  labels = [];
  data = [{data: [0], label: 'Login'}, {data: [0], label: 'Unique'}, {data: [0], label: 'Orders'}];
  numbers = [];
  total = '';
  products = [];
  totalPrice = null;
  options = {
    height: 300,
    legend: {
      position: 'top',
    }
  };

  constructor(private service: ApiService, public translate: TranslateService, private datePipe: DatePipe) {
  }

  ngOnInit() {
    this.getStatistics();
    this.datesChanged.subscribe((data: any) => {
      this.start = data.start;
      this.end = data.end;
      this.getStatistics();
    });
  }

  getStatistics() {
    this.loading = true;
    const start = this.datePipe.transform(this.start, 'yyyy-MM-dd');
    const end = this.datePipe.transform(this.end, 'yyyy-MM-dd');
    this.service.getHourlyStatistic(start, end).subscribe((apiData: any) => {
      this.labels = [];
      this.data = [];
      const dailyData = [];
      const uniqueData = [];
      const ordersData = [];
      const daily: LoginData[] = apiData['allLogins'];
      daily.forEach((item: LoginData) => {
        dailyData.push(item.count);
        this.labels.push(item.hour + 1 + 'h'); //add 1 hour bc of UTC
      });
      const unique: LoginData[] = apiData['uniqueLogins'];
      unique.forEach((item: LoginData) => {
        uniqueData.push(item.count);
      });

      const orders: LoginData[] = apiData['orders'];
      orders.forEach((item: LoginData) => {
        ordersData.push(item.count);
      });

      this.data.push(
        {data: dailyData, label: this.translate.instant('allLogin')},
        {data: uniqueData, label: this.translate.instant('uniqueLogin')},
        {data: ordersData, label: this.translate.instant('orders')}
      );

      console.log("LINEDATA:  ",this.data);
      console.log("LINELABELS:  ",this.labels);

      this.loading = false;
    })
  }


}
