import {Component, Input, OnInit} from '@angular/core';
import {Observable} from "rxjs/Observable";
import {ApiService} from "../../../api.service";
import {TranslateService} from "@ngx-translate/core";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-registration-steps',
  templateUrl: './registration-steps.component.html',
  styleUrls: ['./registration-steps.component.css']
})
export class RegistrationStepsComponent implements OnInit {
  @Input() start;
  @Input() end;
  @Input() datesChanged: Observable<void>;
  loading = false;
  data = [];
  labels = [];
  total = 0;
  colors = [
    {backgroundColor: ["#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF"]}
  ];
  public barChartOptions = {
    barThickness: 2
  };
  height = 200;
  constructor(private service: ApiService, private translate: TranslateService, private datePipe: DatePipe) { }

  ngOnInit() {
    if (window.innerWidth <= 900) {
      this.height = 100;
    }
    window.onresize = (e) => {
      if (window.innerWidth <= 900) {
        this.height = 100;
      }
    }
    this.getStatistics();

    this.datesChanged.subscribe((data: any) => {
      if (data) {
        this.start = data.start;
        this.end = data.end;
        this.getStatistics();
      }
    });
  }

  getStatistics() {
    this.loading = true;
    const start = this.datePipe.transform(this.start, 'yyyy-MM-dd');
    const end = this.datePipe.transform(this.end, 'yyyy-MM-dd');
    this.service.getRegistrationSteps(start, end).subscribe((apiData: any) => {
      this.labels = apiData.labels.map(l => this.translate.instant(l));
      this.data = [{data: apiData.data,  barPercentage: 0.5}];
      this.total = apiData.total;
      this.loading = false;
    })
  }


}
