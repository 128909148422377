import {Component, OnInit} from '@angular/core';
import {ApiService} from "../../api.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {CampaignPopupComponent} from "../../users/campaign-popup/campaign-popup.component";
import {ConfirmModalComponent} from "../../confirm-modal/confirm-modal.component";
import {SurveyPopupComponent} from "../survey-popup/survey-popup.component";
import {Colors} from "../../Colors";
import {Params, Router} from "@angular/router";

@Component({
  selector: 'app-surveys',
  templateUrl: './surveys.component.html',
  styleUrls: ['./surveys.component.css']
})
export class SurveysComponent implements OnInit {
  showHeader = false;
  searchText = '';
  surveys = [];
  colors = Colors;

  constructor(private service: ApiService,
              private snackBar: MatSnackBar,
              private translate: TranslateService,
              private dialog: MatDialog,
              private router: Router) {
  }

  ngOnInit() {
    this.getSurveys();
  }

  getSurveys() {
    let text = this.searchText;
    if (text == '') {
      text = '*';
    }
    this.service.getSurveys(text).subscribe((data: any[]) => {
      this.surveys = data;
      this.surveys.map((survey) => {
        survey.status = this.getStatus(survey);
        survey.questions.forEach((q) => {
          q.type = Number(q.type);
        })
        return survey;
      })
      console.log(this.surveys);
    }, (err) => {
      this.snackBar.open(this.translate.instant('thereWasAnError'), '', {duration: 2000});
    })
  }


  getTranslation(value) {
    return this.translate.instant(value);
  }

  openPopup(survey, mode) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'no-info-dialog';
    dialogConfig.data = {survey, mode};
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    const dialogOpen = this.dialog.open(SurveyPopupComponent, dialogConfig);

    dialogOpen.afterClosed().subscribe((result: any) => {
      if (result) {
        result.status = this.getStatus(result);
        if (result.questions) {
          result.questions.forEach((q) => {
            q.type = Number(q.type);
          })
        }
        const index = this.surveys.findIndex(s => s.id == result.id);
        console.log("index  ", index);
        if (index == -1) {
          this.surveys.unshift(result);
        } else {
          this.surveys[index] = result;
        }

        this.surveys = [...this.surveys];
      }
    });
  }

  deleteSurvey(survey) {
    const openDeleteDialog = this.dialog.open(ConfirmModalComponent, {
      disableClose: true,
      width: '400px',
      height: '200px',
      position: {
        top: '150px',
      },
      data: {
        data: '',
        message: this.translate.instant('areYouSureYouWantToDeleteSurvey'),
        mode: 'other',
      },
    });
    openDeleteDialog.afterClosed().subscribe((response) => {
      if (response) {
        this.service.deleteSurvey(survey.id).subscribe((data) => {
          this.snackBar.open(this.translate.instant('deleted'), '', {duration: 2000});
          const index = this.surveys.findIndex(c => c.id == survey.id);
          if (index != -1) {
            this.surveys.splice(index, 1);
            this.surveys = [...this.surveys];
          }
        }), (err) => {
          this.snackBar.open(this.translate.instant('thereWasAnError'), '', {duration: 2000});

        }
      }
    });
  }

  getStatus(survey) {
    const startDate = new Date(survey.start);
    const endDate = new Date(survey.end);
    const now = new Date();

    if (endDate < now) {
      return 2;
    } else if (startDate <= now) {
      return 1;
    } else {
      return 0;
    }
  }

  showSurvey(survey) {
    const sliderMin = 0;
    const sliderMax = 50;
    const filter = {
      active: false,
      inactive: false,
      loyal: false,
      ordersCount: [sliderMin, sliderMax],
      averageSpent: [sliderMin, sliderMax],
      totalSpent: [sliderMin, sliderMax],
      promoPercentage: [sliderMin, sliderMax],
      dateOfBirth: [1920, new Date().getFullYear()],
      surveys: [survey.id]
    };

    const queryParams: Params = {filter: JSON.stringify(filter)};

    this.router.navigate(['main/users'], {
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

}
