import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {OrderList} from "../models";
import {Router} from "@angular/router";
import {Location} from "@angular/common";

@Component({
    selector: 'app-print-orders',
    templateUrl: './print-orders.component.html',
    styleUrls: ['./print-orders.component.css'],
})
export class PrintOrdersComponent implements OnInit, AfterViewInit, OnDestroy {

    orders: OrderList[];
    printableElements: HTMLElement[];
    constructor(private router: Router,
                private location: Location,
                private elem: ElementRef) { }

    ngOnInit() {
        this.orders = JSON.parse(localStorage.getItem('printOrders'));
        console.log(this.orders);
        if(!this.orders) {
            this.router.navigate(['main'])
        }
        // if (!localStorage.getItem('print')) {
        //     localStorage.setItem('print', JSON.stringify(this.dataService.getOrderList()));
        // } else {
        //     this.orders = JSON.parse(localStorage.getItem('print'));
        // }
    }

    ngAfterViewInit(){
        // you'll get your through 'elements' below code
        this.printableElements = this.elem.nativeElement.querySelectorAll('.slip');
    }
    goBack() {
        this.location.back();
    }

    onPrint(){
        window.print();
    }

    onPrintOne(id) {
        for (const element  of this.printableElements) {
            if (element.dataset.receiptId != id) {
                element.classList.add('not-printable');
            }
        }
        window.print();
        for (const element  of this.printableElements) {
            element.classList.remove('not-printable');
        }

    }

    ngOnDestroy(): void {
        localStorage.removeItem('printOrders');
    }


}
