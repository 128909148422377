<div class="header" style="background-color: #d92a73; z-index: 5 !important; position: relative; padding-bottom: 18px"
     [ngClass]="{'hiddenHeader' : !showHeader}">
  <span
          style="font-size:1.7em; color:white; padding-left: 20px; font-weight: 500; margin-bottom:10px;"> {{'survey' | translate}} </span>
</div>
<div class="survey-main-container">
        <div class="survey-main-box">
            <div class="surveys-user-data" >
                <div class="survey-total" *ngIf="savedFilter">
                    {{savedFilter.name}}
                </div>
                <div class="survey-detail">
                    {{'total' | translate}}: {{totalUsers}}
                </div>
            </div>

            <app-survey-form isNew="1" (surveyChange)="surveySent()"  [filter]="filter"></app-survey-form>
        </div>
</div>
