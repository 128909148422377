<div>
  <h3 mat-dialog-title>
    Dodaj ID-eve albuma
  </h3>
  <mat-dialog-content>
    <div style="width: 600px; padding: 16px">
       <textarea style="border: 2px; border-color: #1b233f" matInput [(ngModel)]="ids"></textarea>

    </div>

    <br>
    <div>
      <span>Razlika: {{diff}}</span>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="col">
      <button mat-raised-button (click)="onCheck($event)">Proveri</button>
    </div>
    <div class="col" style="text-align: right">
      <button mat-raised-button color=primary (click)="onConfirm($event)" style="right: 0">OK</button>
    </div>
  </mat-dialog-actions>
</div>
