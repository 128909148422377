import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Country, Product, UpsellingAction} from "../../models";
import {countries} from "../../Countries";
import {Colors} from "../../Colors";
import {ApiService} from "../../api.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-upselling-form',
  templateUrl: './upselling-form.component.html',
  styleUrls: ['./upselling-form.component.css']
})
export class UpsellingFormComponent implements OnInit {

  @Input() upsellingActionFromParent: UpsellingAction;
  @Input() products: Product[];
  @Output() sendUpsellingAction = new EventEmitter<UpsellingAction>();
  @Input() set gotHighestPriority(priority: number) {
    this.updateHighestPriority(priority);
  };

  initialUpsellingAction: UpsellingAction = {
    name: '',
    country: '',
    from: '',
    to: '',
    products: [{discount: 0, product: {id: -1, name: ''}}],
    targetProduct: {id: -1, name: ''},
    id: -1,
    priority: 1
  }
  upsellingAction: UpsellingAction;
  countries: Country[] = countries;
  colors = Colors;
  priorities = Array.from({length: 10}, (_, i) => i + 1);


  constructor(private apiService: ApiService, private snackBar: MatSnackBar, private translate: TranslateService) {
  }

  ngOnInit() {
    if (this.upsellingActionFromParent) {
      this.upsellingAction = this.upsellingActionFromParent;
    } else {
      this.upsellingAction = this.initialUpsellingAction;
    }

    if (this.upsellingAction.id != -1) { // its update, not create
      this.countries = this.countries.filter(c => c.value !== 'all');
    }
  }

  addProduct() {
    this.upsellingAction.products.push({discount: 0, product: {id: -1, name: ''}});
  }

  deleteProduct(index) {
    this.upsellingAction.products.splice(index, 1);
  }

  updateHighestPriority(priority: number) {
    if (priority && this.upsellingAction && this.upsellingAction.id == -1 ) {
      this.upsellingAction.priority = priority;
    }
  }

  checkIfUpsellingActionHasData() {
    let hasAllData = true;
    this.upsellingAction.products.forEach((product) => {
      if (product.product.id == -1) {
        this.snackBar.open(this.translate.instant("checkUpsellingProduct"), "", {duration: 1000});
        hasAllData = false;
      }
    });

    if (this.upsellingAction.name == "" || this.upsellingAction.from == "" || this.upsellingAction.to == "" || this.upsellingAction.targetProduct.id == -1) {
      this.snackBar.open(this.translate.instant("completeAllFields"), "", {duration: 1000});
      hasAllData = false;
    }

    return hasAllData;

  }

  save() {

    if (!this.checkIfUpsellingActionHasData()) {
      return;
    }
    this.upsellingAction.id == -1 ? this.createNewUpselling() : this.updateUpselling();
  }

  createNewUpselling() {
    if (this.upsellingAction.country == 'all') {
      const starterUpsellingAction = JSON.stringify(this.upsellingAction);
      this.countries.forEach(c => {
        if (c.value !== 'all') {
          const newUpsellingAction = JSON.parse(starterUpsellingAction);
          newUpsellingAction.country = c.value;
          newUpsellingAction.name = newUpsellingAction.name + " " + c.value.toUpperCase();
          this.apiService.createUpsellingAction(newUpsellingAction).subscribe((action: UpsellingAction) => {
            this.sendUpsellingAction.emit(action);
          })
          this.snackBar.open(this.translate.instant("saved"), "", {duration: 1000});
          this.upsellingAction = this.initialUpsellingAction;
        }
      });
    } else {
      this.apiService.createUpsellingAction(this.upsellingAction).subscribe((action: UpsellingAction) => {
        this.snackBar.open(this.translate.instant("saved"), "", {duration: 1000});
        this.upsellingAction = this.initialUpsellingAction;
        this.sendUpsellingAction.emit(action);
      })
    }
  }

  updateUpselling() {
    this.apiService.updateUpsellingAction(this.upsellingAction).subscribe((action: UpsellingAction) => {
      console.log(action);
      this.snackBar.open(this.translate.instant("saved"), "", {duration: 1000});
      this.upsellingAction = this.initialUpsellingAction;
      this.sendUpsellingAction.emit(action);
    })
  }

}
