<div class="content" style="height: 100%; overflow: hidden !important;">
  <div class="dialog-wrapper">
    <span style="color:#424242; font-size: 2em; position:relative; font-weight: bold;"> {{data.name}} </span>
    <div class="row">
      <div class="main-picture col-6">
        <span class="mini-title">{{'mainPicture' | translate}}</span>
        <img class="main-picture-image" [src]="data.image">
        <div class="for-buttons">
          <input type='file' id="upload-file" (change)="onSelectFile($event.target.files)" style="display: none;">
          <button mat-raised-button color="primary" (click)="uploadNewImage()">{{'uploadNewImage' | translate}}</button>
          <button mat-raised-button color="primary" (click)="selectOldImage()">{{'selectOldImage' | translate}}</button>
        </div>

      <div *ngIf="data.mainImageChanged" class="bottom-actions">
        <mat-divider></mat-divider>
        <div class="for-save-button">
          <button mat-raised-button color="accent" (click)="save()">{{'save' | translate}}</button>
        </div>
      </div>

      </div>
      <div class="other-picture col-6">
        <span class="mini-title">{{'carouselPicture' | translate}}</span>

        <div class="for-detail-images">
          <div class="before-box" *ngFor="let image of data.media; let i = index">
            <div class="detail-image-box" *ngIf="selectedIndex == i">
              <div class="arrow" [ngClass]="{'visible-arrow': selectedIndex != 0}"
                   (click)="selectedIndex = selectedIndex - 1">
                <mat-icon>keyboard_arrow_left</mat-icon>
              </div>
              <div class="for-detail-image">
                <img class="detail-image-image" *ngIf="image.isVideo" src="/assets/video.png">
                <img class="detail-image-image" *ngIf="image.isVideo !== true" [src]="image.media">
              </div>
              <div class="detail-image-actions">
                <button mat-mini-fab class="detail-image-action" color="accent" (click)="editDetailImage(image)">
                  <mat-icon>edit</mat-icon>
                </button>
                <button mat-mini-fab class="detail-image-action" color="warn" (click)="deleteDetailImage(image)">
                  <mat-icon>delete</mat-icon>
                </button>

              </div>
              <div class="arrow" [ngClass]="{'visible-arrow': selectedIndex != data.media.length - 1}" *ngIf="selectedIndex != data.media.length - 1"
                   (click)="selectedIndex = selectedIndex + 1">
                <mat-icon>keyboard_arrow_right</mat-icon>
              </div>

              <div class="arrow" [ngClass]="{'visible-arrow': selectedIndex == data.media.length - 1 }"
                   (click)="addDetailImage()" title="{{'addImage' | translate}}">
                <mat-icon>add</mat-icon>
              </div>
              <!-- this div will always be invisible, it's here just for display purposes      -->
              <div class="arrow" *ngIf="selectedIndex == data.media.length - 1"
                   (click)="selectedIndex = selectedIndex + 1">
                <mat-icon>keyboard_arrow_right</mat-icon>
              </div>
            </div>
          </div>
          <input type='file' id="upload-file-2" (change)="onSelectFile2($event.target.files)" style="display: none;">

          <div class="arrow" [ngClass]="{'visible-arrow': data.media.length === 0 }"
               (click)="addDetailImage()" title="{{'addImage' | translate}}">
            <mat-icon>add</mat-icon>
          </div>


        </div>

        <div *ngIf="detailsChanged" class="bottom-actions">
          <mat-divider></mat-divider>
          <div class="for-save-button">
            <button mat-raised-button color="accent" (click)="saveDetails()">{{'save' | translate}}</button>
          </div>
        </div>

      </div>
      <div class="col-12" style="margin-top: 20px" *ngIf="loading">
        <mat-progress-bar id="progress-bar" mode="determinate" [value]="progressBarValue"></mat-progress-bar>
      </div>
    </div>
  </div>

</div>
