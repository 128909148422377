<section class="add-new-cover">
  <div>
    <h2 class="margin-title" *ngIf="data.type === 1">{{'createCover' | translate}}</h2>
    <h2 class="margin-title" *ngIf="data.type === 2">{{'editCover' | translate}}</h2>

  </div>
  <div class="images-box">
    <article class="picture-container">
      <h4>{{'frontPic' | translate}}</h4>
      <div class="image-wrapper">
        <div class="image">
          <input type="file" name="file" id="front-image" class="input-file"
                 (change)="readImageURL($event, 'front');" accept="image/*"/>
          <img class="image-preview" [src]="frontPic" alt="your image" *ngIf="frontPic"/>
          <div class="upload-text-holder">
            <span class="update-text">{{'choosePic' | translate}}</span>
          </div>
        </div>
      </div>
    </article>
    <article class="picture-container">
      <h4>{{'backPic' | translate}}</h4>
      <div class="image-wrapper">
        <div class="image">
          <input type="file" name="file" id="back-image" class="input-file" (change)="readImageURL($event, 'back');"
                 accept="image/*"/>
          <img class="image-preview" [src]="backPic" alt="your image" *ngIf="backPic"/>
          <div class="upload-text-holder">
            <span class="update-text">{{'choosePic' | translate}}</span>
          </div>
        </div>
      </div>
    </article>
    <article *ngIf="data.categoryType === 6 || data.categoryType === 7" class="picture-container">
      <h4>{{'firstPage' | translate}}</h4>
      <div class="image-wrapper">
        <div class="image">
          <input type="file" name="file" id="first-page" class="input-file"
                 (change)="readImageURL($event, 'first');" accept="image/*"/>
          <img class="image-preview" [src]="firstPageImage" alt="your image" *ngIf="firstPageImage"/>
          <div class="upload-text-holder">
            <span class="update-text">{{'choosePic' | translate}}</span>
          </div>
        </div>
      </div>
    </article>
    <article *ngIf="data.categoryType === 6 || data.categoryType === 7" class="picture-container">
      <h4>{{'lastPage' | translate}}</h4>
      <div class="image-wrapper">
        <div class="image">
          <input type="file" name="file" id="last-page" class="input-file" (change)="readImageURL($event, 'last');"
                 accept="image/*"/>
          <img class="image-preview" [src]="lastPageImage" alt="your image" *ngIf="lastPageImage"/>
          <div class="upload-text-holder">
            <span class="update-text">{{'choosePic' | translate}}</span>
          </div>
        </div>
      </div>
    </article>
  </div>
  <mat-form-field>
    <mat-label>{{"amount" | translate}}</mat-label>
    <input matInput type="text" [(ngModel)]="amountValue">
  </mat-form-field>
  <mat-form-field class="details-box__user_field" *ngIf="data.type == 2">
    <mat-label>{{"selectCategory" | translate}}</mat-label>
    <mat-select [(value)]="data.cover.category">
      <mat-option *ngFor="let category of data.categories" [value]="category.id">{{category.name}}</mat-option>
    </mat-select>
  </mat-form-field>
  <div class="buttons-container">
    <button mat-raised-button color="primary" style="width:60%; margin: 10px 0 0 0;" (click)="saveCover()">
      {{'save' | translate}}
    </button>
  </div>
</section>
