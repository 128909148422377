import {Component, OnInit} from '@angular/core';
import {Product, UpsellingAction} from "../models";
import {ApiService} from "../api.service";
import {ConfirmModalComponent} from "../confirm-modal/confirm-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {UpsellingModalComponent} from "./upselling-modal/upselling-modal.component";
import {DataService} from "../data-service.service";

@Component({
    selector: 'app-upselling',
    templateUrl: './upselling.component.html',
    styleUrls: ['./upselling.component.css']
})
export class UpsellingComponent implements OnInit {

    upsellingActions: UpsellingAction[] = [];
    page = 0;
    products: Product[];
    actionsLoading = true;
    highestPriority = 1;
    stopLoadingActions = false;

    constructor(private apiService: ApiService, private dialog: MatDialog, private dataService: DataService) {
    }

    ngOnInit() {
        this.getUpsellingActions(true);
        this.getProducts();

        this.dataService.country.subscribe((country) => {
              this.actionsLoading = true;
              this.page = 0;
              this.upsellingActions = [];
              this.getUpsellingActions(true);
        });
    }



    pushProductToProducts(product: Product) {
        if (product.children && product.children.length > 0) {
            if (product.active == 1) {
                product.children.forEach(sub => sub.active == 1 ? this.pushProductToProducts(sub) : null);
            }
        } else {
            product.active == 1 ? this.products.push(product) : null;
        }
    }


    getProducts(): void {
        this.apiService.getProducts({isActive: 1}).subscribe((res: Product[]) => {
            this.products = [];
            res.forEach(product => this.pushProductToProducts(product));
        });
    }

    getUpsellingActions(freshStart = false): void {
        this.apiService.getUpsellingActions(this.page).subscribe((actions: UpsellingAction[]) => {
            freshStart ? this.upsellingActions = [] : null;
            actions.length === 0 ? this.stopLoadingActions = true : null;
            this.upsellingActions = [...this.upsellingActions, ...actions];
            this.findHighestPriority();
            this.getAdditionalData();
        })
    }

    getAdditionalData(): void {
        this.upsellingActions.forEach(upsellingAction => {
            upsellingAction = this.getAdditionalInfoForAction(upsellingAction);
        })
        this.actionsLoading = false;
    }

    getAdditionalInfoForAction(upsellingAction: UpsellingAction, overwrite: boolean = false): UpsellingAction {
        if (!upsellingAction.productsString || overwrite) {
            upsellingAction.productsString = upsellingAction.products.map(product => {
                return product.product.name + " " + product.discount + "%";
            }).join("<br>");
        }
        upsellingAction.toDate = new Date(upsellingAction.to).toLocaleDateString();
        upsellingAction.fromDate = new Date(upsellingAction.from).toLocaleDateString();
        return upsellingAction;
    }

    editUpselling(upsellingAction: UpsellingAction, index: number): void {
        const openDialog = this.dialog.open(UpsellingModalComponent, {
            disableClose: true,
            panelClass: 'upselling-modal-class',
            width: '445px',
            position: {
                top: '150px',
            },
            data: {
                upsellingAction: upsellingAction,
                mode: 'edit',
                products: this.products
            },
        });
        openDialog.afterClosed().subscribe((editedAction: UpsellingAction) => {
            if (editedAction) {
                upsellingAction = this.getAdditionalInfoForAction(editedAction, true);
                this.upsellingActions[index] = upsellingAction;
                this.upsellingActions = [...this.upsellingActions];
            }
        });
    }

    deleteUpselling(upsellingAction: UpsellingAction, index: number): void {
        const openDialog = this.dialog.open(UpsellingModalComponent, {
            disableClose: true,
            panelClass: 'upselling-modal-class',
            width: '445px',
            position: {
                top: '150px',
            },
            data: {
                upsellingAction: upsellingAction,
                mode: 'delete',
                products: this.products
            },
        });
        openDialog.afterClosed().subscribe((toDelete?: boolean) => {
            if (toDelete) {
                this.apiService.deleteUpsellingAction(upsellingAction).subscribe((data: any) => {
                    this.upsellingActions.splice(index, 1);
                    this.upsellingActions = [...this.upsellingActions];
                    this.findHighestPriority();

                });
            }
        });
    }

    duplicateUpselling(upsellingAction: UpsellingAction): void {
        const openDialog = this.dialog.open(UpsellingModalComponent, {
            disableClose: true,
            panelClass: 'upselling-modal-class',
            width: '445px',
            position: {
                top: '150px',
            },
            data: {
                upsellingAction: upsellingAction,
                mode: 'duplicate',
                products: this.products
            },
        });
        openDialog.afterClosed().subscribe((duplicatedAction: UpsellingAction) => {
            if (duplicatedAction) {
                upsellingAction = this.getAdditionalInfoForAction(duplicatedAction, true);
                this.upsellingActions.push(upsellingAction)
                this.upsellingActions.sort((a, b) => a.priority - b.priority);
                this.upsellingActions = [...this.upsellingActions];
                this.findHighestPriority();
            }
        });
    }

    newActionCreated(upsellingAction: UpsellingAction) {
        upsellingAction = this.getAdditionalInfoForAction(upsellingAction, true);
        this.upsellingActions.push(upsellingAction)
        this.upsellingActions.sort((a, b) => a.priority - b.priority);
        this.upsellingActions = [...this.upsellingActions];
        this.findHighestPriority();
    }

    findHighestPriority() {
        const arrayForSort = JSON.stringify(this.upsellingActions);
        const sortedActions = JSON.parse(arrayForSort).sort((a, b) => b.priority - a.priority);
        if (sortedActions[0]) {
            this.highestPriority = sortedActions[0].priority + 1;
        }
    }

    scrolled(event) {
        if (event.offsetY > 160 * this.page) {
            this.page += 1;
            !this.stopLoadingActions ? this.getUpsellingActions() : null;
        }
    }

}
