<div class="header" style="background-color: #d92a73">
    <span style="font-size:1.7em; color:white; padding-left: 20px; font-weight: 500; ">
      {{'finance' | translate}}
    </span>
</div>
<mat-card class="col-md-10 offset-md-1 col-12">

  <mat-tab-group>
    <mat-tab label="{{'earnings' | translate}}" style="overflow:hidden !important;">
      <div class="row" style="margin-top: 10px; margin-left: 0px !important; margin-right: 0px !important;">
        <div class="col-12 col-xl-2 offset-xl-8">
          <mat-form-field style="float: left; width: 100%">
            <input #fromInput matInput [matDatepicker]="pickerFrom" placeholder="Od"
                   (dateChange)="onDateChanged($event.value)" [(ngModel)]="fromD">
            <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
            <mat-datepicker #pickerFrom></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-12 col-xl-2">
          <mat-form-field style="float: left; width: 100%">
            <input #toInput matInput [matDatepicker]="pickerTo" placeholder="Do"
                   (dateChange)="onDateChanged($event.value)" [(ngModel)]="toD">
            <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
            <mat-datepicker #pickerTo></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="row" style="margin-top: 20px;">
        <div class="col-auto align-self-center">
        </div>
        <div *ngIf="profits && info" class="row">
          <div class="col-md-auto margin-button">
            <span class="roundSpan">Profit: {{profitTotal| currency:'EUR'}}</span>
          </div>
          <div class="col-md-auto margin-button">
            <span class="roundSpan">Prihod: {{incomeTotal| currency:'EUR'}}</span>
          </div>
          <div class="col-md-auto margin-button">
            <span class="roundSpan">Troškovi: {{costTotal| currency: 'EUR'}}</span>
          </div>
        </div>
      </div>
      <div class=" baseWrapper" *ngIf="profits.length > 0">
        <div class="col-12">
          <canvas baseChart height="400"
                  [datasets]="profits"
                  [labels]="profitsLabel"
                  [options]="profitOptions"
                  [legend]="true"
                  [chartType]="'line'"></canvas>
        </div>
      </div>

        <div class="row" style="margin-top: 20px;">
          <div class="col-auto align-self-center">
          </div>
          <div *ngIf="sentProfits && info" class="row">

            <div class="col-md-auto margin-button">
              <span class="roundSpan">{{'sent' | translate}}: {{sentIncomeTotal| currency:'EUR'}}</span>
            </div>

          </div>

        </div>
      <div class=" baseWrapper" *ngIf="sentProfits.length > 0">
        <div class="col-12">
          <canvas baseChart height="400"
                  [datasets]="sentProfits"
                  [labels]="sentProfitsLabel"
                  [options]="profitOptions"
                  [legend]="true"
                  [chartType]="'line'"></canvas>
        </div>
      </div>

    </mat-tab>
    <mat-tab label="{{'costing' | translate}}">


      <mat-card class="col-12">


        <div class="row d-flex justify-content-start align-items-center ">
          <div class="col-xl-4">
            <mat-form-field style="float: left; width: 100%;">
              <input matInput [matDatepicker]="picker" placeholder="{{'from' | translate}}" (dateChange)="onFromChanged($event)">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-xl-4">
            <mat-form-field style="float: left; width: 100%;">
              <input matInput [matDatepicker]="pickerTo2" placeholder="{{'to' | translate}}" (dateChange)="onToChanged($event)">
              <mat-datepicker-toggle matSuffix [for]="pickerTo2"></mat-datepicker-toggle>
              <mat-datepicker #pickerTo2></mat-datepicker>
            </mat-form-field>
          </div>


          <div class="col-xl-4">
            <mat-form-field style="float: left; width: 100%">
              <input matInput placeholder="{{'cost' | translate}}" type="number" [(ngModel)]="cost.cost">
            </mat-form-field>
          </div>


          <div class="col-xl-4">
            <mat-form-field style="float: left; width: 100%">
              <input matInput placeholder="{{'description' | translate}}" [(ngModel)]="cost.desc">
            </mat-form-field>
          </div>

          <div class="col-xl-4">
            <mat-form-field style="width: 100%;">
              <mat-select placeholder="{{'type' | translate}}" (selectionChange)="cost.type = $event.value">
                <mat-option *ngFor="let s of types" [value]="s.value">
                  {{ s.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>


          <div class="col-xl-4">
              <mat-checkbox [(ngModel)]="cost.repeat"></mat-checkbox>
              {{'repeat' | translate}}
            </div>
            <div class="col-sm-4 col-xs-12">
              <mat-select [(ngModel)]="cost.repeat_every" [disabled]="!cost.repeat"
                          placeholder="{{'repeat' | translate}}">
                <mat-option [value]=1> {{'everyMonth' | translate}}</mat-option>
                <mat-option [value]=2> {{'every10days' | translate}}</mat-option>
                <mat-option [value]=3> {{'every15days' | translate}}</mat-option>
                <mat-option [value]=4> {{'every3months' | translate}}</mat-option>
                <mat-option [value]=5> {{'every6months' | translate}}</mat-option>
                <mat-option [value]=6> {{'everyYear' | translate}}</mat-option>
              </mat-select>
            </div>
          </div>
          <div class="button-wrapper">
            <div class="col-xl-3 addButton">
              <app-button text="{{'save' | translate}}" [color]="colors.PINK" [textColor]="colors.WHITE" height="48px" width="205px" (click)="addCost()">
              </app-button>
            </div>
          </div>

      </mat-card>

      <br>

      <ngx-datatable
              class="material expandable"
              style="height: 93vh; overflow-y: scroll;"
              [rows]="costList"
              [columnMode]="'force'"
              [headerHeight]="50"
              [footerHeight]="75"
              [scrollbarH]="true"
              [rowHeight]="'auto'"
              [messages]="messages"
              [reorderable]="true"
              [externalSorting]="true">

<!--        <ngx-datatable-column sortable="false" prop="uuid" [width]="50">-->
<!--          <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>-->
<!--            <span>{{value}}</span>-->
<!--          </ng-template>-->
<!--        </ngx-datatable-column>-->

        <ngx-datatable-column name="{{'cost' | translate}}" sortable="false" prop="cost" [width]="140">
          <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
            <span>{{value}} EUR</span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="{{'type' | translate}}" sortable="false" prop="type" [width]="80">
          <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
            <span>{{getCostType(value)}}</span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="{{'description' | translate}}" sortable="false" prop="desc" [width]="200">
          <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
            <span>{{value}}</span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="{{'from' | translate}}"  sortable="false" prop="from" [width]="100">
          <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
            <span>{{value | date}}</span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="{{'to' | translate}}"  sortable="false" prop="to" [width]="100">
          <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
            <span>{{value | date}}</span>
          </ng-template>
        </ngx-datatable-column>


        <ngx-datatable-column name="{{'repeat' | translate}}" sortable="false" prop="repeat_every" [width]="60">
          <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
            <span *ngIf="value == 1"> {{'everyMonth' | translate}}</span>
            <span *ngIf="value == 2"> {{'every10days' | translate}}</span>
            <span *ngIf="value == 3"> {{'every15days' | translate}}</span>
            <span *ngIf="value == 4"> {{'every3months' | translate}}</span>
            <span *ngIf="value == 5"> {{'every6months' | translate}}</span>
            <span *ngIf="value == 6"> {{'everyYear' | translate}}</span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="" sortable="false" prop="uuid" [width]="60">
          <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
            <img src="assets/ic_trash.svg" (click)="onDeleteClick(costList[rowIndex].uuid)">
          </ng-template>
        </ngx-datatable-column>

      </ngx-datatable>
    </mat-tab>

  </mat-tab-group>


</mat-card>
