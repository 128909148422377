import {Component, Inject, OnInit} from '@angular/core';
import {Machine} from "../models";
import {ApiService} from "../api.service";
import {MAT_DIALOG_DATA} from "@angular/material";
import {MatDialogRef} from "@angular/material/dialog";

import {DialogData} from "../supply-history/supply-history.component";
import {DataService} from "../data-service.service";

@Component({
  selector: 'app-machine-info',
  templateUrl: './machine-info.component.html',
  styleUrls: ['./machine-info.component.css']
})
export class MachineInfoComponent implements OnInit {
  machine: Machine;
  name: '';
  constructor(private dataService: DataService, private apiService: ApiService, @Inject(MAT_DIALOG_DATA) public data: DialogData, private dialogRef: MatDialogRef<MachineInfoComponent>) { }

  ngOnInit() {
    if (this.data['type'] == 'new'){
      this.machine = {name: '', description: '', status: 0, age: '', id: 0};
    } else {
      this.machine = this.data['machine'];
      this.name = this.data['machine']['name'];
    }
  }

  add() {
  this.apiService.addMachine(this.machine).subscribe(
    data => {
      this.dialogRef.close();
      this.dataService.newRefresh(JSON.stringify(data));
    }
  )
  }

  edit() {
    this.apiService.editMachine(this.machine).subscribe(
      data => {
        this.dialogRef.close();
        this.dataService.newRefresh('hardRefresh');
      }
    )
  }

}
