<div class="detail-box">
  <img class="detail-image" *ngIf="!data.isVideo && data.media" [src]="data.media">
  <img class="detail-image" *ngIf="!data.isVideo && data.image" [src]="data.image">

  <div class="detail-image" *ngIf="data.isVideo">
    <div class="detail-image-div" *ngIf="data.isVideo">
      <div class="detail-image-div-inner" *ngIf="!data.thumbnail" (click)="uploadThumbnail()" >Click to add thumbnail</div>
      <img class="detail-image" *ngIf="data.thumbnail" [src]="data.thumbnail">

    </div>
    <div class="detail-image-video">
    <img class="detail-image-video-only" *ngIf="data.isVideo" src="assets/video.png">
    </div>
  </div>
  <input type='file' id="upload-file-thumbnail" (change)="onSelectFile($event.target.files)" style="display: none;">


  <mat-label>{{'position' | translate}}</mat-label>

  <mat-select [(ngModel)]="data.position">
    <mat-option *ngFor="let position of positions" [value]="position"> {{position}} </mat-option>
  </mat-select>

  <div class="for-actions">
    <button mat-raised-button color="accent" (click)="dialogRef.close(data)">{{'save' | translate}}</button>
  </div>
</div>
