import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material";
import {MatDialogRef} from "@angular/material/dialog";
export interface DialogData {
  id?: number,
  histories: any[]
}

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.css']
})
export class OrderHistoryComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<OrderHistoryComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData, ) { }

  ngOnInit() {
    console.log(this.data)
  }

}
