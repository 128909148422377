<div class="header" style="background-color: #d92a73">
  <span *ngIf="selectedCategory === 0" style="font-size:1.7em; color:white; padding-left: 20px; font-weight: 500; ">
    {{'coverCategories' | translate}} <span *ngIf="selectedType">:</span>
  </span>
  <span *ngIf="selectedCategory !== 0" style="font-size:1.7em; color:white; padding-left: 20px; font-weight: 500; ">
    {{selectedCategory["name"]}} :
  </span>
  <span *ngIf="!loading" style="font-size:1.7em; color:white; font-weight: 500; ">
    <span *ngIf="selectedCategory == 0 && selectedType">
      {{totalCount}}
    </span>

    <span *ngIf="selectedCategory != 0">
      {{coverCount}}
    </span>
    {{'onLager' | translate}}
  </span>
</div>
<div class="mainDiv" style="margin-left: 5vw; margin-right: 5vw; margin-top: 10px;">

  <div class="covers-sub-header">
  <div class="covers__button" *ngIf="selectedCategory === 0">
    <app-button [matMenuTriggerFor]="coverTypes" [text]="getDropdownText()" img="assets/ic_keyboard_down.svg"
                [color]="colors.WHITE" [textColor]="colors.ULTRA_PINK" height="48px" width="204px"></app-button>
  </div>
    <div class="breadcrumb" *ngIf="selectedCategory !== 0">
      <span class="breadcrumb__main" (click)="unselectCategory()"> {{selectedType['name']}}  </span> <span
      class="breadcrumb__sign">></span> <span class="breadcrumb__name">  {{selectedCategory.name}}</span>
    </div>
    <div class="covers-date-box">
      <div>
        <mat-form-field style="float: left; width: 100%">
          <input #fromInput matInput [matDatepicker]="pickerFrom" placeholder="Od"
                 (dateChange)="onDateChanged($event.value)" [(ngModel)]="fromD">
          <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
          <mat-datepicker #pickerFrom></mat-datepicker>
        </mat-form-field>
      </div>
      <div >
        <mat-form-field style="float: left; width: 100%">
          <input #toInput matInput [matDatepicker]="pickerTo" placeholder="Do"
                 (dateChange)="onDateChanged($event.value)" [(ngModel)]="toD">
          <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
          <mat-datepicker #pickerTo></mat-datepicker>
        </mat-form-field>
      </div>
  </div>
  </div>
  <mat-menu #coverTypes="matMenu" class="type-menu" overlapTrigger="false" yPosition="below">
    <div style="padding:10px; width: 204px">
      <div *ngFor="let type of types;" class="covers__button__option" (click)="selectType(type)">
        {{type['name']}}
      </div>
    </div>
  </mat-menu>

  <app-loading *ngIf="loading"></app-loading>
  <div *ngIf="!loading">
    <div class="row" style="margin: 20px auto;display: flex; justify-content: center;"
         *ngIf="selectedCategory === 0">
      <div class="add-new-cover" (click)="openAddDialogCategory()" *ngIf="allowedEdit && selectedType">
        {{'addNewCoverCategory' | translate}}
      </div>
      <div *ngFor="let c of coverCategories" style="margin-bottom:15px; cursor: pointer;">
        <mat-card style="margin-right: 20px; width:320px; padding-top: 0px !important;" class="categoryCard"
                  (click)="selectCategory(c)">
          <img [src]="c.image" (error)="c.categoryImage = 'assets/header_logo.png'"
               style="object-fit: cover; width: calc(100% + 32px);
    margin-left: -16px; height: 210px !important; padding: 0px !important; background-color: #d92a73; border-top-left-radius: 4px; border-top-right-radius: 4px">
          <div style="padding-bottom: 20px; padding-top: 10px">
          <span style="float:left" (click)="addChange[c.id] = true" *ngIf="!addChange[c.id]">
             {{'amount' | translate}} : {{c.lager}}
          </span>
            <span style="float:right">
          <mat-slide-toggle [checked]="c['isActive'] === 1" (change)="toggleCoverCategory(c.id)"
                            [disabled]="!allowedEdit">
          <span *ngIf="c['isActive'] === 1">{{'active' | translate}}</span>
          <span *ngIf="c['isActive'] === 0">{{'inactive' | translate}}</span>

          </mat-slide-toggle> <br>
             <span style="text-align: right; float:right;">
               #{{c.id}}
             </span>
        </span> <br>
            <span style="position: absolute; left: 15px; margin-top: 5px;">
            {{'used' | translate}}: {{c.used}}
          </span>
          </div>

        </mat-card>
      </div>
    </div>
    <div
      class="covers-select"
      *ngIf="selectedCategory != 0 && !loading">
      <div class="add-new-cover" (click)="openAddDialog()" *ngIf="allowedEdit">
        {{'addNewCover' | translate}}
      </div>
      <div *ngFor="let c of covers" style="margin-bottom:15px; cursor: pointer;">
        <mat-card style="margin-right: 20px; width:320px; padding-top: 0px !important; position: relative"
                  class="categoryCard">
          <div class="cover-options" [matMenuTriggerFor]="coverOptions">
            <img src="assets/ic_more.svg">
          </div>
          <mat-menu #coverOptions="matMenu" class="type-menu" overlapTrigger="false" yPosition="below"
                    xPosition="before">
            <div style="padding:10px; width: 204px">
              <div class="covers__button__option" (click)="editCover(c)">
                {{'editCover' | translate}}
              </div>
            </div>
          </mat-menu>
          <div class="image-container">
            <img [src]="c.imageFront" (error)="c.imageFront = 'assets/header_logo.png'"
                 class="image-box"
                 (click)="showFullImage(c.imageFront)">
            <img [src]="c.imageBack" (error)="c.imageBack = 'assets/header_logo.png'"
                 class="image-box back-pic"
                 (click)="showFullImage(c.imageFront)">
          </div>
          <div style="padding-bottom: 20px; padding-top: 10px">
          <span style="float:left;" (click)="addChange[c.id] = true" *ngIf="!addChange[c.id]">
             {{'amount' | translate}} : {{c.lager}}
          </span>
            <span *ngIf="addChange[c.id]" style="float:left">
            <input type="number" [(ngModel)]="change[c.id]" (keyup.enter)="toAddChange(c.id)"
                   (blur)="change[c.id]=false" style="width: 50px;">
          </span>
            <span style="float:left">
            <mat-icon style="cursor:pointer; margin-left: 8px; color:#d92a73;" (click)="openHistory(c.id)">
              history
            </mat-icon>
          </span>
            <span style="float:right">
          <mat-slide-toggle [checked]="c['isActive'] === 1" (change)="toggleCover(c.id)" [disabled]="!allowedEdit">
          <span *ngIf="c['isActive'] === 1">{{'active' | translate}}</span>
          <span *ngIf="c['isActive'] === 0">{{'inactive' | translate}}</span>

          </mat-slide-toggle> <br>
             <span style="text-align: right; float:right;">
               #{{c.id}}
             </span>
        </span> <br>
            <span style="position: absolute; left: 15px; margin-top: 5px;">
            {{'used' | translate}}: {{c.used}}
          </span>
          </div>
        </mat-card>
      </div>
    </div>
    <div style="text-align: center; margin-top: 20px; margin-bottom: 20px;">
    </div>
  </div>
</div>
