import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material";
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialogRef} from '@angular/material/dialog';

import {ApiService} from "../api.service";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {DialogData} from "../appointment/appointment.component";
import {DataService} from "../data-service.service";

export interface DialogData {
  name: any;
  start: '';
  end: '';
  date: '';
}

@Component({
  selector: 'app-vacation',
  templateUrl: './vacation.component.html',
  styleUrls: ['./vacation.component.css']
})
export class VacationComponent implements OnInit {
  dateStart = '';
  dateEnd = '';
  description = '';
  message = '';

  // tslint:disable-next-line:max-line-length
  constructor(private dataService: DataService, @Inject(MAT_DIALOG_DATA) public data: DialogData, private translate: TranslateService, public snackBar: MatSnackBar, private service: ApiService, public dialogRef: MatDialogRef<VacationComponent>) { }

  ngOnInit() {
    if (this.data !== null){
      if (this.data.start !== ''){
        this.dateStart = this.data.start;
      }
      if (this.data.end !== ''){
        this.dateEnd = this.data.end;
      }
    }

  }
  onCancel($event) {
    this.dialogRef.close(null);
  }

  format(date) {
    const dated = new Date(date);
    let dd = dated.getDate().toString();
    const ddN = dated.getDate();
    let mm = (dated.getMonth() + 1).toString();
    const mmN = dated.getMonth() + 1;
    const yyyy = dated.getFullYear();

    if (ddN < 10){
      dd = '0' + dd;
    }

    if (mmN < 10) {
      mm = '0' + mm;
    }

    return dd + "." + mm + "." + yyyy;
  }

  onConfirm($event) {
    if (this.dateStart === '' || this.dateEnd === ' ' ){
      this.message = 'pleaseSelectADate';
      return;
    }
    if (this.description === '' ){
      this.message = 'pleaseAddDescription';
      return;
    }

    const start = this.format(this.dateStart);
    const end = this.format(this.dateEnd);


    this.service.addVacation(start, end, this.description).subscribe(
      data => {
        console.log(data);
        this.dataService.event.next(data);
      }, err => {
        this.snackBar.open(this.translate.instant('thereWasAnError'), null, {
          duration : 3000
        });
      },
      () => {
        this.snackBar.open(this.translate.instant('vacationScheduled'), null, {
          duration : 3000
        });
        this.dialogRef.close(true);
      }
    );
  }
}
