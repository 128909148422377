<div class="change-pass-modal">
  <div style="color: #606060; font-size: 26px; margin-bottom: 10px;">{{'changePassword' | translate}}</div>
  <mat-form-field class="details-box__address_field">
    <mat-label>{{"password" | translate}}</mat-label>
    <input matInput type="text" [(ngModel)]="password" >
  </mat-form-field>

  <mat-form-field class="details-box__address_field">
    <mat-label>{{"confirmPassword" | translate}}</mat-label>
    <input matInput type="text" [(ngModel)]="confirmPassword" >
  </mat-form-field>
  <div style="display: flex; justify-content: space-between; align-items: center" >

  <app-button (click)="check()" text="{{'save' | translate}}"  [height]="'48px'" [width]="'104px'" [textColor]="colors.WHITE"
              [color]="colors.PINK"></app-button>
    <div class="add-new-address" (click)="copyPassword()">{{'copyPass' | translate}}</div>

  </div>
</div>
