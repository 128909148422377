<div *ngIf="orders">
	<div class="btn-control">

    <button mat-raised-button style="margin-top: 8px; background-color: #868e96;"
            (click)="onPrint()">
     ŠTAMPAJ ({{orders.length}})
    </button>
	</div>

	<div id="print-area">
		<div class="slip" *ngFor="let order of orders;let i=index" [attr.data-receipt-id]="i">
			<span class="name">{{order.address.fullName}}</span>
			<span class="price">{{order.price}}</span>
			<span class="order-id">{{order.id}} ({{order.items.length}})</span>
			<span class="list-number" (click)="onPrintOne(i)"><mat-icon mat-list-icon style="width: auto; height: 50%; display: inline-block; vertical-align: middle">print</mat-icon></span>
			<span class="popup-msg">Štampaj jedan</span>
		</div>
	</div>
</div>

