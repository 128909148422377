import { Component, OnInit } from '@angular/core';
import {ApiService} from "../api.service";
import {DataService} from "../data-service.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {Admin} from "../models";

@Component({
  selector: 'app-all-requests',
  templateUrl: './all-requests.component.html',
  styleUrls: ['./all-requests.component.css']
})
export class AllRequestsComponent implements OnInit {
  requests = [];
  page: object = {};
  user: Admin;
  constructor(private snackBar: MatSnackBar, private translate: TranslateService, private apiService: ApiService, private dataService: DataService) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('adminYolo'));
    this.setPage({offset: 0});
  }


  setPage(pageInfo) {
    this.page['pageNumber'] = pageInfo.offset;
    this.apiService.getRequests(this.page['pageNumber']).subscribe(
      data => {
        const p = [];
        p['p'] = data;
        console.log(data);
        this.requests = p['p']['data'];
        this.page =p['p']['page'];
      }
    )
  }

  getRowClass(row) {
    return {
      'approved': row.status == 1,
      'declined' : row.status == -1,
      'cancelled': row.status == 3,
      'pending' : row.status === 0
    };
  }

  getPeriod(d) {
    if (d.start == d.end){
      return d.start;
    } else {
      return d.start + ' - ' + d.end;
    }
  }

  confirm(event) {
    if (event['type'] == 0){ //dayOff
      this.apiService.confirmDayOff(event['id'], true).subscribe(
        data => {
          const index = this.requests.findIndex((x) => x.id == data['id']);
          this.requests[index].status = 1;
          this.requests = [...this.requests];
        },
        err => {
          this.snackBar.open(this.translate.instant('thereWasAnError'), '', {duration: 2000});
        }
      )
    }
  }

  decline(event) {
    if (event['type'] == 0){ //dayOff
      this.apiService.confirmDayOff(event['id'], false).subscribe(
        data => {
          const index = this.requests.findIndex((x) => x.id == data['id']);
          this.requests[index].status = -1;
          this.requests = [...this.requests];
        },
        err => {
          this.snackBar.open(this.translate.instant('thereWasAnError'), '', {duration: 2000});
        }
      )
    }
  }

  cancel(event) {
    if (event['type'] == 0) { //dayOff
      this.apiService.cancelDayOff(event['id']).subscribe(
        data => {
          const index = this.requests.findIndex((x) => x.id == event['id']);
          this.requests[index].status = 3;
          this.requests = [...this.requests];
        },
        err => {
          this.snackBar.open(this.translate.instant('thereWasAnError'), '', {duration: 2000});
        }
      )
    } else if (event['type'] == 1) { //appoint
      this.apiService.cancelAppointment(event['id']).subscribe(
        data => {
          const index = this.requests.findIndex((x) => x.id == event['id']);
          this.requests[index].status = 3;
          if (event['parentId'] != null){
            const secondIndex = this.requests.findIndex((x) => x.id == event['parentId']);
            this.requests[secondIndex].status = 3;
          } else {
            const secondIndex = this.requests.findIndex((x) => x.parentId == event['id']);
            this.requests[secondIndex].status = 3;
          }
          this.requests = [...this.requests];
        },
        err => {
          this.snackBar.open(this.translate.instant('thereWasAnError'), '', {duration: 2000});
        }
      )
    }
  }


  openHistory() {

  }

}
