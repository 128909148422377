<div class="header" style="background-color: #d92a73">
    <span style="font-size:1.7em; color:white; padding-left: 20px; font-weight: 500; ">
      {{'bills' | translate}}
    </span>
</div>
<mat-card class="col-12">

  <mat-card>
    <div class="row">
      <div class="col-12 col-xl-2">
        <mat-form-field style="float: left; width: 100%">
          <input #fromInput matInput [matDatepicker]="pickerFrom" placeholder="Od datuma"
                 (dateChange)="filter.fromDate = $event.value.toISOString()">
          <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
          <mat-datepicker #pickerFrom></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-12 col-xl-2">
        <mat-form-field style="float: left; width: 100%">
          <input #toInput matInput [matDatepicker]="pickerTo" placeholder="Do datuma"
                 (dateChange)="filter.toDate = $event.value.toISOString()">
          <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
          <mat-datepicker #pickerTo></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-12 col-xl-1">
        <mat-form-field style="width: 100%">
          <input type="tel" matInput placeholder="ID" [(ngModel)]="filter.id"
                 (keyup.enter)="onSearch($event)"/>
        </mat-form-field>
      </div>

      <div class="col-12 col-xl-1">
        <mat-form-field style="width: 100%">
          <input type="tel" matInput placeholder="ID Porudzbine" [(ngModel)]="filter.orderId"
                 (keyup.enter)="onSearch($event)"/>
        </mat-form-field>
      </div>

      <div class="col-12 col-xl-1">
        <mat-form-field style="width: 100%">
          <input type="text" matInput placeholder="Od ID Porudžbine" [(ngModel)]="filter.fromOrderId"
                 (submit)="onSearch($event)" (keyup.enter)="onSearch($event)"/>
        </mat-form-field>
      </div>
      <div class="col-12 col-xl-1">
        <mat-form-field style="width: 100%">
          <input type="tel" matInput placeholder="Do ID Porudžbine" [(ngModel)]="filter.toOrderId"
                 (keyup.enter)="onSearch($event)"/>
        </mat-form-field>
      </div>

<!--      <div class="col-12 col-xl">-->
<!--        <mat-form-field style="width: 100%">-->
<!--          <mat-select placeholder="Status" [value]="getFilterStatusValue()"-->
<!--                      (selectionChange)="filter.isDeleted = $event.value">-->
<!--            <mat-option *ngFor="let s of statusFilter" [value]="s.value">-->
<!--              {{ s.name }}-->
<!--            </mat-option>-->
<!--          </mat-select>-->
<!--        </mat-form-field>-->
<!--      </div>-->

      <div class="col-xl-2">
        <button mat-raised-button color="primary" style="margin-top: 8px; width: 100%" (click)="onSearch($event)">
          <mat-icon>search</mat-icon>
        </button>
      </div>

      <div class="col-xl-2">
        <button mat-raised-button color="warn" style="margin-top: 8px; width: 100%; outline:none"
                (click)="onReset($event)">
          <mat-icon>cancel</mat-icon>
        </button>
      </div>

    </div>

    <div class="col-xl-2" style="margin: 10px 0;">
      {{'total' | translate}} : {{count}}
    </div>

    <div class="col-xl-2 offset-xl-2 col">
      <input type="file" accept=".xlsx, .xls, .csv, .pdf" #file style="display: none; width: 0px; height: 0px;"
             (change)="uploadReport($event.target.files)">
    </div>
    <div class="row">
      <div class="col-xs-6 col-sm-6 col-lg-4">
        <button mat-raised-button
                style="margin-top: 8px; width: 100%; background-color: lightcoral;padding: 0px 4px; outline:none"
                (click)="file.click()">
          {{'uploadReport' | translate | uppercase}}
        </button>
      </div>
      <div class="col-xs-6 col-sm-6 col-lg-4">
        <button mat-raised-button
                style="margin-top: 8px; width: 100%; background-color: #9999ff;padding: 0px 4px;  outline:none"
                (click)="onSummary('recapitulation')">
          {{'recapitulation' | translate | uppercase}}
        </button>
      </div>
      <div class="col-xs-6 col-sm-6 col-lg-4">
        <button mat-raised-button
                style="margin-top: 8px; width: 100%; background-color: #9fdfcf;padding: 0px 4px;  outline:none"
                (click)="onBils()">
          {{'downloadInvoices' | translate | uppercase}}
        </button>
      </div>
      <!--    </div>-->
      <!--    <div class="row">-->
      <div class="col-xs-6 col-sm-6 col-lg-4">
        <button mat-raised-button
                style="margin-top: 8px; width: 100%; background-color: #ffcc99;padding: 0px 4px;  outline:none"
                (click)="onSummary('summaryCards')">
          <span class="breakText">{{'summaryCards' | translate | uppercase}}</span>

        </button>
      </div>
      <div class="col-xs-6 col-sm-6 col-lg-4">
        <button mat-raised-button
                style="margin-top: 8px; width: 100%; background-color: paleturquoise;padding: 0px 4px;  outline:none"
                (click)="onBook()">
          <span class="breakText">{{'outgoingBook' | translate | uppercase}}</span>
        </button>
      </div>
      <div class="col-xs-6 col-sm-6 col-lg-4">
        <button mat-raised-button
                style="margin-top: 8px; width: 100%; background-color: palevioletred;padding: 0px 4px;  outline:none"
                (click)="onSummary('summaryCash')">
          <span class="breakText">{{'summaryCash' | translate | uppercase}}</span>
        </button>
      </div>
    </div>
  </mat-card>

  <br>
  <div *ngIf="message != ''" style="text-align: center; cursor: pointer;" (click)="message = ''">
    <span> {{message | translate}}</span>
  </div>
  <mat-spinner *ngIf="dataLoading" [diameter]="30"></mat-spinner>
  <ngx-datatable style="margin-top: 10px;"
                 #table
                 class="material expandable"
                 [rows]="invoices"
                 [count]="count"
                 [externalPaging]="false"
                 [columnMode]="'force'"
                 [headerHeight]="50"
                 [footerHeight]="75"
                 [rowHeight]="'auto'"
                 [scrollbarH]="true"
                 [loadingIndicator]="isLoading"
                 [scrollbarV]="false"
                 [limit]="100"
                 [rowClass]="getRowClass"
                 [messages]="messages"
                 [selected]="selectedInvoices"
                 [selectAllRowsOnPage]="false"
                 [rowIdentity]="getRowId"
                 (select)='onSelect($event)'
  >


    <!--<ngx-datatable-column
      [width]="50"
      [sortable]="false"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="false"
      [headerCheckboxable]="true"
      [checkboxable]="true">
    </ngx-datatable-column>-->

    <ngx-datatable-column name="Broj računa" sortable="false" prop="id">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        <span>{{value}}</span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="ID Porudžbine" sortable="false" prop="orderId">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        <span>{{value}}</span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Datum" sortable="false" prop="createdAt">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        <span>{{value | date}}</span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="{{'paid' | translate}}" sortable="false" prop="paid">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        <span *ngIf="value == 1"><mat-icon>done</mat-icon></span>
        <span *ngIf="value == 0"><mat-icon>clear</mat-icon></span>
      </ng-template>
    </ngx-datatable-column>

    <!--    <ngx-datatable-column name="{{'action' | translate}}" sortable="false" prop="isDeleted">-->
    <!--      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>-->
    <!--        <button mat-raised-button *ngIf='value === false' class="button cancel" (click)="onSelectRow(row)"-->
    <!--        >{{'cancelTheAccount' | translate}}</button>-->
    <!--        <button mat-raised-button *ngIf="value !== false" class="button create" (click)="onSelectRow(row)">-->
    <!--          {{'createTheAccount' | translate}}-->
    <!--        </button>-->
    <!--      </ng-template>-->
    <!--    </ngx-datatable-column>-->


    <ngx-datatable-column name="Datum brisanja" sortable="false" prop="deletedAt">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        <span *ngIf="!row.isCancelled">{{value | date}}</span>
      </ng-template>
    </ngx-datatable-column>

  </ngx-datatable>
  <br>
  <div style="text-align: center" *ngIf="invoices && invoices.length > 0 && !noMoreData">
    <button
      style="background-color: #6e92ad; cursor: pointer; color:white; border-radius: 24px; border:0px !important; padding: 10px;"
      (click)="loadNewOrders()"> {{'loadNextPage' | translate}}</button>
  </div>
</mat-card>


