<mat-card class="main" style="padding: 10px; width: 50vw; height: 70vh;">
  <div style="float:left">
    <h1>{{machine["name"]}}</h1>
    <h5>{{machine["description"]}}</h5>
    <h5>{{'dateOfProduct' | translate}} : {{machine["age"]}}</h5>
  </div>
  <div style="float:right">
    <mat-icon class="icon" (click)="edit(data)"> edit</mat-icon>
    <mat-icon class="icon" (click)="delete(data)"> delete </mat-icon>
  </div>
  <span style="clear: both; float:unset;"></span>
  <hr style="clear: both; float: unset;">
  <h6 style="float:left;"> {{'repairs' | translate}} : </h6>
  <div style="float:right;">
    <span *ngIf="!editable" style="cursor: pointer; padding-left: 10px; padding-right: 10px; padding-top: 5px; padding-bottom: 5px;" class="addButton" (click)="editable = !editable"> {{'addNew' | translate}}</span>
    <span *ngIf="editable" style="cursor: pointer; padding-left: 10px; padding-right: 10px; padding-top: 5px; padding-bottom: 5px;" class="addButton" (click)="editable = !editable"> {{'exit' | translate}}</span>
  </div>
  <div *ngIf="repairs.length == 0 && !editable" style="float:unset; clear: both; text-align: center; color: grey;">
  {{'noRepairsRightNow' | translate}}
  </div>
  <br>
  <div *ngIf="editable" style="display: flex;justify-content: space-evenly;">
    <mat-form-field>
      <input matInput type="text" placeholder="{{'change' | translate}}"  [(ngModel)]="repair.service">
    </mat-form-field>
    <mat-form-field >
      <input matInput type="text" placeholder="{{'description' | translate}}"  [(ngModel)]="repair.description">
    </mat-form-field>
    <mat-form-field>
      <input #dates matInput [matDatepicker]="dates" placeholder="{{'date' | translate}}"  [(ngModel)]="repair.date">
      <mat-datepicker-toggle matSuffix [for]="dates"></mat-datepicker-toggle>
      <mat-datepicker #dates></mat-datepicker>
    </mat-form-field>
  </div>
  <div  *ngIf="editable" style="text-align: center; margin-top:10px;">
      <span class="addButton" style="cursor: pointer; padding-left: 10px; padding-right: 10px; padding-top: 5px; padding-bottom: 5px;" (click)="save()">
        {{'save' | translate}}
      </span>
  </div>
  <ngx-datatable *ngIf="repairs.length > 0"
      #table
      class="material expandable" style="margin-top: 20px; cursor: pointer;float:unset; clear: both;"
      [rows]="repairs"
      [columnMode]="'force'"
      [headerHeight]="50"
      [footerHeight]="75"
      [rowHeight]="'auto'"
      [scrollbarH]="true"
      (activate)="open($event)"
      [scrollbarV]="false" >
    <ngx-datatable-column sortable="false" prop="id" [width]="30" >
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        <span>{{value}}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column sortable="false" prop="service" [width]="30"  name="{{'change' | translate}}" >
    <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
      <span> {{value}}</span>
    </ng-template>
  </ngx-datatable-column>
    <ngx-datatable-column sortable="false" prop="description" [width]="30"  name="{{'description' | translate}}" >
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        <span> {{value}}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column sortable="false" prop="date" [width]="30"  name="{{'date' | translate}}" >
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        <span> {{value}}</span>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</mat-card>

