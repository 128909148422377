import { Component, OnInit } from '@angular/core';
import {Admin} from "../models";
import {ApiService} from "../api.service";
import {MatDialogConfig} from "@angular/material/dialog";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";

import {HttpClient} from "@angular/common/http";
import {DataService} from "../data-service.service";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {SupplyHistoryComponent} from "../supply-history/supply-history.component";
import {MachineRepairComponent} from "../machine-repair/machine-repair.component";
import {MachineInfoComponent} from "../machine-info/machine-info.component";

@Component({
  selector: 'app-machines',
  templateUrl: './machines.component.html',
  styleUrls: ['./machines.component.css']
})
export class MachinesComponent implements OnInit {
  user: Admin;
  isLoading = false;
  machines = [];
  change = [];
  allowedEdit = false;
  constructor(private apiService: ApiService,
              private snackBar: MatSnackBar,
              private mdDialog: MatDialog,
              private http: HttpClient,
              private dataService: DataService,
              private translate: TranslateService,
              private router: Router,
              private dialog: MatDialog) {
  }

  ngOnInit() {
    this.dataService.refresh.subscribe(
      data => {
      if (data !== 'refresh'){
        if (data == 'hardRefresh') {
          this.apiService.getMachines().subscribe(
            data => {
              const p = [];
              p['supplies'] = data;
              this.machines = [...p['supplies']];
            }
          )
        } else {
          this.machines = [...this.machines, ...[JSON.parse(data)]];
        }

      }
      }
    );
    this.user = JSON.parse(localStorage.getItem('adminYolo'));
    if (!this.checkPermission('supplies')) {
      this.router.navigate(['/']);
    }
    this.apiService.getMachines().subscribe(
      data => {
        const p = [];
        p['supplies'] = data;
        this.machines = p['supplies'];
      }
    )
  }

  checkPermission(page){
    const permissions = this.user.permissions;
    const rightPermission = permissions.find( (x) => x.page === page);
    if (rightPermission){
      if (rightPermission.edit === 1){
        this.allowedEdit = true;
      } else {
        this.allowedEdit = false;
      }
      if (rightPermission.view === 1){
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  openHistory(event){
    if (event.type === 'click' && event.cellIndex !== 4){
      const dialogConfig = new MatDialogConfig();
      dialogConfig.panelClass = 'machines-info-dialog';
      dialogConfig.data = {id: event.row.id};
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      this.dialog.open(MachineRepairComponent, dialogConfig);
    }
  }

  getRowClass(row) {
    if (row.status == 0){
      return 'working23';
    } else if (row.status == 1) {
    return 'repair';
    } else {
      return 'broken';
    }
  }

  addNew() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'machines-info-dialog';
    dialogConfig.data = {type: 'new'};
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    this.dialog.open(MachineInfoComponent, dialogConfig);
  }

  addChange(id){
    this.apiService.addSupplyHistory(id, this.change[id]).subscribe(
      data1 => {
        this.apiService.getSupplies().subscribe(
          data => {
            const p = [];
            p['supplies'] = data;
            this.machines = p['supplies'];
          }
        );
      }
    );
    this.change[id] = 0;
  }

}
