<mat-card style="width: 300px; height: 150px;">
  <h5>{{data.name}}</h5>
  <hr>
  {{data.start | date:'d.M.y.'}} - {{data.end| date:'d.M.y.'}} <br>
  {{data.time}}
<div style="position: relative; bottom: 10px;" *ngIf="this.data['type'] !== 2">
  <div style="float:right;">
         <span class="addButton" style="cursor: pointer; padding-left: 10px; padding-right: 10px; padding-top: 5px; padding-bottom: 5px;" (click)="cancel()">
            {{'cancel' | translate}}
        </span>
  </div>
</div>

</mat-card>


