<div class="address-modal">
  <div style="color: #606060; font-size: 26px">{{'addNewAddress' | translate}}</div>
  <div class="address-for-types" >
    <div *ngFor="let t of types" class="type-option" (click)="address.type = t.id" [ngClass]="{'type-selected': address.type == t.id}">
      <app-svg-icon [fillColor]="address.type == t.id ? '#D92A73' : '#8B7F84'" [type]="t.id"></app-svg-icon>
      {{t.name | translate}}
    </div>
  </div>
  <mat-label style="color: gray; font-size: 12px">{{'country' | translate}}</mat-label>
<mat-select [(value)]="address.country" style="margin-bottom: 10px">
  <mat-option *ngFor="let c of countries" [value]="c.value">{{c.name}}</mat-option>
</mat-select>
<mat-form-field class="details-box__address_field">
  <mat-label>{{"address" | translate}}</mat-label>
  <input matInput type="text" [(ngModel)]="address.address" >
</mat-form-field>
<mat-form-field class="details-box__address_field">
  <mat-label>{{"number" | translate}}</mat-label>
  <input matInput type="text" [(ngModel)]="address.addressNumber" >
</mat-form-field>
<mat-form-field class="details-box__address_field details-box__address_field--half ">
  <mat-label>{{"city" | translate}}</mat-label>
  <input matInput type="text" [(ngModel)]="address.city" >
</mat-form-field>
<mat-form-field class="details-box__address_field details-box__address_field--half ">
  <mat-label>{{"postalCode" | translate}}</mat-label>
  <input matInput type="text" [(ngModel)]="address.postalCode" >
</mat-form-field>
<mat-form-field class="details-box__address_field details-box__address_field--half ">
  <mat-label>{{"floor" | translate}}</mat-label>
  <input matInput type="text" [(ngModel)]="address.floor" >
</mat-form-field>
<mat-form-field class="details-box__address_field details-box__address_field--half ">
  <mat-label>{{"apartmentNumber" | translate}}</mat-label>
  <input matInput type="text" [(ngModel)]="address.apartmentNumber">
</mat-form-field>
<!--            <mat-form-field class="details-box__address_field">-->
<!--              <mat-label>{{"note" | translate}}</mat-label>-->
<!--              <input matInput type="text" [(ngModel)]="address.note">-->
<!--            </mat-form-field>-->
<div (click)="saveAddress()" style="margin-top: 15px">
  <app-button text="{{'save' | translate}}"  [height]="'48px'" [width]="'104px'" [textColor]="colors.WHITE"
              [color]="colors.PINK"></app-button>
</div>
</div>
