<div class="header" style="background-color: #d92a73">
    <span style="font-size:1.7em; color:white; padding-left: 20px; font-weight: 500; ">
      {{'temporalPrice' | translate}}
    </span>
</div>
<mat-card class="col-md-10 offset-md-1 col-12">

  <div>
    <mat-accordion class="example-headers-align">
      <mat-expansion-panel [expanded]='isExpanded'>
        <mat-expansion-panel-header (click)="expand()">
          <mat-panel-title>
            <mat-icon style="font-size: 23px;margin-top: 9px;">add_box</mat-icon>
            <h4 style="margin:7px 0 0 20px;">{{'createTemporallyPrice' | translate}}</h4>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <form [formGroup]="temporallyPriceForm">

          <mat-form-field class="input-margin">
            <mat-label>{{'product' | translate}}</mat-label>
            <mat-select formControlName="name">
              <mat-option *ngFor="let name of names" [value]="name">{{name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="input-margin">
            <mat-label>{{'price' | translate}}</mat-label>
            <input matInput type="number" formControlName="price">
          </mat-form-field>

          <mat-form-field class="input-margin">
            <mat-label>{{'currency' | translate}}</mat-label>
            <mat-select formControlName="currency">
              <mat-option *ngFor="let val of countriesAndCurrency" [value]="val.currency">{{val.currency}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="input-margin">
            <mat-label>{{'country' | translate}}</mat-label>
            <mat-select formControlName="country">
              <mat-option *ngFor="let val of countriesAndCurrency" [value]="val.country">{{val.country}}</mat-option>
            </mat-select>
          </mat-form-field>


          <mat-form-field appearance="fill" class="input-margin">
            <mat-label>{{'info' | translate}}</mat-label>
            <input matInput formControlName="info">
          </mat-form-field>


          <div class="text-area">
            <mat-form-field class="input-margin">
              <mat-label>{{'startDate' | translate}}</mat-label>
              <input readonly style="cursor: auto;" [matDatepicker]="basicDatepickerStart" matInput
                     formControlName="startDate" (focus)="basicDatepickerStart.open()">
              <mat-icon matSuffix (click)="basicDatepickerStart.open()">today</mat-icon>
              <mat-datepicker #basicDatepickerStart></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="input-margin">
              <mat-label>{{'endDate' | translate}}</mat-label>
              <input readonly style="cursor: auto;" [matDatepicker]="basicDatepickerEnd" matInput
                     formControlName="endDate" (focus)="basicDatepickerEnd.open()">
              <mat-icon matSuffix (click)="basicDatepickerEnd.open()">today</mat-icon>
              <mat-datepicker #basicDatepickerEnd></mat-datepicker>
            </mat-form-field>
          </div>


        </form>
        <mat-action-row>
          <button mat-raised-button color="accent" class='button button--margin'
                  (click)="closePanel()">{{'cancel' | translate}}</button>
          <button mat-raised-button color="primary" class='button' (click)="createTemporallyPrice()">
            {{'create' | translate}}
          </button>
        </mat-action-row>

      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <ngx-datatable style="margin-top: 10px;"
                 #table
                 class="material expandable"
                 [rows]="data"
                 [count]="count"
                 [externalPaging]="false"
                 [columnMode]="'force'"
                 [headerHeight]="50"
                 [footerHeight]="75"
                 [rowHeight]="'auto'"
                 [scrollbarH]="true"
                 [scrollbarV]="false"
                 [limit]="100"
                 [selected]="selectedPrice"
                 [selectAllRowsOnPage]="false"
                 (activate)='onUserEvent($event)'
  >

    <ngx-datatable-column name="{{'id' | translate}}" sortable="false" prop="id">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        <span>{{value}}</span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="{{'product' | translate}}" sortable="false" prop="name">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        <span>{{value}}</span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="{{'price' | translate}}" sortable="false" prop="price">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        <span>{{value}}</span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="{{'currency' | translate}}" sortable="false" prop="currency">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        <span>{{value}}</span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="{{'country' | translate}}" sortable="false" prop="country">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        <span>{{value}}</span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="{{'info' | translate}}" sortable="false" prop="infoText">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        <span>{{value}}</span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="{{'startDate' | translate}}" sortable="false" prop="startDate">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        <span>{{value}}</span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="{{'endDate' | translate}}" sortable="false" prop="endDate">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        <span>{{value}}</span>
      </ng-template>
    </ngx-datatable-column>

  </ngx-datatable>
</mat-card>
