import {Component, OnInit} from '@angular/core';
import {Colors} from "../Colors";
import {TranslateService} from "@ngx-translate/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ApiService} from "../api.service";
import {MoreTextComponent} from "../users/user/more-text/more-text.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-issues',
  templateUrl: './issues.component.html',
  styleUrls: ['./issues.component.css']
})
export class IssuesComponent implements OnInit {
  showHeader: false;
  colors = Colors;
  page = 0;
  collumns = {
    name: true,
    lastname: true,
    initiationDate: true,
    type: true,
    status: true,
    resolutionDate: true,
    resolution: true,
    benefit: true,
    providedBenefit: true
  };
  filter = {
    isActive: false,
    isResolved: false,
    firstName: null,
    lastName: null,
    username: null,
    email: null,
    id: null,
    phone: null,
    startDate: null,
    endDate: null
  };
  issues = [];
  issueOptions = [];

  constructor(public translate: TranslateService,
              private snackBar: MatSnackBar,
              private service: ApiService,
              private dialog: MatDialog) {
  }

  ngOnInit() {
    if (localStorage.getItem('issuesCollumns')) {
      this.collumns = JSON.parse(localStorage.getItem('issuesCollumns'));
    }
    this.getIssueOptions();
    this.filterIssues(0);
  }

  resetFilter() {
    this.filter = {
      isActive: false,
      isResolved: false,
      firstName: null,
      lastName: null,
      username: null,
      email: null,
      id: null,
      phone: null,
      startDate: null,
      endDate: null
    };
    this.filterIssues(0);
  }

  getIssueOptions() {
    this.service.getIssueOptions().subscribe((data: any) => {
      this.issueOptions = data;
    })
  }


  filterIssues(page: number | null) {
    if (page) {
      this.page = page;
    }

    const fixedFilter = this.filter;

    if (this.filter.isActive == true && this.filter.isResolved == true) {
      fixedFilter.isActive = false;
      fixedFilter.isResolved = false;
    }
    this.service.filterIssues(fixedFilter, this.page).subscribe((issues: any[]) => {
      this.issues = issues;
    })
  }

  saveCollumns() {
    localStorage.setItem('issuesCollumns', JSON.stringify(this.collumns));
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  getTranslation(value) {
    return this.translate.instant(value);
  }

  getIssueOption(type, id) {
    let value = '';
    this.issueOptions[type].forEach(option => {
      if (option.id == id) {
        value = option;
      }
    })
    return value;
  }


  openMore(row) {
    const seeMoreDialog = this.dialog.open(MoreTextComponent, {
      disableClose: true,
      width: '450',
      position: {
        top: '150px',
      },
      data: {
        text: row.type_explanation
      }
    });
  }

  changedResolutionDate(value, row) {
    row.resolution_date = value;
    this.updateIssue(row);
  }

  updateIssue(issue) {
    this.service.updateIssue(issue).subscribe((res) => {
      this.issues.push(res);
      this.snackBar.open(this.translate.instant("saved"), "", {duration: 1000});

    }, (err) => {
      this.snackBar.open(this.translate.instant("thereWasAnError"), "", {duration: 1000});
    })
  }

  getRowClass(row): any {

    if (row) {
      if (row.status == 4)
        return 'issue-active';
      if (row.status == 5)
        return 'issue-resolved';
    }


  }
}
