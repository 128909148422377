<div style="width: 450px; height: 344px; padding: 20px; position: relative">
  <h2>{{'message' | translate}}</h2>
  <span class="close" (click)="dialogRef.close()">&times;</span>

  <div  style="padding-left: 0px !important;" class="breadcrumb">
    <span class="breadcrumb__main"> {{data.rating}}  </span> <span
    class="breadcrumb__sign">></span> <span class="breadcrumb__name">  {{data.reason}}</span>
  </div>
  <div style="font-size: 12px; color:#606060">{{'message' | translate}}</div>
  <div>{{data.message}}</div>
</div>
