<div class="header" style="background-color: #d92a73">
    <span style="font-size:1.7em; color:white; padding-left: 20px; font-weight: 500; ">
      {{'supplies' | translate}}
    </span>
</div>
<div class="container" style="overflow: hidden !important;">

  <div>
    <ngx-datatable
      style="margin-top: 20px; cursor: pointer; margin-bottom: 70px;"
      #table
      class="material expandable"
      [rows]="supplies"
      [columnMode]="'force'"
      [headerHeight]="50"
      [footerHeight]="75"
      [rowHeight]="'auto'"
      [scrollbarH]="true"
      [loadingIndicator]="isLoading"
      [scrollbarV]="false"
      (activate)="openHistory($event)">
      <ngx-datatable-column sortable="false" prop="id" [width]="30">
        <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
          <span>{{value}}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column sortable="false" prop="type" name="{{'type' | translate}}" [width]="30">
        <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
          <span>{{value}}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column sortable="false" prop="lager" [width]="30" name="{{'amount' | translate}}">
        <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
          <span> {{value}}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column sortable="false" prop="used" [width]="30" name="{{'used' | translate}}">
        <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
          <span *ngIf="value"> {{value}}</span>
          <span *ngIf="!value"> 0 </span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column sortable="false" prop="lastChange" [width]="30" name="{{'lastChange' | translate}}">
        <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
          <span> {{value}}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column sortable="false" [width]="30" name="{{'change' | translate}}" *ngIf="allowedEdit">
        <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
          <mat-form-field style="width:30%; margin-left: 35%;">
            <input matInput type="number" [(ngModel)]="change[row.id]" (keyup.enter)="addChange(row.id)">
          </mat-form-field>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>

  </div>

</div>
