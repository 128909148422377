<div class="dates-row" >

    <mat-form-field>
        <input #fromInput matInput [matDatepicker]="pickerFrom" placeholder="{{'from' | translate}}"
               (dateChange)="onDateChanged($event.value)" [(ngModel)]="start">
        <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
        <mat-datepicker #pickerFrom></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
        <input #fromInput matInput [matDatepicker]="pickerTo" placeholder="{{'to' | translate}}"
               (dateChange)="onDateChanged($event.value)" [(ngModel)]="end">
        <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
        <mat-datepicker #pickerTo></mat-datepicker>
    </mat-form-field>

</div>

<div class="statistics-row"  *ngIf="!loading">
    <div class="three-piece-row-box">
        <div class="statistics-box">
            <div class="statistics-box-title">Turnover</div>
            <div class="statistics-box-value">€ {{salesStatistics?.turnover}}</div>
        </div>

        <div class="statistics-box">
            <div class="statistics-box-title">Revenue</div>
            <div class="statistics-box-value">€ {{salesStatistics?.revenue}}</div>
        </div>

      <div class="statistics-box">
        <div class="statistics-box-title">Delivery Costs</div>
        <div class="statistics-box-value">€ {{salesStatistics?.delivery}} - <span [ngStyle]="{'font-size': '16px'}">({{ (salesStatistics?.delivery / salesStatistics?.revenue * 100) | number:'1.2-2' }} %)</span></div>
      </div>

      <div class="statistics-box">
        <div class="statistics-box-title">Production Costs</div>
        <div class="statistics-box-value">€ {{salesStatistics?.production}} - <span [ngStyle]="{'font-size': '16px'}">({{ (salesStatistics?.production / salesStatistics?.revenue * 100) | number:'1.2-2' }} %)</span></div>
      </div>

        <div class="statistics-box">
            <div class="statistics-box-title">OPEX</div>
            <div class="statistics-box-value">€ {{salesStatistics?.opex}} - <span [ngStyle]="{'font-size': '16px'}">({{ (salesStatistics?.opex / salesStatistics?.revenue * 100) | number:'1.2-2' }} %)</span></div>
        </div>

      <div class="statistics-box">
        <div class="statistics-box-title">IT Costs</div>
        <div class="statistics-box-value">€ {{salesStatistics?.it}} - <span [ngStyle]="{'font-size': '16px'}">({{ (salesStatistics?.it / salesStatistics?.revenue * 100) | number:'1.2-2' }} %)</span></div>
      </div>

        <div class="statistics-box">
            <div class="statistics-box-title">Marketing Costs</div>
          <div class="statistics-box-value">€ {{salesStatistics?.marketing}} - <span [ngStyle]="{'font-size': '16px'}">({{ (salesStatistics?.marketing / salesStatistics?.revenue * 100) | number:'1.2-2' }} %)</span></div>
        </div>

        <div class="statistics-box">
            <div class="statistics-box-title">Profit</div>
            <div class="statistics-box-value">€ {{salesStatistics?.profit}}</div>
        </div>

    </div>

    <div class="statistics-box third-width-box">
        <div class="statistics-box-title">Profit percentage</div>
        <app-profit [refreshData]="refreshData.asObservable()" ></app-profit>
    </div>
</div>
<div class="spinner-container"  *ngIf="loading">
<mat-spinner [diameter]="50"></mat-spinner>
</div>


