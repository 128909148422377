<div style="padding: 20px; width: 300px; height: 450px; position:relative;">
  <span style="color:#747474; font-size:18px;"> {{'addDayOff' | translate}}</span>

  <div style="margin-top: 50px;">
    <mat-form-field style="width: 100%">
      <input #fromDate matInput [matDatepicker]="fromDate" placeholder="{{'fromDate' | translate}}"  [(ngModel)]="dateStart" >
      <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
      <mat-datepicker #fromDate></mat-datepicker>
    </mat-form-field>
    <mat-form-field style="width: 100%">
      <input #toDate matInput [matDatepicker]="toDate" placeholder="{{'toDate' | translate}}"   [(ngModel)]="dateEnd" >
      <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
      <mat-datepicker #toDate></mat-datepicker>
    </mat-form-field>
    <mat-form-field style="width: 100%">
      <textarea matInput rows="0" placeholder="{{'description' | translate}}" [(ngModel)]="description" style="height: 32px;"></textarea>
    </mat-form-field>
  </div>
  <span style="color:red; left: 0px; position: absolute; text-align: center; width: 100%;" *ngIf="message !== ''"> {{ message | translate }} </span>
  <span (click)="onConfirm($event)" style="font-weight: bold; position: absolute; bottom: 10px; right: 10px; cursor: pointer; float:right; padding: 10px; color:#1e88e5; font-size: 18px;"> {{'confirm' | translate }}</span></div>

