<div class="header" style="background-color: #d92a73">
    <span style="font-size:1.7em; color:white; padding-left: 20px; font-weight: 500; ">
      {{'notifications' | translate}}
    </span>
</div>
<mat-card class="col-md-10 offset-md-1 col-12">
  <div *ngFor="let notification of notifications$ | async ">
    <div class="data-container">
      <span class="data-name">{{notification.name}}</span>
      <mat-slide-toggle (change)="changeActive(notification)"
                        [checked]="notification.active === 1">

      </mat-slide-toggle>
    </div>
    <hr style="margin: 0"/>
  </div>
  <div class="button-container">
    <button mat-raised-button color="primary" class="saave-button" (click)="Update()">{{'save' | translate}}</button>
  </div>
</mat-card>
