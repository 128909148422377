import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-facture',
  templateUrl: './facture.component.html',
  styleUrls: ['./facture.component.css']
})
export class FactureComponent implements OnInit {
  facture = {
    responsible_person : "",
    sack : 0,
    account_number : ""
  }
  constructor(public dialogRef: MatDialogRef<FactureComponent>,) { }

  ngOnInit() {
    
  }
  sendFacture(){
    this.dialogRef.close(this.facture);
  }
}
