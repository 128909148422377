<div class="header" style="background-color: #d92a73" >
    <span   style="font-size:1.7em; color:white; padding-left: 20px; font-weight: 500; ">
      {{'machines' | translate}}
    </span>
</div>
<div class="container" style="overflow: hidden !important;">
  <div style=" margin-top: 20px; ">
        <span style="font-size:1.7em; color:#747474; padding-left: 20px; font-weight: 500;">

    </span>
    <div (click)="addNew()" style="margin-right: 10px; vertical-align: middle; float: right; background-color: #d92a73;; border-radius:24px; color:white; font-weight: bold; font-size:18px; padding: 5px; cursor: pointer; width: 130px; text-align: center;">
      {{'addNew' | translate}}
    </div>
  </div>


  <div>
    <ngx-datatable
      style="margin-top: 20px; margin-bottom: 70px; cursor: pointer;"
      #table
      class="material expandable"
      [rows]="machines"
      [columnMode]="'force'"
      [headerHeight]="50"
      [footerHeight]="75"
      [rowHeight]="'auto'"
      [scrollbarH]="true"
      [loadingIndicator]="isLoading"
      [rowClass]="getRowClass"
      [scrollbarV]="false"
      (activate)="openHistory($event)">
      <ngx-datatable-column sortable="false" prop="id" [width]="30" >
        <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
          <span>{{value}}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column sortable="false" prop="name" name="{{'name' | translate}}" [width]="30">
        <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
          <span>{{value}}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column sortable="false" prop="status" [width]="30"  name="{{'status' | translate}}" >
        <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
          <span *ngIf="value == 0">{{'good' | translate}}</span>
          <span *ngIf="value == 1">{{'inRepair' | translate}}</span>
          <span *ngIf="value == 2">{{'broken' | translate}}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column sortable="false" prop="lastChange" [width]="30"  name="{{'lastChange' | translate}}" >
        <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
          <span> {{value}}</span>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>

  </div>

</div>
