import {Component, Input, OnInit} from '@angular/core';
import {Observable} from "rxjs/Observable";
import {ApiService} from "../../../api.service";
import {TranslateService} from "@ngx-translate/core";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-customer-not-satisfied',
  templateUrl: './customer-not-satisfied.component.html',
  styleUrls: ['./customer-not-satisfied.component.css']
})
export class CustomerNotSatisfiedComponent implements OnInit {
  @Input() start;
  @Input() end;
  @Input() datesChanged: Observable<void>;

  loading = false;
  labels = [];
  data = [{}];
  numbers = [];
  options = {
    cutoutPercentage: 65,
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return value + '%' ;
        }
      }
    }
  };
  colors = [
    {backgroundColor: ["#2D99FF", "#FF7300", "#2CD9C5", "#826AF9"]}
  ];
  total = 0;
  constructor(private service: ApiService, public translate: TranslateService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.getCustomerSatisfaction();

    this.datesChanged.subscribe((data: any) => {
      if (data) {
        this.start = data.start;
        this.end = data.end;
        this.getCustomerSatisfaction();
      }
    });
  }

  getCustomerSatisfaction() {
    this.loading = true;
    const start = this.datePipe.transform(this.start, 'yyyy-MM-dd');
    const end = this.datePipe.transform(this.end, 'yyyy-MM-dd');
    this.service.getStatisticsCustomerNotSatisfied(start, end).subscribe((apiData: any) => {
      this.data = [{data: apiData.data}];
      this.labels = apiData.labels;
      this.total = apiData.total;
      this.numbers = apiData.numbers;
      this.loading = false;
    })
  }


}
