import {Component, Input, OnInit} from '@angular/core';
import {ApiService} from "../../../api.service";
import {TranslateService} from "@ngx-translate/core";
import {Observable} from "rxjs/Observable";


@Component({
  selector: 'app-user-per-country',
  templateUrl: './user-per-country.component.html',
  styleUrls: ['./user-per-country.component.css']
})
export class UserPerCountryComponent implements OnInit {
  @Input() refreshData: Observable<void>;
  loading = false;
  data = [];
  labels = [];
  total = 0;
  colors = [
    {backgroundColor: ["#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF", "#2D99FF"]}
  ];
  public barChartOptions = {
    barThickness: 2
  };
  constructor(private service: ApiService, private translate: TranslateService) { }

  ngOnInit() {
    this.getCustomersPerCountry();

    this.refreshData.subscribe((data: any) => {
      if (data) {
        this.getCustomersPerCountry();
      }
    });
  }

  getCustomersPerCountry() {
    this.loading = true;
      this.service.getStatisticsUserPerCountry().subscribe((apiData: any) => {
        this.labels = apiData.labels.map(l => this.translate.instant(l));
        this.data = [{data: apiData.data,  barPercentage: 0.5}];
        this.total = apiData.total;
        this.loading = false;
      })
  }


}
