
<div style="padding: 10px;">
  {{'areYouSureYouWantToDelete' | translate}} {{data['data']['service']}}?
  <br> <br>
  <div style="text-align: center">
<span class="addButton" style="cursor: pointer; padding-left: 10px; padding-right: 10px; padding-top: 5px; padding-bottom: 5px;" (click)="save()">
        {{'save' | translate}}
      </span>
  </div>
</div>

