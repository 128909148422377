<div class="campaign-popup">
  <mat-icon class="modal-close" (click)="dialogRef.close()">close</mat-icon>
  <div class="campaign-info" *ngIf="data.mode == 1">
    <div class="campaign-status">
      <span *ngIf="data.campaign.status == 0" style="color:#F0CB15">{{'waiting' | translate}}</span>
      <span *ngIf="data.campaign.status == 1" style="color:#FF9D56">{{'inProgress' | translate}}</span>
      <span *ngIf="data.campaign.status == 2" style="color:#2AC090">{{'done' | translate}}</span>
      <span *ngIf="data.campaign.status == 3" style="color:#F44C4C">{{'error' | translate}}</span>
    </div>
    <div class="campaign-name">
      {{data.campaign.name}}
    </div>
    <mat-label>{{'title' | translate}}</mat-label>
    <div class="campaign-title" *ngIf="data.campaign.type != 3">
      {{data.campaign.title}}
    </div>
    <mat-label>{{'text' | translate}}</mat-label>
    <div class="campaign-text" *ngIf="data.campaign.type != 1">
      {{data.campaign.text}}
    </div>
    <div *ngIf="data.campaign.type == 1" class="campaign-text-email" [innerHTML]="data.campaign.text"></div>
    <mat-label *ngIf="data.campaign.type != 2">{{'buttonText' | translate}}</mat-label>
    <div class="campaign-title" style="margin-top: 5px" *ngIf="data.campaign.type == 1 && selectedButton">
      <app-button  [text]="data.campaign.button_text"
                  [color]="selectedButton.background_color" [textColor]="selectedButton.color" height="48px" width="204px"></app-button>
    </div>
    <app-button></app-button>
    <img class="campaign-image" [src]="data.campaign.imageUrl">

    <div class="campaign-details">
      <span class="campaign-details-first">
        {{'start' | translate}}:
      </span>
      {{data.campaign.start | date : 'dd.MM.yyyy HH:mm'}}
    </div>
    <div class="campaign-details">
      <span class="campaign-details-first">
        {{'type' | translate}}:
      </span>
      <span *ngIf="data.campaign.type == 1">{{'email' | translate}}</span>
      <span *ngIf="data.campaign.type == 2">{{'notifications' | translate}}</span>
      <span *ngIf="data.campaign.type == 3">{{'viber' | translate}}</span>
    </div>
    <div class="campaign-details campaign-details__start">
      <span class="campaign-details-first" style="margin-top: 20px">
        {{'listOFAudience' | translate}}:
      </span>
      <div style="margin-top: 20px">
        <div *ngFor="let a of getAudienceArray()">
          {{a.name}} ({{a.userCount}})
        </div>
      </div>
    </div>
    <div class="campaign-details" style="border-bottom: 0 !important;">
           <span class="campaign-details-first">
        {{'total' | translate}}:
      </span>
      {{totalCount}}
    </div>
  </div>
  <div class="campaign-duplicate" *ngIf="data.mode == 2">
    <div class="campaign-popup-title">{{'duplicateCampaign' | translate}}</div>
    <app-campaign-form [width]="'400px'" (campaignChange)="campaignChanged($event)"  isPopup="1" [audiences]="data.audiences" [mode]="data.campaign.type" [campaign]="data.campaign" [buttons]="buttons" isNew="1" [audienceIds]="audienceIds"></app-campaign-form>
  </div>
  <div class="campaign-duplicate" *ngIf="data.mode == 3">
    <div class="campaign-popup-title">{{'editCampaign' | translate}}</div>
    <app-campaign-form [width]="'400px'"  isPopup="1"  (campaignChange)="campaignChanged($event)"  [audiences]="data.audiences" [mode]="data.campaign.type" [campaign]="data.campaign" [buttons]="buttons" isNew="0" [audienceIds]="audienceIds"></app-campaign-form>
  </div>
  </div>
