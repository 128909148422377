import {MatSnackBar} from "@angular/material/snack-bar";
import {Component, OnInit} from "@angular/core";
import {ApiService} from "../api.service";
import {Admin, Social} from "../models";
import {DataService} from "../data-service.service";
import {DomSanitizer} from "@angular/platform-browser";


@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css'],
  providers: [ApiService]
})
export class AboutComponent implements OnInit {
  user: Admin;
  showHeader = true;
  country = '';
  currentPage = 'about';
  social: Object= [];

  constructor(private service: ApiService, private snackBar: MatSnackBar, private dataService: DataService, private sanitizer: DomSanitizer) {
this.sanitizer = sanitizer;
  }

  ngOnInit() {
    this.country = localStorage.getItem('country');
    this.dataService.country.subscribe((country) => {
      console.log("Order country changed - " + country);
      this.country = country;
    });

    this.service.getSocials(this.country).subscribe(
      data => {
        this.social = data;
        console.log(this.social);
      }
    );

    this.user = JSON.parse(localStorage.getItem('adminYolo'));
    window.addEventListener('scroll', this.scroll, true);
  }

  toTop(id) {
    document.getElementById(id).scrollIntoView({behavior: "smooth"});
  }

  scroll = (): void => {
    console.log(window.pageYOffset);

    if (window.pageYOffset > 120) {
      this.showHeader = false;
    } else {
      this.showHeader = true;
    }

    if (window.pageYOffset > 0) {
      this.currentPage = 'about';
    }

  };
}
