
<mat-card class="col-md-10 offset-md-1 col-12" style="margin-top: 40px">
  <span style="float:left">
    <h4 *ngIf="!editMode">{{'addPromo' | translate}}</h4>
    <h4 *ngIf="editMode">{{'editPromo' | translate}}</h4>
    </span>

  <span *ngIf="editMode" style="float: right;" title="{{'delete' | translate}}"><mat-icon style="cursor: pointer;"
                                                                                          (click)="deletePromo(promo)">delete</mat-icon></span>
  <br style="float:unset; clear: both;">
  <mat-divider></mat-divider>
  <br>
  <mat-card class="col-12">
    <div class="row">
      <div class="col-sm-6 col-md-6 col-lg-3">
        <mat-form-field style="float: left; width: 100%">
          <input matInput placeholder="{{'promoCode' | translate}}" [(ngModel)]="promo.name">
        </mat-form-field>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-3">
        <mat-form-field style="float: left; width: 100%">
          <input matInput placeholder="{{'discount' | translate}}" type="number" step="0.1"
                 [(ngModel)]="promo.discount">
          <span matSuffix>%</span>
        </mat-form-field>
      </div>
      <div class="col-12 col-sm-6 col-md-6 col-lg-3">
        <div style="float:left; padding-top: 20px; width: 50%;">
          <mat-checkbox [(ngModel)]="allowMax">Max</mat-checkbox>
        </div>
        <div style="float:right; width: 50%; right: 40px;">
          <mat-form-field style="width: 100%;">
            <input matInput placeholder="Max" [disabled]="!allowMax" [(ngModel)]="promo.max">
          </mat-form-field>
        </div>
      </div>

      <div class="col-12 col-sm-6 col-md-6 col-lg-3" style="padding-top: 20px;">
        <mat-select placeholder="{{'country' | translate}}" [(ngModel)]="promo.country">
          <mat-option [value]="'rs'">Serbia</mat-option>
          <mat-option [value]="'ro'">Romania</mat-option>
          <mat-option [value]="'hr'">Croatia</mat-option>
          <mat-option [value]="'ba'">Bosnia</mat-option>
          <mat-option [value]="'sk'">Slovakia</mat-option>
          <mat-option [value]="'at'">Austria</mat-option>
          <mat-option [value]="'si'">Slovenia</mat-option>
          <mat-option [value]="'de'">Germany</mat-option>
          <mat-option [value]="'bg'">Bulgaria</mat-option>
          <mat-option [value]="'be'">Belgium</mat-option>
          <mat-option [value]="'nl'">Netherlands</mat-option>
          <mat-option [value]="'dk'">Denmark</mat-option>
          <mat-option [value]="'hu'">Hungary</mat-option>
          <mat-option [value]="'pl'">Poland</mat-option>
          <mat-option [value]="'li'">Liechtenstein</mat-option>
          <mat-option [value]="'lu'">Luxembourg</mat-option>
          <mat-option [value]="'lt'">Lithuania</mat-option>
          <mat-option [value]="'sm'">San Marino</mat-option>
          <mat-option [value]="'tr'">Turkey</mat-option>
          <mat-option [value]="'pt'">Portugal</mat-option>
          <mat-option [value]="'gr'">Greece</mat-option>
          <mat-option [value]="'it'">Italia</mat-option>
          <mat-option [value]="'mc'">Monaco</mat-option>
          <mat-option [value]="'lv'">Latvia</mat-option>
          <mat-option [value]="'fr'">France</mat-option>
          <mat-option [value]="'ee'">Estonia</mat-option>
          <mat-option [value]="'lt'">Sweden</mat-option>


        </mat-select>
      </div>

      <div class="col col-sm-4 col-md-4 col-lg-4">
        <mat-form-field style="float: left; width:100%">
          <input matInput [matDatepicker]="picker" placeholder="{{'from' | translate}}" [(ngModel)]="promo.from"
                 (dateChange)="onFromChanged(promo, $event)">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col col-sm-4 col-md-4 col-lg-4">
        <mat-form-field style="float: left; width:100%">
          <input matInput [matDatepicker]="picker2" placeholder="{{'to' | translate}}" [(ngModel)]="promo.to"
                 (dateChange)="onToChanged(promo, $event)">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col col-sm-4 col-md-4 col-lg-4">
        <mat-form-field style="width: 100%;">
          <input matInput placeholder="Minimalan Iznos" [(ngModel)]="promo.minAmount">
        </mat-form-field>
      </div>

    </div>
    <div class="row">
      <div class="col-12" style="padding-top: 20px;">
        <mat-checkbox [checked]="promo.productsRaw.length == 0"
                      (change)="changeConditions()">{{'noConditions' | translate}}</mat-checkbox>
      </div>
      <div class="col-6" *ngFor="let c of promo.productsRaw; let i = index">
        <mat-form-field style="display: block">
          <mat-select placeholder="{{'condition' | translate}}" multiple [(ngModel)]="c"
                      (selectionChange)="selectionChange(i, c)">
            <mat-option *ngFor="let p of products" [value]="p.type">
              <div style="display: inline-flex !important;">
                {{p.name}}
              </div>
            </mat-option>





          </mat-select>
        </mat-form-field>
      </div>
      <div class="col" *ngIf="promo.products.length > 0">
        <button mat-flat-button color="accent" style="position: relative;  display: block; margin: 12px 0 0 0;"
                (click)="addOrCondition()">{{'addOrCondition' | translate}}</button>
      </div>
    </div>
    <div>
      <hr>
      <button *ngIf="!editMode" mat-raised-button color="primary"
              style="cursor: pointer; display: block;  margin: auto; width: 200px; align-self: flex-end"
              (click)="createPromo()">{{'add' | translate}}</button>
      <button *ngIf="editMode" mat-raised-button color="primary"
              style="cursor: pointer; display: block;  margin: auto; width: 200px; align-self: flex-end"
              (click)="updatePromo(promo)">{{'update' | translate}}</button>

    </div>
  </mat-card>
  <br>
  <mat-divider></mat-divider>
  <br>
  <span *ngIf="editMode">
    <button mat-flat-button style="float:right" (click)="addNew()">{{'newPromo' | translate}}</button>
    <br> <br>
  </span>
  <ngx-datatable
    style="margin-top: 20px; margin-bottom: 70px; cursor: pointer; float: unset; clear: both"
    #table
    class="material expandable"
    [rows]="promoList"
    [columnMode]="'force'"
    infiniteScroll style="height: 93vh; overflow-y: scroll; cursor: pointer"
    [scrollWindow]="false"
    [infiniteScrollDistance]="5"
    [infiniteScrollThrottle]="50"
    (scrolled)="onScrollDown()"
    [headerHeight]="50"
    [footerHeight]="75"
    [rowHeight]="'100px'"
    [scrollbarH]="true"
    (activate)="onActivate($event)"
    [loadingIndicator]="isLoading"
    [rowClass]="getRowClass"
    [scrollbarV]="false">
    <ngx-datatable-column sortable="false" prop="id" [width]="100">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template
                   style="cursor: pointer">
        <span>{{value}}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column sortable="false" prop="country" name="{{'country' | translate}}" [width]="200"
                          *ngIf="country == 'all'">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template
                   style="cursor: pointer">
        <span><img [src]="imageUrl(value)"
                   style="width:30px; height: 30px; border-radius: 50%; object-fit: cover"></span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column sortable="false" prop="name" name="{{'promoCode' | translate}}" [width]="140">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template
                   style="cursor: pointer">
        <span>{{value}}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column sortable="false" prop="discount" name="{{'discount' | translate}}" [width]="100">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
        <span>{{value}}%</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column sortable="false" prop="from" name="{{'from' | translate}}" [width]="100">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template
                   style="cursor: pointer">
        <span>{{value | date : 'shortDate'}}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column sortable="false" prop="to" name="{{'to' | translate}}" [width]="100">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template
                   style="cursor: pointer">
        <span>{{value | date : 'shortDate'}}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column sortable="false" prop="minAmount" name="Min Iznos" [width]="100">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template
                   style="cursor: pointer">
        <span *ngIf="value != -1">{{value}}</span>
        <span *ngIf="value == -1"> <mat-icon>clear</mat-icon> </span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column sortable="false" prop="max" name="Max" [width]="100">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template
                   style="cursor: pointer">
        <span *ngIf="value != -1">{{value}}</span>
        <span *ngIf="value == -1"> <mat-icon>clear</mat-icon> </span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column sortable="false" prop="sendBill" name="{{'sendBill' | translate}}" [width]="100">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template
                   style="cursor: pointer">
        <span *ngIf="value == 1"><mat-icon>check</mat-icon></span>
        <span *ngIf="value == 0"> <mat-icon>clear</mat-icon> </span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column sortable="false" prop="count" name="{{'used' | translate}}" [width]="100">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template
                   style="cursor: pointer">
        <span>{{value}}</span>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column sortable="false" prop="products" name="{{'condition' | translate}}" [width]="150">
      <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template style="cursor: pointer; text-overflow: ellipsis !important;
                   overflow: hidden!important;   white-space: nowrap!important;">
        <div *ngIf="value.length == 0">{{'noConditions' | translate}}</div>
        <div style="text-overflow: ellipsis !important;
  overflow: hidden!important;   white-space: nowrap!important; width: 100%!important; height: 100px!important;"
             title="{{getTitle(value)}}">
          <div *ngFor="let v of value;let i = index">
            <div *ngIf="v.length == 1">
              <span *ngIf="v == 1"> Yolobook </span>
              <span *ngIf="v == 4"> Yolobook XL </span>
              <span *ngIf="v == 2"> Yolobox </span>
              <span *ngIf="v == 3"> Yoloframe </span>
              <span *ngIf="v == 20"> YoloCalendar Basic </span>
              <span *ngIf="v == 21"> YoloCalendar Kids </span>
              <span *ngIf="v == 26"> YoloHard Mini S </span>
              <span *ngIf="v == 27"> YoloHard Maxi S </span>
              <span *ngIf="v == 30"> YoloTiles Square </span>
              <span *ngIf="v == 31"> YoloTiles Hexa </span>
              <span *ngIf="v == 35"> YoloPremium </span>
              <span *ngIf="v == 46"> YoloPremiumCustom </span>

            </div>
            <div *ngIf="v.length > 1">
              <div *ngFor="let m of v; let in = index">
                <span *ngIf="m == 1"> Yolobook</span>
                <span *ngIf="m == 4"> Yolobook XL </span>
                <span *ngIf="m == 2"> Yolobox</span>
                <span *ngIf="m == 3"> Yoloframe</span>
                <span *ngIf="m == 20"> YoloCalendar Basic </span>
                <span *ngIf="m == 21"> YoloCalendar Kids </span>
                <span *ngIf="m == 26"> YoloHard Mini S </span>
                <span *ngIf="m == 27"> YoloHard Maxi S </span>
                <span *ngIf="m == 30"> YoloTiles Square </span>
                <span *ngIf="m == 31"> YoloTiles Hexa </span>
                <span *ngIf="v == 35"> YoloPremium </span>
                <span *ngIf="v == 46"> YoloPremiumCustom </span>
                <span *ngIf="in != v.length - 1">, </span>
              </div>
            </div>
            <span *ngIf="i != value.length - 1 "> {{'or' | translate}}</span>
          </div>
        </div>
      </ng-template>
    </ngx-datatable-column>

  </ngx-datatable>
</mat-card>
