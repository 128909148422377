<div style="width: 100%; height: 100vh; background-color: #1b233f">
  <input #blur type="text" style="position: absolute; opacity: 0; height: 0; width: 0">
  <div class="container col-12 offset-0 col-md-4 offset-md-4" style="padding-top: 32px">
    <mat-card>
      <div style="text-align: center">
        <img src="assets/logo.png">
      </div>
      <br>
      <mat-form-field style="width: 100%">
        <input type="text" matInput #usernameInput placeholder="Korisničko ime" (keyup.enter)="passwordInput.focus()"/>
      </mat-form-field>
      <mat-form-field style="width: 100%">
        <input type="password" matInput #passwordInput placeholder="Lozinka" (keyup.enter)="onLogin(usernameInput.value, passwordInput.value, blur)"/>
      </mat-form-field>
      <br>
      <br>
      <button mat-raised-button color="warm" style="width: 100%" (click)="onLogin(usernameInput.value, passwordInput.value, blur)">Prijava</button>
    </mat-card>
  </div>
</div>
