<div class="container">
  <div class="row">
    <!--    <div class="col-1"></div>-->
    <div class="col-12" style="margin: 0 auto"><br><br>
      <h2>{{'products' | translate}}</h2>
      <br>
      <table class="table bg-light ">
        <thead>
        <tr>
          <th scope="col">ID</th>
          <th scope="col">{{"image" | translate}}</th>
          <th scope="col">{{"name" | translate}}</th>
          <th scope="col">{{"status" | translate}}</th>
          <th scope="col">Count</th>
          <th scope="col">{{"actions" | translate}}</th>
          <th scope="col">{{"lager" | translate}}</th>
        </tr>
        </thead>
        <tbody>

        <tr *ngFor="let product of products">
          <td>{{product.id}} </td>
          <td><img src="{{product.image}}" class="height-image" alt=""></td>
          <td>{{product.name}}</td>
          <td *ngIf="product.isActive == 1">
            <span class="material-icons text-success">
              done
            </span>
          </td>
          <td *ngIf="product.isActive == 0">
            <span class="material-icons text-danger">
              clear
            </span>
          </td>
          <td>{{product.boxCount}}</td>
          <td *ngIf="product.isActive == 1">
            <button class="btn btn-danger" (click)="deactivate(product.id);">{{'deactivate' | translate}}</button>
          </td>
          <td *ngIf="product.isActive == 0">
            <button class="btn btn-success" (click)="activate(product.id);">{{'activate' | translate}}</button>
          </td>
          <td>
            <mat-form-field class="example-full-width">
              <input matInput placeholder="{{'lager' | translate}}" [(ngModel)]="product.lager"
                     (keyup.enter)="changeLagerValues($event,product)">
            </mat-form-field>

          </td>
        </tr>


        </tbody>
      </table>
    </div>
    <!--    <div class="col-1"></div>-->
  </div>
</div>
