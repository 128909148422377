<div class="header" style="background-color: #d92a73">
    <span style="font-size:1.7em; color:white; padding-left: 20px; font-weight: 500; ">
      {{'survey' | translate}}
    </span>
</div>
<mat-card class="col-md-10 offset-md-1 col-12">
  <div class="row">
    <div class="col-12 col-xl-2 offset-xl-8">
      <mat-form-field style="float: left; width: 100%">
        <input #fromInput matInput [matDatepicker]="pickerFrom" placeholder="Od"
               (dateChange)="onDateChanged($event.value, 'start')" [(ngModel)]="fromD">
        <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
        <mat-datepicker #pickerFrom></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-12 col-xl-2">
      <mat-form-field style="float: left; width: 100%">
        <input #toInput matInput [matDatepicker]="pickerTo" placeholder="Do"
               (dateChange)="onDateChanged($event.value, 'end')" [(ngModel)]="toD">
        <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
        <mat-datepicker #pickerTo></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-6" *ngIf="pieChartData">
      <div class="col align-self-center">
        <h4>{{'survey' | translate}}</h4>
      </div>
      <canvas baseChart height="200"
              [data]="pieChartData"
              [labels]="pieChartLabel"
              [options]="albumPercentageOptions"
              [legend]="true"
              [chartType]="'pie'">
      </canvas>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-12" *ngIf="chartReady">
      <div class="col align-self-center">
        <h4>TEST</h4>
      </div>
      <canvas baseChart height="100"
              [datasets]="dailyLoginsData"
              [labels]="dailyLoginsLabels"
              [options]="albumPercentageOptions"
              [legend]="true"
              [chartType]="'line'"></canvas>
    </div>
  </div>
</mat-card>
