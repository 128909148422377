import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from "../../api.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {AddressModalComponent} from "../user/address-modal/address-modal.component";
import {CampaignPopupComponent} from "../campaign-popup/campaign-popup.component";
import {ConfirmModalComponent} from "../../confirm-modal/confirm-modal.component";

@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.css']
})
export class CampaignsComponent implements OnInit, OnDestroy {
  showHeader = false;
  searchText = '';
  campaigns = [];
  audiences = [];
  interval: any;
  constructor(private service: ApiService,
              private snackBar: MatSnackBar,
              private translate: TranslateService,
              private dialog: MatDialog) { }

  ngOnInit() {
    this.getCampaigns();
    this.getAudiences();

    this.interval = setInterval(() => {
      this.getCampaigns();
    }, 10000);
  }

  getCampaigns() {
    let text = this.searchText;
    if (text == '') {
      text = '*';
    }
    this.service.getCampaigns(text).subscribe((data: any[]) => {
      this.campaigns = data;
    }, (err) => {
      this.snackBar.open(this.translate.instant('thereWasAnError'), '', {duration: 2000});
    })
  }

  getAudiences() {
    this.service.getAudiences().subscribe((audiences: any[]) => {
      this.audiences = audiences;
    })
  }

  getAudiencesText(stringIds: string) {
    const ids = stringIds.split(",");
    let text = '';

    const firstAudienceIndex = this.audiences.findIndex((a) => a.id == ids[0]);
    if (firstAudienceIndex != -1) {
      text = text + this.audiences[firstAudienceIndex].name;
    }

    if (ids.length > 1) {
      text = text + ", +" + (ids.length - 1) ;
    }

    return text;

  }

  getTranslation(value) {
    return this.translate.instant(value);
  }

  openPopup(campaign, mode) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'no-info-dialog';
    dialogConfig.data = {campaign, mode, audiences: this.audiences};
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;

    const dialogOpen = this.dialog.open(CampaignPopupComponent, dialogConfig);

    dialogOpen.afterClosed().subscribe((result: any) => {
      if (result) {
        const index = this.campaigns.findIndex(c => c.id == result.id);

        if (index == -1) {
          this.campaigns.unshift(result);
        } else {
          this.campaigns[index] = result;
        }

        this.campaigns = [...this.campaigns];
      }
    });
  }

  deleteCampaign(campaign) {
    const openDeleteDialog = this.dialog.open(ConfirmModalComponent, {
      disableClose: true,
      width: '400px',
      height: '200px',
      position: {
        top: '150px',
      },
      data: {
        data: '',
        message: this.translate.instant('areYouSureYouWantToDeleteCampaign'),
        mode: 'other',
      },
    });
    openDeleteDialog.afterClosed().subscribe((response) => {
      if (response) {
        this.service.deleteCampaign(campaign.id).subscribe((data) => {
          this.snackBar.open(this.translate.instant('deleted'), '', {duration: 2000});
          const index = this.campaigns.findIndex(c => c.id == campaign.id);
          if (index != -1 ) {
            this.campaigns.splice(index, 1);
            this.campaigns = [...this.campaigns];
          }
        }), (err) => {
          this.snackBar.open(this.translate.instant('thereWasAnError'), '', {duration: 2000});

        }
      }
    });
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

}
