 import {Component, OnInit} from '@angular/core';
import {formatDate} from "@angular/common";
import {ApiService} from "../api.service";
import {DataService} from "../data-service.service";
import {Admin} from "../models";
import {Router} from "@angular/router";

@Component({
  selector: 'app-marketing-statistic',
  templateUrl: './marketing-statistic.component.html',
  styleUrls: ['./marketing-statistic.component.css']
})
export class MarketingStatisticComponent implements OnInit {

  dateTime = new Date().toUTCString();
  date = formatDate(new Date().toUTCString(), 'y-MM-dd', 'en');
  ordersCount = 0;
  traffic = 0;
  usersCount = 0;
  changed = false;
  loading = false;

  user: Admin;
  allowedEdit = false;

  constructor(
    private apiService: ApiService,
    private dataService: DataService,
    private router: Router
  ) {
  }

  ngOnInit() {

    this.user = JSON.parse(localStorage.getItem('adminYolo'));
    if (!this.checkPermission('statistics')) {
      this.router.navigate(['/']);
    }

    this.date = formatDate(this.dateTime, 'y-MM-dd', 'en');
    this.changedDate();
    this.dataService.country.subscribe((country) => {
      this.changedDate();
    });
  }

  checkPermission(page) {
    const permissions = this.user.permissions;
    const rightPermission = permissions.find((x) => x.page === page);
    if (rightPermission) {
      if (rightPermission.edit === 1) {
        this.allowedEdit = true;
      } else {
        this.allowedEdit = false;
      }
      if (rightPermission.view === 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }


  changedDate() {
    this.loading = true;
    this.apiService.marketingStatistics(this.date).subscribe(
      data => {
        this.changed = true;
        setTimeout(() => {
          this.changed = false;
          this.loading = false;
        }, 250);
        this.ordersCount = data['ordersCount'];
        this.traffic = data['ordersTraffic'];
        this.usersCount = data['newUsersCount'];
      }
    )
  }

}
