<div class="upselling-form-box">
  <mat-form-field class="upselling-form-field">
    <mat-label>{{'name' | translate}}</mat-label>
    <input matInput type="text" [(ngModel)]="upsellingAction.name">
  </mat-form-field>
  <br>
  <mat-form-field class="upselling-form-field">
    <mat-label>{{"country" | translate}}</mat-label>
    <mat-select [(value)]="upsellingAction.country">
      <mat-option *ngFor="let c of countries" [value]="c.value">{{c.name}}</mat-option>
    </mat-select>
  </mat-form-field>
  <br>
  <mat-form-field class="upselling-form-field--half">
    <input
      (focus)="fromPicker.open()"
      [(ngModel)]="upsellingAction.from"
      matInput
      [matDatepicker]="fromPicker"
      placeholder="{{'from' | translate}}">
    <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
    <mat-datepicker #fromPicker></mat-datepicker>
  </mat-form-field>
  <mat-form-field class="upselling-form-field--half upselling-form-field--half-last">
    <input
      (focus)="toPicker.open()"
      [(ngModel)]="upsellingAction.to"
      matInput
      [matDatepicker]="toPicker"
      placeholder="{{'to' | translate}}">
    <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
    <mat-datepicker #toPicker></mat-datepicker>
  </mat-form-field>
  <br>
  <mat-form-field class="upselling-form-field">
    <mat-label>{{"priority" | translate}}</mat-label>
    <mat-select [(value)]="upsellingAction.priority">
      <mat-option *ngFor="let p of priorities" [value]="p">{{p}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="upselling-form-field">
    <mat-label>{{"targetProduct" | translate}}</mat-label>
    <mat-select [(value)]="upsellingAction.targetProduct.id">
      <mat-option *ngFor="let p of products" [value]="p.id">{{p.name}}</mat-option>
    </mat-select>
  </mat-form-field>

  <div class="upselling-form-product" *ngFor="let upsellingProduct of upsellingAction.products; let i = index">
    <img class="upselling-form-product-delete" *ngIf="upsellingProduct.product.id != -1" (click)="deleteProduct(i)" src="assets/ic_trash.svg">
    <mat-form-field class="upselling-form-field">
      <mat-label>{{"productWithDiscount" | translate}}</mat-label>
      <mat-select [(value)]="upsellingProduct.product.id">
        <mat-option *ngFor="let p of products" [value]="p.id">{{p.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <br>
    <mat-form-field class="upselling-form-field">
      <mat-label>{{'discount' | translate}}</mat-label>
      <input matInput type="text" [(ngModel)]="upsellingProduct.discount" min="0" max="100">
      <span matSuffix>%</span>
    </mat-form-field>
    <br>
  </div>
  <div class="upselling-form-add-more" (click)="addProduct()">{{'addMoreProducts' | translate}}</div>
  <div class="upselling-form-save-button">
    <app-button text="{{'save' | translate }}" (click)="save()" width="205px" height="48px" [color]="colors.PINK" [textColor]="colors.WHITE"></app-button>
  </div>

</div>
