
<mat-form-field>
  <input matInput type="text" [(ngModel)]="ordersArray" placeholder="{{'orders' | translate}}">
</mat-form-field>
<br>
<mat-form-field>
  <input matInput type="text" [(ngModel)]="promo" placeholder="{{'promoCode' | translate}}">
</mat-form-field>
<br>
<mat-form-field>
  <input matInput type="number" step="0.01" [(ngModel)]="price"  placeholder="{{'price' | translate}}">
</mat-form-field>


<div style="text-align: center">
  <button mat-raised-button style="width: 100%; background-color: #d92a73; color: white" (click)="merge()"> {{'merge' | translate}}</button>
</div>
