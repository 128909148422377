import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Subject} from "rxjs/Subject";
import {ApiService} from "../../api.service";
import {DatePipe} from "@angular/common";
import {DataService} from "../../data-service.service";

@Component({
    selector: 'app-customer-statistics',
    templateUrl: './customer-statistics.component.html',
    styleUrls: ['./customer-statistics.component.css']
})
export class CustomerStatisticsComponent implements OnInit {
    date = new Date();
    start = new Date(this.date.getFullYear(), this.date.getMonth(), 1).toISOString();
    end = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0).toISOString();
    datesChanged: Subject<any> = new Subject<any>();
    generalLoading = false;
    generalStatistics = null;


    constructor(private service: ApiService, private datePipe: DatePipe, private dataService: DataService) {
    }

    ngOnInit() {
        this.getGeneralStatistics();

        this.dataService.country.subscribe((country) => {
            if (country) {
                this.onDateChanged(country);
            }
        });
    }

    onDateChanged(value) {
        this.datesChanged.next({start: this.start, end: this.end});
        this.getGeneralStatistics();
    }



    getGeneralStatistics() {
        this.generalLoading = true;
        const start = this.datePipe.transform(this.start, 'yyyy-MM-dd');
        const end = this.datePipe.transform(this.end, 'yyyy-MM-dd');
        this.service.getGeneralStatistics(start, end).subscribe((apiData: any) => {
            this.generalStatistics = apiData;
            this.generalLoading = false;
        })
    }

}
