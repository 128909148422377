import { Component, OnInit } from '@angular/core';
import {Subject} from "rxjs/Subject";
import {ApiService} from "../../api.service";
import {DatePipe} from "@angular/common";
import {DataService} from "../../data-service.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-sales-statistics',
  templateUrl: './sales-statistics.component.html',
  styleUrls: ['./sales-statistics.component.css']
})
export class SalesStatisticsComponent implements OnInit {
  date = new Date();
  start = new Date(this.date.getFullYear(), this.date.getMonth(), 1).toISOString();
  end = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0).toISOString();
  datesChanged: Subject<any> = new Subject<any>();
  refreshData: Subject<any> = new Subject<any>();
  loading = false;
  salesStatistics = null;

  constructor(private service: ApiService, private datePipe: DatePipe, private dataService: DataService, private snackBar: MatSnackBar, private translate: TranslateService) {
  }

  ngOnInit() {
    this.getSalesStatistics();
    this.dataService.country.subscribe((country) => {
      if (country) {
        this.onDateChanged(country);
      }
    });
  }

  onDateChanged(value) {
    this.datesChanged.next({start: this.start, end: this.end});
    this.getSalesStatistics();
  }

  getSalesStatistics() {
    this.loading = true;
    const start = this.datePipe.transform(this.start, 'yyyy-MM-dd');
    const end = this.datePipe.transform(this.end, 'yyyy-MM-dd');
    this.service.getSalesStatistics(start, end).subscribe((apiData: any) => {
      console.log("got data");
      this.salesStatistics = apiData;
      setTimeout(() => {
        this.refreshData.next(apiData);
      }, 2000);

      this.loading = false;
    }, (err) => {
      console.log(err)
      this.snackBar.open(this.translate.instant('thereWasAnError'), 'X', {
        duration: 3000
      });
    })
  }


}

