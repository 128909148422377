<div class="dates-row">

  <mat-form-field>
    <input #fromInput matInput [matDatepicker]="pickerFrom" placeholder="{{'from' | translate}}"
           (dateChange)="onDateChanged($event.value)" [(ngModel)]="start">
    <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
    <mat-datepicker #pickerFrom></mat-datepicker>
  </mat-form-field>

  <mat-form-field>
    <input #fromInput matInput [matDatepicker]="pickerTo" placeholder="{{'to' | translate}}"
           (dateChange)="onDateChanged($event.value)" [(ngModel)]="end">
    <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
    <mat-datepicker #pickerTo></mat-datepicker>
  </mat-form-field>

</div>
<div class="statistics-row">
  <div class="statistics-box three-piece-row-box">
    <div class="statistics-box-title">
      {{'totalNumberOfUsers' | translate}}
    </div>
    <app-user-per-status [datesChanged]="datesChanged.asObservable()" [start]="start" [end]="end"></app-user-per-status>

  </div>

  <div class="statistics-box three-piece-row-box">
    <div class="statistics-box-title">
      {{'customersSatisfaction' | translate}}
    </div>
    <app-customer-satisfaction [datesChanged]="datesChanged.asObservable()" [start]="start" [end]="end"></app-customer-satisfaction>

  </div>

  <div class="statistics-box three-piece-row-box">
    <div class="statistics-box-title">
      {{'notSatisfied' | translate}}
    </div>
    <app-customer-not-satisfied [datesChanged]="datesChanged.asObservable()" [start]="start" [end]="end"></app-customer-not-satisfied>

  </div>
</div>

<div class="general-all-time">
  <div class="statistics-box">
    <div class="statistics-box-title">
      {{'totalNumberOfOrders' | translate}}
    </div>
    <div class="all-time-data">
      <div *ngIf="!generalLoading">{{generalStatistics?.totalOrders}}</div>
      <div class="for-dots-loader" *ngIf="generalLoading">
        <app-dots-loader height="50px" color="#606060"></app-dots-loader>
      </div>
    </div>
  </div>

  <div class="statistics-box">
    <div class="statistics-box-title">
      {{'netRevenue' | translate}}
    </div>
    <div class="all-time-data">
      <div *ngIf="!generalLoading">{{generalStatistics?.netRevenue}}</div>
      <div class="for-dots-loader" *ngIf="generalLoading">
        <app-dots-loader height="50px" color="#606060"></app-dots-loader>
      </div>
    </div>
  </div>

  <div class="statistics-box">
    <div class="statistics-box-title">
      {{'aov' | translate}}
    </div>
    <div class="all-time-data">
      <div *ngIf="!generalLoading">€ {{generalStatistics?.averageOrderPrice}}</div>
      <div class="for-dots-loader" *ngIf="generalLoading">
        <app-dots-loader height="50px" color="#606060"></app-dots-loader>
      </div>
    </div>
  </div>

  <div class="statistics-box">
    <div class="statistics-box-title">
      {{'averageNumberOfOrders' | translate}}
    </div>
    <div class="all-time-data">
      <div *ngIf="!generalLoading">{{generalStatistics?.averageNumberOfOrders}}</div>
      <div class="for-dots-loader" *ngIf="generalLoading">
        <app-dots-loader height="50px" color="#606060"></app-dots-loader>
      </div>
    </div>
  </div>

</div>

<div class="statistics-row">
  <div class="statistics-box three-piece-row-box">
    <div class="statistics-box-title">
      {{'newOldCustomers' | translate}}
    </div>
    <app-new-old-users [datesChanged]="datesChanged.asObservable()" [start]="start" [end]="end"></app-new-old-users>
  </div>

  <div class="statistics-box three-piece-row-box">
    <div class="statistics-box-title">
      {{'newOldCustomersRevenue' | translate}}
    </div>
    <app-new-old-users-revenue [datesChanged]="datesChanged.asObservable()" [start]="start" [end]="end"></app-new-old-users-revenue>
  </div>

  <div class="statistics-box three-piece-row-box">
    <div class="statistics-box-title">
      {{'newOldCustomersTurnover' | translate}}
    </div>
    <app-new-old-users-turnover [datesChanged]="datesChanged.asObservable()" [start]="start" [end]="end"></app-new-old-users-turnover>

  </div>

</div>

<div class="statistics-row">
  <div class="statistics-box two-piece-row-box">
    <div class="statistics-box-title">
      {{'usersPerGender' | translate}}
    </div>
    <app-users-per-gender [datesChanged]="datesChanged.asObservable()" [start]="start" [end]="end"></app-users-per-gender>
  </div>

  <div class="statistics-box two-piece-row-box">
    <div class="statistics-box-title">
      {{'ordersPerUser' | translate}}
    </div>
    <app-orders-per-user [datesChanged]="datesChanged.asObservable()" [start]="start" [end]="end"></app-orders-per-user >
  </div>
</div>

<div class="statistics-row">
  <div class="statistics-box three-piece-row-box">
    <div class="statistics-box-title">
      {{'installedDeletedApp' | translate}}
    </div>
    <app-users-per-fcm [datesChanged]="datesChanged.asObservable()" [start]="start" [end]="end"></app-users-per-fcm >
  </div>

  <div class="statistics-box three-piece-row-box">
    <div class="statistics-box-title">
      {{'receivedOpenedNotification' | translate}}
    </div>
    <app-notifications-per-status [datesChanged]="datesChanged.asObservable()" [start]="start" [end]="end"></app-notifications-per-status>
  </div>

</div>

<div class="statistics-row">

  <div class="statistics-box full-width-box">
    <div class="statistics-box-title">
      {{'orders' | translate}}
    </div>
    <app-albums [datesChanged]="datesChanged.asObservable()" [start]="start" [end]="end"></app-albums>
  </div>
</div>

<div class="statistics-row">

  <div class="statistics-box full-width-box">
    <div class="statistics-box-title">
      {{'hourlyData' | translate}}
    </div>
    <app-hourly [datesChanged]="datesChanged.asObservable()" [start]="start" [end]="end"></app-hourly>
  </div>
</div>

<!--<div class="statistics-row">-->

<!--  <div class="statistics-box full-width-box">-->
<!--    <div class="statistics-box-title">-->
<!--      RPV-->
<!--    </div>-->
<!--    <app-rpv [datesChanged]="datesChanged.asObservable()" [start]="start" [end]="end"></app-rpv>-->
<!--  </div>-->
<!--</div>-->

<div class="statistics-row">
  <div class="statistics-box three-piece-row-box">
    <div class="statistics-box-title">
      {{'usersOrdersPercentage' | translate}}
    </div>
    <app-user-orders [datesChanged]="datesChanged.asObservable()" [start]="start" [end]="end"></app-user-orders>

  </div>

  <div class="statistics-box three-piece-row-box">
    <div class="statistics-box-title">
      {{'loginType' | translate}}
    </div>
    <app-login-type [datesChanged]="datesChanged.asObservable()" [start]="start" [end]="end"></app-login-type>

  </div>

  <div class="statistics-box three-piece-row-box">
    <div class="statistics-box-title">
      {{'returningUsers' | translate}}
    </div>
    <app-returning-users [datesChanged]="datesChanged.asObservable()" [start]="start" [end]="end"></app-returning-users>

  </div>
</div>
