
<div class="flex container main-div">
  <div class="for-actions">
    <div class="mat-raised-button mat-primary" (click)="exit()"
         *ngIf="productPriceChange || deliveryPriceChange">
      {{'exitPriceChange' | translate}}
    </div>
  </div>
  <div>
    <div class="for-data">
      <mat-card class="data">
        <ngx-datatable
          #table
          class="material expandable data-table"
          [rows]="products"
          [columnMode]="'force'"
          [headerHeight]="50"
          [footerHeight]="75"
          [rowHeight]="'auto'"
          [scrollbarH]="true"
          [scrollbarV]="false"
        >
          <ngx-datatable-column name="{{'name' | translate}}" sortable="false" prop="name" width="160">
            <ng-template #unverifiedBtn let-row="row" let-rowIndex="rowIndex" let-value="value"
                         ngx-datatable-cell-template>
              {{value}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'price' | translate}}" sortable="false" prop="price" width="120">
            <ng-template #unverifiedBtn let-row="row" let-rowIndex="rowIndex" let-value="value"
                         ngx-datatable-cell-template>
              <div class="price-box" *ngIf="!productPriceChange">
                <span *ngIf="parseArray(noChangeProducts)[rowIndex]?.oldPrice"
                      class="old-price">{{parseArray(noChangeProducts)[rowIndex]?.oldPrice}} {{noChangeProducts[rowIndex]?.currency}}</span>
                {{parseArray(noChangeProducts)[rowIndex]?.price}} {{parseArray(noChangeProducts)[rowIndex]?.currency}}
              </div>

              <span *ngIf="productPriceChange">
                <mat-form-field>
                  <mat-label>{{row.priceColumn}}</mat-label>
                  <input matInput [name]="row.priceColumn" type="number" [(ngModel)]="products[rowIndex].price"
                         (ngModelChange)="changedValue()">
                <span matSuffix>{{products[rowIndex].currency}}</span>

                </mat-form-field>
              </span>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>


      </mat-card>
    </div>
    <div class="for-actions">
      <div class="mat-raised-button mat-accent" (click)="changeProductPriceMode(true)"
           *ngIf="!productPriceChange && !deliveryPriceChange">
        {{'changeProductPrices' | translate}}
      </div>

      <mat-checkbox class="discount" [(ngModel)]="isDiscount" *ngIf="productPriceChange"
                    matTooltip="{{'discountInfo' | translate}}">{{'isDiscount' | translate}} &#9432;
      </mat-checkbox>


      <div class="mat-raised-button mat-warn" (click)="confirmProductChange()" *ngIf="productPriceChange">
        {{'confirm' | translate}}
      </div>


    </div>

  </div>
  <div>
    <div class="for-data">
      <mat-card class="data">
        <ngx-datatable
          #table
          class="material expandable data-table"
          [rows]="deliveryRows"
          [columnMode]="'force'"
          [headerHeight]="50"
          [footerHeight]="75"
          [rowHeight]="'auto'"
          [scrollbarH]="true"
          [scrollbarV]="false"
        >
          <ngx-datatable-column name="{{'name' | translate}}" sortable="false" prop="name" width="160">
            <ng-template #unverifiedBtn let-row="row" let-rowIndex="rowIndex" let-value="value"
                         ngx-datatable-cell-template>
              <span *ngIf="rowIndex === 0"> {{'delivery' | translate}}</span>
              <span *ngIf="rowIndex === 1"> {{'deliveryFreeLimit' | translate}}</span>

            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'price' | translate}}" sortable="false" prop="price" width="120">
            <ng-template #unverifiedBtn let-row="row" let-rowIndex="rowIndex" let-value="value"
                         ngx-datatable-cell-template>
              <span *ngIf="rowIndex === 0">
                <span *ngIf="!deliveryPriceChange">
                {{parseArray(noChangeDeliveries)[0]?.price}} {{parseArray(noChangeDeliveries)[0]?.currency}}
                </span>
                <span *ngIf="deliveryPriceChange">
                <mat-form-field>
                  <input matInput type="number" [(ngModel)]="deliveries[0].price"
                         (ngModelChange)="changedValue()">
                  <span matSuffix>{{deliveries[0].currency}}</span>
                </mat-form-field>
                </span>
              </span>

              <span *ngIf="rowIndex === 1">
                <span *ngIf="!deliveryPriceChange">
                {{parseArray(noChangeDeliveries)[0]?.free_limit}} {{parseArray(noChangeDeliveries)[0]?.currency}}
                </span>
                <span *ngIf="deliveryPriceChange">
                <mat-form-field>
                  <input matInput type="number" [(ngModel)]="deliveries[0].free_limit"
                         (ngModelChange)="changedValue()">
                  <span matSuffix>{{deliveries[0].currency}}</span>
                </mat-form-field>
                </span>
              </span>

            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>

      </mat-card>
    </div>

    <div class="for-actions">
      <div class="mat-raised-button mat-accent" (click)="changeDeliveryPriceMode(true)"
           *ngIf="!productPriceChange && !deliveryPriceChange">
        {{'changeDeliveryPrice' | translate}}
      </div>
      <div class="mat-raised-button mat-warn" (click)="confirmDeliveryChange()" *ngIf="deliveryPriceChange">
        {{'confirm' | translate}}
      </div>
    </div>

  </div>
</div>
