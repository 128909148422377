<div class="new-issue-div">
    <mat-icon class="modal-close" (click)="dialogRef.close()">close</mat-icon>

    <div class="details-box__title">{{'addNewIssue' | translate}}</div>
<!--    hidden input field to prevent autofocus of the date field-->
    <input readonly type="text" style="opacity: 0; height: 0px !important;">
    <mat-form-field class="details-box__address_field ">
        <mat-label>{{"initiationDate" | translate}}</mat-label>
        <input readonly [autofocus]="false" style="cursor: auto;" [matDatepicker]="initiationDate" matInput
               formControlName="startDate"  (focus)="initiationDate.open()" (dateChange)="changedInitiationDate($event.value)">
        <mat-icon matSuffix (click)="initiationDate.open()">today</mat-icon>
        <mat-datepicker #initiationDate></mat-datepicker>
    </mat-form-field>
    <mat-label class="select-label">{{'typeOfRequest' | translate}}</mat-label>
    <mat-select [(value)]="issue.type" style="margin-bottom: 10px">
        <mat-option *ngFor="let t of data.issueOptions[1]" [value]="t.id">{{t.name}}</mat-option>
    </mat-select>
    <mat-form-field class="details-box__address_field" *ngIf="issue.type == 3">
        <mat-label>{{"requestExplanation" | translate}}</mat-label>
        <textarea matInput [(ngModel)]="issue.type_explanation"></textarea>
    </mat-form-field>
    <mat-label class="select-label">{{'status' | translate}}</mat-label>
    <mat-select [(value)]="issue.status" style="margin-bottom: 10px">
        <mat-option *ngFor="let t of data.issueOptions[2]" [value]="t.id">{{t.name}}</mat-option>
    </mat-select>
    <mat-form-field class="details-box__address_field ">
        <mat-label>{{"resolutionDate" | translate}}</mat-label>
        <input readonly [autofocus]="false" style="cursor: auto;" [matDatepicker]="resolutionDate" matInput
               formControlName="startDate"  (focus)="resolutionDate.open()" (dateChange)="changedResolutionDate($event.value)">
        <mat-icon matSuffix (click)="resolutionDate.open()">today</mat-icon>
        <mat-datepicker #resolutionDate></mat-datepicker>
    </mat-form-field>
    <mat-label class="select-label">{{'resolutionType' | translate}}</mat-label>
    <mat-select [(value)]="issue.resolution_type" style="margin-bottom: 10px">
        <mat-option *ngFor="let t of data.issueOptions[3]" [value]="t.id">{{t.name}}</mat-option>
    </mat-select>
    <div (click)="dialogRef.close(issue)" style="margin-top: 15px">
        <app-button text="{{'save' | translate}}"  [height]="'48px'" [width]="'104px'" [textColor]="colors.WHITE"
                    [color]="colors.PINK"></app-button>
    </div>
</div>
