import {Component, Input, OnInit} from '@angular/core';
import {Observable} from "rxjs/Observable";
import {ApiService} from "../../../api.service";
import {TranslateService} from "@ngx-translate/core";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-profit',
  templateUrl: './profit.component.html',
  styleUrls: ['./profit.component.css']
})
export class ProfitComponent implements OnInit {
  @Input() start;
  @Input() end;
  @Input() refreshData: Observable<void>;

  loading = false;
  labels = ['Delivery Costs', 'Production Costs', 'OPEX', 'It Costs', 'Marketing Costs', 'Profit'];
  data = [{}];
  options = {
    cutoutPercentage: 65,
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          const label = data.labels[tooltipItem.index];

          return value + '€ - ( ' + label + ' )';

        }
      }
    }
  };
  numbers = [];
  colors = [
    {backgroundColor: ["#2D99FF", "#FF7300", "#2CD9C5", "#826AF9", "#FF3399", "#00CC66", "#FFCC00", "#9933FF", "#FF9966", "#66CCCC", "#FF3333", "#66FF66", "#FF6600", "#3399FF", "#FFCC33", "#FF00FF", "#66FFCC", "#FF6666", "#0099CC", "#FF9933", "#CC33FF", "#33CC33", "#FF3366", "#66CCFF", "#FF9900", "#339966", "#FF0066", "#99CC33", "#FF6666", "#0066CC"]}
  ];
  total = 0;

  constructor(private service: ApiService, public translate: TranslateService, private datePipe: DatePipe) {}

  ngOnInit() {
      this.refreshData.subscribe((data: any) => {
        console.log("GOT REFRESHED DATA");

        this.total = data.revenue;
        this.data = [data.delivery, data.production, data.opex, data.it, data.marketing, data.profit];

      });
  }
}
