import {Component, OnInit, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ApiService} from "../api.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {mergeMap} from "rxjs/operators";
import {Observable} from "rxjs/Observable";
import {forkJoin} from "rxjs";

@Component({
  selector: 'app-cover-add-new',
  templateUrl: './cover-add-new.component.html',
  styleUrls: ['./cover-add-new.component.css']
})
export class CoverAddNewComponent implements OnInit {
  frontPic: any;
  backPic: any;
  firstPage: any;
  lastPage: any;
  amountValue = 0;
  frontImage: string | undefined;
  backImage: string | undefined;
  firstPageImage: string | ArrayBuffer;
  lastPageImage: string | ArrayBuffer;

  uploadedFrontImage = false;
  uploadedBackImage = false;
  uploadedFirstPage = false;
  uploadedLastPage = false;

  constructor(public dialog: MatDialog,
              public dialogRef: MatDialogRef<CoverAddNewComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private apiService: ApiService,
              private snackBar: MatSnackBar,
              private translate: TranslateService,
  ) {
  }

  ngOnInit() {
    if (this.data.type === 2) {
      this.frontPic = this.data.cover.imageFront;
      this.backPic = this.data.cover.imageBack;
      this.firstPageImage = this.data.cover.firstPage;
      this.lastPageImage = this.data.cover.lastPage;
      this.amountValue = this.data.cover.lager;
    }
  }

  saveCover() {
    if (this.data.type == 2) {
      this.updateCover();
    } else {
      this.createCover();
    }
  }

  createCover() {

    if (this.frontPic !== undefined && this.backPic !== undefined) {
      const sendData = {
        categoryId: this.data.categoryId,
        lager: this.amountValue,
      }
      this.apiService.addNewCover(sendData).subscribe((cover: any) => {

        if (this.uploadedFrontImage) {
          const frontFormData: FormData = new FormData();
          frontFormData.append('image', this.frontImage);
          this.apiService.uploadFrontImage(cover.id, frontFormData).subscribe();
          cover.imageFront = this.frontImage;
        }

        if (this.uploadedBackImage) {
          const backFormData: FormData = new FormData();
          backFormData.append('image', this.backImage);
          this.apiService.uploadBackImage(cover.id, backFormData).subscribe();
          cover.imageBack = this.backImage;


        }

        if (this.uploadedFirstPage) {
          const firstPageData: FormData = new FormData();
          firstPageData.append('image', this.firstPage);
          this.apiService.uploadCoverPages('first', cover.id, firstPageData).subscribe();
          cover.firstPage = this.firstPage;
        }

        if (this.uploadedLastPage) {
          const lastPageData: FormData = new FormData();
          lastPageData.append('image', this.lastPage);
          this.apiService.uploadCoverPages('last', cover.id, lastPageData).subscribe();
          cover.lastPage = this.lastPage;


        }

        setTimeout(() => {
          this.dialogRef.close(cover);
        }, 1000);

      });
    } else {
      this.snackBar.open(this.translate.instant('PleaseAddPicture'), 'OK', {duration: 2000});
    }
  }

  updateCover() {

    if (this.frontPic !== undefined && this.backPic !== undefined) {
      const sendData = {
        id: this.data.cover.id,
        categoryId: this.data.cover.category,
        lager: this.amountValue,
      }

      this.apiService.updateCover(sendData).subscribe((cover: any) => {
        if (this.uploadedFrontImage) {
          const frontFormData: FormData = new FormData();
          frontFormData.append('image', this.frontImage);
          this.apiService.uploadFrontImage(cover.id, frontFormData).subscribe();
          cover.imageFront = this.frontImage;
        }

        if (this.uploadedBackImage) {
          const backFormData: FormData = new FormData();
          backFormData.append('image', this.backImage);
          this.apiService.uploadBackImage(cover.id, backFormData).subscribe();
          cover.imageBack = this.backImage;


        }

        if (this.uploadedFirstPage) {
          const firstPageData: FormData = new FormData();
          firstPageData.append('image', this.firstPage);
          this.apiService.uploadCoverPages('first', cover.id, firstPageData).subscribe();
          cover.firstPage = this.firstPage;
        }

        if (this.uploadedLastPage) {
          const lastPageData: FormData = new FormData();
          lastPageData.append('image', this.lastPage);
          this.apiService.uploadCoverPages('last', cover.id, lastPageData).subscribe();
          cover.lastPage = this.lastPage;


        }

        setTimeout(() => {
          this.dialogRef.close(cover);
        }, 1000);

      });


    } else {
      this.snackBar.open(this.translate.instant('PleaseAddPicture'), 'OK', {duration: 2000});
    }
  }

  readImageURL(event: any, type: string): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const reader = new FileReader();
      if (type === 'front') {
        this.uploadedFrontImage = true;
        this.frontImage = event.target.files[0];
        reader.onload = () => this.frontPic = reader.result;
        reader.readAsDataURL(file);
      } else if (type === 'back') {
        this.backImage = event.target.files[0];
        reader.onload = () => this.backPic = reader.result;
        reader.readAsDataURL(file);
        this.uploadedBackImage = true;
      } else if (type === 'first') {
        this.firstPage = event.target.files[0];
        reader.onload = () => this.firstPageImage = reader.result;
        reader.readAsDataURL(file);
        this.uploadedFirstPage = true;
      } else if (type === 'last') {
        this.lastPage = event.target.files[0];
        reader.onload = () => this.lastPageImage = reader.result;
        reader.readAsDataURL(file);
        this.uploadedLastPage = true;
      }
    }
  }
}
