<div class="header" style="background-color: #d92a73; z-index: 5 !important; position: relative; padding-bottom: 18px"
     [ngClass]="{'hiddenHeader' : !showHeader}">
  <span
          style="font-size:1.7em; color:white; padding-left: 20px; font-weight: 500; margin-bottom:10px;"> {{'issues' | translate}} </span>
</div>

<div class="searchHeader"
     style="background-color: #b61052;  z-index: 5 !important; position: relative; display: flex; flex-wrap: wrap; font-size: 18px; color:white; font-weight: bold; align-content: stretch; justify-content: space-between;">
    <div class="menuFieldContainer">
        <div class="flex-grow-3 menuField" [matMenuTriggerFor]="filtered"
             style=" vertical-align: middle; min-width:17%; border-right: solid 2px rgba(45, 53, 71, 0.2); cursor:pointer;height: 100%;">
            <div class="filterText">
       <span class="d-none d-lg-block">
              <img src="assets/ic-search.svg">  {{'filter' | translate}} <img src="assets/ic-keyboard-arrow-down.svg">
       </span>
                <figure class="d-block d-sm-block d-md-block d-lg-none noMargin">
                    <img class="hidden-lg" src="assets/ic_pretrazi po.svg">
                </figure>
            </div>

        </div>
        <div class="flex-grow-3 menuField" [matMenuTriggerFor]="status"
             style=" vertical-align: middle; min-width:17%; border-right: solid 2px rgba(45, 53, 71, 0.2); cursor:pointer;height: 100%;">
            <div class="filterText">
       <span class="d-none d-lg-block">
            {{'status' | translate}} <img src="assets/ic-keyboard-arrow-down.svg">
       </span>
            </div>

        </div>
        <div class="flex-grow-3 menuField" [matMenuTriggerFor]="date"
             style=" vertical-align: middle; min-width:17%; border-right: solid 2px rgba(45, 53, 71, 0.2); cursor:pointer;height: 100%;">
            <div class="filterText">
       <span class="d-none d-lg-block">
               {{'date' | translate}} <img src="assets/ic-keyboard-arrow-down.svg">
       </span>
            </div>

        </div>
    </div>
    <mat-menu #filtered="matMenu" yPosition="below">
        <div style="padding:10px; width: 300px" (click)="stopPropagation($event)">
            <mat-form-field style="width: 80%; margin: 8px">
                <input
                        type="text"
                        matInput
                        placeholder="{{'name' | translate}}"
                        [(ngModel)]="filter.firstName"
                        (keyup.enter)="filterIssues(0)"/>
            </mat-form-field>
            <mat-form-field style="width: 80%; margin: 8px">
                <input
                        type="text"
                        matInput
                        placeholder="{{'lastName' | translate}}"
                        [(ngModel)]="filter.lastName"
                        (keyup.enter)="filterIssues(0)"/>
            </mat-form-field>
            <mat-form-field style="width: 80%; margin: 8px">
                <input
                        type="tel"
                        matInput
                        placeholder="ID"
                        [(ngModel)]="filter.id"
                        (keyup.enter)="filterIssues(0)"/>
            </mat-form-field>
            <mat-form-field style="width: 80%; margin: 8px">
                <input
                        type="text"
                        matInput
                        placeholder="Email"
                        [(ngModel)]="filter.email"
                        (keyup.enter)="filterIssues(0)"/>
            </mat-form-field>
            <mat-form-field style="width: 80%; margin: 8px">
                <input
                        type="text"
                        matInput
                        placeholder="{{'username' | translate}}"
                        [(ngModel)]="filter.username"
                        (keyup.enter)="filterIssues(0)"/>
            </mat-form-field>
            <mat-form-field style="width: 80%; margin: 8px">
                <input
                        type="text"
                        matInput
                        placeholder="{{'phone' | translate}}"
                        [(ngModel)]="filter.phone"
                        (keyup.enter)="filterIssues(0)"/>
            </mat-form-field>
        </div>
    </mat-menu>

    <mat-menu #status="matMenu" yPosition="below">
        <div style="padding:10px; width: 300px" (click)="stopPropagation($event)">
            <mat-checkbox [(ngModel)]="filter.isResolved">{{'resolved' | translate}}</mat-checkbox>
            <br>
            <mat-checkbox [(ngModel)]="filter.isActive">{{'active' | translate}}</mat-checkbox>
            <br>
        </div>
    </mat-menu>

    <mat-menu #date="matMenu" yPosition="below">
        <div style="padding:10px; width: 300px" (click)="stopPropagation($event)">
            <mat-form-field style="width: 80%; margin: 8px">
                <input
                        [value]="filter.startDate"
                        matInput
                        [matDatepicker]="pickerFromSatisfaction"
                        placeholder="{{'dateFrom' | translate}}"
                        (dateChange)="filter.startDate = $event.value.toISOString()">
                <mat-datepicker-toggle matSuffix [for]="pickerFromSatisfaction"></mat-datepicker-toggle>
                <mat-datepicker #pickerFromSatisfaction></mat-datepicker>
            </mat-form-field>
            <mat-form-field style="width: 80%; margin: 8px">
                <input
                        [value]="filter.endDate"
                        matInput
                        [matDatepicker]="pickerToSatisfaction"
                        placeholder="{{'dateTo' | translate}}"
                        (dateChange)="filter.endDate = $event.value.toISOString()">
                <mat-datepicker-toggle matSuffix [for]="pickerToSatisfaction"></mat-datepicker-toggle>
                <mat-datepicker #pickerToSatisfaction></mat-datepicker>
            </mat-form-field>
        </div>
    </mat-menu>
    <div class="search-right-list">
        <app-button [text]="translate.instant('search')" [textColor]="colors.PINK" [color]="colors.WHITE"
                    [width]="'111px'" style="margin-right: 30px"
                    [height]="'46px'" (click)="filterIssues(0)">
        </app-button>
        <div class="settings-box" [matMenuTriggerFor]="tableFilter">
            <mat-menu #tableFilter="matMenu" yPosition="below">
                <div style="padding:10px;" (click)="stopPropagation($event)">
                    <span style="font-weight: bold;">{{'showTable' | translate}}</span>
                    <br>
                    <div class="col">

                        <div class="col">
                            <mat-checkbox (change)="saveCollumns()"
                                          [(ngModel)]="collumns.name">{{'name' | translate}}</mat-checkbox>
                        </div>

                        <div class="col">
                            <mat-checkbox (change)="saveCollumns()"
                                          [(ngModel)]="collumns.lastname">{{'lastName' | translate}}</mat-checkbox>
                        </div>

                        <div class="col">
                            <mat-checkbox (change)="saveCollumns()"
                                          [(ngModel)]="collumns.initiationDate">{{'initiationDate' | translate}}</mat-checkbox>
                        </div>

                        <div class="col">
                            <mat-checkbox (change)="saveCollumns()"
                                          [(ngModel)]="collumns.type">{{'typeOfRequest' | translate}}</mat-checkbox>
                        </div>

                        <div class="col">
                            <mat-checkbox (change)="saveCollumns()"
                                          [(ngModel)]="collumns.status">{{'status' | translate}}</mat-checkbox>
                        </div>

                        <div class="col">
                            <mat-checkbox (change)="saveCollumns()"
                                          [(ngModel)]="collumns.resolutionDate">{{'resolutionDate' | translate}}</mat-checkbox>
                        </div>

                        <div class="col">
                            <mat-checkbox (change)="saveCollumns()"
                                          [(ngModel)]="collumns.resolution">{{'resolutionType' | translate}}</mat-checkbox>
                        </div>

                        <div class="col">
                            <mat-checkbox (change)="saveCollumns()"
                                          [(ngModel)]="collumns.benefit">{{'benefit' | translate}}</mat-checkbox>
                        </div>

                        <div class="col">
                            <mat-checkbox (change)="saveCollumns()"
                                          [(ngModel)]="collumns.providedBenefit">{{'providedBenefit' | translate}}</mat-checkbox>
                        </div>

                    </div>
                </div>
            </mat-menu>

            <img src="assets/ic_settings.svg" class="tableSettings"/>
        </div>
    </div>
</div>
<div class="listFlex">
    <div class="tagList">
        <div class="tag" *ngIf="filter.firstName">
            {{filter.firstName}}
            <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
                 (click)="filter.firstName = null"> &#10005;
            </div>
        </div>
        <div class="tag" *ngIf="filter.lastName">
            {{filter.lastName}}
            <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
                 (click)="filter.lastName = null"> &#10005;
            </div>
        </div>
        <div class="tag" *ngIf="filter.id">
            {{filter.id}}
            <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
                 (click)="filter.id = null"> &#10005;
            </div>
        </div>
        <div class="tag" *ngIf="filter.email">
            {{filter.email}}
            <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
                 (click)="filter.email = null"> &#10005;
            </div>
        </div>
        <div class="tag" *ngIf="filter.phone">
            {{filter.phone}}
            <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
                 (click)="filter.phone = null"> &#10005;
            </div>
        </div>
        <div class="tag" *ngIf="filter.username">
            {{filter.username}}
            <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
                 (click)="filter.username = null"> &#10005;
            </div>
        </div>
        <div class="tag" *ngIf="filter.isActive">
            {{'active' | translate}}
            <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
                 (click)="filter.isActive = false"> &#10005;
            </div>
        </div>
        <div class="tag" *ngIf="filter.isResolved">
            {{'resolved' | translate}}
            <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
                 (click)="filter.isResolved = false"> &#10005;
            </div>
        </div>
        <div class="tag" *ngIf="filter.startDate">
            {{filter.startDate  | date : 'dd.MM.yyyy' : 'Europe/Belgrade'}}
            <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
                 (click)="filter.startDate = null"> &#10005;
            </div>
        </div>
        <div class="tag" *ngIf="filter.endDate">
            {{filter.endDate  | date : 'dd.MM.yyyy' : 'Europe/Belgrade'}}
            <div class="closeButton" style="cursor: pointer; color:#424242; margin-left: 3px;"
                 (click)="filter.endDate = null"> &#10005;
            </div>
        </div>
    </div>
    <div (click)="resetFilter()">
        <app-button [text]="translate.instant('resetSearch')" [color]="colors.WHITE"
                    [textColor]="colors.DARK"
                    [width]="'174px'" [height]="'46px'"></app-button>
    </div>
</div>
<div class="bottomList">
    <div class="total-list">
        <div class="totalNumber">{{'total' | translate}}
            : {{issues.length}}</div>
        <div class="totalNumber">{{'shown' | translate}}
            : {{issues.length}}</div>
    </div>
</div>
<div class="table-container">
    <ngx-datatable
        #table
        class="material users-orders-list"
        [rows]="issues"
        [columnMode]="'force'"
        [headerHeight]="50"
        [footerHeight]="75"
        [rowHeight]="'auto'"
        [scrollbarH]="true"
        [scrollbarV]="false"
        [rowClass]="getRowClass"
        *ngIf="issues.length > 0"
    >

        <ngx-datatable-column *ngIf="collumns.name" [name]="getTranslation('name')" sortable="false" prop="name" width="100">
            <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                {{value}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="collumns.lastname" [name]="getTranslation('lastName')" sortable="false" prop="lastname" width="100">
            <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                {{value}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="collumns.initiationDate" [name]="getTranslation('initiationDate')" sortable="false" prop="initiation_date"
                              width="100">
            <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                {{value  | date : 'dd.MM.yyyy' : 'Europe/Belgrade' }}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="collumns.type" [name]="getTranslation('typeOfRequest')" sortable="false" prop="type" width="100">
            <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                {{getIssueOption(1, value)?.name}} <span *ngIf="value == 3" style="cursor: pointer; margin-left: 5px"
                                                         (click)="openMore(row)">(<span
                    style="text-decoration: underline">{{'seeMore' | translate}}</span>)</span>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="collumns.status" [name]="getTranslation('status')" sortable="false" prop="status" width="100">
            <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                <mat-select [(value)]="row.status" class="select-field" style="margin-bottom: 10px"
                            (selectionChange)="updateIssue(row)">
                    <mat-option *ngFor="let t of issueOptions[2]" class="select-field"
                                [value]="t.id">{{t.name}}</mat-option>
                </mat-select>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="collumns.resolutionDate" [name]="getTranslation('resolutionDate')" sortable="false" prop="resolution_date"
                              width="100">
            <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                <input class="editable-input" readonly [autofocus]="false" style="cursor: auto;"
                       [matDatepicker]="resolutionDate" matInput
                       formControlName="startDate" [value]="value" (focus)="resolutionDate.open()"
                       (dateChange)="changedResolutionDate($event.value, row)">
                <mat-datepicker #resolutionDate></mat-datepicker>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="collumns.resolution" [name]="getTranslation('resolutionType')" sortable="false" prop="resolution_type"
                              width="100">
            <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
                <mat-select [(value)]="row.resolution_type" class="select-field" style="margin-bottom: 10px"
                            (selectionChange)="updateIssue(row)">
                    <mat-option *ngFor="let t of issueOptions[3]" [value]="t.id">{{t.name}}</mat-option>
                </mat-select>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>

</div>
