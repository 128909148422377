<div style="max-width: 250px; width: 100%">
  <h2 matDialogTitle>{{title}}</h2>
  <div style="margin-bottom: 8px">
    <span>{{message}}</span>
    <mat-form-field *ngIf="country == 'eu' || country == 'hu'" style="margin-top: 15px;">
      <input matInput [matDatepicker]="pickerFrom" placeholder="{{'pickupDate' | translate}}" [(ngModel)]="dateTime"
             (dateChange)="changedDate()">
      <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
      <mat-datepicker #pickerFrom></mat-datepicker>
    </mat-form-field>
  </div>
  <mat-dialog-actions>
    <div class="col">
      <button mat-raised-button (click)="onCancel($event)">Prekini</button>
    </div>
    <div class="col" style="text-align: right">
      <button mat-raised-button color=primary (click)="onConfirm($event)" style="right: 0">{{button}}</button>
    </div>
  </mat-dialog-actions>
</div>
