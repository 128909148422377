
<div class="header" style="background-color: #d92a73" >
    <span   style="font-size:1.7em; color:white; padding-left: 20px; font-weight: 500; ">
      {{'marketingStatistics' | translate}}
    </span>
</div>
<div class="container">
  <div class="miniHeader">
    <mat-form-field style="margin-top: 15px; margin-right: 10px; float:right;">
      <input matInput [matDatepicker]="pickerFrom" placeholder="{{'date' | translate}}" [(ngModel)]="date" (dateChange)="changedDate()" >
      <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
      <mat-datepicker #pickerFrom></mat-datepicker>
    </mat-form-field>
    <p style="float:unset; clear: both;"></p>
  </div>
  <div class="forSpinner" *ngIf="loading">
    <div class="forSpinnerSpecific">
    <mat-spinner diameter="50"></mat-spinner>
    </div>
  </div>
  <div class="statistics row" *ngIf="!loading">
    <div [ngClass]="{'changed' : changed}" class="block mat-card col-md-3">
        <span style="color:grey; text-align: left !important;">{{"ordersCount" | translate}} </span>
        <div  style="text-align: center !important;  position: relative; top: 25%;">
          <span style="font-size: 30px !important;">{{ordersCount}} </span>
        </div>
      </div>

    <div [ngClass]="{'changed' : changed}" class="block mat-card col-md-3">
        <span style="color:grey; text-align: left !important;">{{"traffic" | translate}} </span>
        <div  style="text-align: center !important;  position: relative; top: 25%;">
          <span style="font-size: 30px !important;">{{traffic}} €</span>
        </div>
    </div>
    <div [ngClass]="{'changed' : changed}" class="block mat-card col-md-3">
        <span  style="color:grey; text-align: left !important;">{{"usersCount" | translate}} </span>
        <div  style="text-align: center !important;  position: relative; top: 25%;">
          <span style="font-size: 30px !important;">{{usersCount}} </span>
        </div>
  </div>
</div>

