<div class="statistics-box-data" *ngIf="!loading">
  <canvas baseChart height="200"
          [datasets]="data"
          [labels]="labels"
          [legend]="true"
          [options]="options"
          [chartType]="'pie'">

  </canvas>
</div>
<app-loading height="462px"  *ngIf="loading"></app-loading>
