import { Pipe, PipeTransform} from '@angular/core';

// Pipe that transforms this format '2019-07-27 10:59:57'  into DD/MM/YYYY 27/07/2019
@Pipe({
  name: 'DMYPipe'
})
export class DMYPipe implements PipeTransform{
  transform(prop?: string):string {
    //return if prop is undefined - case where there is no date value
    if(!prop)
      return;
    const tmpArr = prop.split(' ')[0].split('-');
    return `${tmpArr[2]} / ${tmpArr[1]} / ${tmpArr[0]}`;
  }
}
