import {Component, Input, OnInit} from '@angular/core';
import {Observable} from "rxjs/Observable";
import {ApiService} from "../../../api.service";
import {TranslateService} from "@ngx-translate/core";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-albums',
  templateUrl: './albums.component.html',
  styleUrls: ['./albums.component.css']
})
export class AlbumsComponent implements OnInit {
  @Input() start;
  @Input() end;
  @Input() datesChanged: Observable<void>;
  @Input() refreshData: Observable<void>;

  loading = false;
  labels = [];
  data = [{}];
  numbers = [];
  total = '';
  products = [];
  totalPrice = null;

  stackedBar: any = {
    responsive: false,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        stacked: true
      }],
      yAxes: [{
        stacked: true,
        display: true,
        min: 0,
        ticks: {
          min: 0,
          beginAtZero: true
        }
      }]
    }
  };

  constructor(private service: ApiService, public translate: TranslateService, private datePipe: DatePipe) {
  }

  ngOnInit() {
    this.getAlbumsStatistics();
    this.datesChanged.subscribe((data: any) => {
      this.start = data.start;
      this.end = data.end;
      this.getAlbumsStatistics();
    });
  }

  getAlbumsStatistics() {
    this.loading = true;
    const start = this.datePipe.transform(this.start, 'yyyy-MM-dd');
    const end = this.datePipe.transform(this.end, 'yyyy-MM-dd');
    this.service.getAlbumsStatistics(start, end).subscribe((apiData: any) => {
      console.log("orders: ", apiData);
      this.data = apiData.result;
      this.labels = apiData.labels;
      this.total = apiData.total;
      this.totalPrice = apiData.totalPrice;
      this.products = apiData.allProducts;
      this.loading = false;
    })
  }


}
