<section>
  <h3 (click)="closeDialog(null)">{{'updateTemporalPrice' | translate}}</h3>
  <hr/>
  <form [formGroup]="temporalGroup">
    <mat-card-content class="content">
      <div>
        <mat-form-field class="width">
          <mat-label>{{'product' | translate}}</mat-label>
          <mat-select formControlName="name">
            <mat-option *ngFor="let name of names" [value]="name">{{name}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="width">
          <mat-label>{{'price' | translate}}</mat-label>
          <input matInput type="number" formControlName="price">
        </mat-form-field>

        <mat-form-field class="width">
          <mat-label>{{'currency' | translate}}</mat-label>
          <mat-select formControlName="currency">
            <mat-option *ngFor="let val of countriesAndCurrency" [value]="val.currency">{{val.currency}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="width">
          <mat-label>{{'country' | translate}}</mat-label>
          <mat-select formControlName="country">
            <mat-option *ngFor="let val of countriesAndCurrency" [value]="val.code">{{val.country}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="width">
          <mat-label>{{'info' | translate}}</mat-label>
          <input matInput formControlName="info">
        </mat-form-field>

        <mat-form-field class="width">
          <mat-label>{{'startDate' | translate}}</mat-label>
          <input readonly style="cursor: auto;" [matDatepicker]="basicDatepickerStart" matInput
                 formControlName="startDate"  (focus)="basicDatepickerStart.open()">
          <mat-icon matSuffix (click)="basicDatepickerStart.open()">today</mat-icon>
          <mat-datepicker #basicDatepickerStart></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="width">
          <mat-label>{{'endDate' | translate}}</mat-label>
          <input readonly style="cursor: auto;" [matDatepicker]="basicDatepickerEnd" matInput
                 formControlName="endDate" (focus)="basicDatepickerEnd.open()">
          <mat-icon matSuffix (click)="basicDatepickerEnd.open()">today</mat-icon>
          <mat-datepicker #basicDatepickerEnd></mat-datepicker>
        </mat-form-field>
      </div>
    </mat-card-content>
    <div class="buttons">
      <button mat-raised-button color="accent" style="margin-right: 15px; width: 120px;outline: none"
              (click)="closeDialog(null)">
        {{'cancel' | translate}}
      </button>
      <button mat-raised-button color="primary" style="width: 120px;outline: none" (click)="save()">{{'save' | translate}}
      </button>
    </div>

  </form>
</section>


