import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ApiService} from "../../api.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Colors} from "../../Colors";
import {TranslateService} from "@ngx-translate/core";
import {DataService} from "../../data-service.service";

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.css']
})
export class CampaignComponent implements OnInit {
  audiences: any[];
  showHeader = true;
  mode = 1;
  balance = '';
  approximateCost = '';
  moneySpent = '';
  buttons = [];
  title: '';
  modes = [
    {
      id: 1, name: 'email'
    },
    {
      id: 2, name: 'notifications'
    },
    {
      id: 3, name: 'viber'
    },];
  totalCount = 0;
  name = '';
  text = '';
  startDate = null;
  image = null;
  imagePath = null;
  colors = Colors;
  audienceIds = '';



  constructor(private route: ActivatedRoute,
              private service: ApiService,
              private snackBar: MatSnackBar,
              private router: Router,
              private translate: TranslateService,
              private dataService: DataService) {
  }

  ngOnInit() {
    this.audienceIds = this.route.snapshot.paramMap.get('ids');
    this.getAudiences(this.audienceIds.split(","));
    this.getBalance();
    this.getEmailButtons();

    this.dataService.country.subscribe((country) => {
      this.getApproximateCost(this.totalCount);
    });
  }

  getAudiences(ids) {
    this.service.getAudiencesByIds(ids).subscribe((data: any[]) => {
      this.audiences = data;
      this.audiences.map((audience) => {
        audience.userCount = audience.user_ids.split(",").length;
        this.totalCount = this.totalCount + audience.userCount;
        return audience;
      })

      this.getApproximateCost(this.totalCount);
      this.getMoneySpent();

    })
  }

  getBalance() {
    this.service.getInfoBipBalance().subscribe((data: any) => {
      this.balance = data.balance;
    });
  }

  getApproximateCost(audience) {
    this.approximateCost = '/'
    this.service.getInfoApproximateCost(this.mode, audience).subscribe((data: any) => {
      this.approximateCost = data.approximateCost;
    });
  }

  getMoneySpent() {
    this.moneySpent = '/'
    this.service.getInfoCampaignMoneySpent().subscribe((data: any) => {
      this.moneySpent = data.moneySpent;
    });
  }


  getEmailButtons() {
    this.service.getEmailButtons().subscribe((data: any[]) => {
      this.buttons = data;
    });
  }

  changeMode(mode) {
    this.mode = mode;
  }

  savedCampaign() {
    this.router.navigate(['main', 'campaigns']);
  }

  openUsers() {
    this.router.navigate([`main`, 'users']);
  }


}
