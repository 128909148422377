import {Component, OnDestroy, OnInit, Pipe, PipeTransform} from "@angular/core";
import {ApiService} from "../api.service";
import {Admin} from "../models";
import {Router} from "@angular/router";
import {DataService} from "../data-service.service";
import {TranslateService} from "@ngx-translate/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MessagingService} from "../shared/messaging.service";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-sectors',
  templateUrl: './sectors.component.html',
  styleUrls: ['./sectors.component.css'],
  providers: [ApiService]
})
export class SectorsComponent implements OnInit {
  showHeader = true;
  currentPage = 0;
  user: Admin;
  sectors = [];
  employees = [];
  filterName = '';
  filterPhone = '';
  filterEmail = '';
  filterPosition = '';

  constructor(private sanitizer: DomSanitizer, private router: Router, private service: ApiService, private dataService: DataService, private translate: TranslateService, private _snackBar: MatSnackBar, private messagingService: MessagingService) {
  }


  ngOnInit() {
    window.addEventListener('scroll', this.scroll, true);
    this.user = JSON.parse(localStorage.getItem('adminYolo'));
    this.service.sectorsList().subscribe(
      data => {
        const s = [];
        s['sectors'] = data;
        this.sectors = s['sectors'];
      }
    )
    console.log('sectors', this.sectors);
  }


  toTop(id) {
    document.getElementById(id).scrollIntoView({behavior: "smooth", block: "start"});
  }

  onInViewportChange(type, event) {
    if (event == true) {
      this.currentPage = type;
      console.log(type);
      this.toCenter(type + 'nav');
    }
  }

  toCenter(id) {
    if (document.getElementById(id) != null) {
      document.getElementById(id).scrollIntoView({inline: 'center', behavior: "smooth"});
    }
  }

  scroll = (): void => {
    if (window.pageYOffset > 110) {
      this.showHeader = false;
    } else {
      this.showHeader = true;
    }
  };

  sanatizeUrl(url) {
    return <string>this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Pipe({name: 'safe'})
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
